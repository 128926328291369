/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type { EntityModelOffer } from '@SLR/solution3-sdk';
import { Button } from '@mui/material';
import AssignmentAddIcon from './assignment-add-icon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useBooking } from 'context/booking';
import { getTextIn } from 'localization';
import { convertSearchToQueryParams, useUrlParams } from 'utils/url-param';
import { SEARCH_PARAMS_CONVERSION_TABLE } from 'pages/offer/offers-page';

const getOfferText = getTextIn('offer-booking');

const AddBookingListButton = ({
  offer,
  isBookingEnabled
}: {
  offer: EntityModelOffer;
  isBookingEnabled?: boolean;
}) => {
  const [search] = useUrlParams();
  const {
    addOfferToList,
    removeOfferFromList,
    offers: bookingList
  } = useBooking();
  const isOnList = bookingList.some((ofr) => ofr.id === offer.id);

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  return isOnList ? (
    <Button
      disabled={!isBookingEnabled}
      variant="contained"
      size="small"
      data-cy="offer-on-bookinglist-button"
      onClick={() => {
        removeOfferFromList(offer.id);
      }}
      startIcon={<AssignmentTurnedInIcon />}
    >
      {getOfferText('added')}
    </Button>
  ) : (
    <Button
      disabled={!isBookingEnabled}
      variant="outlined"
      size="small"
      data-cy="offer-add-to-bookinglist-button"
      onClick={() => {
        addOfferToList(offer, queryParams);
      }}
      startIcon={<AssignmentAddIcon />}
    >
      {getOfferText('toBookingList')}
    </Button>
  );
};

export default AddBookingListButton;
