/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ElementType } from 'react';
import { Button, CircularProgress, lighten, useTheme } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button';

type LoadingButtonProps = {
  dataCy: string;
  label: string;
  loading?: boolean;
  component?: ElementType;
};

const LoadingButton = ({
  dataCy,
  label,
  variant,
  size = 'medium',
  color = 'primary',
  loading = false,
  disabled = false,
  startIcon,
  endIcon,
  sx,
  onClick,
  component = 'button'
}: ButtonProps & LoadingButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      data-cy={`${dataCy}-loadingbutton`}
      variant={variant}
      size={size}
      color={color}
      disabled={disabled || loading}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={[
        {
          bgcolor:
            disabled || loading
              ? `${lighten(theme.palette.primary.main, 0.9)} !important`
              : !variant /* `contained` is the default value */ ||
                variant === 'contained'
              ? color
              : 'unset'
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      component={component}
    >
      {label}
      {loading && (
        <CircularProgress
          data-cy={`${dataCy}-loadingbutton-progress`}
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            mt: '-12px',
            ml: '-12px'
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
