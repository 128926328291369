/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  TextField
} from '@mui/material';
import {
  type EntityModelGeoArea,
  type EntityModelKeyword
} from '@SLR/solution3-sdk';
import LoadingSpinner from './loading-spinner';
import { getText } from 'localization';

const getLoadingInputProps = (
  params: AutocompleteRenderInputParams,
  isFetching: boolean
) => {
  return {
    ...params.InputProps,
    endAdornment: (
      <>
        {isFetching && (
          <InputAdornment position="end">
            <LoadingSpinner dataCy="autocomplete" size={20} />
          </InputAdornment>
        )}
        {params.InputProps.endAdornment}
      </>
    )
  };
};

type Props<E> = {
  label: string;
  query: string;
  selectableOptions: E[];
  isFetching: boolean;
  error?: boolean;
  errorMessage?: string;
  onAccept: VoidFunction;
  onQueryChanged: (query: string) => void;
  selectedOptionName: string;
  onOptionSelected?: (option?: E) => void;
  onOptionSelectedNew?: (option?: E | string) => void;
};

const SearchFieldAutocomplete = <
  E extends EntityModelGeoArea | EntityModelKeyword
>({
  label,
  query,
  selectableOptions,
  isFetching,
  error,
  errorMessage = '',
  onQueryChanged,
  onAccept,
  selectedOptionName,
  onOptionSelected,
  onOptionSelectedNew
}: Props<E>) => {
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: E | string | null) => {
      if (value) {
        if (onOptionSelected && typeof value !== 'string') {
          onOptionSelected(value);
        }

        if (onOptionSelectedNew) {
          onOptionSelectedNew(value);
        }

        setSearchIsOpen(false);
      }
    },
    [onOptionSelected, onOptionSelectedNew]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        event.defaultPrevented = true;
        onAccept();
      }
    },
    [onAccept]
  );

  const handleBlur = useCallback(() => setSearchIsOpen(false), []);

  const onInputChange = (
    _: React.SyntheticEvent<Element, Event>,
    input: string
  ) => {
    onQueryChanged(input);

    // remove a former selected option, if the input has changed (does not match the option name)
    if (selectedOptionName !== input) {
      onOptionSelected?.(undefined);
      onOptionSelectedNew?.(input);
    }

    setSearchIsOpen(input.length > 2);
  };

  return (
    <Autocomplete
      freeSolo
      inputValue={query}
      onChange={handleChange}
      onInputChange={onInputChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      options={selectableOptions}
      getOptionLabel={(option: E | string) =>
        typeof option === 'string' ? '' : option.name
      }
      renderOption={(props, option: E) => (
        <Box
          {...props}
          component="li"
          key={option.id}
          data-cy={`autocomplete-${option.name}`}
        >
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          error={error}
          helperText={errorMessage}
          label={label}
          InputProps={getLoadingInputProps(params, isFetching)}
        />
      )}
      open={searchIsOpen}
      handleHomeEndKeys
      selectOnFocus
      noOptionsText={getText('noMatchingOptions')}
      sx={{ flexBasis: '90%' }}
      data-cy="geoareasearch"
    />
  );
};

export default SearchFieldAutocomplete;
export { getLoadingInputProps };
