/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EmojiEmotionsTwoToneIcon from '@mui/icons-material/EmojiEmotionsTwoTone';
import { getNode, getTextIn } from 'localization';
import FeedbackScreen from './feedback-screen';
import { useGetPortalOrganizationProfileHref } from 'context/user';
import { useProject } from 'context/project';

const getInvitationText = getTextIn('invitation');

export type OrganizationScreenProps = {
  organizationName: string;
  organizationId: string;
};

const WaitOrganizationApprovalScreen = ({
  organizationName,
  organizationId
}: OrganizationScreenProps) => {
  const { project } = useProject();
  const icon = <EmojiEmotionsTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = (
    <Typography component="span">
      {getNode(
        'getNodeWaitText',
        'invitation'
      )({
        link: (
          <Link
            to={useGetPortalOrganizationProfileHref(organizationId)}
            component={RouterLink}
            sx={{
              fontWeight: 600
            }}
          >
            {organizationName}
          </Link>
        ),
        projectName: project?.name
      })}
    </Typography>
  );

  return (
    <FeedbackScreen
      title={getInvitationText('soon')}
      icon={icon}
      text={text}
      hasHomePageButton={false}
      dataCy="waitfororganizationapprovalscreen"
    />
  );
};

export default WaitOrganizationApprovalScreen;
