/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  useSchemaValidationFormContext,
  ValidatedTextField
} from 'feature/forms';
import { getTextIn, required } from 'localization';
import { Offer } from './model';

import {
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField
} from '@mui/material';
import {
  type EntityModelCategory,
  type EntityModelTargetGroup
} from '@SLR/solution3-sdk';
import { useListSegments } from 'feature/hooks';
import {
  CATEGORIES,
  TARGET_GROUPS,
  type KeyCheckboxParam
} from 'utils/url-param';

const getOfferText = getTextIn('offer-details');

const CATEGORY_ID = 'categoryId';
const TARGET_GROUP_ID = 'targetGroupId';

const ids = {
  [CATEGORIES]: CATEGORY_ID,
  [TARGET_GROUPS]: TARGET_GROUP_ID
};

const textKeys = {
  [CATEGORIES]: 'category',
  [TARGET_GROUPS]: 'targetGroup'
};

type IdTypes = typeof CATEGORY_ID | typeof TARGET_GROUP_ID;

const SegmentsForm = ({ segmentType }: { segmentType: KeyCheckboxParam }) => {
  const { data } = useListSegments(segmentType);
  const segments = data ?? [];
  const segmentId = ids[segmentType];

  const { setValueValidating } = useSchemaValidationFormContext<Offer>();

  return (
    <ValidatedTextField
      name={segmentId}
      render={({ field, props }) => {
        const { errorMessage, error } = props;
        return (
          <FormControl error={error}>
            <Autocomplete
              disableClearable
              getOptionLabel={(segment) => segment.name}
              // Note: when disableClearable is used, value is not allowed to be null, but it is needed for the initial case
              // eslint-disable-next-line
              // @ts-ignore
              value={
                segments?.find((segment) => segment.id === field.value) ?? null
              }
              onChange={(
                _,
                newValue: EntityModelTargetGroup | EntityModelCategory | null
              ) => {
                if (newValue)
                  setValueValidating(segmentId as IdTypes, newValue.id);
              }}
              options={segments}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${required(getOfferText(textKeys[segmentType]))}`}
                  placeholder={getOfferText('pleaseSelect')}
                />
              )}
            />
            <FormHelperText sx={{ m: 0 }}>{errorMessage}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default SegmentsForm;
