/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  SearchParams,
  getTrimmedSearchTerm,
  type SetSearchParamWithPageSetback,
  type UrlParams
} from 'utils/url-param';

type SearchBarFilterProps = {
  search: UrlParams;
  setSearchParamWithPageSetback: SetSearchParamWithPageSetback;
  placeholderText: string;
  helperText?: string;
  fullWidth?: boolean;
  queryKey?: 'query';
};

const SearchBarFilter = ({
  search,
  setSearchParamWithPageSetback,
  placeholderText,
  helperText,
  fullWidth = false,
  queryKey = 'query'
}: SearchBarFilterProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const passedQueryTerm = search[SearchParams[queryKey]] ?? '';

  const [searchTerm, setSearchTerm] = useState(passedQueryTerm);

  // necessary when the navigation comes from the search bar
  useEffect(() => {
    setSearchTerm(passedQueryTerm);
  }, [passedQueryTerm]);

  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const trimmedValue = value.trim();

    setSearchTerm(value);

    if (trimmedValue === '') {
      setSearchParamWithPageSetback({
        [SearchParams[queryKey]]: trimmedValue
      });
    }
  };

  const handleSearchClick = () => {
    const trimmedSearchTerm = getTrimmedSearchTerm(searchTerm);

    if (trimmedSearchTerm) {
      setSearchParamWithPageSetback({
        [SearchParams[queryKey]]: trimmedSearchTerm
      });

      // force blur to close a virtual keyboard
      setTimeout(() => ref.current?.blur(), 0);
    }
  };

  const handleClearSearchClick = () => {
    if (passedQueryTerm) {
      setSearchParamWithPageSetback({ [SearchParams[queryKey]]: '' });
    } else {
      setSearchTerm('');
    }
  };

  return (
    <TextField
      inputRef={ref}
      sx={{
        width: { xs: '100%', md: fullWidth ? '100%' : '70%' },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: 'secondary.main'
          }
        },
        '& .MuiOutlinedInput-root': {
          height: 42
        }
      }}
      variant="outlined"
      placeholder={placeholderText}
      color="secondary"
      value={searchTerm}
      onChange={handleChangeSearchTerm}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleSearchClick();
        }
        if (event.key === 'Escape') {
          handleClearSearchClick();
        }
      }}
      helperText={helperText}
      inputProps={{ inputMode: 'search' }}
      InputProps={{
        sx: {
          pr: '0 !important',
          borderRadius: 1.75,
          '& input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }
        },
        endAdornment: (
          <>
            {searchTerm && (
              <IconButton
                aria-label="clear search input"
                onClick={handleClearSearchClick}
              >
                <CancelRoundedIcon sx={{ color: 'secondary.main' }} />
              </IconButton>
            )}
            <Button
              aria-label="search"
              onClick={handleSearchClick}
              variant="contained"
              color="secondary"
              disableElevation
              sx={{
                alignItems: 'center',
                borderRadius: '0 7px 7px 0',
                ml: 0.5,
                width: 5.25,
                minWidth: 'unset',
                height: 42
              }}
            >
              <SearchIcon fontSize="medium" />
            </Button>
          </>
        )
      }}
    />
  );
};

export default SearchBarFilter;
