/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MouseEvent, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, MenuItem, Link, Box, IconButton } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { ShowProtectedLink } from 'routes';
import { ItemText, SearchBar } from 'feature';
import type { NavigationLink } from './types';
import { getText } from 'localization';

type NavMenuProps = {
  mainRoute: string;
  navigationLinks: NavigationLink[];
  topNavigationLinks: NavigationLink[];
};

const NavMenu = ({
  mainRoute,
  navigationLinks,
  topNavigationLinks
}: NavMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const open = Boolean(anchorEl);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAndBlurMenuButton = () => {
    handleClose();
    // get the button blur out of the stack
    setTimeout(() => menuButtonRef?.current?.blur(), 0);
  };

  const Icon = open ? CloseIcon : MenuIcon;

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <IconButton
        ref={menuButtonRef}
        aria-label="toggle-navigation-menu"
        onClick={toggleMenu}
        color="primary"
      >
        <Icon sx={{ fontSize: { xs: 36, sm: 40 } }} />
      </IconButton>
      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        sx={{ height: { xs: `calc(100dvh + 24px)`, sm: 'unset' } }}
        slotProps={{
          paper: {
            sx: {
              display: { md: 'none' },
              width: { xs: '75vw', sm: '50vw' },
              height: { xs: '100dvh', sm: 'auto' },
              mt: { xs: 2, sm: 1 },
              ml: { xs: -2, sm: -3.625 },
              borderRadius: 0,
              bgcolor: 'primary.main',
              clipPath: 'inset(0px -16px -20px -5px)',
              boxShadow: '6px 3px 6px #00000029',
              '.MuiMenu-list': {
                bgcolor: 'white',
                pt: { xs: 2.25, sm: 3.75 },
                pb: { xs: 0, sm: 3.75 }
              }
            }
          }
        }}
      >
        {navigationLinks.map(({ path, title, protect }) => {
          const isSelected = mainRoute === path;

          const showProtectedLink = ShowProtectedLink({
            protect
          });

          return (
            showProtectedLink && (
              <MenuItem
                key={`${title}-${path}`}
                disableRipple
                onClick={handleClose}
                sx={{
                  height: { xs: 50, sm: 60 },
                  p: 0
                }}
              >
                <Link
                  data-cy={`navigationLinksMobile-mainMobile-${mainRoute}`}
                  component={RouterLink}
                  to={path}
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    width: '100%',
                    height: '100%',
                    px: { xs: 3.75, sm: 5.25 },
                    py: 0.75,
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                  >
                    <ItemText
                      text={title}
                      color={isSelected ? 'secondary.main' : 'primary.main'}
                      fontSize={{ xs: 16, sm: 18 }}
                    />

                    {isSelected && (
                      <Box
                        sx={{
                          height: 6,
                          width: '100%',
                          mt: 0.25,
                          mb: 0.5,
                          bgcolor: 'secondary.main'
                        }}
                      />
                    )}
                  </div>
                </Link>
              </MenuItem>
            )
          );
        })}

        {/* xs specific "topbar" navigation */}
        <Box
          component="li"
          // diables menu item selecting by typing in search field
          onKeyDown={(event) => event.stopPropagation()}
          sx={{ display: { xs: 'block', sm: 'none' }, mt: 3 }}
        >
          <Box
            sx={{
              px: 4,
              pt: 2.5,
              height: 80,
              bgcolor: 'primary.main',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <SearchBar
              callback={handleCloseAndBlurMenuButton}
              placeholder={getText('navBarSearchBarPlaceholder')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: 48,
              bgcolor: 'primary.main',
              justifyContent: 'space-around',
              alignItems: 'center',
              font: '14px/16px Roboto'
            }}
          >
            {topNavigationLinks?.map(({ title, path, protect }) => {
              const isSelected = mainRoute === path.split('/')[0];

              const showProtectedLink = ShowProtectedLink({
                protect
              });

              return (
                showProtectedLink && (
                  <Link
                    key={`${title}-${path}`}
                    data-cy={`navigationLinks-topMobile-${mainRoute}`}
                    component={RouterLink}
                    to={path}
                    onClick={handleClose}
                    color={
                      isSelected ? 'secondary.main' : 'primary.contrastText'
                    }
                    sx={{
                      display: 'flex',
                      fontWeight: isSelected ? 'bold' : '',
                      textDecoration: 'none',
                      width: '33%'
                    }}
                  >
                    {title}
                  </Link>
                )
              );
            })}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default NavMenu;
