/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  type CreateOfferRequest,
  type DeleteOfferRequest,
  type GetOfferRequest,
  type ListLocationsRequest,
  type ListOffersRequest,
  OffersApi,
  type UpdateOfferRequest,
  type ListBookableOffersRequest
} from '@SLR/solution3-sdk';
import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';

const OFFERS_LIST_KEY = 'offerList';
const OFFER_KEY = 'offer';
const OFFER_LOCATIONS_KEY = 'offerLocations';

const useOffersApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.offers) as OffersApi;
  }, []);

  const getOffers = useCallback(
    async (requestParameters: ListOffersRequest) => {
      try {
        return await apiInstance.listOffers(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getBookableOffers = useCallback(
    async (requestParameters: ListBookableOffersRequest) => {
      try {
        return await apiInstance.listBookableOffers(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getOffer = useCallback(
    async (requestParameters: GetOfferRequest) => {
      try {
        return await apiInstance.getOffer(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const listOfferLocations = useCallback(
    async (requestParameters: ListLocationsRequest) => {
      try {
        return await apiInstance.listLocations(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createOffer = useCallback(
    async (requestParameters: CreateOfferRequest) => {
      try {
        return await apiInstance.createOffer(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateOffer = useCallback(
    async (requestParameters: UpdateOfferRequest) => {
      try {
        return await apiInstance.updateOffer(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteOffer = useCallback(
    async (requestParameters: DeleteOfferRequest) => {
      try {
        return await apiInstance.deleteOffer(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getOffers,
    getBookableOffers,
    getOffer,
    listOfferLocations,
    createOffer,
    updateOffer,
    deleteOffer
  };
};

export default useOffersApi;
export { OFFERS_LIST_KEY, OFFER_KEY, OFFER_LOCATIONS_KEY };
