/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useNavigate } from 'react-router-dom';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { useDeleteArticle } from 'feature/hooks/compass';
import { getTextIn, getPath } from 'localization';

const getTextCompass = getTextIn('compass');

const useDeleteArticleMutate = ({
  id,
  handleClose,
  categoryId
}: {
  id: string;
  handleClose: VoidFunction;
  categoryId: string;
}) => {
  const navigate = useNavigate();
  const deleteArticle = useDeleteArticle();

  const onDeleteConfirm = () => {
    if (id) {
      deleteArticle.mutate(id, {
        onSuccess: () => {
          notifyMutationSuccess(getTextCompass('deleteArticleSuccess'));
          navigate(`/${getPath('compass')}/${categoryId}`, {
            replace: true
          });
        },
        onError: () => {
          notifyMutationError(getTextCompass('deleteArticleError'));
        }
      });
    }

    handleClose();
  };

  return { onDeleteConfirm };
};

export default useDeleteArticleMutate;
