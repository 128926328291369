/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { type ArticleCreate, type ArticleUpdate } from '@SLR/solution3-sdk';
import { ContentWrapper, SanitizedHTML } from 'feature';
import { TextEditor } from 'feature/editor';
import {
  ActionsPanel,
  SchemaProvider,
  ValidatedHtmlEditor,
  ValidatedTextField
} from 'feature/forms';
import SubFormWrapper from 'feature/forms/subform-wrapper';
import { getText, getTextIn, required } from 'localization';
import ArticleFormCategoryField from './article-form-category-field';
import ArticleKeywords from './article-keywords';
import { useStyleOverrides } from './hooks';
import { ArticleSchema, CONTENT, TITLE } from './model';

const getTextCompass = getTextIn('compass');

interface Props {
  categoryId: string;
  article: ArticleCreate;
  articleId?: string;
  inProgress?: boolean;
  onConfirm: (article: ArticleUpdate | ArticleCreate) => void;
  onCancel: VoidFunction;
  createMode?: boolean;
  editMode?: boolean;
}

const ArticleForm = ({
  categoryId,
  article,
  inProgress = false,
  onConfirm,
  onCancel,
  createMode = false,
  editMode = false
}: Props) => {
  const theme = useTheme();
  const { h2StyleOverrides } = useStyleOverrides();

  const readOnlyMode = !(createMode || editMode);

  return (
    <SchemaProvider<ArticleUpdate>
      defaultValues={article}
      values={article}
      schema={ArticleSchema}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <ContentWrapper additionalPadding={editMode ? 2 : 'unset'}>
              <Stack spacing={4} sx={{ position: 'relative' }}>
                {!readOnlyMode && (
                  <>
                    <ValidatedTextField
                      name={TITLE}
                      render={({ field, props }) => {
                        const { errorMessage, ...fieldProps } = props;
                        return (
                          <TextField
                            label={`${required(
                              getTextCompass('articleTitle')
                            )}`}
                            helperText={
                              errorMessage ??
                              getTextCompass('articleTitleHelperText')
                            }
                            inputProps={{
                              maxLength: 100,
                              'data-cy': 'articleform-title'
                            }}
                            {...field}
                            {...fieldProps}
                          />
                        );
                      }}
                    />

                    <ArticleFormCategoryField categoryId={categoryId} />
                  </>
                )}

                <ValidatedHtmlEditor
                  name={CONTENT}
                  render={({ field, props }) => {
                    const { error, errorMessage, content, onEditorChange } =
                      props;

                    return (
                      <FormControl>
                        {!readOnlyMode && (
                          <Typography variant="editorLabel">
                            {required(getTextCompass('articleContent'))}
                          </Typography>
                        )}

                        <Box
                          sx={{
                            '&.MuiBox-root .mce-content-body h2': {
                              ...h2StyleOverrides?.({
                                theme
                              }),
                              color: theme.palette.secondary.main
                            }
                          }}
                        >
                          {readOnlyMode ? (
                            <SanitizedHTML dirtyHTML={content} />
                          ) : (
                            <TextEditor
                              placeholderText={getTextCompass(
                                'articleContentPlaceholder'
                              )}
                              content={content}
                              onEditorChange={onEditorChange}
                              showError={error}
                              customFontSize="18px"
                              compassEdition
                              dataCy="articleform-content"
                              {...field}
                            />
                          )}

                          <FormHelperText error={error}>
                            {error ? errorMessage : ' '}
                          </FormHelperText>
                        </Box>
                      </FormControl>
                    );
                  }}
                />

                {!readOnlyMode && (
                  <FormControl sx={{ pb: 2.5 }}>
                    <Typography variant="editorLabel">
                      {getTextCompass('articleKeywords')}
                    </Typography>

                    <SubFormWrapper>
                      <ArticleKeywords />
                    </SubFormWrapper>
                  </FormControl>
                )}
              </Stack>

              {!readOnlyMode && (
                <FormControl>
                  <FormHelperText>{getText('requiredFields')}</FormHelperText>
                </FormControl>
              )}
            </ContentWrapper>

            {!readOnlyMode && (
              <ActionsPanel
                confirmLabel={
                  createMode ? getTextCompass('createArticle') : getText('save')
                }
                inProgress={inProgress}
                onConfirm={handleSubmit(onConfirm)}
                onCancel={onCancel}
              />
            )}
          </>
        );
      }}
    </SchemaProvider>
  );
};

export default ArticleForm;
