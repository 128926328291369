/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  Pagination,
  SearchBarFilter,
  SearchBarWrapper,
  usePageClick
} from 'feature';
import { useListArticles } from 'feature/hooks/compass';
import { getNode, getText } from 'localization';
import { useEffect, useState } from 'react';
import {
  SearchParams,
  convertSearchToQueryParams,
  useUrlParams
} from 'utils/url-param';
import ArticleGrid from './article-grid';

const QUERY = 'query';

const SEARCH_PARAMS_CONVERSION_TABLE = {
  [SearchParams.page]: 'page',
  [SearchParams.query]: QUERY
};

const SearchBarCompass = ({
  setIsSearchMode,
  showArticleHint = false
}: {
  setIsSearchMode: React.Dispatch<React.SetStateAction<boolean>>;
  showArticleHint?: boolean;
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [search, setSearchParamWithPageSetback] = useUrlParams();

  const hasQuery = search[QUERY] !== undefined;

  useEffect(() => {
    setIsSearchMode(hasQuery);
  }, [hasQuery, setIsSearchMode]);

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  const { page, query } = queryParams;

  const { data, isLoading, isError } = useListArticles({
    query,
    page,
    size: rowsPerPage,
    sort: ['desc']
  });

  const { handlePageClick, totalElements } = usePageClick({
    dataPage: data?.page,
    page,
    setSearchParamWithPageSetback
  });

  return (
    <Box sx={{ mb: 4 }}>
      <SearchBarWrapper>
        <SearchBarFilter
          search={search}
          setSearchParamWithPageSetback={setSearchParamWithPageSetback}
          placeholderText={getText('articlePlaceholder', 'searchBar')}
          fullWidth
        />
      </SearchBarWrapper>
      {hasQuery && showArticleHint && (
        <Typography paddingTop={{ xs: 0, sm: 1 }}>
          {getNode('getNodeArticleHint', 'compass')({})}
        </Typography>
      )}
      {hasQuery && (
        <>
          <Box sx={{ mt: 4 }}>
            <ArticleGrid
              isLoading={isLoading}
              isError={isError}
              articles={data?.embedded?.articles}
              noArticlesMsg={getNode(
                'getNodeNoArticlesForSearch',
                'compass'
              )({})}
            />
          </Box>

          <Pagination
            count={totalElements}
            page={page}
            setPage={handlePageClick}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default SearchBarCompass;
