/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import TimeFormBlock from './time/time-form-block';
import { EMPTY_DATE_FILTER } from './time/model';
import {
  SearchParams,
  type UrlParams,
  type OnClickDate,
  type OnRemoveFilter
} from 'utils/url-param';

type DateFiltersProps = {
  search: UrlParams;
  onClick: OnClickDate;
  onCancel: OnRemoveFilter;
};

const DateFilters = ({ search, onClick, onCancel }: DateFiltersProps) => {
  const timeRange = search[SearchParams.timeRange];
  const time = search[SearchParams.time];

  const [fromRange, toRange] = timeRange ?? [];

  const onRemoveEveryFilter = () => {
    if (timeRange) {
      onCancel(SearchParams.timeRange, '')();
    }

    if (time) {
      onCancel(SearchParams.time, '')();
    }
  };

  const dateFilter = { ...EMPTY_DATE_FILTER };

  if (fromRange) {
    dateFilter.rangeFrom = new Date(fromRange);
  }

  if (toRange) {
    dateFilter.rangeTo = new Date(toRange);
  }

  if (time) {
    dateFilter.time = new Date(time ?? '');
  }

  return (
    <TimeFormBlock
      defaultFilter={EMPTY_DATE_FILTER}
      dateFilter={dateFilter}
      onConfirm={onClick}
      onCancel={onRemoveEveryFilter}
    />
  );
};

export default DateFilters;
