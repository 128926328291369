/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PropsWithChildren } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import { Paper, type SxProps, type Theme } from '@mui/material';

type ContentWrapperProps = {
  additionalPadding?: boolean | BoxProps['pt'];
  breadcrumbOnly?: boolean;
  backgroundColor?: string;
  bgColorFullWidth?: boolean;
  additionalSx?: SxProps<Theme>;
};

/**
 * Special use cases:
 * @param additionalPadding This is used for the moment for the settings pages; depending on the design differences maybe also for other pages
 *    in the case a pt value is defined for the additionalPadding,
 *    this value will be applied as pt and the standard additional padding value (9) for pb
 * @param breadcrumbOnly This case is for a ContentWrapper for breadcrumbs only
 */
const ContentWrapper = ({
  additionalPadding = false,
  breadcrumbOnly = false,
  backgroundColor = 'transparent',
  bgColorFullWidth = false,
  additionalSx,
  children
}: PropsWithChildren<ContentWrapperProps>) => {
  return (
    <Paper
      square
      elevation={0}
      sx={{
        backgroundColor: bgColorFullWidth ? backgroundColor : 'unset',
        overflowWrap: 'break-word',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={[
          {
            px: { xs: 2, sm: 5, lg: 12 },
            pt: additionalPadding
              ? additionalPadding === true
                ? 9
                : additionalPadding
              : breadcrumbOnly
              ? 3
              : { xs: 2, lg: 3 },
            pb: additionalPadding ? 9 : breadcrumbOnly ? 1.5 : { xs: 4, lg: 6 },
            display: 'flex',
            flexDirection: 'column',
            maxWidth: { lg: 1440, xxl: 1600 },
            width: '100%',
            backgroundColor
          },
          ...(Array.isArray(additionalSx) ? additionalSx : [additionalSx])
        ]}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default ContentWrapper;
