/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useSearchParams } from 'react-router-dom';
import { CATEGORIES, TARGET_GROUPS, OTHER } from 'feature/hooks';
import { limitValueRangeWithStep } from 'utils/helper';
import { setSearchParamWithPageSetbackFor } from './helper';
import { GEO_AREA_STEP } from './config';

const SORT = 'sort';
const TIME_RANGE = 'timeRange';
const TIME = 'time';
const DURATION = 'duration';
const GEO_AREA = 'geoAreaId';
const LOCATION_TYPES = 'locationTypes';

const WITHOUT = {
  [CATEGORIES]: 'withoutCategory',
  [TARGET_GROUPS]: 'withoutTargetGroup'
};

enum FilterParamsCheckbox {
  locationTypes = 'locationTypes',
  categories = 'categories',
  targetGroups = 'targetGroups'
}

enum FilterParamsGeo {
  geoAreaId = 'geoAreaId',
  distance = 'distance'
}

enum FilterParamsDate {
  timeRange = 'timeRange',
  time = 'time'
}

enum FilterParamsDuration {
  duration = 'duration'
}

const FilterParams = {
  ...FilterParamsCheckbox,
  ...FilterParamsGeo,
  ...FilterParamsDate,
  ...FilterParamsDuration
};

enum NonFilterParams {
  page = 'page',
  query = 'query',
  sort = 'sort',
  loadFilter = 'loadFilter',
  subject = 'subject',
  body = 'body'
}

const SearchParams = { ...NonFilterParams, ...FilterParams };

// checks for different enums
enum FilterParamsGeoUnit {
  geoAreaId = 'geoAreaId'
}

enum FilterParamsSortUnit {
  sort = 'sort'
}

// for mobile use
const FilterAndSortUnitParams = {
  ...FilterParamsCheckbox,
  ...FilterParamsDate,
  ...FilterParamsDuration,
  ...FilterParamsGeoUnit,
  ...FilterParamsSortUnit
};

// generic definition of the conversion of the string url params to the specific type (to add: array)
const SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS = {
  boolean: (string = '') => string === 'true',
  number: (string = '') => (string ? Number(string) : undefined),
  array: (string = '') => (string ? string.split(',') : undefined),
  date: (string = '') => (string ? new Date(string) : undefined),
  dateArray: (string = '') =>
    string
      ? string
          .split(',')
          .map((dateString) => dateString && new Date(dateString))
      : undefined
};

// specific definition for offers
const conversionHashSearchParams = {
  [SearchParams.page]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.number,
  [SearchParams.locationTypes]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.array,
  [SearchParams.categories]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.array,
  [SearchParams.targetGroups]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.array,
  [SearchParams.distance]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.number,
  [SearchParams.time]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.date,
  [SearchParams.timeRange]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.dateArray,
  [SearchParams.duration]: SEARCH_PARAM_TYPE_PRESERVE_FUNCTIONS.array
};

type PreserveFunctionNumber = (value: string) => number | undefined;
type PreserveFunctionString = (value: string) => string;
type PreserveFunctionStringArray = (value: string) => string[] | undefined;
type PreserveFunctionDate = (value: string) => Date | undefined;
type PreserveFunctionDateArray = (
  value: string
) => Array<Date | string> | undefined;

type SearchParamsPreserveFunctions = {
  [SearchParams.query]?: PreserveFunctionString;
  [SearchParams.page]?: PreserveFunctionNumber;
  [SearchParams.locationTypes]?: PreserveFunctionStringArray;
  [SearchParams.categories]?: PreserveFunctionStringArray;
  [SearchParams.targetGroups]?: PreserveFunctionStringArray;
  [SearchParams.geoAreaId]?: PreserveFunctionString;
  [SearchParams.distance]?: PreserveFunctionNumber;
  [SearchParams.time]?: PreserveFunctionDate;
  [SearchParams.timeRange]?: PreserveFunctionDateArray;
  [SearchParams.duration]?: PreserveFunctionStringArray;
};

type ConversionFx<T> = (x: T) => T;
type ConversionFxNumber = ConversionFx<number>;

type ConversionFxSearchParams = {
  [SearchParams.distance]: ConversionFxNumber;
};

const distanceConversionFx: ConversionFxNumber = (keyValue) =>
  limitValueRangeWithStep({
    step: GEO_AREA_STEP,
    value: keyValue,
    always: true
  });

const conversionFxOffers = {
  [SearchParams.distance]: distanceConversionFx
};

function applyConversionFx(
  key: keyof SearchParamsPreserveFunctions,
  keyValue?: unknown,
  conversionFx?: ConversionFxSearchParams
): number | Date | undefined {
  let newKeyValue;

  if (keyValue && conversionFx) {
    if (key === SearchParams.distance && typeof keyValue === 'number') {
      const fx = conversionFx[key];
      newKeyValue = fx(keyValue);
    }
  }

  return newKeyValue;
}

// generic search params hook with type preserving
const useTypePreservedSearchParams = (
  urlSearchParamsPreserveFunctions: SearchParamsPreserveFunctions = {},
  conversionFx?: ConversionFxSearchParams
) => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));

  const typePreservedSearch = (
    Object.keys(urlSearchParamsPreserveFunctions) as Array<
      keyof typeof urlSearchParamsPreserveFunctions
    >
  ).reduce((acc, key) => {
    let keyValue = urlSearchParamsPreserveFunctions[key]?.(acc[key]);
    keyValue = applyConversionFx(key, keyValue, conversionFx) ?? keyValue;

    const newAcc = {
      ...acc,
      [key]: keyValue
    } as typeof searchAsObject;

    if (newAcc[key] === undefined) {
      delete newAcc[key];
    }

    return newAcc;
  }, searchAsObject);

  const setSearchParamWithPageSetback = setSearchParamWithPageSetbackFor(
    typePreservedSearch,
    setSearch
  );

  return [typePreservedSearch, setSearchParamWithPageSetback] as const;
};

// specific preloading for url params
const useUrlParams = () =>
  useTypePreservedSearchParams(conversionHashSearchParams, conversionFxOffers);

export {
  SORT,
  TIME_RANGE,
  TIME,
  DURATION,
  GEO_AREA,
  LOCATION_TYPES,
  CATEGORIES,
  TARGET_GROUPS,
  OTHER,
  WITHOUT,
  useUrlParams,
  SearchParams,
  NonFilterParams,
  FilterParams,
  FilterParamsCheckbox,
  FilterParamsDate,
  FilterParamsDuration,
  FilterParamsGeo,
  FilterAndSortUnitParams
};
