/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { CalendarView, ContentWrapper, PrivateKeyDialog } from 'feature';
import { Typography } from '@mui/material';
import { useSetDocumentTitle } from 'context/project';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { getText } from 'localization';
import useListEvents from 'feature/hooks/events/useListEvents';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useUser } from 'context/user';
import { useCrypto } from 'context/crypto';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from './invitation-landing-page';

const CalendarPage = () => {
  const query = useQueryParams();
  const eventStartQuery = query.get('eventStart');
  const navigate = useNavigate();
  const { perspective } = useUser();
  const { showPrivateKeyDialog } = useCrypto();
  const eventsTerm: string = getText('events');
  useSetDocumentTitle(eventsTerm);
  const seedDate = dayjs(eventStartQuery || undefined);
  const [currentRange, setCurrentRange] = useState({
    start: seedDate.startOf('week').toDate(),
    end: seedDate.endOf('week').toDate()
  });

  const encryptedEvents = useListEvents({
    from: currentRange.start,
    to: currentRange.end
  });

  const onRangeChange = (range: Date[]) => {
    setCurrentRange({ start: range[0], end: range[6] });
  };

  useEffect(() => {
    if (encryptedEvents.isError) {
      enqueueSnackbar(getText('fetchEventsError', 'calendar'), {
        variant: 'error'
      });
    }
  }, [encryptedEvents.isError]);

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1">{eventsTerm}</Typography>
      <CalendarView
        seedDate={seedDate.toDate()}
        events={encryptedEvents.data ?? []}
        onRangeChange={onRangeChange}
        isFetching={encryptedEvents.isFetching}
      />
      <PrivateKeyDialog
        showDialog={showPrivateKeyDialog}
        closeDialog={() => {
          navigate(-1);
        }}
        organizationId={perspective.id}
        isOrganizationAdmin={perspective.data?.canConfigureEncryption ?? false}
      />
    </ContentWrapper>
  );
};

export default CalendarPage;
