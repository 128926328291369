/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, Button, Stack } from '@mui/material';
import LoadingButton from 'components/loading-button';
import { getText } from 'localization';

export interface Props {
  confirmLabel: string;
  inProgress?: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
}

const ActionsPanel = ({
  confirmLabel,
  inProgress = false,
  onConfirm,
  onCancel
}: Props) => {
  return (
    <Box
      sx={{
        mt: 4,
        boxShadow: '0px -3px 6px #00000029'
      }}
    >
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row'
        }}
        spacing={{
          xs: 2,
          sm: 8
        }}
        alignContent="center"
        justifyContent="center"
        sx={{ p: 3 }}
      >
        <Button variant="text" onClick={onCancel} data-cy="actionspanel-cancel">
          {getText('cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          label={confirmLabel}
          disabled={inProgress}
          loading={inProgress}
          onClick={onConfirm}
          dataCy="actionspanel-confirm"
        />
      </Stack>
    </Box>
  );
};

export default ActionsPanel;
