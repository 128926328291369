/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import type { SxProps, Theme } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonIcon from '@mui/icons-material/Person';
import { useUser } from 'context/user';

type UserAvatarProps = {
  sx?: SxProps<Theme>;
};

const AvatarFallback = ({ isOrganization = false }) => {
  const Fallback = isOrganization ? CorporateFareIcon : PersonIcon;
  return (
    <Fallback
      sx={{
        color: 'primary.contrastText',
        height: '60%',
        width: '60%'
      }}
    />
  );
};

const UserAvatar = ({ sx }: UserAvatarProps) => {
  const { perspective } = useUser();
  const { image, isOrganization } = perspective;

  return (
    <AvatarGroup>
      <Avatar
        sx={{
          zIndex: 1,
          borderWidth: { xs: '0 !important', lg: '3px !important' },
          ...sx
        }}
        alt={image?.alternativeText}
        src={image?.urls.small}
      >
        <AvatarFallback isOrganization={isOrganization} />
      </Avatar>
    </AvatarGroup>
  );
};

export default UserAvatar;
export { AvatarFallback };
