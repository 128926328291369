/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { getTextIn } from 'localization';
import { Typography } from '@mui/material';
import { Loadable } from 'types';
import { HtmlContentSection, TextContentSection } from 'feature';
import { type EntityModelOffer } from '@SLR/solution3-sdk';
import AdditionalInformationSection from './additional-information-section';

const getOfferText = getTextIn('offer-details');

interface Props {
  offer?: EntityModelOffer;
}

const OfferView = ({ offer, loading = false }: Loadable<Props>) => {
  return (
    <>
      <HtmlContentSection
        title={
          <Typography component="h2" variant="sectionTitle" color="secondary">
            {getOfferText('description')}
          </Typography>
        }
        content={offer?.description}
        loading={loading}
        dataCyPostfix="description"
      />

      <TextContentSection
        title={
          <Typography
            component="h2"
            variant="sectionTitle"
            color="secondary"
            sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
          >
            {getOfferText('requirements')}
          </Typography>
        }
        content={offer?.requirements}
        hideEmpty
        loading={loading}
        dataCyPostfix="requirements"
      />

      <AdditionalInformationSection
        title={
          <Typography
            component="h2"
            variant="sectionTitle"
            color="secondary"
            sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
          >
            {getOfferText('additionalInformation')}
          </Typography>
        }
        offer={offer}
        hideEmpty
        loading={loading}
      />
    </>
  );
};

export default OfferView;
