/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type AnonymousMessage } from '@SLR/solution3-sdk';
import { getTextIn, getErrorText } from 'localization';
import { EMAIL_REGEX } from 'utils/helper';
import { ObjectSchema, boolean, object, string } from 'yup';

const getContactText = getTextIn('contact');

const MAX_SUBJECT_LENGTH = 200;
const MAX_CONTENT_LENGTH = 2000;

type ExtendedAnonymousMessage = AnonymousMessage & {
  privacyPolicy: boolean;
};

const EMPTY_ANONYMOUSMESSAGE: ExtendedAnonymousMessage = Object.freeze({
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  content: '',
  privacyPolicy: false
});

const AnonymousMessageSchema = (): ObjectSchema<ExtendedAnonymousMessage> =>
  object().shape({
    firstName: string().required().min(1).label(getContactText('firstName')),
    lastName: string().required().min(1).label(getContactText('lastName')),
    email: string()
      .required()
      .min(1)
      .matches(EMAIL_REGEX, { message: getErrorText('emailFormatError') })
      .label(getContactText('email')),
    subject: string()
      .required()
      .min(1)
      .max(MAX_SUBJECT_LENGTH)
      .label(getContactText('subject')),
    content: string()
      .required()
      .min(1)
      .max(MAX_CONTENT_LENGTH)
      .label(getContactText('content')),
    privacyPolicy: boolean().required().oneOf([true], '')
  });

export {
  AnonymousMessageSchema,
  EMPTY_ANONYMOUSMESSAGE,
  MAX_SUBJECT_LENGTH,
  MAX_CONTENT_LENGTH
};
export type { ExtendedAnonymousMessage };
