/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Skeleton, Typography } from '@mui/material';
import { ResponseError } from '@SLR/solution3-sdk';
import { getProviderProfileUrl } from 'routes';
import {
  GenericContentSection,
  DetailPageWrapper,
  ProviderProfileCard
} from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { HeroSection } from 'feature/info';
import {
  useDeleteOffer,
  useGetOffer,
  useGetOrganizationProfile
} from 'feature/hooks';
import { OfferView, OfferActions } from 'feature/offers';
import {
  RequestErrorFallback,
  getErrorMessage,
  notifyMutationError,
  notifyMutationSuccess
} from 'feature/error';
import { getTextIn, getPath } from 'localization';
import { useSetDocumentTitle } from 'context/project';

// the backend error message
const OFFER_HAS_EVENTS = 'Event still has upcoming events';

const getOfferText = getTextIn('offer');
const getSegmentsText = getTextIn('settings-segments');

const OfferPage = () => {
  const { offerId = '' } = useParams();
  const navigate = useNavigate();
  const getOffer = useGetOffer(offerId);
  const deleteOffer = useDeleteOffer(offerId);

  const offer = getOffer.data;

  const organizationId = offer?.organizationId;

  const profile = useGetOrganizationProfile(organizationId);

  const handleDelete = useCallback(
    (reason = '') => {
      deleteOffer.mutate(reason, {
        onSuccess: () => {
          notifyMutationSuccess(getOfferText('deleteSuccess'));
          navigate(`/${getPath('offers')}`);
        },
        onError: async (error) => {
          const errorMsgOfferHasEvents = await getErrorMessage(
            error as ResponseError,
            {
              is: OFFER_HAS_EVENTS
            }
          );
          notifyMutationError(
            errorMsgOfferHasEvents ? getOfferText('deleteError') : undefined
          );
        }
      });
    },
    [deleteOffer, navigate]
  );

  const title = offer?.title ?? getOfferText('offer');
  useSetDocumentTitle(title);

  return (
    <RequestErrorFallback queryResult={getOffer}>
      <HeroSection image={offer?.picture} />
      <DetailPageWrapper
        overlayBox={
          <>
            <ProviderProfileCard
              providerProfile={profile.data}
              showProviderName
              providerNameVariant="h2"
              urlMore={getProviderProfileUrl(organizationId)}
            />
            {offer && (
              <OfferActions
                offer={offer}
                onDelete={handleDelete}
                deleting={deleteOffer.isLoading}
              />
            )}
          </>
        }
        maxWidth={{ md: 380 }}
        flexDirection={{ xs: 'column-reverse', md: 'column' }}
        mt={{ xs: 7, sm: 7 }}
      >
        <>
          <Box sx={{ display: { xs: 'none', md: 'unset' } }}>
            <BreadcrumbNavigation
              customItem={{
                2: {
                  title: offer?.category?.name ?? getSegmentsText('other')
                },
                3: { title }
              }}
            />
          </Box>

          <GenericContentSection
            title={
              <Typography variant="h1">
                {offer?.title ?? <Skeleton width="50%" />}
              </Typography>
            }
            content={<OfferView offer={offer} loading={getOffer.isLoading} />}
          />
        </>
      </DetailPageWrapper>
    </RequestErrorFallback>
  );
};

export default OfferPage;
