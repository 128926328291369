/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Box, Typography, type SxProps, type Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type ProcessElementProps = {
  icon: JSX.Element;
  text: string;
  linkTo: string;
  additionalBoxSx?: SxProps<Theme>;
  additionalLabelSx?: SxProps<Theme>;
};

const ProcessElement = ({
  icon,
  text,
  linkTo,
  additionalBoxSx,
  additionalLabelSx
}: ProcessElementProps) => {
  return (
    <Box
      component={RouterLink}
      to={linkTo}
      sx={[
        {
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row-reverse' },
          alignItems: 'center',
          gap: { xs: 1, xxl: 3 },
          color: 'primary.main',
          '&:hover': {
            color: 'secondary.main',
            cursor: 'pointer'
          },
          zIndex: 1,
          width: { xs: '5rem', lg: '16rem' },
          textDecoration: 'none'
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(additionalBoxSx)
          ? additionalBoxSx
          : [additionalBoxSx])
      ]}
    >
      <Box
        sx={{
          borderWidth: { xs: '0.3rem', lg: '0.4rem' },
          borderStyle: 'solid',
          borderColor: 'inherit',
          color: 'inherit',
          borderRadius: '100%',
          bgcolor: 'white',
          width: { xs: '3.75rem', lg: '5rem' },
          height: { xs: '3.75rem', lg: '5rem' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0
        }}
      >
        {icon}
      </Box>
      <Typography
        variant="processLabel"
        sx={[
          {
            bgcolor: { xs: 'white', sm: 'unset' }
          },
          ...(Array.isArray(additionalLabelSx)
            ? additionalLabelSx
            : [additionalLabelSx])
        ]}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ProcessElement;
