/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link } from '@mui/material';
import { useAutoBreadcrumbItems } from './helper';
interface BreadcrumbItem {
  title: string;
  path?: string;
}

interface BreadcrumbNavigationProps {
  customItem?: { [key: number]: BreadcrumbItem };
}

/**
 * Breadcrumbs will be generated directly from the url and the hash table values in
 * getBreadcrumb() in breadcrumbs/helper.ts.
 * A different breadcrumb title can be defined by the customItem parameter
 *
 * @param customItem Object with key (position in the breadcrumb array) and object value (s. BreadcrumbItem),
 *    z.B. { 2: { title: 'Title' } } - the third (start value has key 0!) breadcrumb element has
 *          the title 'Title
 *
 * @returns React.element
 */
const BreadcrumbNavigation = ({ customItem }: BreadcrumbNavigationProps) => {
  const breadcrumbItems = useAutoBreadcrumbItems({ customItem });

  const lastIndex = breadcrumbItems.length - 1;

  const components = breadcrumbItems.map((breadcrumbItem, index) => {
    const isLast = index === lastIndex;
    const variant = isLast ? 'currentBreadcrumb' : 'breadcrumb';

    return breadcrumbItem.path ? (
      <Link
        key={index}
        variant={variant}
        component={RouterLink}
        to={breadcrumbItem.path}
        aria-current={isLast ? 'page' : undefined}
      >
        {breadcrumbItem.title}
      </Link>
    ) : (
      <Link
        key={index}
        variant={variant}
        sx={{ display: 'block' }}
        underline="none"
      >
        {breadcrumbItem.title}
      </Link>
    );
  });

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{ display: { xs: 'none', sm: 'unset' }, my: 1, zIndex: 1 }}
    >
      {components}
    </Breadcrumbs>
  );
};

export default BreadcrumbNavigation;
export type { BreadcrumbItem };
