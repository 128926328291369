/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { CustomCard, CardDescription } from 'feature/cards';
import { Loadable } from 'types';

type ArticleCardProps = {
  title: string;
  content: string;
  url: string;
};

const ArticleCard = ({
  title,
  content,
  loading,
  url
}: Loadable<ArticleCardProps>) => {
  return (
    <CustomCard href={loading ? '' : url} minWidthPercent={31}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 0,
          '& .MuiCardHeader-content': {
            width: '100%'
          }
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {loading ? (
              <Skeleton animation="wave" />
            ) : (
              <Typography
                variant="customCardTitle"
                sx={{
                  fontSize: 24,
                  mb: 0,
                  minHeight: 'unset'
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        }
      />

      <CardContent key="content" sx={{ pt: 1, px: 2 }}>
        <CardDescription html={content} loading={loading} />
      </CardContent>
    </CustomCard>
  );
};

export default ArticleCard;
