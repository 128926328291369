/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Button, Link, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import { OrganizationScreenProps } from './wait-organization-approval-screen';
import { useProject } from 'context/project';
import FeedbackScreen from './feedback-screen';
import { getProviderProfileEditUrl, getSeekerProfileEditUrl } from 'routes';
import { getNode, getText, getTextIn } from 'localization';
import {
  useGetPortalOrganizationProfileHref,
  useIsProvider,
  useUser
} from 'context/user';

const getInvitationText = getTextIn('invitation');

const SuccessOrganizationScreen: FC<OrganizationScreenProps> = ({
  organizationName,
  organizationId
}) => {
  const { project } = useProject();
  const { changePerspective, perspective } = useUser();
  const isProvider = useIsProvider();
  const icon = <CelebrationTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = (
    <Typography component="span">
      {getNode(
        'getNodeSuccessText',
        'invitation'
      )({
        link: (
          <Link
            to={useGetPortalOrganizationProfileHref(organizationId)}
            component={RouterLink}
            sx={{
              fontWeight: 600
            }}
          >
            {organizationName}
          </Link>
        ),
        projectName: project?.name
      })}
    </Typography>
  );

  const button = (
    <Button
      variant="contained"
      component={RouterLink}
      to={
        isProvider
          ? getProviderProfileEditUrl(perspective.id)
          : getSeekerProfileEditUrl(perspective.id)
      }
    >
      {getText('editProfile', 'profile')}
    </Button>
  );

  useEffect(() => {
    changePerspective(organizationId);
  }, [changePerspective, organizationId]);

  return (
    <FeedbackScreen
      title={getInvitationText('congratulation')}
      icon={icon}
      text={text}
      hasHomePageButton={true}
      actionButton={button}
      dataCy="organizationsuccessscreen"
    />
  );
};

export default SuccessOrganizationScreen;
