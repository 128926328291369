/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  type EntityModelOrganizationProfile,
  EntityType
} from '@SLR/solution3-sdk';
import { useUser } from 'context/user';
import { ReportButton } from 'feature';
import { getTextIn, getPath } from 'localization';

const getProfileText = getTextIn('profile');

interface Props {
  profile?: EntityModelOrganizationProfile;
}

const ProviderActions = ({ profile }: Props) => {
  const { perspective } = useUser();
  const organizationId = profile?.organizationId ?? '';
  const isOwner =
    perspective.isOrganization && organizationId === perspective.id;

  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        justifyContent: { xs: 'center', md: 'end' }
      }}
    >
      {isOwner ? (
        <Button
          variant="outlined"
          size="small"
          startIcon={<ModeEditIcon />}
          component={Link}
          to={getPath('edit')}
          sx={{
            mt: 0.75
          }}
          data-cy="edit-profile-button"
        >
          {getProfileText('editProfile')}
        </Button>
      ) : (
        <ReportButton
          type={EntityType.OrganizationProfile}
          entityId={organizationId}
        />
      )}
    </Box>
  );
};

export default ProviderActions;
