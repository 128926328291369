/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { InvitationApprovePage, InvitationLandingPage } from 'pages';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ForceAuth, Restricted } from './restricted-route';
import { getPath } from 'localization';
import { useIsAdmin } from 'context/user';

const InvitationRoutes = () => {
  const isAdmin = useIsAdmin();

  return (
    <>
      <Routes>
        <Route
          path={`:invitationId`}
          element={
            <ForceAuth>
              <InvitationLandingPage />
            </ForceAuth>
          }
        />
        <Route
          path={`:invitationId/${getPath('approve')}`}
          element={
            <Restricted isVisible={isAdmin} forceLogin={true}>
              <InvitationApprovePage />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default InvitationRoutes;
