/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Delete, FileUpload } from '@mui/icons-material';
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  type Theme
} from '@mui/material';
import { type Image } from '@SLR/solution3-sdk';
import PlaceholderImage from 'assets/image-placeholder.svg';
import { ImageUpdateLogic } from 'feature';
import { getText, getTextIn } from 'localization';
import { useState } from 'react';

const getEditModalText = getTextIn('edit-editHeroImage');

type ManageImageSectionProps = {
  keyValue: string;
  image?: Image;
  index?: number;
  handleAltTextChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteImage?: (removeId: string) => void;
  handleAddImage: (newImage: Image) => void;
  uploadHint?: string;
  isNotValid: boolean;
};

/**
 * View Section to upload, change or delete images and edit their alternativeText and description.
 * general parameters
 * @param keyValue: key attribute to distinguish different MangeImageSections
 * @param index: (optional) provide an index that is append to data-cy and key attributes. Useful for automatic testing of image lists.
 * -- upload, change image (optional)
 * @param handleAddImage: necessary to upload (if no image is provided) or change image (if image is provided)
 * @param uploadHint: (optional) help text shown to users im upload mode
 * @param image: (optional)
 * @returns Upload section with placeholder image or provided image with button to change image
 * -- edit alternativeText (optional)
 * @param handleAltTextChange: function that changes the alternativeText attribute in Image
 * @param image: necessary to edit alternativeText
 * @returns Edit image Section with text field for alternativeText
 * -- delete image (optional)
 * @param handleDeleteImage: function that removes the given image
 * @param image necessary to delete image
 * @returns Edit image section with delete Button
 */
const ManageImageSection = ({
  keyValue,
  index,
  image,
  handleDeleteImage,
  handleAltTextChange,
  handleAddImage,
  uploadHint,
  isNotValid
}: ManageImageSectionProps) => {
  const theme = useTheme<Theme>();
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const indexSuffix = index ? '-' + index : '';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        gap: 2
      }}
      key={keyValue}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            height: 120,
            width: { xs: '100%', sm: 184 },
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'center' }
          }}
        >
          {image ? (
            <img
              data-cy={`manage-image-uploaded-image${indexSuffix}`}
              key={`manage-image-uploaded-image${indexSuffix}`}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={image.urls.small}
              alt={image.alternativeText ?? getEditModalText('uploadAltText')}
            />
          ) : (
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                backgroundColor: theme.palette.placeholder.main,
                display: 'inherit'
              }}
              src={PlaceholderImage}
              alt={getEditModalText('placeholderImage')}
            />
          )}
        </Box>
        {image && handleDeleteImage && (
          <Button
            data-cy={`manage-image-delete-button${indexSuffix}`}
            key={`manage-image-delete-button${indexSuffix}`}
            color="error"
            variant="text"
            startIcon={<Delete />}
            onClick={() => handleDeleteImage(image.id)}
          >
            {getText('delete')}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'space-between',
          gap: 1
        }}
      >
        {image && handleAltTextChange ? (
          <TextField
            multiline
            fullWidth
            label={getEditModalText('textField')}
            disabled={isLoadingImage}
            value={image?.alternativeText}
            onChange={handleAltTextChange}
            required
            inputProps={{
              maxLength: 255
            }}
            data-cy="alternativeTextTextField"
            error={isNotValid}
            helperText={
              isNotValid
                ? getEditModalText('helperError')
                : getEditModalText('helperText')
            }
          />
        ) : (
          <Typography>{uploadHint}</Typography>
        )}

        <ImageUpdateLogic
          handleAddImage={handleAddImage}
          setIsLoadingImage={setIsLoadingImage}
          inputDataCy="imageuploadsection-input"
          variant={image ? 'text' : 'outlined'}
          size="large"
          startIcon={<FileUpload />}
          label={
            image ? getEditModalText('change') : getEditModalText('upload')
          }
          dataCy="imageuploadsection-upload"
        />

        <Typography variant="body2" sx={{ pt: 1 }}>
          ({getEditModalText('maxSize')})
        </Typography>
      </Box>
    </Box>
  );
};

export default ManageImageSection;
