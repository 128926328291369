/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  ChangeEventHandler
} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  type EntityModelGalleryEntry,
  type ProfileGalleryEntriesUpdate,
  type Image
} from '@SLR/solution3-sdk';
import { merge } from 'lodash';
import LoadingSpinner from 'components/loading-spinner';
import { DialogGeneric, ImagePlaceholder } from 'feature';
import { useImageLoader } from 'feature/gallery';
import { useCreateImage, useValidation } from 'feature/hooks';
import { ADD, REMOVE, ALT, DESC } from './constants';
import GalleryEditImageList from './gallery-edit-image-list';
import { type Action } from './types';
import { isEmptyArray } from 'utils/helper';
import { getText, getTextIn } from 'localization';
import { onUploadError } from 'utils/image-utils';

const getProfileText = getTextIn('profile');
const getEditModalText = getTextIn('edit-editHeroImage'); // AW: check loc

const compareCreatedDesc = (
  a: EntityModelGalleryEntry,
  b: EntityModelGalleryEntry
) => {
  return a.created > b.created ? -1 : a.created < b.created ? 1 : 0;
};

const getImages = (
  galleryEntries: EntityModelGalleryEntry[],
  state: ProfileGalleryEntriesUpdate,
  uploadedImages: UploadedImageHash
) => {
  const createdEntriesAcc: EntityModelGalleryEntry[] = [];

  // create - lookup for image details in reversed order
  const createdEntries = state.create
    ? state.create.reduceRight((acc, entry) => {
        const imageId = entry.image.imageId;
        const image = uploadedImages[imageId];

        acc.push(
          merge(
            { image, id: '', created: image.created, description: '' },
            entry
          )
        );

        return acc;
      }, createdEntriesAcc)
    : [];

  // replace and delete
  const updatedGalleryEntries = galleryEntries.reduce((acc, entry) => {
    const { id } = entry;

    if (!state._delete?.includes(id)) {
      acc.push(state.replace?.[id] ? merge(entry, state.replace[id]) : entry);
    }

    return acc;
  }, createdEntries);

  return updatedGalleryEntries;
};

const findEntryById = (
  galleryEntries: (
    | EntityModelGalleryEntry
    | { image: Image; id: string; description: string }
  )[],
  id?: string
) => {
  return galleryEntries.filter((entry) => entry.image.id === id)[0];
};

type UploadedImageHash = {
  [key: string]: Image & { created: Date };
};

type GalleryEditDialogProps = {
  openDialog: boolean;
  handleClose: VoidFunction;
  handleConfirm: VoidFunction;
  galleryEntries: EntityModelGalleryEntry[];
  state: ProfileGalleryEntriesUpdate;
  dispatchAction: Dispatch<Action>;
  activeImageId: string;
  setActiveImageId: Dispatch<SetStateAction<string>>;
};

const GalleryEditDialog = ({
  openDialog,
  handleClose,
  handleConfirm,
  galleryEntries,
  state,
  dispatchAction,
  activeImageId,
  setActiveImageId
}: GalleryEditDialogProps) => {
  const addImage = useCreateImage();
  const [uploadedImages, setUploadedImages] = useState<UploadedImageHash>({});

  const imagesOrg = getImages(galleryEntries, state, uploadedImages);
  const images = imagesOrg.sort(compareCreatedDesc);
  const maxTiles = images.length;

  useEffect(() => {
    if (openDialog && !activeImageId && !isEmptyArray(images)) {
      setActiveImageId(images[0].image.id);
    }
  }, [activeImageId, images, openDialog, setActiveImageId]);

  const currentEntry = findEntryById(images, activeImageId);
  const currentId = currentEntry?.id;
  const currentImage = currentEntry?.image;

  const { loadedSrc } = useImageLoader({
    image: currentImage
  });

  const handleChangeAlt = (text: string) => {
    dispatchAction({
      type: ALT,
      id: currentId,
      imageId: activeImageId,
      alt: text,
      entry: currentEntry
    });
  };

  const checkForNotValidHash = () => {
    const notValidHashAcc: { [key: string]: boolean } = {};

    const notValidHash = images.reduce((acc, imageEntry) => {
      const image = imageEntry.image;

      if (!image.alternativeText?.trim()) {
        acc[image.id] = true;
      }

      return acc;
    }, notValidHashAcc);

    return notValidHash;
  };

  const { isNotValidFor, onChange, onConfirm, onCancel } = useValidation({
    handleChange: handleChangeAlt,
    handleConfirm,
    handleCancel: handleClose,
    checkForNotValidHash
  });

  const getNewImage: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget
  }) => {
    if (currentTarget?.files) {
      Object.values(currentTarget?.files)?.map((file) => {
        addImage.mutate(file, {
          onSuccess: (data) => {
            setActiveImageId('');

            dispatchAction({
              type: ADD,
              id: '',
              imageId: data.id
            });

            setUploadedImages((prevImages) => ({
              ...prevImages,
              [data.id]: { ...data, created: new Date() }
            }));
          },
          onError: onUploadError
        });
      });
    }
  };

  return (
    <>
      <DialogGeneric
        open={openDialog}
        onClose={onCancel}
        onConfirm={onConfirm}
        fullWidth
        title={getProfileText('imageGallery')}
        confirmText={getText('save')}
        prefix="profile-gallery"
        disableEscapeKeyDown
      >
        <Box>
          <Grid container spacing={7}>
            <Grid item xs={6}>
              <GalleryEditImageList
                getNewImage={getNewImage}
                activeImageId={activeImageId}
                setActiveImageId={setActiveImageId}
                images={images}
                maxTiles={maxTiles}
                isNotValidFor={isNotValidFor}
              />
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2,
                    height: 256,
                    width: '100%'
                  }}
                >
                  {loadedSrc && !addImage.isLoading && currentImage ? (
                    <img
                      src={currentImage.urls?.small}
                      alt={currentImage.alternativeText}
                      loading="lazy"
                      style={{
                        objectFit: 'contain',
                        width: '100%',
                        maxHeight: 256
                      }}
                    />
                  ) : isEmptyArray(images) ? (
                    <ImagePlaceholder
                      style={{
                        width: 398
                      }}
                    />
                  ) : (
                    <LoadingSpinner dataCy="profile-gallery-edit" />
                  )}
                </Box>

                {loadedSrc && !addImage.isLoading && currentImage && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => {
                      if (activeImageId) {
                        setActiveImageId('');

                        dispatchAction({
                          type: REMOVE,
                          id: currentId,
                          imageId: activeImageId
                        });
                      }
                    }}
                    startIcon={<DeleteIcon />}
                    sx={{
                      alignSelf: 'end',
                      alignItems: 'inherit',
                      border: 'none !important',
                      mt: 0.75
                    }}
                    data-cy="profile-gallery-edit-delete-image"
                  >
                    {getProfileText('deleteImage')}
                  </Button>
                )}

                <TextField
                  multiline
                  fullWidth
                  label={getProfileText('description')}
                  disabled={addImage.isLoading || !loadedSrc}
                  value={currentEntry?.description ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatchAction({
                      type: DESC,
                      id: currentId,
                      imageId: activeImageId,
                      desc: event.target.value
                    });
                  }}
                  inputProps={{
                    maxLength: 255
                  }}
                  sx={{ mt: 4 }}
                  data-cy="profile-gallery-grid-description"
                />

                <TextField
                  multiline
                  fullWidth
                  required
                  label={getEditModalText('textField')}
                  disabled={addImage.isLoading || !loadedSrc}
                  value={currentImage?.alternativeText ?? ''}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 255
                  }}
                  sx={{ mt: 4 }}
                  data-cy="profile-gallery-grid-alternativeText"
                  error={isNotValidFor(activeImageId)}
                  helperText={
                    isNotValidFor(activeImageId) &&
                    getEditModalText('helperError')
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogGeneric>
    </>
  );
};

export default GalleryEditDialog;
