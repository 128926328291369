/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogGeneric } from 'feature';
import { getTextIn } from 'localization';

const getOfferText = getTextIn('offer');
const getOfferDeleteText = getTextIn('offer-details-delete');

const ReasonInput = ({ onChange }: { onChange: (value: string) => void }) => {
  const [value, setValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <TextField
      label={getOfferDeleteText('adminReasonPlaceholder')}
      value={value}
      helperText={getOfferDeleteText('adminReasonHelperText')}
      onChange={handleChange}
      multiline
      minRows={5}
    />
  );
};

const OfferAdminDeleteDialog = ({
  progress,
  handleDelete
}: {
  progress?: boolean;
  handleDelete: (reason?: string) => void;
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [reason, setReason] = useState('');
  const textDeleteOffer = getOfferText('deleteOffer');

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => {
          setOpenDelete(true);
        }}
        data-cy="deleteofferasadminbutton"
        color="error"
        sx={{ mt: 0.75 }}
      >
        {textDeleteOffer}
      </Button>

      {openDelete && (
        <DialogGeneric
          open={openDelete}
          onClose={() => {
            setOpenDelete(false);
          }}
          onConfirm={() => handleDelete(reason)}
          title={textDeleteOffer}
          confirmText={textDeleteOffer}
          confirmColor="error"
          prefix="delete-offer-as-admin-alert-dialog"
          prefixCy="deleteofferasadmindialog"
          isLoading={progress}
        >
          <Stack gap={3}>
            <Typography>{getOfferDeleteText('adminQuestion')}</Typography>
            <ReasonInput onChange={setReason} />
          </Stack>
        </DialogGeneric>
      )}
    </>
  );
};

export default OfferAdminDeleteDialog;
