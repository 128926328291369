/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Stack from '@mui/material/Stack';

const SearchBarWrapper = ({ children }: { children: React.ReactElement }) => {
  return (
    <Stack
      direction="column"
      justifyContent="start"
      alignItems="start"
      spacing={{ xs: 4, md: 2 }}
      sx={{ pr: { xs: 0 } }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 4, sm: 2 }}
        width="100%"
        sx={{
          pb: { xs: 3, sm: 0 }
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default SearchBarWrapper;
