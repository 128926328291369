/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { OFFERS_LIST_KEY, OFFER_KEY } from 'feature/hooks/offers/useOffersApi';
import useCategoriesApi from './useCategoriesApi';
import useTargetGroupsApi from './useTargetGroupsApi';
import { CATEGORIES, TARGET_GROUPS, type SegmentType } from './constants';
import { getSegmentListType } from './helper';
import { getText, getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');

const useDeleteSegment = (segmentType: SegmentType) => {
  const queryClient = useQueryClient();
  const { deleteCategory } = useCategoriesApi();
  const { deleteTargetGroup } = useTargetGroupsApi();
  const projectId = useGetProjectId() ?? '';

  return useMutation(
    (id: string): Promise<void> => {
      switch (segmentType) {
        case CATEGORIES: {
          return deleteCategory({
            projectId,
            categoryId: id
          });
        }
        case TARGET_GROUPS: {
          return deleteTargetGroup({
            projectId,
            targetGroupId: id
          });
        }
      }
    },
    {
      onSuccess: () => {
        notifyMutationSuccess(
          getSegmentsText('getSuccessAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('delete')
          })
        );

        queryClient.refetchQueries([getSegmentListType(segmentType)]);
        queryClient.refetchQueries([OFFERS_LIST_KEY]);
        queryClient.refetchQueries([OFFER_KEY]);
      },
      onError: () =>
        notifyMutationError(
          getSegmentsText('getErrorAction', {
            singular: getText('segment', `settings-${segmentType}`),
            action: getSegmentsText('delete')
          })
        )
    }
  );
};

export default useDeleteSegment;
