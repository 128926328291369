/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { type FieldValues } from 'react-hook-form';
import { type UseSchemaValidationFormReturn } from './use-form';

const HookSchemaValidationFormContext =
  React.createContext<UseSchemaValidationFormReturn | null>(null);

export const useSchemaValidationFormContext = <
  TFieldValues extends FieldValues,
  TransformedValues extends FieldValues | undefined = undefined
>(): UseSchemaValidationFormReturn<TFieldValues> =>
  React.useContext(
    HookSchemaValidationFormContext
  ) as UseSchemaValidationFormReturn<TFieldValues, TransformedValues>;

type Props<
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
> = UseSchemaValidationFormReturn<TFieldValues, TContext>;

const SchemaValidationFormProvider = <
  TFieldValues extends FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any
>({
  children,
  ...data
}: PropsWithChildren<Props<TFieldValues, TContext>>) => {
  return (
    <HookSchemaValidationFormContext.Provider
      value={data as UseSchemaValidationFormReturn}
    >
      {children}
    </HookSchemaValidationFormContext.Provider>
  );
};

export default SchemaValidationFormProvider;
