/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DialogGeneric } from 'feature';
import { getText, getTextIn, getErrorText } from 'localization';
import { ObjectSchema, object, string, boolean } from 'yup';
import { useCreateInvitation } from './hooks';
import {
  SchemaValidationFormProvider,
  ValidatedTextField,
  useSchemaValidationForm
} from './forms';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { notifyMutationSuccess, notifyMutationError } from './error';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import SearchOrganizationField from './search-organization-field';
import { useEffect, useState } from 'react';
import { Warning } from '@mui/icons-material';
import { type EntityModelOrganization } from '@SLR/solution3-sdk';
import { EMAIL_REGEX } from 'utils/helper';
import { useGetAvailableRoles } from 'context/project/hooks';

const getSettingsText = getTextIn('settings-participants');

type OrganizationInvitation = {
  provider: boolean;
  email: string;
  organizationName: string;
  organizationId?: string;
};

const EMPTY_INVITATION: OrganizationInvitation = {
  provider: false,
  email: '',
  organizationName: '',
  organizationId: undefined
};

const InvitationSchema = (): ObjectSchema<OrganizationInvitation> =>
  object().shape({
    provider: boolean().required(),
    email: string()
      .required()
      .min(1)
      .matches(EMAIL_REGEX, { message: getErrorText('emailFormatError') })
      .label(getSettingsText('email')),
    organizationName: string()
      .required()
      .min(1)
      .label(getSettingsText('organizationName')),
    organizationId: string().optional()
  });

type InvitationFormDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const InvitationFormDialog = ({
  isOpen,
  onClose
}: InvitationFormDialogProps) => {
  const [orgNameQuery, setOrgNameQuery] = useState('');
  const [showNameHint, setShowNameHint] = useState(false);
  const [shouldUseSearchTermAsName, setShouldUseSearchTermAsName] =
    useState(false);
  const createInvitation = useCreateInvitation();

  const availableRoles = useGetAvailableRoles('organization');

  const form = useSchemaValidationForm<OrganizationInvitation>(
    EMPTY_INVITATION,
    EMPTY_INVITATION,
    InvitationSchema()
  );
  const { handleSubmit, reset, setValue, control, formState } = form;

  const close = () => {
    onClose();
    setOrgNameQuery('');
    setShowNameHint(false);
    setShouldUseSearchTermAsName(false);
    reset();
  };

  const onSubmit: SubmitHandler<OrganizationInvitation> = (data) =>
    createInvitation.mutate(
      {
        ...data,
        seeker: !data.provider
      },
      {
        onSuccess: () => {
          notifyMutationSuccess(getSettingsText('success'));
          close();
        },
        onError: () => {
          notifyMutationError(getSettingsText('failure'));
        }
      }
    );
  useEffect(() => {
    if (shouldUseSearchTermAsName) {
      setValue('organizationName', orgNameQuery);
      setValue('organizationId', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgNameQuery, shouldUseSearchTermAsName]);

  return (
    <DialogGeneric
      open={isOpen}
      title={getSettingsText('inviteOrganization')}
      onConfirm={handleSubmit(onSubmit)}
      onClose={() => {
        close();
      }}
      confirmText={getSettingsText('invite')}
      prefix={'invite-organization-form'}
      fullWidth
      isLoading={createInvitation.isLoading}
    >
      <SchemaValidationFormProvider {...form}>
        <Stack gap={3}>
          <FormLabel>{getSettingsText('chooseRole')}</FormLabel>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="provider"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  {availableRoles.options.map(({ value: role }) => (
                    <FormControlLabel
                      value={
                        role === 'provider' ||
                        availableRoles.options.length === 1
                      }
                      control={<Radio />}
                      label={getText(role, 'role')}
                      key={getText(role, 'role')}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormLabel>{getSettingsText('searchOrganization')}</FormLabel>
          <Controller
            control={control}
            name={`organizationId`}
            render={({ fieldState }) => {
              const error = !!fieldState.error;
              return (
                <SearchOrganizationField
                  onOrganizationSelected={(org: EntityModelOrganization) => {
                    setValue('organizationId', org.id);
                    setValue('organizationName', org.name);
                  }}
                  error={error}
                  noOrgSearchResults={setShowNameHint}
                  query={orgNameQuery}
                  setQuery={setOrgNameQuery}
                />
              );
            }}
          />
          {showNameHint && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap={2}
                sx={{ pr: { xs: 0, md: 8 } }}
              >
                <Warning color="primary" fontSize="large" />
                <Typography color="primary" fontWeight={600}>
                  {getSettingsText('organizationSearchFailed')}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={shouldUseSearchTermAsName}
                      onChange={(event) =>
                        setShouldUseSearchTermAsName(event.target.checked)
                      }
                      data-cy="shouldUseSearchTermAsNameSwitch"
                    />
                  }
                  label={getSettingsText('getInviteWithName', {
                    name: orgNameQuery
                  })}
                />
              </Stack>
            </>
          )}
          {formState.errors.organizationName && (
            <Typography color="error">
              {getSettingsText('organizationNameError')}
            </Typography>
          )}
          <FormLabel>{getSettingsText('moreInfo')}</FormLabel>
          <ValidatedTextField
            name="email"
            render={({ field, props }) => {
              const { errorMessage, ...fieldProps } = props;
              return (
                <TextField
                  helperText={errorMessage}
                  label={getSettingsText('email')}
                  required
                  inputProps={{
                    maxLength: 254,
                    'data-cy': 'invite-organization-form-email'
                  }}
                  {...field}
                  {...fieldProps}
                />
              );
            }}
          />
          <FormControl>
            <FormHelperText>{getText('requiredFields')}</FormHelperText>
          </FormControl>
        </Stack>
      </SchemaValidationFormProvider>
    </DialogGeneric>
  );
};

export default InvitationFormDialog;
