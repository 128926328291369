/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useKeycloakAuth } from 'context/auth';
import { useUser } from 'context/user';
import { useOnboarding } from 'context/onboarding';
import OnboardingDialog from './onboarding-dialog';
import OnboardingPanel from './onboarding-panel';
import { useOnboardingLogic } from './logic';
import { INIT, PROFILE, KEY, type StepType } from './config';
import { getPath } from 'localization';
import { useBooking } from 'context/booking';

const Onboarding = ({
  children
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useKeycloakAuth();
  const { perspective } = useUser();

  const { successMode, setSuccessMode, type, setType } = useOnboarding();
  const { onboardingDone, getAllStepsDone } = useOnboardingLogic();

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (successMode) {
      setOpen(true);
    }
  }, [successMode]);

  const { isOffersBookable: showKeyStep } = useBooking();
  const allStepsDone = getAllStepsDone(showKeyStep ? undefined : KEY);

  const getsOnboarding = isAuthenticated && perspective.isOrganization;
  const isInvitationScreen = pathname.split('/')[1] === getPath('invitation');

  const showDialog =
    getsOnboarding &&
    !isInvitationScreen &&
    (type === INIT ? !allStepsDone : !onboardingDone) &&
    (type !== KEY || showKeyStep);

  const showPanel =
    getsOnboarding && !isInvitationScreen && !onboardingDone && type !== INIT;

  const setTypeAndOpen = (name: StepType) => {
    setType(name);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);

    setTimeout(() => {
      setSuccessMode(false);
    }, 100);

    // set a different type than the INIT type, for showing up the panel
    if (type === INIT) {
      setType(PROFILE);
    }
  };

  return (
    <>
      {children}
      {showDialog && (
        <OnboardingDialog
          open={open}
          type={type}
          onClose={onClose}
          showKeyStep={showKeyStep}
          setType={setType}
        />
      )}
      {showPanel && (
        <OnboardingPanel
          setType={setTypeAndOpen}
          showKeyStep={showKeyStep}
          allStepsDone={allStepsDone}
        />
      )}
    </>
  );
};

export default Onboarding;
