/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material';
import { RRule } from 'rrule';
import dayjs, { type Dayjs } from 'dayjs';
import { ValidatedDatePicker, DefaultDatePicker } from 'feature/forms';
import { dateToDayjs } from 'utils/date';
import {
  Frequency,
  getFrequency,
  getFrequencyText,
  createRRuleString
} from 'utils/recurrence';
import { getTextIn } from 'localization';

const getRecurrenceText = getTextIn('recurrence');

const getFrequencyOptions = (prefixCy: string) => {
  return Object.values(Frequency).map((frequency) => (
    <MenuItem
      key={frequency}
      value={frequency}
      data-cy={`${prefixCy}-frequency-${frequency}`}
    >
      {getFrequencyText(frequency)}
    </MenuItem>
  ));
};

type Props = {
  /**
   * A RRule string
   */
  value?: string | null;
  onValueChanged?: (value: string | null) => void;
  start: Date | null;
  maxDate?: Dayjs;
  prefixCy?: string;
};

const RecurrenceControl = ({
  value,
  onValueChanged,
  start,
  prefixCy = 'recurrence'
}: Props) => {
  const [frequency, setFrequency] = useState<Frequency>(Frequency.ONCE);
  const [until, setUntil] = useState<Dayjs | null>(null);
  const [rrule, setRrule] = useState<string | null>(null);

  const showUntil = frequency !== Frequency.ONCE;

  useEffect(() => {
    if (value) {
      const rule = RRule.fromString(value);
      setFrequency(getFrequency(rule));
      setUntil(dateToDayjs(rule.options.until) ?? null);
    } else {
      setFrequency(Frequency.ONCE);
      setUntil(null);
    }
  }, [value]);

  useEffect(() => {
    if (onValueChanged && (!until || dayjs(until).isValid())) {
      const newValue = createRRuleString(frequency, until, start);

      // necessary for useEffect control
      setRrule(newValue);
      onValueChanged(newValue);
    }
  }, [onValueChanged, frequency, until, start, rrule]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="recurrence-control-label">
            {getRecurrenceText('series')}
          </InputLabel>
          <Select
            labelId="recurrence-control-label"
            label={getRecurrenceText('series')}
            value={String(frequency)}
            onChange={(event: SelectChangeEvent) => {
              const val = event.target.value as Frequency;
              setFrequency(val);
            }}
            fullWidth
            inputProps={{
              'data-cy': `${prefixCy}-frequency-select`
            }}
          >
            {getFrequencyOptions(prefixCy)}
          </Select>
        </FormControl>
      </Grid>

      {showUntil && (
        <Grid item xs={12} sm={6}>
          <ValidatedDatePicker
            name="recurrenceRule"
            render={(props) => (
              <DefaultDatePicker
                disablePast
                minDate={dateToDayjs(start)}
                closeOnSelect
                value={until}
                onChange={(val: Dayjs | null) => {
                  setUntil(val);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    label: getRecurrenceText('endDate'),
                    inputProps: { 'data-cy': `${prefixCy}-until-edit` }
                  },
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                {...props}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RecurrenceControl;
