/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type ArticleCreate } from '@SLR/solution3-sdk';
import { DEFAULT_MAX_CLOB_LENGTH, NON_EMPTY_HTML } from 'feature/forms';
import { getTextIn } from 'localization';
import { ObjectSchema, array, object, string } from 'yup';

const TITLE = 'title';
const CONTENT = 'content';
const CATEGORY_ID = 'categoryId';
const KEYWORDS = 'keywords';

type Keyword = {
  id: string;
  name: string;
};

interface Article {
  [TITLE]: string;
  [CONTENT]: string;
  [CATEGORY_ID]: string;
  [KEYWORDS]: { id: string; keyword: Keyword }[];
}

const getTextCompass = getTextIn('compass');

const EMPTY_ARTICLE: ArticleCreate = Object.freeze({
  [TITLE]: '',
  [CONTENT]: '',
  [CATEGORY_ID]: '',
  [KEYWORDS]: []
});

const ArticleSchema = (): ObjectSchema<ArticleCreate> =>
  object().shape({
    [TITLE]: string().required().min(1).max(100).label(getTextCompass('title')),
    [CONTENT]: string()
      .required()
      .min(1)
      .max(DEFAULT_MAX_CLOB_LENGTH)
      .test(NON_EMPTY_HTML)
      .label(getTextCompass('description')),

    [CATEGORY_ID]: string().required().label(getTextCompass('category')),
    [KEYWORDS]: array().required().label(getTextCompass('keywords'))
  });

export {
  ArticleSchema,
  CATEGORY_ID,
  CONTENT,
  EMPTY_ARTICLE,
  KEYWORDS,
  TITLE,
  type Article,
  type Keyword
};
