/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from '@tanstack/react-query';
import {
  type ListArticleCategoriesRequest,
  type GetArticleCategoryRequest,
  type EntityModelArticleCategory
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticleCategoriesApi } from './use-compass-api';

type ListArticleCategories = Omit<ListArticleCategoriesRequest, 'projectId'>;

type GetArticleCategory = Omit<GetArticleCategoryRequest, 'projectId'>;

const LIST_ARTICLE_CATEGORIES = 'listArticleCategories';
const GET_ARTICLE_CATEGORY = 'getArticleCategory';

const useListArticleCategories = (params?: ListArticleCategories) => {
  const projectId = useGetProjectId();
  const { listArticleCategories } = useArticleCategoriesApi();

  const requestParameters: ListArticleCategoriesRequest = {
    ...params,
    projectId: projectId ?? ''
  };

  return useQuery(
    [LIST_ARTICLE_CATEGORIES, requestParameters],
    () => {
      return listArticleCategories(requestParameters);
    },
    {
      enabled: !!projectId
    }
  );
};

const useArticleCategories = (params?: ListArticleCategories) => {
  const defaultListParameters = { sort: ['name,asc'] };

  const { data, isLoading } =
    useListArticleCategories(params ?? defaultListParameters) ?? {};

  const articleCategories = data?.embedded?.categories;

  return { articleCategories, isLoading };
};

const useGetArticleCategory = ({ categoryId }: GetArticleCategory) => {
  const projectId = useGetProjectId();
  const { getArticleCategory } = useArticleCategoriesApi();

  const requestParameters: GetArticleCategoryRequest = {
    projectId: projectId ?? '',
    categoryId
  };

  return useQuery<EntityModelArticleCategory, Response>(
    [GET_ARTICLE_CATEGORY, requestParameters],
    () => {
      return getArticleCategory(requestParameters);
    },
    {
      enabled: !!projectId && !!categoryId
    }
  );
};

export {
  useGetArticleCategory,
  useArticleCategories,
  LIST_ARTICLE_CATEGORIES,
  GET_ARTICLE_CATEGORY
};
