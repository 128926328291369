/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from 'auth';
import ProjectContextProvider from 'context/project';
import UserContextProvider from 'context/user';
import OnboardingContextProvider from 'context/onboarding';
import App from './App';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import reportWebVitals from './reportWebVitals';
import 'localization/globalConfig';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fontsource/lusitana';
import { CryptoContextProvider } from 'context/crypto';
import BookingContextProvider from 'context/booking';
import { ResponseError } from '@SLR/solution3-sdk';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async (error) => {
        const ex = await (error as ResponseError)?.response?.json();
        console.log(ex);
      }
    }
  }
});

root.render(
  <AuthContextProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        {/* BrowserRouter muss über allen Route hooks stehen (werden auch in ProjectContextProvider verwendet) */}
        <BrowserRouter>
          <ProjectContextProvider>
            <BookingContextProvider>
              <CryptoContextProvider>
                <UserContextProvider>
                  <OnboardingContextProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <App />
                    </LocalizationProvider>
                  </OnboardingContextProvider>
                </UserContextProvider>
              </CryptoContextProvider>
            </BookingContextProvider>
          </ProjectContextProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
