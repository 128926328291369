/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  CategoriesApi,
  type CreateCategoryRequest,
  type DeleteCategoryRequest,
  type GetCategoryRequest,
  type ListCategoriesRequest,
  type UpdateCategoryRequest
} from '@SLR/solution3-sdk';

const useCategoriesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.categories) as CategoriesApi;
  }, []);

  const listCategories = useCallback(
    async (requestParameters: ListCategoriesRequest) => {
      try {
        return await apiInstance.listCategories(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getCategory = useCallback(
    async (requestParameters: GetCategoryRequest) => {
      try {
        return await apiInstance.getCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createCategory = useCallback(
    async (requestParameters: CreateCategoryRequest) => {
      try {
        return await apiInstance.createCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateCategory = useCallback(
    async (requestParameters: UpdateCategoryRequest) => {
      try {
        return await apiInstance.updateCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteCategory = useCallback(
    async (requestParameters: DeleteCategoryRequest) => {
      try {
        return await apiInstance.deleteCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );
  return {
    listCategories,
    createCategory,
    getCategory,
    deleteCategory,
    updateCategory
  };
};

export default useCategoriesApi;
