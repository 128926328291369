/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type EntityModelOffer } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi, { OFFER_KEY } from './useOffersApi';

const useGetOffer = (offerId: string) => {
  const { getOffer } = useOffersApi();
  const projectId = useGetProjectId();

  return useQuery<EntityModelOffer, Response>(
    [OFFER_KEY, { projectId, offerId }],
    () => {
      return getOffer({
        projectId: projectId ?? '',
        offerId
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!offerId
    }
  );
};

export default useGetOffer;
