/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createContext, useContext, ReactElement, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { type EntityModelProject } from '@SLR/solution3-sdk';
import { useGetCurrentProject, useSetConfig } from './hooks';
import LoadingSpinner from 'components/loading-spinner';
import { ErrorPageNotFound } from 'pages/error';
import useColors from './hooks/useColors';
import { responsiveTheme } from 'theme/theme';
import { getErrorText } from 'localization';
import { addNumberOfDays, getFormattedRangeString } from 'utils/date';

interface ProjectContextValues {
  isLoading: boolean;
  project?: EntityModelProject;
  primaryColor: string;
  secondaryColor: string;
  setPrimaryColor: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryColor: React.Dispatch<React.SetStateAction<string>>;
  resetColors: () => void;
  isProjectNew: boolean;
  maxDateFromNow: Date;
  minDateFromNow?: Date;
}

const defaultProjectContextValues: ProjectContextValues = {
  isLoading: false,
  primaryColor: responsiveTheme.palette.primary.main,
  secondaryColor: responsiveTheme.palette.secondary.main,
  setPrimaryColor: () => {},
  setSecondaryColor: () => {},
  resetColors: () => {},
  isProjectNew: false,
  maxDateFromNow: addNumberOfDays(90),
  minDateFromNow: undefined
};

const ProjectContext = createContext<ProjectContextValues>(
  defaultProjectContextValues
);

const useProject = () => useContext(ProjectContext);
const useGetProjectId = () => {
  const { project } = useProject();
  return project?.id;
};

interface ProjectContextProviderProps {
  children: ReactElement;
}

const ProjectContextProvider = ({ children }: ProjectContextProviderProps) => {
  const {
    isLoading,
    data: project,
    isSuccess,
    isError
  } = useGetCurrentProject();
  const [colorValues, colorTheme, themingReady] = useColors(isLoading, project);
  const isConfigSet = useSetConfig(project);
  const isProjectNew = project?.locale.includes('neustadt') ?? false;

  const minDaysInFuture = isProjectNew ? 2 : 0;

  useEffect(() => {
    // Set icon
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = project?.logo?.urls.small ?? '';
  }, [project?.logo?.urls.small]);

  return (
    <ProjectContext.Provider
      value={{
        isLoading,
        project,
        ...colorValues,
        isProjectNew,
        maxDateFromNow: addNumberOfDays(project?.maxDaysInFuture ?? 90),
        minDateFromNow: minDaysInFuture
          ? addNumberOfDays(minDaysInFuture)
          : undefined
      }}
    >
      <ThemeProvider theme={colorTheme}>
        <CssBaseline />
        {isSuccess && themingReady && isConfigSet ? (
          children
        ) : isError ? (
          <ErrorPageNotFound />
        ) : (
          <LoadingSpinner dataCy="project-context" />
        )}
      </ThemeProvider>
    </ProjectContext.Provider>
  );
};

const useSetDocumentTitle = (title: string) => {
  const { project } = useProject();

  // fetch solutionName via error text as it will be also accessed by the error page
  document.title = getFormattedRangeString(
    title,
    project?.name ?? getErrorText('solutionName')
  );
};

export default ProjectContextProvider;
export { useProject, useGetProjectId, useSetConfig, useSetDocumentTitle };
