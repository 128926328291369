/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Typography from '@mui/material/Typography';
import { useSetDocumentTitle } from 'context/project';
import { ContentWrapper, OfferGrid, Pagination, usePageClick } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { useGetOffers, useGetOrganizationProfile } from 'feature/hooks';
import { getText, getTextIn } from 'localization';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SearchParams, useUrlParams } from 'utils/url-param';

const SORT_BY = ['created,desc'];

const getOfferText = getTextIn('offer');

const ProviderOffersPage = () => {
  useSetDocumentTitle(getOfferText('offers'));
  const { providerId } = useParams();
  const profile = useGetOrganizationProfile(providerId);

  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [search, setSearchParamWithPageSetback] = useUrlParams();

  const customItem = {
    2: {
      title: profile.data?.name ?? getText('profilePage')
    }
  };

  const page = Number(search[SearchParams.page] ?? 0);

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    organization: providerId,
    page,
    size: rowsPerPage,
    sort: SORT_BY
  });

  const { handlePageClick, totalElements } = usePageClick({
    dataPage: offers?.page,
    page,
    setSearchParamWithPageSetback
  });

  return (
    <ContentWrapper
      additionalSx={{ maxWidth: { lg: 1140 }, px: { lg: '0 !important' } }}
    >
      <BreadcrumbNavigation customItem={customItem} />
      <Typography variant="h1">
        {profile.data?.name
          ? `${profile.data?.name}s ${getOfferText('offers')}`
          : getOfferText('offers')}
      </Typography>

      <OfferGrid
        isError={isError}
        isLoading={isLoading}
        offers={offers?.embedded?.offers}
        totalElements={totalElements}
      />

      <Pagination
        count={totalElements}
        page={page}
        setPage={handlePageClick}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </ContentWrapper>
  );
};

export default ProviderOffersPage;
