/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect, useState } from 'react';
import { Box, Popover, TextField } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { getText } from 'localization';

export const hexFormat = new RegExp('^#[A-Fa-f0-9]{6}$');

type ColorPickerProps = {
  globalColor: string;
  handleGlobalColorChange: (newValue: string) => void;
  dataCyPrefix: string;
  resetClicked?: boolean;
};

const ColorPicker = ({
  globalColor,
  handleGlobalColorChange,
  dataCyPrefix,
  resetClicked = false
}: ColorPickerProps) => {
  const [color, setColor] = useState<string>(globalColor);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updateGlobalColor = () => {
    handleGlobalColorChange(color);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateGlobalColor();
  };

  const open = Boolean(anchorEl);

  const validateColor = (c: string): boolean => hexFormat.test(c);

  useEffect(() => {
    setColor(globalColor);
  }, [globalColor, resetClicked]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'flex-start'
        }}
      >
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{
            bgcolor: color,
            width: '2rem',
            height: '2rem',
            borderRadius: 1
          }}
        ></Box>
        <TextField
          error={!validateColor(color)}
          helperText={
            validateColor(color)
              ? ''
              : getText('colorHelperText', 'settings-appearance')
          }
          variant="standard"
          value={color.toUpperCase()}
          onChange={(event) => setColor(event.target.value)}
          onBlurCapture={() => {
            if (validateColor(color)) {
              updateGlobalColor();
            } else {
              setColor(globalColor);
            }
          }}
          sx={{ width: 165 }}
          data-cy={`${dataCyPrefix}color-textfield`}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          mt: 1,
          '.MuiPaper-root': {
            borderRadius: '9px'
          }
        }}
      >
        <Box
          sx={{
            overflow: 'hidden'
          }}
        >
          <HexColorPicker color={color} onChange={setColor} />
        </Box>
      </Popover>
    </>
  );
};

export default ColorPicker;
