/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  type CreateEventSuggestionsRequest,
  type EventSuggestionCreate
} from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useBooking } from 'context/booking';
import { useGetProjectId } from 'context/project';
import useEventSuggestionsApi from './useEventSuggestionApi';

const useGetEventSuggestions = (offerIds: string[], offset?: number) => {
  const { getEventSuggestions } = useEventSuggestionsApi();
  const projectId = useGetProjectId();
  const { requestedGeoAreaId, requestedTimeRange } = useBooking();

  const eventRequestParameters: CreateEventSuggestionsRequest = {
    projectId: projectId ?? '',
    eventRequests: {
      offerIds,
      geoAreaId: requestedGeoAreaId,
      startTime: requestedTimeRange?.startTime,
      endTime: requestedTimeRange?.endTime
    } as EventSuggestionCreate,
    offset
  };
  return useQuery(
    ['eventSuggestions', eventRequestParameters],
    () => {
      return getEventSuggestions(eventRequestParameters);
    },
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: !!projectId && !!requestedGeoAreaId && offerIds.length !== 0,
      select: (data) => {
        if (data?.possibleTimeSlots) {
          return data.possibleTimeSlots;
        } else return [];
      }
    }
  );
};

export default useGetEventSuggestions;
