/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import AccessTimeIcon from '@mui/icons-material/AccessTimeFilled';
import {
  type EntityModelOrganizationProfile,
  type EntityModelBusinessHours,
  type TimeRange
} from '@SLR/solution3-sdk';
import { useGetBusinessHours } from 'feature/hooks';
import { getTextIn, getText } from 'localization';
import Address from 'components/address';
import { isEmptyObject } from 'utils/helper';
import { getFormattedRangeString } from 'utils/date';
import { AvatarFallback } from 'feature/user-avatar';

const getWeekDayText = getTextIn('weekDay');

const AVATAR_SIZE = { xs: 140, lg: 170 };
const AVATAR_SX = {
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  mx: 'auto'
};

const ContactLink = ({ href, text }: { href: string; text: string }) => {
  return (
    <Link
      href={href}
      target="_blank"
      underline="none"
      color="black"
      sx={{ wordBreak: 'break-all' }}
    >
      {text}
    </Link>
  );
};

const BusinessHoursRow = ({
  day,
  businessHours
}: {
  day: string;
  businessHours?: TimeRange[];
}) => {
  return (
    <Stack key={day} direction="row" justifyContent="space-between" spacing={1}>
      <>{getWeekDayText(day)}</>
      <Stack>
        {businessHours?.map((timeRange, index) => (
          <Stack
            key={`${day}-${index}`}
            direction="row"
            spacing={1}
            sx={{ mr: 4 }}
          >
            <span>
              {getFormattedRangeString(
                timeRange?.startTime.toString().slice(0, 5),
                timeRange?.endTime.toString().slice(0, 5)
              )}
            </span>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const getContactDetails = ({
  providerProfile,
  businessHours
}: {
  providerProfile?: EntityModelOrganizationProfile;
  businessHours?: EntityModelBusinessHours;
}) => {
  const contactDetails = [
    {
      icon: <PlaceIcon color="secondary" fontSize="large" />,
      text: providerProfile?.street ? (
        <Stack spacing={0.5}>
          <Address
            address={{
              street: providerProfile?.street,
              houseNumber: providerProfile?.houseNumber,
              zipCode: providerProfile?.zipCode,
              city: providerProfile?.city
            }}
          />
        </Stack>
      ) : undefined,
      dataCy: 'address'
    },
    {
      icon: <PhoneIcon color="secondary" fontSize="large" />,
      text: providerProfile?.phone ? (
        <ContactLink
          href={`tel:${providerProfile.phone}`}
          text={providerProfile.phone}
        />
      ) : undefined,
      dataCy: 'phone'
    },
    {
      icon: <MailIcon color="secondary" fontSize="large" />,
      text: providerProfile?.email ? (
        <ContactLink
          href={`mailto:${providerProfile.email}`}
          text={providerProfile.email}
        />
      ) : undefined,
      dataCy: 'mail'
    },
    {
      icon: <LanguageIcon color="secondary" fontSize="large" />,
      text: providerProfile?.website ? (
        <ContactLink
          href={providerProfile.website}
          text={providerProfile.website}
        />
      ) : undefined,
      dataCy: 'website'
    }
  ];

  if (
    businessHours?.businessHours &&
    !isEmptyObject(businessHours.businessHours)
  ) {
    const businessHoursDetails = {
      icon: <AccessTimeIcon color="secondary" fontSize="large" />,
      text: (
        <Stack alignItems="stretch" spacing={1}>
          {Object.entries(businessHours.businessHours).map(([key, value]) => {
            return (
              <BusinessHoursRow key={key} day={key} businessHours={value} />
            );
          })}
        </Stack>
      ),
      dataCy: 'businessHours'
    };

    contactDetails.push(businessHoursDetails);
  }

  return contactDetails;
};

type ProviderProfileCardProps = {
  providerProfile?: EntityModelOrganizationProfile;
  showProviderName?: boolean;
  showBusinessHours?: boolean;
  providerNameVariant?: TypographyProps['variant'];
  urlMore?: string;
  textMore?: string;
};

const ProviderProfileCard = ({
  providerProfile,
  showProviderName,
  showBusinessHours,
  providerNameVariant = 'h1',
  urlMore,
  textMore = getText('more')
}: ProviderProfileCardProps) => {
  const { data: businessHours } = useGetBusinessHours(
    providerProfile?.organizationId
  );

  const contactDetails = getContactDetails({
    providerProfile,
    businessHours: showBusinessHours ? businessHours : undefined
  });

  const isCardSecondaryInfo = providerNameVariant === 'h2';

  return (
    <Card sx={{ zIndex: 10, minWidth: 350 }}>
      <Box
        sx={{
          pt: { xs: 3, lg: 3.75 },
          pb: { xs: 2.5, lg: 5 },
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {providerProfile ? (
          <Avatar
            alt={providerProfile?.logo?.alternativeText}
            src={providerProfile?.logo?.urls.small}
            sx={AVATAR_SX}
          >
            <AvatarFallback
              isOrganization={!!providerProfile?.organizationId}
            />
          </Avatar>
        ) : (
          <Skeleton variant="circular">
            <Avatar sx={AVATAR_SX} />
          </Skeleton>
        )}
      </Box>
      <CardContent sx={{ py: 0 }}>
        {showProviderName && (
          <Typography
            variant={providerNameVariant}
            color={isCardSecondaryInfo ? 'secondary' : 'primary'}
            sx={{ textAlign: 'start' }}
          >
            {providerProfile?.name ?? <Skeleton />}
          </Typography>
        )}
        {contactDetails.map(({ dataCy, text, icon }, index) => (
          <Box key={`contact-detail-${index}`}>
            {text && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  mb: 2,
                  overflowWrap: 'anywhere'
                }}
              >
                <Box sx={{ mt: -0.5 }}>{icon}</Box>
                <Typography
                  component="div"
                  data-cy={`contact-details-${dataCy}`}
                  sx={{ width: '100%' }}
                >
                  {text}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
        {urlMore && (
          <Box key="button" sx={{ textAlign: 'right' }}>
            <Button
              component={RouterLink}
              to={urlMore}
              variant="text"
              color="primary"
            >
              {textMore}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProviderProfileCard;
