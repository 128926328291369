/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Loadable } from 'types';
import GenericParticipantCard, { toAvatar } from './generic-participant-card';
import { type EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import { getProviderProfileUrl, getSeekerProfileUrl } from 'routes';
import { Role } from 'utils/roles';

interface Props {
  participantInfo: EntityModelParticipantInfo;
  role: Role;
}

const ParticipantCard = ({
  participantInfo,
  role,
  loading = false
}: Loadable<Props>) => {
  const title = participantInfo?.name ?? '';
  const avatarProps = toAvatar(participantInfo?.picture);
  const description = participantInfo?.description ?? '';
  const url =
    role === 'provider'
      ? getProviderProfileUrl(participantInfo?.participantId)
      : role === 'seeker'
      ? getSeekerProfileUrl(participantInfo?.participantId)
      : '';

  return (
    <GenericParticipantCard
      title={title}
      avatar={avatarProps}
      description={description}
      address=""
      url={url}
      loading={loading}
      isOrganization={!participantInfo?.user}
    />
  );
};

export default ParticipantCard;
