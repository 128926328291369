/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { MouseEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useKeycloakAuth } from 'auth';
import { useIsProvider, useUser } from 'context/user';
import { Logout, UserAvatar } from 'feature';
import MenuItemLink from 'feature/menu/menu-item-link';
import NavUserAvatar from './nav-user-avatar';
import OtherProfiles from './menu/user-menu-other-profiles';
import { getProviderProfileUrl, getSeekerProfileUrl } from 'routes';
import { getTextIn, getPath, getText } from 'localization';
import { Add } from '@mui/icons-material';
import { MenuDivider } from 'components';

const getTextUser = getTextIn('user');

const UserMenu = () => {
  const { isAuthenticated } = useKeycloakAuth();
  const { isLoadingCurrentUser, perspective, mayCreateOffers } = useUser();
  const isProvider = useIsProvider();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { isOrganization } = perspective;

  /**
   * Open the user menu
   * @param event "Trigger" event of the clicked html element
   */
  const openUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const closeUserMenu = () => {
    setAnchorElUser(null);
  };

  const location = useLocation();
  useEffect(() => {
    closeUserMenu();
  }, [location]);

  return (
    <Stack>
      <Box
        sx={{
          display: isAuthenticated ? 'flex' : 'none',
          alignItems: 'center'
        }}
      >
        <NavUserAvatar onUserAvatarClick={openUserMenu} />
        {!isLoadingCurrentUser && (
          <Menu
            id="menu-appbar"
            sx={{ mt: 9 }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            disableScrollLock
            open={Boolean(anchorElUser)}
            onClose={closeUserMenu}
            slotProps={{
              paper: {
                sx: {
                  width: 264,
                  mt: { xs: -2.5, md: 0.5 }
                }
              }
            }}
          >
            {[
              <MenuItem
                key="user-avatar"
                disableRipple
                sx={{
                  justifyContent: 'center',
                  pt: 3,
                  pb: 1.25,
                  cursor: 'default',
                  bgcolor: 'transparent !important'
                }}
              >
                <UserAvatar sx={{ width: 85, height: 85 }} />
              </MenuItem>,
              isOrganization && (
                <MenuItemLink
                  key="my-profile"
                  data-cy="my-profile"
                  itemText={getTextUser('myProfile')}
                  linkTo={
                    isProvider
                      ? getProviderProfileUrl(perspective.id)
                      : getSeekerProfileUrl(perspective.id)
                  }
                />
              ),
              isOrganization && (
                <MenuItemLink
                  key="manage-account"
                  itemText={getTextUser('manageAccount')}
                  linkTo={getPath('account')}
                  sx={{ mb: 1 }}
                />
              ),
              mayCreateOffers && <MenuDivider key="add-offer-divider" />,
              mayCreateOffers && (
                <MenuItemLink
                  key="create-offer"
                  data-cy="create-offer"
                  itemText={getText('createOffer', 'offer')}
                  linkTo={`${getPath('offers')}/${getPath('create')}`}
                  icon={
                    <Add
                      fontSize="small"
                      sx={{ color: 'primary.main', mr: 1 }}
                    />
                  }
                />
              ),

              <OtherProfiles
                key="other-profiles"
                closeUserMenu={closeUserMenu}
              />,
              <MenuDivider key="logout-divider" />,
              <Logout key="logout" />
            ].flat()}
          </Menu>
        )}
      </Box>
    </Stack>
  );
};

export default UserMenu;
