/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { type ImageUpdate } from '@SLR/solution3-sdk';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { ContentWrapper } from 'feature';
import {
  RequestErrorFallback,
  notifyMutationError,
  notifyMutationSuccess
} from 'feature/error';
import {
  EditableOfferForm,
  type Offer,
  EMPTY_OFFER,
  toFormRepresentation,
  toUpdateRepresentation
} from 'feature/offers';
import { convertDeleteValue } from 'feature/forms';
import { useUpdateOffer, useGetOffer } from 'feature/hooks';
import { Typography } from '@mui/material';
import { useSetDocumentTitle } from 'context/project';
import { getText, getPath } from 'localization';
import { Restricted } from 'routes';
import { useUser } from 'context/user';

const OfferEditPage = () => {
  useSetDocumentTitle(getText('editOffer', 'offer'));
  const { offerId = '' } = useParams();
  const { perspective } = useUser();
  const navigate = useNavigate();
  const getOffer = useGetOffer(offerId);
  const updateOffer = useUpdateOffer(offerId);

  const offerData = useMemo(
    () => toFormRepresentation(getOffer.data),
    [getOffer.data]
  );

  const offerUrl = getPath('getOffer', {
    category: getOffer.data?.category?.name,
    offerId
  });

  const handleConfirm = useCallback(
    (offer: Offer) => {
      convertDeleteValue<Offer, ImageUpdate>(offer, 'picture');

      updateOffer.mutate(toUpdateRepresentation(offer), {
        onSuccess: (data) => {
          notifyMutationSuccess();
          navigate(
            getPath('getOffer', {
              category: data?.category?.name,
              offerId: data.id
            })
          );
        },
        onError: () => {
          notifyMutationError();
        }
      });
    },
    [updateOffer, navigate]
  );

  const title = getOffer.data?.title ?? getText('offer', 'offer');

  return (
    <Restricted
      isVisible={getOffer.data?.organizationId === perspective.id}
      forceLogin
    >
      <RequestErrorFallback queryResult={getOffer}>
        <ContentWrapper breadcrumbOnly>
          <BreadcrumbNavigation
            customItem={{
              2: {
                title,
                path: getPath('getOffer', {
                  category: getOffer.data?.category?.name,
                  offerId
                })
              }
            }}
          />

          <Typography variant="h1" align="center">
            {getText('editOffer', 'offer')}
          </Typography>
        </ContentWrapper>

        <EditableOfferForm
          defaultOffer={EMPTY_OFFER}
          offer={offerData}
          inProgress={updateOffer.isLoading}
          onConfirm={handleConfirm}
          onCancel={() => navigate(offerUrl)}
        />
      </RequestErrorFallback>
    </Restricted>
  );
};

export default OfferEditPage;
