/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const replacerTable: Record<string, string> = {
  '\u00df': 'ss',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00fc': 'ue',
  '\u00c4': 'Ae',
  '\u00d6': 'Oe',
  '\u00dc': 'Ue'
};

/**
 * Escape umlauts in the given text
 * @param text Text (optionally including umlauts) to escape
 * @returns The escaped text
 */
export function escapeUmlauts(text: string) {
  return text
    .split('')
    .map((char) => replacerTable[char] ?? char)
    .join('');
}

const fragmentSpecialCharactersRegex = /[^a-zA-Z0-9\s\-#_]/g;
const wordDelimiterRegex = /[\s\-#_]+/g;

export function transformToFragment(text: string) {
  return escapeUmlauts(text)
    .replace(fragmentSpecialCharactersRegex, '')
    .trim()
    .replace(wordDelimiterRegex, '-')
    .toLowerCase();
}
