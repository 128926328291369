/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FormControlLabel, Stack, Switch } from '@mui/material';
import { InfoSymbol } from 'feature/forms';
import SubFormWrapper from './subform-wrapper';

type SwitchSubFormProps = {
  checked: boolean;
  handleChange: (value: boolean) => void;
  title: string;
  info: string;
  switchDataCy: string;
  children: React.ReactNode;
};

const SwitchSubForm = ({
  checked,
  handleChange,
  title,
  info,
  switchDataCy,
  children
}: SwitchSubFormProps) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={(event) => handleChange(event.target.checked)}
              data-cy={switchDataCy}
            />
          }
          label={title}
        />
        <InfoSymbol info={info} />
      </Stack>

      {children && checked && <SubFormWrapper>{children}</SubFormWrapper>}
    </>
  );
};

export default SwitchSubForm;
