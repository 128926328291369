/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SentimentDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentDissatisfiedTwoTone';
import { getErrorText, getPath, getTextIn } from 'localization';
import FeedbackScreen from './feedback-screen';

const getInvitationText = getTextIn('invitation');

const NoOrganizationRightsScreen = () => {
  const icon = <SentimentDissatisfiedTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = <Typography>{getInvitationText('noOrgRights')}</Typography>;
  const button = (
    <Button
      variant="contained"
      component={RouterLink}
      to={'/' + getPath('contact')}
    >
      {getInvitationText('contact')}
    </Button>
  );

  return (
    <FeedbackScreen
      title={`${getErrorText('oops')} ${getErrorText('somethingWentWrong')}`}
      icon={icon}
      text={text}
      hasHomePageButton={true}
      actionButton={button}
      dataCy="norightsfororganizationscreen"
    />
  );
};

export default NoOrganizationRightsScreen;
