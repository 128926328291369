/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const NODE_ENV = process.env.NODE_ENV as string;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

export const KEYCLOAK_BASE_URL = process.env
  .REACT_APP_KEYCLOAK_BASE_URL as string;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM as string;
export const KEYCLOAK_CLIENT_ID = process.env
  .REACT_APP_KEYCLOAK_CLIENT_ID as string;

export const USERPORTAL_URL = process.env.REACT_APP_USERPORTAL_URL as string;

export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL as string;

export const IS_DEVELOPMENT: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
