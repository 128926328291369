/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Registration } from '@SLR/solution3-sdk';
import { getTextIn } from 'localization';
import { useProject } from '..';

export type UserType = 'user' | 'organization';

const useGetAvailableRoles = (type: UserType) => {
  const { project } = useProject();
  const getRoleText = getTextIn('role');

  const options = [];

  const ROLE_RADIO_OPTION = {
    PROVIDER: { value: 'provider', label: getRoleText('provider') },
    SEEKER: { value: 'seeker', label: getRoleText('seeker') }
  };

  switch (type) {
    case 'user': {
      if (project?.seekerUsersRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.SEEKER);
      }
      if (project?.providerUsersRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.PROVIDER);
      }
      break;
    }
    case 'organization': {
      if (project?.seekerOrganizationsRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.SEEKER);
      }
      if (project?.providerOrganizationsRegistration !== Registration.None) {
        options.push(ROLE_RADIO_OPTION.PROVIDER);
      }
      break;
    }
  }
  return {
    key: 'role',
    options
  };
};

export default useGetAvailableRoles;
