/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useState } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { Button, Stack } from '@mui/material';
import {
  type PagedModelEntityModelKeyword,
  type EntityModelKeyword
} from '@SLR/solution3-sdk';
import { useListKeywords } from 'feature/hooks';
import SearchFieldAutocomplete from 'components/search-field-autocomplete';
import { type Keyword } from './model';

const getSelectableKeywords = (
  listKeywords: UseQueryResult<PagedModelEntityModelKeyword, unknown>,
  keywordsToExclude: Keyword[]
) =>
  (listKeywords?.data?.embedded?.keywords ?? []).filter(
    (keyword) =>
      !keywordsToExclude.some(
        (keywordToExclude) => keywordToExclude.id === keyword.id
      )
  );

interface Props {
  label: string;
  selectCaption?: string;
  excludedOptions?: Keyword[];
  error?: boolean;
  errorMessage?: string;
  onSelectOption: (option: Keyword | string) => void;
}

const ArticleKeywordsSelector = ({
  label,
  selectCaption,
  error,
  errorMessage,
  excludedOptions = [],
  onSelectOption
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const listKeywords = useListKeywords(query);
  const [selectedKeyword, setSelectedKeyword] = useState<
    EntityModelKeyword | string
  >();

  const selectableOptions = getSelectableKeywords(
    listKeywords,
    excludedOptions
  );

  const { isFetching } = listKeywords;

  const handleSelect = useCallback(() => {
    if (selectedKeyword) {
      onSelectOption(selectedKeyword);
    }

    // Reset search field
    setQuery('');
    setSelectedKeyword(undefined);
  }, [onSelectOption, selectedKeyword]);

  return (
    <Stack direction="row" spacing={2} alignItems="stretch">
      {SearchFieldAutocomplete<EntityModelKeyword>({
        label,
        query,
        selectableOptions,
        isFetching,
        error,
        errorMessage,
        onQueryChanged: setQuery,
        selectedOptionName:
          typeof selectedKeyword === 'string'
            ? selectedKeyword
            : selectedKeyword?.name ?? '',
        onOptionSelectedNew: setSelectedKeyword,
        onAccept: handleSelect
      })}

      <Button
        variant="outlined"
        disabled={!selectedKeyword}
        onClick={handleSelect}
        sx={{ height: 56 }}
        data-cy="keywordselector-select-button"
      >
        {selectCaption}
      </Button>
    </Stack>
  );
};

export default ArticleKeywordsSelector;
