/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Typography } from '@mui/material';
import { useBooking } from 'context/booking';
import { DialogGeneric } from 'feature';
import { getTextIn } from 'localization';

const getFilterText = getTextIn('filter');

const RemoveFilterDialog = ({
  showDialog,
  onClose,
  removeFilters,
  bodyText
}: {
  showDialog: boolean;
  onClose: () => void;
  removeFilters: () => void;
  bodyText: string;
}) => {
  const { clearBookingList } = useBooking();
  return (
    <DialogGeneric
      open={showDialog}
      onClose={onClose}
      onConfirm={() => {
        clearBookingList();
        removeFilters();
      }}
      confirmText={getFilterText('delete')}
      confirmColor="error"
      title={getFilterText('removeFilterDialogTitle')}
      prefix="remove-filters"
      maxWidth="md"
    >
      <Typography>{bodyText}</Typography>
    </DialogGeneric>
  );
};

export default RemoveFilterDialog;
