/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState, useEffect, PropsWithChildren, ReactNode } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { type ErrorInformation } from '@SLR/solution3-sdk';
import useProjectNavigate from 'utils/useProjectNavigate';
import { getErrorText } from 'localization';

interface Props {
  title?: string;
  description?: string | ReactNode;
  response?: Response | null;
}

const RequestFailed = ({
  title = getErrorText('somethingWentWrong'),
  description = (
    <>
      {getErrorText('errorOnPage')}
      <br />
      {getErrorText('tryAgain')}
    </>
  ),
  response
}: PropsWithChildren<Props>) => {
  const { navigateBack, navigateToHome } = useProjectNavigate();
  const [errorInfo, setErrorInfo] = useState<ErrorInformation>();

  useEffect(() => {
    if (!response?.bodyUsed) {
      response?.json().then((data: ErrorInformation) => {
        setErrorInfo(data);
      });
    }
  }, [response]);

  return (
    <Stack alignItems="center" spacing={2} mt={4} mb={4} data-cy="error">
      <Typography variant="h1">{getErrorText('oops')}</Typography>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>
      <Typography textAlign="center">{description}</Typography>

      {errorInfo?.trace && (
        <Typography color="primary">
          {getErrorText('errorId')}: {errorInfo.trace}
        </Typography>
      )}

      <Stack direction="row" spacing={2} pt={4}>
        <Button variant="outlined" onClick={navigateBack}>
          {getErrorText('back')}
        </Button>
        <Button variant="contained" onClick={navigateToHome}>
          {getErrorText('toHome')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default RequestFailed;
