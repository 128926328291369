/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type ListBookableOffersRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useProject } from 'context/project';
import useOffersApi, { OFFERS_LIST_KEY } from './useOffersApi';
import { useIsSeeker } from 'context/user';
import { useBooking } from 'context/booking';
import { getMaxExpired } from 'utils/duration';

type OffersListFilters = Omit<ListBookableOffersRequest, 'projectId'>;

const useGetOffers = (params: OffersListFilters) => {
  const { getOffers, getBookableOffers } = useOffersApi();
  const { project } = useProject();
  const { isOffersBookable, offers } = useBooking();
  const projectId = project?.id;

  const isSeeker = useIsSeeker();

  const shouldUseBookingEndpoint = !!(
    isOffersBookable &&
    isSeeker &&
    params.locationTypes &&
    params.geoAreaId &&
    (params.fromDate || params.toDate)
  );

  const offerIdsList = offers.map((o) => o.id);

  const offerParams = {
    ...params,
    projectId: projectId ?? '',
    maxExpired: getMaxExpired(),
    offerIds: offerIdsList.length > 0 ? new Set(offerIdsList) : undefined
  };

  return useQuery(
    [
      OFFERS_LIST_KEY,
      offerParams,
      shouldUseBookingEndpoint ? offerIdsList : null
    ],
    () => {
      return (shouldUseBookingEndpoint ? getBookableOffers : getOffers)(
        offerParams
      );
    },
    {
      enabled: !!projectId
    }
  );
};

export default useGetOffers;
