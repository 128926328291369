/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from 'components/loading-button';
import { getText } from 'localization';

type CtrlButtonsProps = {
  disabled: boolean;
  isLoading: boolean;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
};

const CtrlButtons = ({
  disabled,
  isLoading,
  onConfirm,
  onCancel
}: CtrlButtonsProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={{ xs: 2, sm: 7.25 }}
      sx={{
        my: 8,
        flexWrap: 'wrap'
      }}
    >
      <Button variant="text" onClick={onCancel}>
        {getText('cancel')}
      </Button>

      <LoadingButton
        dataCy="project-settings"
        label={getText('save')}
        loading={isLoading}
        disabled={disabled}
        onClick={onConfirm}
      />
    </Stack>
  );
};

export default CtrlButtons;
