/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FormControlLabel, Radio, Stack } from '@mui/material';
import { InfoSymbol } from 'feature/forms';
import SubFormWrapper from './subform-wrapper';

type RadioSubFormProps = {
  name: string;
  value: string;
  checked: boolean;
  onChange: VoidFunction;
  title: string;
  info: string;
  prefixCy?: string;
  children?: React.ReactNode;
};

const RadioSubForm = ({
  name,
  value,
  checked = false,
  onChange,
  title,
  info,
  prefixCy = name,
  children
}: RadioSubFormProps) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Radio
              checked={checked}
              onChange={onChange}
              name={name}
              value={value}
              data-cy={`${prefixCy}-radio`}
            />
          }
          label={title}
        />
        <InfoSymbol info={info} />
      </Stack>

      {children && checked && <SubFormWrapper>{children}</SubFormWrapper>}
    </>
  );
};

export default RadioSubForm;
