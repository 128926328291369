/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import {
  SettingsAppearance,
  SettingsGeneral,
  SettingsVisibility,
  SettingsParticipants,
  SettingsCategories,
  SettingsTargetGroups
} from 'pages/settings';
import { Restricted } from 'routes';
import { useIsAdmin } from 'context/user';
import { getPath } from 'localization';
import { useProject } from 'context/project';

const SettingsRoutes = () => {
  const isAdmin = useIsAdmin();
  const { project } = useProject();
  return (
    <Restricted isVisible={isAdmin} forceLogin={true}>
      <Routes>
        <Route index element={<Navigate replace to={getPath('general')} />} />
        <Route path={getPath('general')} element={<SettingsGeneral />} />
        <Route path={getPath('appearance')} element={<SettingsAppearance />} />
        <Route path={getPath('visibility')} element={<SettingsVisibility />} />
        <Route
          path={getPath('participants')}
          element={<SettingsParticipants />}
        />
        <Route path={getPath('categories')} element={<SettingsCategories />} />
        <Route
          path={getPath('targetGroups')}
          element={
            <Restricted isVisible={project?.features?.targetGroups ?? false}>
              <SettingsTargetGroups />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </Restricted>
  );
};

export default SettingsRoutes;
