/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import { Schema, type TestConfig } from 'yup';
import { isNotNegativeInteger } from 'utils/helper';
import { getTextIn } from 'localization';

dayjs.extend(durationPlugin);

const DURATION_CONFIG = { min: 0, max: 480, step: 15 };

const CUSTOM_DURATION_REGEX = /^(?:(0?[0-7]):([0-5][0-9])|(0?8):(00))$/;

const getBookingText = getTextIn('offer-booking');

const getIsValidDurationFormat = (duration: string) =>
  duration === '' ||
  (!/^(0?0):(00)$/.test(duration) && /^\d{1,2}:\d{2}$/.test(duration));

const getIsValidDurationRange = (duration: string) =>
  duration === '' || CUSTOM_DURATION_REGEX.test(duration);

const getIsValidDurationRangeFilter = (duration: string) =>
  duration === '' ||
  (!/^(0?0:0?0)$/.test(duration) &&
    /^(?:(0?[0-7]):([0-5][0-9])|(0?8):(00))$/.test(duration));

const durationTests = {
  format: getIsValidDurationFormat,
  range: getIsValidDurationRange,
  rangeFilter: getIsValidDurationRangeFilter
};

const getIsValidDuration = (
  message: string,
  testType?: keyof typeof durationTests,
  name = 'valid-duration-range'
): TestConfig<Schema['__outputType']> => {
  return {
    name,
    test: durationTests[testType || 'range'],
    message
  };
};

const getDuration = (match: RegExpExecArray) => {
  return dayjs.duration({
    hours: Number(match[1] || match[3]),
    minutes: Number(match[2] || match[4])
  });
};

const durationToMinutes = (
  customDuration: string,
  type: keyof typeof DURATION_CONFIG
) => {
  const match = customDuration && CUSTOM_DURATION_REGEX.exec(customDuration);

  if (match) {
    const duration = getDuration(match);
    const ms = duration['$ms' as keyof typeof duration];
    const minutes = typeof ms === 'number' ? ms / 60000 : 0;

    // cut to range limits
    return Math.max(
      DURATION_CONFIG.min,
      Math.min(minutes, DURATION_CONFIG.max)
    );
  }

  return DURATION_CONFIG[type];
};

const customDurationToIso = (customDuration: string): string | undefined => {
  if (!customDuration) {
    return undefined;
  }
  const match = CUSTOM_DURATION_REGEX.exec(customDuration);

  if (match) {
    return getDuration(match).toISOString();
  }
};

const isoDurationToCustom = (isoDuration?: string): string | undefined => {
  if (!isoDuration) {
    return undefined;
  }
  const duration = dayjs.duration(isoDuration);
  return duration.format('H:mm');
};

const addMissingLeadingZero = (value: string) => {
  const regex = /^([0-8]):([0-5][0-9])$/;
  return value.replace(regex, '0$1:$2');
};

const formatAsDurationString = (duration: string): string => {
  const hours = dayjs.duration(duration).format('H');
  const minutes = dayjs.duration(duration).format('m');
  return `${hours} ${
    hours === '1' ? `${getBookingText('hour')}` : `${getBookingText('hours')}`
  }
          ${minutes} ${
    minutes === '1'
      ? `${getBookingText('minute')}`
      : `${getBookingText('minutes')}`
  }`;
};

const DEFAULT_EXPIRED_DAYS = 14;

const getMaxExpired = (days = DEFAULT_EXPIRED_DAYS) => {
  return isNotNegativeInteger(days)
    ? dayjs
        .duration({
          days
        })
        .toISOString()
    : '';
};

export {
  DURATION_CONFIG,
  customDurationToIso,
  isoDurationToCustom,
  durationToMinutes,
  addMissingLeadingZero,
  formatAsDurationString,
  getIsValidDuration,
  getMaxExpired
};
