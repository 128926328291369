/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Fragment, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ShowProtectedLink, useRouteLinks } from 'routes';
import { getText } from 'localization';

const MenuMobile = ({ routeLinksName }: { routeLinksName: string }) => {
  const location = useLocation();
  const subRoute = location.pathname.split('/')[2];
  const navigationLinks = useRouteLinks(routeLinksName);

  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((isOpen) => !isOpen);
  };

  const MenuList = () => (
    <Box role="presentation">
      <List sx={{ p: 0 }}>
        {navigationLinks.map(
          ({
            path,
            title,
            protect
          }: {
            path: string;
            title: string;
            protect: string;
          }) => {
            const isSelected: boolean = subRoute === path;
            const color = isSelected ? 'primary.main' : 'initial';

            const showProtectedLink = ShowProtectedLink({
              protect
            });

            return (
              showProtectedLink && (
                <Fragment key={`${title}-${path}-mobile`}>
                  <ListItem disablePadding>
                    <Link
                      data-cy={`settings-links-mobile-${subRoute}`}
                      component={RouterLink}
                      to={`../${path}`}
                      sx={{
                        textDecoration: 'none',
                        width: '100%',
                        height: '100%'
                      }}
                    >
                      <ListItemButton
                        selected={isSelected}
                        sx={{
                          px: { xs: 2, sm: 5 },
                          '&.Mui-selected': { bgcolor: 'primary.background' }
                        }}
                      >
                        <ListItemText
                          primary={title}
                          primaryTypographyProps={{
                            sx: { color }
                          }}
                        />
                        <ListItemIcon
                          sx={{
                            minWidth: 'auto',
                            color
                          }}
                        >
                          <ArrowForwardIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    </Link>
                  </ListItem>
                  <Divider />
                </Fragment>
              )
            );
          }
        )}
      </List>
    </Box>
  );

  const Arrow = open ? KeyboardArrowUp : KeyboardArrowDown;
  const boxShadow = open ? 'none' : '0px 3px 6px #00000029';

  return (
    <Box sx={{ mb: 0.5, display: { xs: 'initial', md: 'none' } }}>
      <Button
        endIcon={<Arrow />}
        aria-label="toggle-navigation-menu"
        onClick={toggleMenu}
        disableRipple
        sx={{
          fontSize: 16,
          fontWeight: 'bold',
          color: open ? 'primary.main' : 'initial',
          bgcolor: 'primary.contrastText',
          boxShadow,
          borderRadius: 0,
          width: '100%',
          height: 36,
          justifyContent: 'space-between',
          px: { xs: 2, sm: 5 },
          '&:hover': {
            bgcolor: 'hover.main',
            boxShadow
          },
          '& svg.MuiSvgIcon-fontSizeMedium': {
            fontSize: 24
          }
        }}
      >
        {getText('menu', 'settings')}
      </Button>

      {open && (
        <Box>
          <MenuList />
        </Box>
      )}
    </Box>
  );
};

export default MenuMobile;
