/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { type ArticleCreate } from '@SLR/solution3-sdk';
import { useSetDocumentTitle } from 'context/project';
import { useIsAdmin } from 'context/user';
import { ContentWrapper } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { useCreateArticle, useGetArticleCategory } from 'feature/hooks/compass';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { Restricted } from 'routes';
import { Article, EMPTY_ARTICLE } from './model';
import ArticleForm from './article-form';
import { convertArticlePayload } from './utils';
import { getTextIn, getPath } from 'localization';

const getTextCompass = getTextIn('compass');

const ArticleCreatePage = () => {
  const isAdmin = useIsAdmin();
  const { categoryId = '' } = useParams();
  const articleCategory = useGetArticleCategory({ categoryId });
  const categoryName =
    articleCategory?.data?.name ?? getTextCompass('category');

  const textCreateArticle = getTextCompass('createArticle');

  useSetDocumentTitle(textCreateArticle);

  const navigate = useNavigate();
  const createArticle = useCreateArticle();

  const handleConfirm = useCallback(
    (article: ArticleCreate) => {
      const articleCreate = convertArticlePayload(
        article as unknown as Article
      );

      createArticle.mutate(articleCreate, {
        onSuccess: () => {
          notifyMutationSuccess(getTextCompass('createArticleSuccess'));
          navigate(
            getPath('getCompassCategory', {
              categoryId
            })
          );
        },
        onError: () => {
          notifyMutationError(getTextCompass('createArticleError'));
        }
      });
    },
    [categoryId, createArticle, navigate]
  );

  return (
    <Restricted isVisible={isAdmin} forceLogin={false}>
      <ContentWrapper breadcrumbOnly>
        <BreadcrumbNavigation
          customItem={{
            2: { title: categoryName },
            3: { title: textCreateArticle }
          }}
        />

        <Typography variant="h1" align="left">
          {textCreateArticle}
        </Typography>
      </ContentWrapper>

      <ArticleForm
        categoryId={categoryId}
        article={{ ...EMPTY_ARTICLE, categoryId }}
        inProgress={createArticle.isLoading}
        onConfirm={handleConfirm}
        onCancel={() => navigate(`/${getPath('compass')}/${categoryId}`)}
        createMode
      />
    </Restricted>
  );
};

export default ArticleCreatePage;
