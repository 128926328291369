/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type EntityModelArticle } from '@SLR/solution3-sdk';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LoadingSpinner } from 'components';
import { getErrorText, getPath, getText } from 'localization';
import ArticleCard from './article-card';

const ArticleGrid = ({
  articles,
  isLoading,
  isError,
  noArticlesMsg = getText('noArticlesYet', 'compass')
}: {
  articles?: EntityModelArticle[];
  isLoading: boolean;
  isError: boolean;
  noArticlesMsg?: string | React.ReactNode;
}) => {
  const compassPathname = getPath('compass');

  return !isLoading && articles === undefined ? (
    <Typography>{noArticlesMsg}</Typography>
  ) : (
    <Grid
      container
      spacing={2}
      sx={[
        {
          mb: 2
        }
      ]}
    >
      <>
        {isError ? (
          <Typography variant="h2">{getErrorText('someError')}</Typography>
        ) : isLoading ? (
          <LoadingSpinner dataCy="articleGrid" />
        ) : (
          articles?.map((article: EntityModelArticle, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                xl={3}
                key={index}
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <ArticleCard
                  key={index}
                  title={article?.title}
                  content={article?.content}
                  loading={isLoading}
                  url={`/${compassPathname}/${article?.category.id}/${article?.id}`}
                />
              </Grid>
            );
          })
        )}
      </>
    </Grid>
  );
};

export default ArticleGrid;
