/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Autocomplete, Box, TextField } from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import { type EntityModelOrganization } from '@SLR/solution3-sdk';
import { useListOrganizations } from 'context/user';
import { getTextIn } from 'localization';
import { getLoadingInputProps } from 'components';

const getSettingsText = getTextIn('settings-participants');

export type SearchOrganizationFieldProps = {
  onOrganizationSelected: (org: EntityModelOrganization) => void;
  noOrgSearchResults: Dispatch<SetStateAction<boolean>>;
  error?: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
};

const SearchOrganizationField = ({
  onOrganizationSelected,
  noOrgSearchResults,
  error,
  query,
  setQuery
}: SearchOrganizationFieldProps) => {
  const [searchBoxIsOpen, setSearchBoxIsOpen] = useState(false);
  const listOrganizations = useListOrganizations(query);

  const handleSelect = useCallback(
    (selectedOrg: EntityModelOrganization) => {
      onOrganizationSelected(selectedOrg);
      setQuery('');
    },
    [onOrganizationSelected, setQuery]
  );

  const handleChange = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      value: EntityModelOrganization | string | null
    ) => {
      if (value && typeof value !== 'string') {
        handleSelect(value);
      }
    },
    [handleSelect]
  );

  const handleBlur = useCallback(() => setSearchBoxIsOpen(false), []);

  const handleInputChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: string) => {
      setQuery(value);
      setSearchBoxIsOpen(value.length > 2);
    },
    [setQuery, setSearchBoxIsOpen]
  );

  useEffect(() => {
    noOrgSearchResults(
      query.length > 2 &&
        !listOrganizations?.data?.embedded?.organizations &&
        listOrganizations.isFetched
    );
  }, [query.length, listOrganizations, noOrgSearchResults]);

  return (
    <Autocomplete
      id="organization-name"
      freeSolo
      inputValue={query}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onBlur={handleBlur}
      options={listOrganizations?.data?.embedded?.organizations ?? []}
      getOptionLabel={(option: string | EntityModelOrganization) =>
        typeof option === 'string' ? query : option.name
      }
      renderOption={(props, option: EntityModelOrganization) => (
        <Box
          {...props}
          component="li"
          key={option.id}
          data-cy={`autocomplete-${option.name}`}
        >
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          required
          label={getSettingsText('organizationSearchLabel')}
          error={error}
          InputProps={getLoadingInputProps(
            params,
            listOrganizations.isFetching
          )}
        />
      )}
      open={searchBoxIsOpen}
      handleHomeEndKeys
      selectOnFocus
      noOptionsText={getSettingsText('organizationSearchNoResults')}
      data-cy="organization-search"
    />
  );
};

export default SearchOrganizationField;
