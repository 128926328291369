/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import type { SxProps, Theme } from '@mui/material';
import ItemText from 'feature/item-text';

type MenuItemLinkProps = {
  itemText: string;
  linkTo: string;
  padding?: number | string;
  sx?: SxProps<Theme>;
  icon?: JSX.Element;
};

const MenuItemLink = ({ itemText, linkTo, sx, icon }: MenuItemLinkProps) => {
  return (
    <MenuItem sx={{ px: 2.5, py: 1.25, height: 44, ...sx }}>
      {icon}
      <Link
        data-cy={`menuitemlink-${itemText}`}
        component={RouterLink}
        to={linkTo}
        sx={{
          textDecoration: 'none',
          width: '100%',
          height: '100%'
        }}
      >
        <ItemText text={itemText} />
      </Link>
    </MenuItem>
  );
};

export default MenuItemLink;
