/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FC, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { Close as CloseIcon } from '@mui/icons-material';
import { Backdrop, Box, IconButton } from '@mui/material';
import { type EntityModelGalleryEntry, type Image } from '@SLR/solution3-sdk';
import 'react-image-gallery/styles/css/image-gallery.css';
import { isEmptyArray } from 'utils/helper';

const getHasImages = (images: Array<EntityModelGalleryEntry>) =>
  !isEmptyArray(images);

const findStartIndex = (
  galleryEntries?: Array<EntityModelGalleryEntry> | undefined,
  image?: Image
) => {
  return galleryEntries && getHasImages(galleryEntries) && image
    ? galleryEntries.findIndex((entry) => entry.image.id === image.id)
    : 0;
};

type ImageGalleryDialogProps = {
  galleryEntries?: Array<EntityModelGalleryEntry>;
  image?: Image;
  open: boolean;
  onClose: () => void;
};

const ImageGalleryDialog: FC<ImageGalleryDialogProps> = ({
  galleryEntries,
  image,
  open,
  onClose
}) => {
  // Prevent background scroll
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  const hasImages = galleryEntries && getHasImages(galleryEntries);
  const startIndex = findStartIndex(galleryEntries, image);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        background: 'rgba(0,0,0,0.96)',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={open}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          m: 2,
          '.image-gallery': {
            width: '96vw',
            margin: '0 auto'
          },
          '.image-gallery-description': {
            textAlign: 'center',
            bottom: '30px',
            font: 'normal normal normal 16px/28px Roboto'
          }
        }}
      >
        <ImageGallery
          items={
            hasImages
              ? galleryEntries.map((entry) => ({
                  original: entry.image.urls.original,
                  thumbnail: entry.image.urls.thumbnail,
                  description: entry.description,
                  originalAlt: entry.image.alternativeText
                }))
              : image
              ? [
                  {
                    original: image.urls.original,
                    thumbnail: image.urls.thumbnail
                  }
                ]
              : []
          }
          startIndex={startIndex}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={hasImages}
          slideDuration={250}
          useBrowserFullscreen={false}
        />
      </Box>

      <IconButton
        aria-label="close-gallery"
        onClick={onClose}
        sx={{
          color: 'white',
          position: 'absolute',
          top: 2,
          right: 2
        }}
      >
        <CloseIcon
          fontSize="large"
          sx={{
            height: '1.5em',
            width: '2em',
            filter: 'drop-shadow(0 2px 2px #1a1a1a)',
            '&:hover': { color: '#337ab7' },
            cursor: 'pointer'
          }}
        />
      </IconButton>
    </Backdrop>
  );
};

export default ImageGalleryDialog;
