/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Grid, Typography, Button } from '@mui/material';
import { useUser, useGetPortalOrganizationProfileHref } from 'context/user';
import { getTextIn } from 'localization';

const getTextChangeData = getTextIn('account-data-changeData');

const ChangeData = () => {
  const { perspective } = useUser();
  const organizationId = perspective?.id ?? '';
  const userPortalHref = useGetPortalOrganizationProfileHref(
    organizationId,
    '&showOrgEdit'
  );

  const changeDataText = getTextChangeData('action');

  return (
    <Grid item>
      <Typography component="div" variant="sectionTitle" sx={{ mb: 0.75 }}>
        {changeDataText}
      </Typography>

      <Typography>{getTextChangeData('info')}</Typography>

      <Button
        variant="outlined"
        sx={{ mt: 2.5 }}
        target="_blank"
        href={userPortalHref}
        data-cy="accountdata-gotouserportal"
      >
        {changeDataText}
      </Button>
    </Grid>
  );
};

export default ChangeData;
