/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Delete } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { type Image } from '@SLR/solution3-sdk';
import PlaceholderImage from 'assets/image-placeholder.svg';
import { DialogGeneric, ImageUpdateLogic } from 'feature';
import { useValidation } from 'feature/hooks';
import { getText, getTextIn } from 'localization';
import React from 'react';

const getEditModalText = getTextIn('edit-editHeroImage');

type HeroImageDialogProps = {
  isOpen: boolean;
  handleClose: VoidFunction;
  handleConfirm: VoidFunction;
  image?: Image;
  handleAddImage: (newImage: Image) => void;
  handleAltTextChange: (newText: string) => void;
  handleDelete: VoidFunction;
  isUpdateLoading: boolean;
  title?: string;
};

const HeroImageDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  image,
  handleAddImage,
  handleAltTextChange,
  handleDelete,
  isUpdateLoading,
  title = getEditModalText('title')
}: HeroImageDialogProps) => {
  const [deleteFired, setDeleteFired] = React.useState(false);

  const [isLoadingImage, setIsLoadingImage] = React.useState(false);

  const checkForNotValid = () => {
    return !!image && !!image.id && !image?.alternativeText?.trim();
  };

  const { isNotValid, onChange, onConfirm, onCancel } = useValidation({
    handleChange: handleAltTextChange,
    handleConfirm,
    handleCancel: () => {
      setDeleteFired(false);
      handleClose();
    },
    checkForNotValid
  });

  return (
    <DialogGeneric
      open={isOpen}
      onClose={onCancel}
      onConfirm={onConfirm}
      fullWidth
      isLoading={isUpdateLoading}
      title={title}
      confirmText={getText('save')}
      prefix="hero-image-dialog"
      disableEscapeKeyDown
    >
      <Box
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          bgcolor: 'primary.background',
          backgroundImage: `url(${image?.urls?.medium ?? PlaceholderImage})`,
          backgroundSize: 'cover',
          display: 'flex',
          minHeight: {
            xs: '20vh',
            md: '24vh'
          },
          backgroundPosition: 'center',
          p: 2,
          gap: 2
        }}
      >
        <Button
          variant="outlined"
          size="small"
          color="error"
          disabled={!Boolean(image?.urls)}
          onClick={() => {
            setDeleteFired(true);
            handleDelete();
          }}
          startIcon={<Delete />}
          data-cy="delete-image"
        >
          {getText('delete')}
        </Button>

        <ImageUpdateLogic
          handleAddImage={handleAddImage}
          setIsLoadingImage={setIsLoadingImage}
          inputId="upload-hero-image"
          inputDataCy="imageuploadsection-input"
          label={getEditModalText(image ? 'change' : 'upload')}
          dataCy="hero-image-dialog-upload"
          additionalOnClick={() => setDeleteFired(false)}
        />
      </Box>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="start"
        spacing={2}
        sx={{ pt: 4 }}
      >
        <TextField
          multiline
          fullWidth
          required
          label={getEditModalText('textField')}
          disabled={isLoadingImage || !Boolean(image)}
          value={deleteFired ? '' : image?.alternativeText}
          onChange={onChange}
          inputProps={{
            maxLength: 255
          }}
          sx={{ width: { xs: '100%', md: '50%' } }}
          data-cy="alternativeTextTextField"
          error={isNotValid}
          helperText={isNotValid && getEditModalText('helperError')}
        />

        <Typography
          variant="body2"
          sx={{
            color: 'primary.main',
            fontWeight: 500,
            whiteSpace: 'pre-wrap'
          }}
        >
          {`${getEditModalText(
            image ? 'helperUpdate' : 'helperAdd'
          )}\n${getEditModalText('bannerHelperAddition')}`}
        </Typography>
      </Stack>
    </DialogGeneric>
  );
};

export default HeroImageDialog;
