/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { getText } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import useGetProjectAccessibilityText from 'feature/hooks/legal/useGetProjectAccessibilityText';
import LegalTextBox from './LegalTexts/LegalTextBox';
import { useSetDocumentTitle } from 'context/project';

const AccessibilityDeclarationPage = () => {
  const accessibility = getText('accessibility');
  useSetDocumentTitle(accessibility);

  const { data } = useGetProjectAccessibilityText();

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1" textAlign="center">
        {accessibility}
      </Typography>
      <LegalTextBox legalHtml={data ?? ''} />
    </ContentWrapper>
  );
};

export default AccessibilityDeclarationPage;
