/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type EntityModelParticipantInfo } from '@SLR/solution3-sdk';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ParticipantCard, useCardPreviewNumber } from 'feature/cards';
import { getText } from 'localization';
import { Role } from 'utils/roles';

const ParticipantGrid = ({
  participantInfos,
  isLoading,
  isPreview = false,
  role
}: {
  participantInfos?: EntityModelParticipantInfo[];
  isLoading: boolean;
  isPreview?: boolean;
  role: Role;
}) => {
  const previewNumber = useCardPreviewNumber();

  if (!isLoading && participantInfos === undefined) {
    return <Typography>{getText('noParticipantsYet')}</Typography>;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={[
        {
          mb: 2
        }
      ]}
    >
      {(participantInfos ?? Array(previewNumber).fill(null))?.map(
        (participant: EntityModelParticipantInfo, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={isPreview ? 3 : 4}
              key={index}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <ParticipantCard
                key={index}
                participantInfo={participant}
                loading={isLoading}
                role={role}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default ParticipantGrid;
