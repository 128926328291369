/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const IS_ADMIN = 'isAdmin';
const PROVIDER_ORGANIZATIONS_VISIBILITY = 'providerOrganizationsVisibility';
const SEEKER_ORGANIZATIONS_VISIBILITY = 'seekerOrganizationsVisibility';
const OFFER_VISIBILITY = 'offerVisibility';
const OFFERS_BOOKABLE_ORGANIZATION = 'offersBookableOrganization';
const OFFERS_BOOKABLE_SEEKER = 'offersBookableSeeker';
const HAS_TARGET_GROUPS = 'hasTargetGroups';
const HAS_KNOWLEDGE_BASE = 'hasKnowledgeBase';

export {
  IS_ADMIN,
  PROVIDER_ORGANIZATIONS_VISIBILITY,
  SEEKER_ORGANIZATIONS_VISIBILITY,
  OFFER_VISIBILITY,
  OFFERS_BOOKABLE_ORGANIZATION,
  OFFERS_BOOKABLE_SEEKER,
  HAS_TARGET_GROUPS,
  HAS_KNOWLEDGE_BASE
};
