/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Anna Kleiner
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    <http://www.apache.org/licenses/LICENSE-2.0>
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi from './useOffersApi';

const useListOfferLocations = (offerId = '') => {
  const { listOfferLocations } = useOffersApi();
  const projectId = useGetProjectId();

  return useQuery(
    ['offerLocations', { projectId, offerId }],
    () => {
      return listOfferLocations({
        projectId: projectId ?? '',
        offerId
      });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!offerId
    }
  );
};

export default useListOfferLocations;
