/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PropsWithChildren } from 'react';
import RequestFailed from './request-failed';
import { type UseQueryResult } from '@tanstack/react-query';
import { ResponseError } from '@SLR/solution3-sdk';
import { ErrorPageNotFound } from 'pages/error';

interface ErrorInfo {
  title: string;
  description: string;
}

interface Props {
  info?: ErrorInfo;
  queryResult: UseQueryResult<unknown, Response>;
}

export const unwrapErrorResponse = (
  response?: Response
): Response | undefined => {
  return response instanceof ResponseError ? response.response : response;
};

const RequestErrorFallback = ({
  children,
  queryResult,
  info
}: PropsWithChildren<Props>) => {
  if (queryResult.isError) {
    const errorResponse = unwrapErrorResponse(queryResult.error);
    if (errorResponse?.status === 404) {
      return <ErrorPageNotFound />;
    } else {
      return (
        <RequestFailed
          title={info?.title}
          description={info?.description}
          response={errorResponse}
        />
      );
    }
  }

  return <>{children}</>;
};

export default RequestErrorFallback;
