/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import RecurrenceControl from './recurrence-control';
import { useSchemaValidationFormContext } from 'feature/forms';
import { Offer } from './model';
import ValidatedDatePicker, {
  DefaultDateTimePicker
} from 'feature/forms/validated-date-picker';
import { dateToDayjs } from 'utils/date';
import { getTextIn, required } from 'localization';

const getOfferText = getTextIn('offer-details');

const TimeRangeForm = () => {
  const { control, setValueValidating } =
    useSchemaValidationFormContext<Offer>();

  const startTime = useWatch<Offer, 'startTime'>({
    control,
    name: 'startTime'
  });

  const endTime = useWatch<Offer, 'endTime'>({
    control,
    name: 'endTime'
  });

  const handleRecurrenceRuleChanged = useCallback(
    (rrule: string) => setValueValidating('recurrenceRule', rrule),
    [setValueValidating]
  );

  return (
    <Stack gap={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ValidatedDatePicker
            name="startTime"
            render={(props) => (
              <DefaultDateTimePicker
                label={`${required(getOfferText('startTime'))}`}
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                    inputProps: { 'data-cy': 'offerform-timerange-start-edit' }
                  }
                }}
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatedDatePicker
            name="endTime"
            render={(props) => (
              <DefaultDateTimePicker
                label={`${required(getOfferText('endTime'))}`}
                disablePast
                minDateTime={dateToDayjs(startTime)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    inputProps: { 'data-cy': 'offerform-timerange-end-edit' }
                  }
                }}
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
      <Controller
        name="recurrenceRule"
        control={control}
        render={({ field: { ref: _, ...fieldProps } }) => (
          <RecurrenceControl
            start={endTime ?? startTime}
            onValueChanged={handleRecurrenceRuleChanged}
            prefixCy="offerform-timerange-recurrence"
            {...fieldProps}
          />
        )}
      />
    </Stack>
  );
};

export default TimeRangeForm;
