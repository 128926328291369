/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { formatAsDateTime } from 'utils/date';
import { type LocaleObject } from 'yup';
import isString from 'lodash/isString';

export const DE: LocaleObject = {
  mixed: {
    default: '${path} ist ungültig',
    required: '${path} ist ein Pflichtfeld',
    defined: '${path} muss definiert sein',
    notNull: '${path} kann nicht leer sein',
    oneOf: '${path} muss einem der folgenden Werte entsprechen: ${values}',
    notOneOf: '${path} darf keinem der folgenden Werte entsprechen: ${values}',
    notType: ({ path, type, value, originalValue }) => {
      const castMsg =
        originalValue != null && originalValue !== value
          ? ` (Umwandlung von Wert \`${originalValue}\`).`
          : '.';

      return type === 'mixed'
        ? `${path} muss mit dem konfigurierten Wert übereinstimmen. ` +
            `Der validierte Wert war: \`${value}\`` +
            castMsg
        : `${path} muss vom Typ \`${type}\` sein, ` +
            `aber der endgültige Wert war: \`${value}\`` +
            castMsg;
    }
  },
  string: {
    length: '${path} muss genau ${length} Zeichen lang sein',
    min: '${path} muss mindestens ${min} Zeichen lang sein',
    max: '${path} darf höchstens ${max} Zeichen lang sein',
    matches: '${path} muss wie folgt aussehen: "${regex}"',
    email: '${path} muss eine gültige E-Mail-Adresse enthalten',
    url: '${path} muss eine gültige URL sein',
    trim: '${path} darf keine Leerzeichen am Anfang oder Ende enthalten',
    lowercase: '${path} darf nur Kleinschreibung enthalten',
    uppercase: '${path} darf nur Großschreibung enthalten',
    uuid: '${path} muss eine gültige UUID sein'
  },
  number: {
    min: '${path} muss größer oder gleich ${min} sein',
    max: '${path} muss kleiner oder gleich ${max} sein',
    lessThan: '${path} muss kleiner sein als ${less}',
    moreThan: '${path} muss größer sein als ${more}',
    positive: '${path} muss eine positive Zahl sein',
    negative: '${path} muss eine negative Zahl sein',
    integer: '${path} muss eine ganze Zahl sein'
  },
  date: {
    min: ({ min }) =>
      `Datum ab dem ${isString(min) ? min : formatAsDateTime(min)}`,
    max: ({ max }) =>
      `Datum vor dem ${isString(max) ? max : formatAsDateTime(max)}`
  },
  boolean: {
    isValue: '${path} Feld muss ${value} sein'
  },
  object: {
    noUnknown:
      '${path}-Feld darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden'
  },
  array: {
    min: '${path}-Feld muss mindesten ${min} Einträge haben',
    max: '${path}-Feld darf höchstens ${max} Einträge haben',
    length: '${path} mus ${length} Einträge haben'
  }
};
