/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createContext,
  useContext,
  useState,
  ReactElement,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import {
  type EntityModelUser,
  type EntityModelOrganizationDetails,
  type Image
} from '@SLR/solution3-sdk';
import useGetCurrentUser from './hooks/useGetCurrentUser';
import useGetCurrentUsersOrganizations from './hooks/useGetCurrentUsersOrganizations';
import {
  useGetPortalOrganizationProfileHref,
  useGetPortalOrganizationsListHref,
  useGetPortalAddOrganizationHref,
  useGetPortalOrganizationSecurityHref
} from './hooks/useGetPortalOrganizationHref';
import useOrganizationSettings from './hooks/use-organization-settings';
import useUpdateOrganizationSettings from './hooks/use-update-organization-settings';
import useIsOrganizationOwner from './hooks/use-is-organization-owner';
import useListOrganizations from './hooks/useListOrganizations';
import { isEmptyObject, noop } from 'utils/helper';
import { useCrypto } from 'context/crypto';
import { useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import { useBooking } from 'context/booking';

type Roles = {
  isSolutionUser: boolean;
  isAdmin: boolean;
  isSeeker: boolean;
  isProvider: boolean;
};

type Perspective = {
  id: string;
  isOrganization: boolean;
  label: string;
  roles: Roles;
  image?: Image;
  data: {
    firstName?: string;
    lastName?: string;
    orgaName?: string;
    email?: string;
    phone?: string;
    canConfigureEncryption?: boolean;
    supportsEncryption: boolean;
  };
};

// standardize the roles for user and organizations
const getRoles = (
  currentUser: EntityModelUser,
  organization?: EntityModelOrganizationDetails
) => {
  return organization
    ? {
        isSolutionUser: true,
        isAdmin: currentUser.admin,
        isSeeker: organization.selectedRoles.seeker,
        isProvider: organization.selectedRoles.provider
      }
    : {
        isSolutionUser: false,
        isAdmin: currentUser.admin,
        isSeeker: false,
        isProvider: false
      };
};

// standardize the perspective for user and organizations
const getUserPerspective = (currentUser: EntityModelUser) => {
  const { id, firstName, lastName, picture, email } = currentUser;
  const label = `${firstName} ${lastName}`;

  return {
    id,
    isOrganization: false,
    label,
    roles: getRoles(currentUser),
    image: picture,
    data: {
      firstName,
      lastName,
      email,
      supportsEncryption: false
    }
  };
};

const getOrganizationPerspective = (
  currentUser: EntityModelUser,
  organization: EntityModelOrganizationDetails
) => {
  const {
    id,
    name: orgaName,
    logo,
    email,
    phone,
    canConfigureEncryption,
    supportsEncryption
  } = organization;

  return {
    id,
    isOrganization: true,
    label: orgaName,
    roles: getRoles(currentUser, organization),
    image: logo,
    data: {
      orgaName,
      email,
      phone,
      canConfigureEncryption,
      supportsEncryption
    }
  };
};

type ProfileDetails = EntityModelUser | EntityModelOrganizationDetails;

function hasEntityModelUserType(
  userOrOrga: ProfileDetails
): userOrOrga is EntityModelUser {
  return !!(userOrOrga as EntityModelUser).firstName;
}

const getPerspective = (
  currentUser: EntityModelUser,
  nextProfile?: ProfileDetails
) => {
  return !nextProfile || hasEntityModelUserType(nextProfile)
    ? getUserPerspective(currentUser)
    : getOrganizationPerspective(
        currentUser,
        nextProfile as EntityModelOrganizationDetails
      );
};

type ExtendedProfile = {
  profile: EntityModelUser | EntityModelOrganizationDetails;
  isOrganization?: boolean;
};

type IndexedProfilesList = {
  [id: string]: ExtendedProfile;
};

// make a flat index list of all profiles associated to a user (user and organizations profiles)
// the profiles can then be accessed by its id-string
const getIndexedProfilesList = (
  currentUser?: EntityModelUser,
  organizations?: EntityModelOrganizationDetails[]
) => {
  if (currentUser) {
    const profiles: IndexedProfilesList = {
      [currentUser.id]: { profile: currentUser }
    };

    if (organizations) {
      organizations?.reduce((acc, organization) => {
        acc[organization.id] = { profile: organization, isOrganization: true };
        return acc;
      }, profiles);
    }

    return profiles;
  }

  return {};
};

type GetPerspective = (
  currentUser: EntityModelUser,
  nextProfile?: ProfileDetails
) => Perspective;

// some values that could be useful for showcasing
interface UserContextValues {
  isLoadingCurrentUser: boolean;
  isLoadingAndFetchingCurrentUser: boolean;
  isLoadingOrganizations: boolean;
  isLoadingAndFetchingOrganizations: boolean;
  currentUser?: EntityModelUser;
  organizations?: EntityModelOrganizationDetails[];
  perspective: Perspective;
  getPerspective: GetPerspective;
  changePerspective: (toId: string) => void;
  indexedProfilesList: IndexedProfilesList;
  mayCreateOffers: boolean;
  showCreateOffersHint: boolean;
  isEditModeOn: boolean;
  toggleEditMode: VoidFunction;
  showBookableContent: boolean;
  mayBookOffers: boolean;
  hasTwoFaEnabled: boolean;
}

const defaultUserContextValues: UserContextValues = {
  isLoadingCurrentUser: false,
  isLoadingAndFetchingCurrentUser: false,
  isLoadingOrganizations: false,
  isLoadingAndFetchingOrganizations: false,
  perspective: {} as Perspective,
  getPerspective: () => ({} as Perspective),
  changePerspective: noop,
  indexedProfilesList: {},
  mayCreateOffers: false,
  showCreateOffersHint: false,
  isEditModeOn: false,
  toggleEditMode: noop,
  showBookableContent: false,
  mayBookOffers: false,
  hasTwoFaEnabled: false
};

const UserContext = createContext<UserContextValues>(defaultUserContextValues);
const useUser = () => useContext(UserContext);

const useIsAdmin = () => {
  const { perspective } = useUser();
  return perspective.roles?.isAdmin;
};

const useIsProvider = () => {
  const { perspective } = useUser();
  return perspective.roles?.isProvider;
};

const useIsSeeker = () => {
  const { perspective } = useUser();
  return perspective.roles?.isSeeker;
};

interface UserContextProviderProps {
  children: ReactElement;
}

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { isOffersBookable } = useBooking();
  const currentUserQuery = useGetCurrentUser();
  const organizationsQuery = useGetCurrentUsersOrganizations();
  const { forgetPrivateKey } = useCrypto();
  const { clearBookingList } = useBooking();
  const isBreakpointUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isBreakpointUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const currentUser = currentUserQuery.data;
  const organizations = organizationsQuery.data?.embedded?.organizations;
  const hasTwoFaEnabled = currentUser?.twoFactorAuthentication ?? false;

  const [perspective, setPerspective] = useState({} as Perspective);
  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const isSeeker = perspective.roles?.isSeeker;
  const isProvider = perspective.roles?.isProvider;

  const indexedProfilesList = useMemo(
    () => getIndexedProfilesList(currentUser, organizations),
    [currentUser, organizations]
  );

  const changePerspective = useCallback(
    (id: string) => {
      forgetPrivateKey();
      const nextProfile = indexedProfilesList[id];
      if (currentUser && nextProfile) {
        localStorage.setItem('orgaId', nextProfile.isOrganization ? id : '');
        setPerspective(getPerspective(currentUser, nextProfile.profile));
      }
    },
    [forgetPrivateKey, currentUser, indexedProfilesList]
  );

  // hooks in after first currentUser loading
  useEffect(() => {
    if (
      isEmptyObject(perspective) &&
      currentUser &&
      !organizationsQuery.isLoading
    ) {
      let newPerspective = 'noValidUser';
      const orgaId = localStorage.getItem('orgaId');
      /*
      Switch to the last selected organization as the perspective,
      if the user is not a member of the last selected organization, select one of the organizations the user is a member of,
      if user has no organization but is a project admin, switch to user perspective
      if user has no organization and is not a project admin, switch to zombie mode
    */
      if (currentUser.admin) {
        newPerspective = currentUser.id;
      }
      if (organizations && organizations.length >= 0) {
        newPerspective = organizations[0].id;
      }
      if (orgaId) {
        if (organizations?.some((org) => org.id === orgaId)) {
          newPerspective = orgaId;
        }
      }
      changePerspective(newPerspective);
    }
  }, [
    changePerspective,
    currentUser,
    organizations,
    organizationsQuery.isLoading,
    perspective
  ]);

  const toggleEditMode = () => {
    if (perspective.roles?.isAdmin) {
      setIsEditModeOn((prevState) => !prevState);
    }
  };

  const showBookableContent = !!(
    isOffersBookable &&
    isBreakpointUpSm &&
    perspective.isOrganization &&
    (isProvider || isSeeker)
  );

  const mayBookOffers = !!(
    isOffersBookable &&
    isBreakpointUpMd &&
    perspective.isOrganization &&
    isSeeker
  );

  const mayCreateOffers = isOffersBookable
    ? isProvider && perspective.data.supportsEncryption
    : isProvider;

  const showCreateOffersHint = isOffersBookable
    ? isProvider && !perspective.data.supportsEncryption
    : false;

  useEffect(() => {
    if (!isSeeker) {
      clearBookingList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeeker]);

  return (
    // Creating the provider and passing the state into it. Whenever the state changes the components using this context will be re-rendered.
    <UserContext.Provider
      value={{
        isLoadingCurrentUser: currentUserQuery.isLoading,
        isLoadingAndFetchingCurrentUser:
          currentUserQuery.isLoading && currentUserQuery.isFetching,
        isLoadingOrganizations: organizationsQuery.isLoading,
        isLoadingAndFetchingOrganizations:
          organizationsQuery.isLoading && organizationsQuery.isFetching,
        currentUser,
        organizations,
        perspective,
        getPerspective,
        changePerspective,
        indexedProfilesList,
        mayCreateOffers,
        showCreateOffersHint,
        isEditModeOn,
        toggleEditMode,
        showBookableContent,
        mayBookOffers,
        hasTwoFaEnabled
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
export {
  useUser,
  useIsAdmin,
  useIsProvider,
  useIsSeeker,
  useIsOrganizationOwner,
  useListOrganizations,
  useGetPortalOrganizationProfileHref,
  useGetPortalOrganizationsListHref,
  useGetPortalAddOrganizationHref,
  useGetPortalOrganizationSecurityHref,
  useOrganizationSettings,
  useUpdateOrganizationSettings
};

export type { Perspective, GetPerspective };
