/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Calendar,
  type Culture,
  DateLocalizer,
  type DateRange,
  Views,
  dayjsLocalizer,
  type EventProps,
  type HeaderProps,
  type View,
  type Messages
} from 'react-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { ComponentType, useState, useMemo, useEffect } from 'react';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExtendedAgendaView } from 'feature/extended-agenda-view';
import { getText, getTextIn } from 'localization';
import { type EntityModelEvent } from '@SLR/solution3-sdk';
import Address from 'components/address';
import LoadingSpinner from 'components/loading-spinner';
import EventDetailsDialog from './event-details-dialog';
import DeleteEventDialog from './delete-event-dialog';
import { useCrypto } from 'context/crypto';
import useGetOrganizationSpecificKey from 'context/crypto/hooks/useGetOrganizationSpecificKey';
import { useSnackbar } from 'notistack';
import { useUser } from 'context/user';
import { getFormattedRangeString } from 'utils/date';

dayjs.locale('de');
const dayLocalizer = dayjsLocalizer(dayjs);

const getCalendarText = getTextIn('calendar');

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DAY_FORMAT = 'MMM DD dddd';
export const HOUR_FORMAT = 'HH';
export const TIME_FORMAT = 'HH:mm';
export const WEEKDAY_FORMAT = 'dd';

const TABLE_CELL_PADDING = { xs: 0, sm: 0.5, lg: 1 };

const CustomHeader: ComponentType<HeaderProps> | undefined = ({ label }) => {
  const labelContent = label.split(' ');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography sx={{ color: 'primary.main', fontSize: '12px !important' }}>
        {labelContent[0]}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'primary.main',
          fontSize: '20px !important'
        }}
      >
        {labelContent[1]}
      </Typography>
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: 'primary.main',
          fontSize: '12px !important'
        }}
      >
        {labelContent[2]}
      </Typography>
    </Box>
  );
};

const CustomEvent: ComponentType<EventProps<EntityModelEvent>> | undefined = ({
  event
}) => {
  const { perspective } = useUser();
  const { decryptEventDetails, selectedOrganizationPrivateKey } = useCrypto();
  const { enqueueSnackbar } = useSnackbar();
  const { data: encryptedSymMsgKey } = useGetOrganizationSpecificKey(
    perspective.id,
    event.id
  );
  const [decryptedEvent, setDecryptedEvent] = useState<EntityModelEvent>(event);

  useEffect(() => {
    if (encryptedSymMsgKey && selectedOrganizationPrivateKey) {
      decryptEventDetails(
        event,
        encryptedSymMsgKey,
        selectedOrganizationPrivateKey
      )
        .then(setDecryptedEvent)
        .catch((reason) => {
          enqueueSnackbar(
            `${getText('decryptionError', 'crypto')}: ${reason}`,
            {
              variant: 'error'
            }
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedSymMsgKey, selectedOrganizationPrivateKey]);

  const isLongEvent = event.timeSlot
    ? dayjs(event.timeSlot.endTime).diff(
        dayjs(event.timeSlot.startTime),
        'minutes'
      ) > 30
    : false;

  return (
    <Box
      title={event.title}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '14px !important',
            textAlign: 'left !important',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {event.title}
        </Typography>
        {event.online && (
          <VideoCameraFrontIcon color="primary" fontSize="small" />
        )}
      </Box>
      <Typography
        sx={{
          fontSize: '12px !important',
          textAlign: 'left !important',
          textOverflow: 'ellipsis',
          whiteSpace: isLongEvent ? 'unset' : 'nowrap',
          overflow: 'hidden'
        }}
      >
        <Address address={decryptedEvent.eventDetails.fixedLocation} asString />
      </Typography>
    </Box>
  );
};

const formats = {
  dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
    localizer?.format(date, DAY_FORMAT, culture) ?? DAY_FORMAT,
  timeGutterFormat: (
    date: Date,
    culture?: Culture,
    localizer?: DateLocalizer
  ) => `${localizer?.format(date, HOUR_FORMAT, culture) ?? HOUR_FORMAT} Uhr`,
  dayRangeHeaderFormat: (
    range: DateRange,
    culture?: Culture,
    localizer?: DateLocalizer
  ) =>
    getFormattedRangeString(
      localizer?.format(range.start, DATE_FORMAT, culture) ?? DATE_FORMAT,
      localizer?.format(range.end, DATE_FORMAT, culture) ?? DATE_FORMAT
    ),
  eventTimeRangeFormat: () => '' // remove time range from events
};

type CalendarViewProps = {
  seedDate: Date;
  events: EntityModelEvent[];
  onRangeChange: (range: Date[]) => void;
  isFetching: boolean;
};

/*
 * Console warning wont get fixed: https://github.com/jquense/react-big-calendar/issues/2104
 * upgrade to reworked library once available
 */

const CalendarView = ({
  seedDate,
  events,
  onRangeChange,
  isFetching
}: CalendarViewProps) => {
  const theme = useTheme();
  const secondary = theme.palette.secondary;
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [currentEvent, setCurrentEvent] = useState<
    EntityModelEvent | undefined
  >();
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const translatedMessages: Messages = useMemo(
    () => ({
      date: getCalendarText('date'),
      time: getCalendarText('time'),
      event: getCalendarText('event'),
      week: getCalendarText('week'),
      work_week: getCalendarText('work_week'),
      day: getCalendarText('day'),
      month: getCalendarText('month'),
      previous: '\u{276E}',
      next: '\u{276F}',
      yesterday: getCalendarText('yesterday'),
      tomorrow: getCalendarText('tomorrow'),
      today: getCalendarText('today'),
      agenda: getCalendarText('agenda'),
      noEventsInRange: isFetching ? '' : getCalendarText('noEventsInRange')
    }),
    [isFetching]
  );

  const storedView: View | undefined =
    localStorage.getItem('calendarView') === 'agenda'
      ? Views.AGENDA
      : Views.WEEK;
  const onViewChange = (view: View) =>
    localStorage.setItem('calendarView', view);

  const eventPropGetter = () => ({
    style: {
      backgroundColor: theme.palette.primary.calendar,
      color: 'black',
      border: `1px solid ${theme.palette.primary.main}`
    }
  });

  const dayPropGetter = (date: Date) => ({
    ...(dayjs().isSame(date, 'day') && {
      style: {
        backgroundColor: secondary.background
      }
    })
  });

  const closeDetailDialog = () => {
    setShowDetailDialog(false);
    setShowConfirmDialog(false);
    setCurrentEvent(undefined);
  };

  const openConfirmDialog = () => {
    setShowDetailDialog(false);
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
    setShowDetailDialog(true);
  };

  const onClickEvent = (calEvent: EntityModelEvent) => {
    setCurrentEvent(calEvent);
    setShowDetailDialog(true);
  };

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <Box
        sx={{
          display: isFetching ? 'block' : 'none',
          position: 'absolute',
          top: 176,
          zIndex: 100,
          width: '100%'
        }}
      >
        <LoadingSpinner dataCy="offerspage" />
      </Box>

      <Box
        sx={{
          '.rbc-allday-cell': {
            display: 'none'
          },
          '.rbc-toolbar': {
            color: secondary.main
          },
          '.rbc-toolbar button': {
            color: secondary.main,
            borderColor: secondary.main
          },
          '.rbc-toolbar button.rbc-active': {
            backgroundColor: secondary.main,
            color: secondary.contrastText,
            borderColor: secondary.main
          },
          '.rbc-toolbar button:hover': {
            backgroundColor: secondary.background,
            color: secondary.main,
            borderColor: secondary.main
          },
          '.rbc-toolbar button:focus': {
            backgroundColor: secondary.main,
            color: secondary.contrastText,
            borderColor: secondary.main
          },
          '.rbc-toolbar-label': {
            fontWeight: 'bold',
            fontSize: 'unset'
          },
          '.rbc-current-time-indicator': {
            backgroundColor: secondary.main
          },
          'td p': {
            textAlign: 'left'
          },
          'table, th, td': {
            border: '1px solid #E6E6E6',
            borderCollapse: 'collapse'
          },
          td: {
            borderTop: 'unset',
            borderBottom: 'unset',
            p: TABLE_CELL_PADDING,
            verticalAlign: 'top'
          },
          th: {
            p: TABLE_CELL_PADDING
          },
          '.rbc-toolbar .rbc-btn-group button': {
            fontSize: 16,
            height: 35
          },
          '.rbc-time-view': {
            opacity: isFetching ? 0.5 : 1
          }
        }}
      >
        <Calendar
          defaultDate={seedDate}
          localizer={dayLocalizer}
          culture="de"
          events={events}
          startAccessor={(event: EntityModelEvent) =>
            new Date(event.timeSlot.startTime ?? '')
          }
          endAccessor={(event: EntityModelEvent) =>
            new Date(event.timeSlot.endTime ?? '')
          }
          style={{
            height: 1158
          }}
          view={isMdDown ? Views.AGENDA : storedView}
          onView={onViewChange}
          views={{
            week: !isMdDown,
            agenda: ExtendedAgendaView
          }}
          timeslots={4} // timeslots and step to have 15min intervals
          step={15}
          messages={{
            ...translatedMessages,
            showMore: (total: number) => `+${total} ${getCalendarText('more')}`
          }}
          eventPropGetter={eventPropGetter} // style events
          dayPropGetter={dayPropGetter} // style columns in week view
          min={new Date(1972, 0, 1, 7, 0, 0)} // start at 7:00 in week view
          max={new Date(1972, 0, 1, 18, 59, 59)} // end at 19:00 in week view
          formats={formats} // http://jquense.github.io/react-big-calendar/examples/index.html?path=/docs/props-full-prop-list--page#formats
          components={{
            week: {
              header: CustomHeader,
              event: CustomEvent
            }
          }}
          onDoubleClickEvent={onClickEvent}
          onSelectEvent={onClickEvent}
          onRangeChange={onRangeChange}
          tooltipAccessor={() => ''}
        />
      </Box>
      {currentEvent && (
        <>
          <EventDetailsDialog
            event={currentEvent}
            showDialog={showDetailDialog}
            closeDialog={closeDetailDialog}
            onDeleteClick={openConfirmDialog}
          />
          <DeleteEventDialog
            showDialog={showConfirmDialog}
            event={currentEvent}
            closeDialog={closeConfirmDialog}
            onDeleteClick={closeDetailDialog}
          />
        </>
      )}
    </Box>
  );
};

export default CalendarView;
