/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Typography from '@mui/material/Typography';
import { SidebarPageWrapper } from 'feature';
import ChangeData from './change-data';
import RequestUserData from './request-user-data';
import DeleteAccount from './delete-account';
import { getText } from 'localization';
import { useUser } from 'context/user';
import { Restricted } from 'routes';
import { Stack } from '@mui/material';

const ManageData = () => {
  const { perspective } = useUser();
  return (
    <Restricted isVisible={perspective.roles.isSolutionUser} forceLogin>
      <SidebarPageWrapper routeLinksName="accountLinks">
        <>
          <Typography variant="h1">
            {getText('manageData', 'account-data')}
          </Typography>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <ChangeData />
            <RequestUserData />
            <DeleteAccount />
          </Stack>
        </>
      </SidebarPageWrapper>
    </Restricted>
  );
};

export default ManageData;
