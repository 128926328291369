/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  MutableRefObject,
  Dispatch,
  SetStateAction
} from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { produce } from 'immer';
import {
  useGetOrganizationProfile,
  useGetBusinessHours,
  useReplaceBusinessHours
} from 'feature/hooks';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import WeekdayRow from './business-hours/weekday-row';
import type {
  Weekday,
  Hours,
  RangeTimeType,
  HoursOptional,
  OnTimeChangeProps,
  BusinessHoursErrors,
  SetBusinessHoursErrors
} from './business-hours/types';
import {
  WEEKDAYS,
  START_TIME,
  END_TIME,
  ERROR_EMPTY
} from './business-hours/constants';
import { isEmptyArray } from 'utils/helper';
import { getTextIn } from 'localization';

const getTextProfile = getTextIn('profile');

const ctrlErrorsFor =
  (errors: BusinessHoursErrors, setErrors: SetBusinessHoursErrors) =>
  (day: Weekday) =>
  (rowIndex: number) =>
  (rangeTimeType: RangeTimeType, value?: string | null) => {
    const getMode = value === undefined;

    if (getMode) {
      return (errors[day] ?? (errors[day] = []))[rowIndex][rangeTimeType];
    } else {
      setErrors((prevErrors) => {
        return produce(prevErrors, (draft: BusinessHoursErrors) => {
          (draft[day] ?? (draft[day] = []))[rowIndex][rangeTimeType] =
            value ?? null;
        });
      });
    }
  };

const setEmptyTimeRanges = () => {
  const emptyTimeRange = { [START_TIME]: null, [END_TIME]: null };
  return [emptyTimeRange, emptyTimeRange];
};

const errorsInit = () => {
  const errorsAcc: BusinessHoursErrors = {};

  return WEEKDAYS.reduce((acc, weekday) => {
    acc[weekday] = setEmptyTimeRanges();
    return acc;
  }, errorsAcc);
};

const EditBusinessHours = ({
  editHoursRef,
  setValidBusinessHours
}: {
  editHoursRef: MutableRefObject<HTMLButtonElement | null>;
  setValidBusinessHours: Dispatch<SetStateAction<boolean>>;
}) => {
  const { profileId } = useParams();
  const organizationProfile = useGetOrganizationProfile(profileId);
  const profile = organizationProfile.data;

  const { data } = useGetBusinessHours(profile?.organizationId);
  const businessHours = data?.businessHours;

  const [hours, setHours] = useState<Hours>({});
  const [errors, setErrors] = useState(errorsInit);
  const [validated, setValidated] = useState(false);

  const replaceBusinessHours = useReplaceBusinessHours();

  useEffect(() => {
    if (businessHours) {
      setHours(businessHours);
    }
  }, [businessHours]);

  const getNoErrors = useCallback(() => {
    return Object.values(errors).every((timeRanges) => {
      return timeRanges.every(
        (timeRange) =>
          timeRange.startTime === null && timeRange.endTime === null
      );
    });
  }, [errors]);

  useEffect(() => {
    const noErrors = getNoErrors();

    if (validated && noErrors) {
      setValidBusinessHours(true);

      if (businessHours !== hours) {
        replaceBusinessHours.mutate(
          { businessHours: hours },
          {
            onSuccess: () => {
              notifyMutationSuccess(
                getTextProfile('changeBusinessHoursSuccess')
              );
            },
            onError: () => {
              notifyMutationError(getTextProfile('changeBusinessHoursError'));
            }
          }
        );
      }
    }

    setValidated(false);
  }, [
    errors,
    validated,
    getNoErrors,
    hours,
    replaceBusinessHours,
    setValidBusinessHours,
    businessHours
  ]);

  const ctrlErrors = ctrlErrorsFor(errors, setErrors);

  const handleTimeChangeFor =
    (weekday: Weekday) =>
    ({
      rowIndex,
      rangeTimeType,
      newTime,
      setEmpty = false,
      removeRow = false
    }: OnTimeChangeProps) => {
      setHours((prevHours) => {
        const newState = produce(prevHours, (draft: HoursOptional) => {
          const replaceWeekday = draft[weekday] ?? (draft[weekday] = []);
          const replaceWeekdayRow =
            replaceWeekday[rowIndex] ??
            (replaceWeekday[rowIndex] = {
              [START_TIME]: undefined,
              [END_TIME]: undefined
            });

          if (removeRow) {
            replaceWeekday.splice(rowIndex, 1);
          } else {
            if (!setEmpty && rangeTimeType) {
              replaceWeekdayRow[rangeTimeType] = newTime;

              // clean up the object
              if (
                !replaceWeekdayRow[START_TIME] &&
                !replaceWeekdayRow[END_TIME]
              ) {
                replaceWeekday.splice(rowIndex, 1);
              }

              if (isEmptyArray(replaceWeekdayRow) || !!!replaceWeekday.length) {
                delete draft[weekday];
              }
            }
          }
        });
        return newState;
      });
    };

  const handleCheckedChangeFor =
    (weekday: Weekday) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleTimeChangeFor(weekday)({ rowIndex: 0, setEmpty: true });
      } else {
        const newState = produce(hours, (draft: HoursOptional) => {
          delete draft[weekday];
        });
        setHours(newState);

        // set back errors
        const newErrors = produce(errors, (draft: BusinessHoursErrors) => {
          draft[weekday] = setEmptyTimeRanges();
        });
        setErrors(newErrors);
      }
    };

  const validateUpdate = () => {
    Object.entries(hours).map(([day, timeRanges]) => {
      const typedDay = day as Weekday;

      timeRanges.forEach((timeRange, index) => {
        const isStartTimeUndefined = timeRange[START_TIME] === undefined;
        const isEndTimeUndefined = timeRange[END_TIME] === undefined;
        const ctrlErrorsDayIndex = ctrlErrors(typedDay)(index);

        if (isStartTimeUndefined && isEndTimeUndefined) {
          // remove from hours object
          handleTimeChangeFor(typedDay)({
            rowIndex: index,
            rangeTimeType: START_TIME
          });
          // set empty errors
        } else if (isStartTimeUndefined) {
          ctrlErrorsDayIndex(START_TIME, ERROR_EMPTY);
        } else if (isEndTimeUndefined) {
          ctrlErrorsDayIndex(END_TIME, ERROR_EMPTY);
        }
      });
    });
  };

  const handleReplace = () => {
    validateUpdate();
    setValidated(true);
  };

  return (
    <>
      <Stack>
        {WEEKDAYS.map((weekday) => {
          return (
            <WeekdayRow
              key={weekday}
              hours={hours}
              weekday={weekday}
              onCheckedChange={handleCheckedChangeFor(weekday)}
              onTimeChange={handleTimeChangeFor(weekday)}
              ctrlErrors={ctrlErrors}
            />
          );
        })}
      </Stack>

      {/* virtual button, controled by the parent */}
      <button
        ref={editHoursRef}
        style={{ display: 'none' }}
        onClick={handleReplace}
      />
    </>
  );
};

export default EditBusinessHours;
