/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Grid, TextField } from '@mui/material';
import { useBooking } from 'context/booking';
import { ValidatedTextField } from 'feature/forms';
import { getTextIn, required } from 'localization';

const getFixedLocationText = getTextIn('offer-details-fixedLocation');

interface Props {
  prefix?: string;
  dataCy?: string;
  spacing?: number;
  readOnly?: boolean;
}

const FixedLocationForm = ({
  prefix = '',
  dataCy = 'offerform',
  spacing = 4,
  readOnly = false
}: Props) => {
  const { setFixedLocation } = useBooking();

  const saveLocation = (
    key: string,
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) =>
    setFixedLocation((prev) => ({
      ...prev,
      [key]: event.target.value
    }));

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12}>
        <ValidatedTextField
          name={`${prefix}fixedLocation.description`}
          render={({ field, props }) => {
            const { errorMessage, ...fieldProps } = props;

            return (
              <TextField
                fullWidth
                label={getFixedLocationText('description')}
                helperText={errorMessage}
                inputProps={{
                  maxLength: 255,
                  readOnly,
                  'data-cy': `${dataCy}-fixedlocation-description`
                }}
                {...field}
                {...fieldProps}
                onBlur={(event) => saveLocation('description', event)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <ValidatedTextField
          name={`${prefix}fixedLocation.street`}
          render={({ field, props }) => {
            const { errorMessage, ...fieldProps } = props;

            return (
              <TextField
                fullWidth
                label={`${required(getFixedLocationText('street'))}`}
                helperText={errorMessage}
                inputProps={{
                  maxLength: 255,
                  readOnly,
                  'data-cy': `${dataCy}-fixedlocation-street`
                }}
                {...field}
                {...fieldProps}
                onBlur={(event) => saveLocation('street', event)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <ValidatedTextField
          name={`${prefix}fixedLocation.houseNumber`}
          render={({ field, props }) => {
            const { errorMessage, ...fieldProps } = props;
            return (
              <TextField
                fullWidth
                label={`${required(getFixedLocationText('houseNumber'))}`}
                helperText={errorMessage}
                inputProps={{
                  maxLength: 5,
                  readOnly,
                  'data-cy': `${dataCy}-fixedlocation-housenumber`
                }}
                {...field}
                {...fieldProps}
                onBlur={(event) => saveLocation('houseNumber', event)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <ValidatedTextField
          name={`${prefix}fixedLocation.zipCode`}
          render={({ field, props }) => {
            const { errorMessage, ...fieldProps } = props;
            return (
              <TextField
                fullWidth
                label={`${required(getFixedLocationText('zipCode'))}`}
                helperText={errorMessage}
                inputProps={{
                  maxLength: 5,
                  readOnly,
                  'data-cy': `${dataCy}-fixedlocation-zipcode`
                }}
                {...field}
                {...fieldProps}
                onBlur={(event) => saveLocation('zipCode', event)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <ValidatedTextField
          name={`${prefix}fixedLocation.city`}
          render={({ field, props }) => {
            const { errorMessage, ...fieldProps } = props;
            return (
              <TextField
                fullWidth
                label={`${required(getFixedLocationText('city'))}`}
                helperText={errorMessage}
                inputProps={{
                  maxLength: 255,
                  readOnly,
                  'data-cy': `${dataCy}-fixedlocation-city`
                }}
                {...field}
                {...fieldProps}
                onBlur={(event) => saveLocation('city', event)}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FixedLocationForm;
