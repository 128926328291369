/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCreateInvitationAcceptance, useGetInvitation } from 'feature/hooks';
import { useProject, useSetDocumentTitle } from 'context/project';
import { getTextIn } from 'localization';
import { RequestFailed } from 'feature/error';
import { ErrorPageNotFound } from './error';
import LoadingSpinner from 'components/loading-spinner';
import { unwrapErrorResponse } from 'feature/error/request-error-fallback';
import {
  WaitOrganizationApprovalScreen,
  SuccessOrganizationScreen,
  NoOrganizationRightsScreen,
  RegisterOrganizationScreen
} from 'feature';

const getInvitationText = getTextIn('invitation');

// parses the query string
const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation();

  return new URLSearchParams(search);
};

const InvitationLandingPage = () => {
  const query = useQueryParams();
  const queryOrganizationId = query.get('organizationId');
  const { invitationId } = useParams();
  const { project } = useProject();
  const {
    data: invitation,
    isLoading,
    isError
  } = useGetInvitation(invitationId);
  const acceptInvitation = useCreateInvitationAcceptance();
  const [view, setView] = useState<ReactElement>();

  useSetDocumentTitle(getInvitationText('invitation'));

  /**
   * accept invitation
   */
  useEffect(() => {
    if (
      invitation?.organizationName &&
      (invitation?.organizationId || queryOrganizationId)
    ) {
      acceptInvitation.mutate(
        {
          invitationId: invitation.id,
          organizationId: invitation.organizationId ?? queryOrganizationId ?? ''
        },
        {
          onSuccess: (data) => {
            if (
              invitation?.organizationName &&
              (invitation?.organizationId || queryOrganizationId) &&
              project?.name
            ) {
              if (data.awaitsApproval) {
                setView(
                  <WaitOrganizationApprovalScreen
                    organizationName={invitation.organizationName}
                    organizationId={
                      invitation.organizationId ?? queryOrganizationId ?? ''
                    }
                  />
                );
              } else {
                setView(
                  <SuccessOrganizationScreen
                    organizationName={invitation.organizationName}
                    organizationId={
                      invitation.organizationId ?? queryOrganizationId ?? ''
                    }
                  />
                );
              }
            }
          },
          onError: (res) => {
            const error = unwrapErrorResponse(res);
            if (error?.status === 403) {
              setView(<NoOrganizationRightsScreen />);
            } else {
              setView(
                <RequestFailed
                  title={getInvitationText('acceptanceError')}
                  response={error}
                />
              );
            }
          }
        }
      );
    } else if (invitation?.organizationName) {
      setView(
        <RegisterOrganizationScreen
          organizationName={invitation.organizationName}
        />
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitation]);

  return isLoading || (!view && acceptInvitation.isLoading) ? (
    <LoadingSpinner dataCy="invitation-landingpage" />
  ) : isError ? (
    <ErrorPageNotFound
      titleKey="invitationNotFound"
      descriptionKey="invitationNotFoundDescription"
    />
  ) : (
    <>{view}</>
  );
};

export default InvitationLandingPage;
export { useQueryParams };
