/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from '@tanstack/react-query';
import { type PagedModelEntityModelParticipantInfo } from '@SLR/solution3-sdk';
import useParticipantsApi from './useParticipantsApi';
import { useGetProjectId } from 'context/project';

export type ListFilterTypes = {
  page?: number;
  size?: number;
  sort?: Array<string>;
  onlyWithDescription?: boolean;
  showProviders?: boolean;
  showSeekers?: boolean;
};

const useListParticipantsInfo = ({
  page,
  size,
  sort,
  onlyWithDescription,
  showProviders,
  showSeekers
}: ListFilterTypes) => {
  const { listParticipantsInfo } = useParticipantsApi();
  const projectId = useGetProjectId();

  return useQuery<PagedModelEntityModelParticipantInfo, Response>(
    [
      'participantsInfo',
      {
        projectId,
        page,
        size,
        sort,
        onlyWithDescription,
        showProviders,
        showSeekers
      }
    ],
    () => {
      return listParticipantsInfo({
        projectId: projectId ?? '',
        page,
        size,
        sort,
        onlyWithDescription,
        showProviders,
        showSeekers
      });
    },
    {
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useListParticipantsInfo;
