/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { getTextIn } from 'localization';
import { isEmptyOrNull } from './helper';

export type Role = 'seeker' | 'provider' | '';

const booleanToRole = (seeker?: boolean, provider?: boolean): Role => {
  if (seeker) {
    return 'seeker';
  } else if (provider) {
    return 'provider';
  } else {
    return '';
  }
};

const getRoleText = getTextIn('role');

const booleanRoleToString = (seeker?: boolean, provider?: boolean): string => {
  if (seeker && provider) {
    return `${getRoleText('provider')}, ${getRoleText('seeker')}`;
  } else if (seeker) {
    return getRoleText('seeker');
  } else if (provider) {
    return getRoleText('provider');
  } else {
    return '';
  }
};

const roleToBoolean = (role?: Role): { seeker: boolean; provider: boolean } => {
  if (isEmptyOrNull(role)) {
    return { seeker: false, provider: false };
  } else if (role === 'seeker') {
    return { seeker: true, provider: false };
  } else if (role === 'provider') {
    return { seeker: false, provider: true };
  } else {
    return { seeker: true, provider: true };
  }
};

export { booleanToRole, booleanRoleToString, roleToBoolean };
