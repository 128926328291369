/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Add from '@mui/icons-material/Add';
import { useSetDocumentTitle } from 'context/project';
import { useIsAdmin } from 'context/user';
import { ContentWrapper, BreadcrumbNavigation } from 'feature';
import { useArticleCategories } from 'feature/hooks/compass';
import CategoriesGrid from './categories-grid';
import CategoryDialog from './category-dialog';
import SearchBarCompass from './search-bar-compass';
import { getTextIn } from 'localization';

const getTextCompass = getTextIn('compass');

const CategoriesList = () => {
  const isAdmin = useIsAdmin();
  const { articleCategories, isLoading } = useArticleCategories();
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const listTitle = getTextCompass('listTitle');

  useSetDocumentTitle(listTitle);

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1">{listTitle}</Typography>

      {isAdmin && (
        <>
          <Box
            sx={{
              textAlign: { xs: 'center', sm: 'right' },
              ml: 0.75,
              mt: { sm: -5.5, md: -8 },
              mb: 3.75
            }}
          >
            <Button
              startIcon={<Add />}
              onClick={() => setIsOpen(true)}
              data-cy="compass-list-create"
              sx={{ width: 'auto', whiteSpace: 'nowrap' }}
            >
              {getTextCompass('createCategory')}
            </Button>
          </Box>

          {isOpen && (
            <CategoryDialog
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
              }}
            />
          )}
        </>
      )}

      <SearchBarCompass setIsSearchMode={setIsSearchMode} />

      {!isSearchMode && (
        <CategoriesGrid
          articleCategories={articleCategories}
          isLoading={isLoading}
        />
      )}
    </ContentWrapper>
  );
};

export default CategoriesList;
