/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type ReactElement } from 'react';
import { Button, Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps
} from 'react-router-dom';

type ShowMoreButtonConfig = {
  text: string;
  to: RouterLinkProps['to'];
};

type PreviewSectionProps = {
  children: ReactElement[] | ReactElement;
  title?: string;
  showMoreButtonConfig?: ShowMoreButtonConfig;
  coloredBackground?: boolean; // Necessary for drilling of backgroundColor in the process wrapper
};

const PreviewSection = ({
  title,
  children,
  showMoreButtonConfig
}: PreviewSectionProps) => {
  return (
    <Box>
      {title && (
        <Typography variant="h2" align="left">
          {title}
        </Typography>
      )}
      {children}
      {showMoreButtonConfig && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'flex-end'
            }
          }}
        >
          <Button
            endIcon={<NavigateNextIcon />}
            component={RouterLink}
            to={showMoreButtonConfig.to}
            data-cy="cardpreviewsection-more-button"
          >
            {showMoreButtonConfig.text}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PreviewSection;
