/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Button, Link, Stack, Typography } from '@mui/material';
import { useKeycloakAuth } from 'auth';
import { useSetDocumentTitle } from 'context/project';
import ContentWrapper from 'feature/content-wrapper';
import { getErrorText, getNode } from 'localization';
import { Link as RouterLink } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { useGetPortalOrganizationSecurityHref, useUser } from 'context/user';
import LoadingSpinner from 'components/loading-spinner';

const TwoFaErrorFallback = () => {
  const { logout } = useKeycloakAuth();
  const { isLoadingCurrentUser } = useUser();
  useSetDocumentTitle(getErrorText('twofaTitle'));
  const securityUrl = useGetPortalOrganizationSecurityHref();

  return (
    <ContentWrapper>
      {isLoadingCurrentUser ? (
        <Stack
          alignItems="center"
          spacing={2}
          mt={4}
          mb={4}
          data-cy="loading-2fa"
        >
          <LoadingSpinner dataCy="loading-2fa" />
          <Typography color="primary.main">
            {getErrorText('twofaLoading')}
          </Typography>
        </Stack>
      ) : (
        <Stack alignItems="center" spacing={2} mt={4} mb={4} data-cy="error">
          <LockIcon sx={{ color: 'primary.light', width: 125, height: 140 }} />
          <Typography variant="h1">{getErrorText('oops')}</Typography>
          <Typography variant="h2" textAlign="center">
            {getErrorText('twofaTitle')}
          </Typography>
          <Typography textAlign="center">
            {getNode(
              'getNodeKey2FADescription',
              'error'
            )({
              portalLink: (text: string) => (
                <Link component={RouterLink} to={securityUrl}>
                  {text}
                </Link>
              )
            })}
          </Typography>

          <Stack direction="row" spacing={2} pt={4}>
            <Button variant="outlined" onClick={logout}>
              {getErrorText('logout')}
            </Button>
            <Button
              variant="contained"
              href={securityUrl}
              data-cy="go-to-userportal-security"
              sx={{ textTransform: 'none' }}
            >
              {getErrorText('userPortalButton')}
            </Button>
          </Stack>
        </Stack>
      )}
    </ContentWrapper>
  );
};

export default TwoFaErrorFallback;
