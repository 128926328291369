/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, hash]);

  return null;
};

type ScrollToIdProps = {
  element: React.MutableRefObject<HTMLHeadingElement | null>;
};

const ScrollToId = ({ element: refElement }: ScrollToIdProps) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (refElement.current?.id === hash.replace('#', '')) {
      const headerHeight =
        document.getElementsByTagName('header')[0]?.offsetHeight ?? 148;

      window.scrollTo({
        top: refElement.current?.offsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }, [hash, refElement]);

  return null;
};

export { ScrollToTop, ScrollToId };
