/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useMemo } from 'react';
import {
  ArticleCategoriesApi,
  type ListArticleCategoriesRequest,
  type GetArticleCategoryRequest,
  type CreateArticleCategoryRequest,
  type DeleteArticleCategoryRequest,
  type UpdateArticleCategoryRequest,
  ArticlesApi,
  type ListArticlesRequest,
  type GetArticleRequest,
  type CreateArticleRequest,
  type DeleteArticleRequest,
  type UpdateArticleRequest,
  KeywordsApi,
  type ListKeywordsRequest
} from '@SLR/solution3-sdk';
import { APINames, getApiInstance } from 'utils/api-utils';

const useArticleCategoriesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.articleCategories) as ArticleCategoriesApi;
  }, []);

  const listArticleCategories = useCallback(
    (requestParameters: ListArticleCategoriesRequest) => {
      try {
        return apiInstance.listArticleCategories(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getArticleCategory = useCallback(
    (requestParameters: GetArticleCategoryRequest) => {
      try {
        return apiInstance.getArticleCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createArticleCategory = useCallback(
    (requestParameters: CreateArticleCategoryRequest) => {
      try {
        return apiInstance.createArticleCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteArticleCategory = useCallback(
    (requestParameters: DeleteArticleCategoryRequest) => {
      try {
        return apiInstance.deleteArticleCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateArticleCategory = useCallback(
    (requestParameters: UpdateArticleCategoryRequest) => {
      try {
        return apiInstance.updateArticleCategory(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    listArticleCategories,
    getArticleCategory,
    createArticleCategory,
    deleteArticleCategory,
    updateArticleCategory
  };
};

const useArticlesApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.articles) as ArticlesApi;
  }, []);

  const listArticles = useCallback(
    (requestParameters: ListArticlesRequest) => {
      try {
        return apiInstance.listArticles(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getArticle = useCallback(
    (requestParameters: GetArticleRequest) => {
      try {
        return apiInstance.getArticle(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const createArticle = useCallback(
    (requestParameters: CreateArticleRequest) => {
      try {
        return apiInstance.createArticle(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const deleteArticle = useCallback(
    (requestParameters: DeleteArticleRequest) => {
      try {
        return apiInstance.deleteArticle(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const updateArticle = useCallback(
    (requestParameters: UpdateArticleRequest) => {
      try {
        return apiInstance.updateArticle(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    listArticles,
    getArticle,
    createArticle,
    deleteArticle,
    updateArticle
  };
};

const useKeywordsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.keywords) as KeywordsApi;
  }, []);

  const listKeywords = useCallback(
    (requestParameters: ListKeywordsRequest) => {
      try {
        return apiInstance.listKeywords(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    listKeywords
  };
};

export { useArticleCategoriesApi, useArticlesApi, useKeywordsApi };
