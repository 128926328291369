/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { type Dayjs } from 'dayjs';
import { useSchemaValidationFormContext } from 'feature/forms';
import { FilterTimeType, type DateFilter } from './model';
import RadioTime from './radio-time';
import TimeRangeFilterForm from './time-range-filter-form';
import TimeFilterForm from './time-filter-form';
import { getText } from 'localization';

type TimeFormProps = {
  showOnlyRange?: boolean;
  values?: DateFilter;
  rangeFromValue?: Dayjs;
  minPointInTime?: Dayjs;
};

const TimeForm = ({
  showOnlyRange = true,
  values,
  rangeFromValue,
  minPointInTime
}: TimeFormProps) => {
  const { control, setValue } = useSchemaValidationFormContext<DateFilter>();

  // Note: timeRange is the default time filter
  const timeType =
    useWatch<DateFilter>({
      control,
      name: 'timeType'
    }) ?? FilterTimeType.timeRange;

  useEffect(() => {
    const timeTypeEffect = values?.time ? 'fixedTime' : 'timeRange';
    setValue('timeType', FilterTimeType[timeTypeEffect]);
  }, [setValue, values]);

  const removeValue = (type: keyof DateFilter) => setValue(type, null);

  return (
    <Stack spacing={{ xs: 4, md: 1 }}>
      {showOnlyRange ? (
        <TimeRangeFilterForm rangeFromValue={rangeFromValue} />
      ) : (
        <>
          <RadioTime
            checked={timeType === FilterTimeType.timeRange}
            onCheck={() => {
              setValue('timeType', FilterTimeType.timeRange);
              removeValue('time');
            }}
            name="times"
            value="range"
            title={getText('timeRange', 'filter')}
            prefixCy="filter-radio-timerange"
          >
            {(disabled: boolean) => (
              <TimeRangeFilterForm
                rangeFromValue={rangeFromValue}
                disabled={disabled}
              />
            )}
          </RadioTime>

          <RadioTime
            checked={timeType === FilterTimeType.fixedTime}
            onCheck={() => {
              setValue('timeType', FilterTimeType.fixedTime);
              removeValue('rangeFrom');
              removeValue('rangeTo');
            }}
            name="times"
            value="point"
            title={getText('pointInTimeTitle', 'filter-time')}
            prefixCy="filter-radio-time"
          >
            {(disabled: boolean) => (
              <TimeFilterForm
                disabled={disabled}
                minPointInTime={minPointInTime}
              />
            )}
          </RadioTime>
        </>
      )}
    </Stack>
  );
};

export default TimeForm;
