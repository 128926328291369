/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChangeEvent, MouseEvent, useEffect } from 'react';
import {
  Container,
  Pagination as MuiPagination,
  TablePagination,
  useMediaQuery
} from '@mui/material';
import { type PageMetadata } from '@SLR/solution3-sdk';
import { SearchParams, UrlParams } from 'utils/url-param';
import theme from 'theme/theme';
import { getText } from 'localization';

const ROWS_PER_PAGE_OPTIONS_NR = 4;

const fillPaginationOptions = (
  rowsPerPageOptionsVariants = 1,
  elemNumberPerPage = 1
) =>
  Array.from(Array(rowsPerPageOptionsVariants), (_, index) => {
    return (index + 1) * elemNumberPerPage;
  });

const usePageClick = ({
  dataPage,
  page,
  setSearchParamWithPageSetback
}: {
  dataPage?: PageMetadata;
  page?: number;
  setSearchParamWithPageSetback: (
    valueObj: UrlParams,
    noPageSetback?: boolean
  ) => void;
}) => {
  const totalElements = dataPage?.totalElements;
  const totalPages = dataPage?.totalPages;

  const handlePageClick = (newPage: number) => {
    // remove page value for page 0
    setSearchParamWithPageSetback(
      Boolean(newPage) ? { [SearchParams.page]: String(newPage) } : {},
      Boolean(newPage)
    );
  };

  // when exceeding the page number, make empty call with page setback
  useEffect(() => {
    if (page && totalPages && totalPages <= page) {
      setSearchParamWithPageSetback({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, totalPages]);

  return { handlePageClick, totalElements };
};

type AutoPaginationProps = {
  count?: number;
  page: number;
  setPage: (newPage: number) => void;
  setRowsPerPage: (value: number) => void;
  rowsPerPage?: number;
  labelRowsPerPage?: string;
};

const Pagination = ({
  count = 0,
  page = 0,
  setPage,
  setRowsPerPage,
  rowsPerPage = 0,
  labelRowsPerPage = getText('numberPerPage')
}: AutoPaginationProps) => {
  const mobilePagination = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBreakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const pageOffset = mobilePagination ? -1 : 0;
  const elemNumberPerPage = isBreakpointDownMd ? 10 : isBreakpointUpLg ? 12 : 9;

  useEffect(() => {
    if (!rowsPerPage) {
      setRowsPerPage(elemNumberPerPage);
    }
  }, [elemNumberPerPage, rowsPerPage, setRowsPerPage]);

  // get the elements per page options
  const rowsPerPageOptions = fillPaginationOptions(
    ROWS_PER_PAGE_OPTIONS_NR,
    elemNumberPerPage
  );

  // necessary for a change in the rows per page due to a responsive screen change
  const rowsPerPageChecked = rowsPerPageOptions.includes(rowsPerPage)
    ? rowsPerPage
    : rowsPerPageOptions[0];

  const onChangePage = (
    _: MouseEvent<HTMLButtonElement> | ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage + pageOffset);
    window.scrollTo(0, 0);
  };

  const onRowsPerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = Number(event.target.value);
    // get page that fits in the new rows per page interval
    const newPage = Math.floor((rowsPerPage / newRowsPerPage) * page);

    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  return count ? (
    mobilePagination ? (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <MuiPagination
          count={Math.ceil(count / elemNumberPerPage)}
          page={page + 1}
          onChange={onChangePage}
          color="primary"
        />
      </Container>
    ) : (
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPageChecked}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage={labelRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        sx={{ '.MuiTablePagination-select': { fontSize: 14 } }}
      />
    )
  ) : null;
};

export default Pagination;
export { usePageClick };
