/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ResponseError, type Image } from '@SLR/solution3-sdk';
import { getTextIn } from 'localization';
import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';

const getEditModalText = getTextIn('edit-editHeroImage');

const filterImageChange = (
  updatedImage: Image | undefined,
  initialImage: Image | undefined
) => {
  if (updatedImage && !isEqual(updatedImage, initialImage)) {
    return {
      imageId:
        updatedImage.id === initialImage?.id ? undefined : updatedImage.id,
      alternativeText: updatedImage.alternativeText
    };
  } else if (updatedImage == undefined) {
    return null;
  }
};

const onUploadError = (error: Response) => {
  const responseStatus =
    error instanceof ResponseError && error.response?.status;

  const badFormatError = responseStatus === 502;
  const isTooLargeError = responseStatus === 413;

  const errorMessage = `${getEditModalText('uploadFail')}${
    isTooLargeError
      ? ` ${getEditModalText('fileTooLargeError')}`
      : badFormatError
      ? ` ${getEditModalText('badFormatError')}`
      : ''
  }`;

  enqueueSnackbar(errorMessage, {
    variant: 'error'
  });
};

export { filterImageChange, onUploadError };
