/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useGetOffers, useGetOrganizationProfile } from 'feature/hooks';
import { useParams } from 'react-router-dom';
import { Skeleton, Typography, useMediaQuery } from '@mui/material';
import {
  ContentWrapper,
  GenericContentSection,
  HtmlContentSection,
  DetailPageWrapper,
  OfferGrid,
  ProviderProfileCard
} from 'feature';
import { HeroSection } from 'feature/info';
import { getPath, getText, getTextIn } from 'localization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { getProviderProfileUrl } from 'routes';
import theme from 'theme/theme';
import { RequestErrorFallback } from 'feature/error';
import ProviderActions from './profile-actions';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { RestrictedVisibility } from 'feature/permissions';
import { useListProfileGalleryEntries } from 'feature/gallery';
import { useProject, useSetDocumentTitle } from 'context/project';
import { useIsOrganizationOwner } from 'context/user';
import ProfileGallery from './profile-gallery';
import { isEmptyArray } from 'utils/helper';

const getProfileText = getTextIn('profile');

// AW: into a config (also in home and provider-offers-page) -> better in useGetOffers or default from BE??
const SORT_BY = ['created,desc'];

const ProfilePage = () => {
  const { profileId } = useParams();
  const profile = useGetOrganizationProfile(profileId);
  const isOwner = useIsOrganizationOwner(profile.data);
  const { project } = useProject();
  const previewNumber = useCardPreviewNumber();
  const { data: galleryEntriesData } = useListProfileGalleryEntries();
  const galleryEntries = galleryEntriesData?.embedded?.entries ?? [];

  const offersVisibility = project?.offersVisibility;

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    organization: profileId,
    size: 4,
    sort: SORT_BY
  });

  const title = profile.data?.name ?? getText('profilePage');
  useSetDocumentTitle(title);

  return (
    <RequestErrorFallback queryResult={profile}>
      <HeroSection image={profile.data?.banner} />

      <DetailPageWrapper
        overlayBox={
          <>
            <ProviderProfileCard
              showProviderName={!isSmUp}
              showBusinessHours
              providerProfile={profile.data}
            />
            <ProviderActions profile={profile.data} />
          </>
        }
      >
        <>
          <BreadcrumbNavigation customItem={{ 2: { title } }} />
          <HtmlContentSection
            title={
              isSmUp && (
                <Typography variant="h1">
                  {profile.data?.name ?? <Skeleton />}
                </Typography>
              )
            }
            content={profile.data?.about}
            loading={profile.isLoading}
            hideEmpty={false}
            dataCyPostfix="about"
          />
          <HtmlContentSection
            title={
              <Typography
                component="h2"
                variant="sectionTitle"
                color="secondary"
                sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
              >
                {getProfileText('qualification')}
              </Typography>
            }
            content={profile.data?.qualification}
            loading={profile.isLoading}
            dataCyPostfix="qualification"
          />
          <HtmlContentSection
            title={
              <Typography
                component="h2"
                variant="sectionTitle"
                color="secondary"
                sx={{ mt: { xs: 2, sm: 4, md: 6 } }}
              >
                {getProfileText('focus')}
              </Typography>
            }
            content={profile.data?.focus}
            loading={profile.isLoading}
            dataCyPostfix="focus"
          />

          {(!isEmptyArray(galleryEntries) || isOwner) && (
            <GenericContentSection
              title={
                <Typography
                  component="h2"
                  variant="sectionTitle"
                  color="secondary"
                  sx={{
                    display: 'block',
                    mt: { xs: 2, sm: 4, md: 6 }
                  }}
                >
                  {getProfileText('imageGallery')}
                </Typography>
              }
              content={
                <ProfileGallery
                  galleryEntries={galleryEntries}
                  isOwner={isOwner}
                  isSmUp={isSmUp}
                />
              }
            />
          )}
        </>
      </DetailPageWrapper>

      {offers?.embedded?.offers && offers?.embedded?.offers.length > 0 ? (
        <RestrictedVisibility visibility={offersVisibility}>
          <ContentWrapper backgroundColor="primary.background">
            <PreviewSection
              title={getText('ourOffers', 'profile')}
              showMoreButtonConfig={
                (offers?.page?.totalElements ?? 0) > previewNumber
                  ? {
                      to: `${getProviderProfileUrl(profileId)}/${getPath(
                        'offers'
                      )}`,
                      text: getText('moreOffers', 'offer')
                    }
                  : undefined
              }
            >
              <OfferGrid
                isError={isError}
                isLoading={isLoading}
                offers={offers?.embedded?.offers.slice(0, previewNumber)}
                isPreview={true}
              />
            </PreviewSection>
          </ContentWrapper>
        </RestrictedVisibility>
      ) : null}
    </RequestErrorFallback>
  );
};

export default ProfilePage;
