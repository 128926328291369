/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery, type QueryFunction } from '@tanstack/react-query';
import type {
  PagedModelEntityModelCategory,
  PagedModelEntityModelTargetGroup
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import useCategoriesApi from './useCategoriesApi';
import useTargetGroupsApi from './useTargetGroupsApi';
import {
  CATEGORIES,
  TARGET_GROUPS,
  OTHER,
  type SegmentType
} from './constants';
import { getSegmentListType } from './helper';
import { getTextIn } from 'localization';

const getSegmentsText = getTextIn('settings-segments');

const useListSegments = (segmentType: SegmentType) => {
  const { listTargetGroups } = useTargetGroupsApi();
  const { listCategories } = useCategoriesApi();
  const projectId = useGetProjectId();

  return useQuery(
    [getSegmentListType(segmentType), projectId],
    () => {
      switch (segmentType) {
        case CATEGORIES: {
          return listCategories({
            projectId: projectId ?? '',
            size: 100
          }) as unknown as QueryFunction<
            PagedModelEntityModelCategory,
            (string | undefined)[]
          >;
        }
        case TARGET_GROUPS: {
          return listTargetGroups({
            projectId: projectId ?? '',
            size: 100
          }) as unknown as QueryFunction<
            PagedModelEntityModelTargetGroup,
            (string | undefined)[]
          >;
        }
      }
    },
    {
      select: (data) => {
        let segments = [];
        switch (segmentType) {
          case CATEGORIES: {
            segments =
              (data as unknown as PagedModelEntityModelCategory).embedded
                ?.categories ?? [];
            break;
          }
          case TARGET_GROUPS: {
            segments =
              (data as unknown as PagedModelEntityModelTargetGroup).embedded
                ?.targetGroups ?? [];
            break;
          }
        }
        return [
          { id: OTHER, name: getSegmentsText('other') },
          ...segments
        ].sort((a, b) => {
          const valueA = a.name.toLowerCase();
          const valueB = b.name.toLowerCase();

          return valueA === valueB ? 0 : valueA < valueB ? -1 : 1;
        });
      },
      staleTime: Infinity,
      enabled: !!projectId
    }
  );
};

export default useListSegments;
