/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  type EntityModelOrganizationProfile,
  type GetOrganizationProfileRequest
} from '@SLR/solution3-sdk';
import useOrganizationProfilesApi from './useOrganizationProfilesApi';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';

const useGetOrganizationProfile = (organizationId: string | undefined) => {
  const projectId = useGetProjectId();
  const { getOrganizationProfile } = useOrganizationProfilesApi();
  const request: GetOrganizationProfileRequest = {
    projectId: projectId ?? '',
    organizationId: organizationId ?? ''
  };
  return useQuery<EntityModelOrganizationProfile, Response>(
    ['organization profile', { projectId, organizationId }],
    () => {
      return getOrganizationProfile(request);
    },

    {
      enabled: !!projectId && !!organizationId
    }
  );
};

export default useGetOrganizationProfile;
