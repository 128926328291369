/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Button, Typography } from '@mui/material';
import React from 'react';
import EmojiPeopleTwoToneIcon from '@mui/icons-material/EmojiPeopleTwoTone';
import { useProject } from 'context/project';
import FeedbackScreen from './feedback-screen';
import { useGetPortalAddOrganizationHref } from 'context/user';
import { getNode, getTextIn } from 'localization';

const getInvitationText = getTextIn('invitation');

const RegisterOrganizationScreen = ({
  organizationName
}: {
  organizationName: string;
}) => {
  const { project } = useProject();
  const icon = <EmojiPeopleTwoToneIcon sx={{ flexGrow: 1 }} />;
  const text = (
    <Typography component="span">
      {getNode(
        'getNodeRegisterText',
        'invitation'
      )({
        orgName: <strong>{organizationName}</strong>,
        projectName: project?.name
      })}
    </Typography>
  );

  const button = (
    <Button
      variant="contained"
      href={useGetPortalAddOrganizationHref(organizationName)}
    >
      {getInvitationText('createOrganization')}
    </Button>
  );

  return (
    <FeedbackScreen
      title={getInvitationText('success')}
      icon={icon}
      text={text}
      hasHomePageButton={false}
      actionButton={button}
      dataCy="callforregisterorganizationscreen"
    />
  );
};

export default RegisterOrganizationScreen;
