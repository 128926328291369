/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Stack from '@mui/material/Stack';
import { ConfigurableDatePicker } from 'feature/forms';
import { dateToDayjs } from 'utils/date';
import { getTextIn } from 'localization';
import { type Dayjs } from 'dayjs';
import { useProject } from 'context/project';

const getTextInFilterTime = getTextIn('filter-time');

const TimeFilterForm = ({
  disabled,
  minPointInTime
}: {
  disabled?: boolean;
  minPointInTime?: Dayjs;
}) => {
  const { maxDateFromNow } = useProject();
  return (
    <Stack gap={4} sx={{ mt: 2, mb: 1.5 }} width={{ xs: '100%', md: 'unset' }}>
      <ConfigurableDatePicker
        name="time"
        label={getTextInFilterTime('pointInTime')}
        disabled={disabled}
        maxDateTime={dateToDayjs(maxDateFromNow)}
        minDateTime={minPointInTime}
        inputProps={{
          'data-cy': 'filter-time',
          sx: { fontSize: 16 }
        }}
        // width: equals width of TimeFilterForm DatePickers (2 x 212) + gaps and m-dash
        pickerSx={{ width: { xs: 'auto', md: 2 * 212 + 42 } }}
      />
    </Stack>
  );
};

export default TimeFilterForm;
