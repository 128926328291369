/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ResponseError } from '@SLR/solution3-sdk';
import { enqueueSnackbar } from 'notistack';
import RequestFailed from './request-failed';
import RequestErrorFallback from './request-error-fallback';
import { getTextIn } from 'localization';

const getNotificationText = getTextIn('generic');

type GetErrorMessageOptions = {
  json?: boolean;
  is?: string;
};

/**
 *
 * @param error The ResponseError, e.g. from mutate
 * @param options Changes the return value
 *  default: error message
 *  json: the complete json response object
 *  is: the comparison of the passed string with the error message
 * @returns
 */
const getErrorMessage = async (
  error: ResponseError,
  options?: GetErrorMessageOptions
) => {
  const errorJson = await error.response?.json();
  const errorMsg = errorJson?.message;
  const { json, is } = options ?? {};

  return is ? errorMsg === is : json ? errorJson : errorMsg;
};

const notifyMutationSuccess = (
  message: string = getNotificationText('modificationSuccess')
) => {
  enqueueSnackbar(message, {
    variant: 'success'
  });
};

const notifyMutationError = (
  message: string = getNotificationText('modificationFailure')
) => {
  enqueueSnackbar(message, {
    variant: 'error'
  });
};

export {
  RequestErrorFallback,
  RequestFailed,
  getErrorMessage,
  notifyMutationSuccess,
  notifyMutationError
};
