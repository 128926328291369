/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useMemo } from 'react';
import { getApiInstance, APINames } from 'utils/api-utils';
import {
  LegalApi,
  type GetProjectImprintTextRequest,
  type GetProjectPrivacyPolicyTextRequest,
  type GetProjectTermsOfUseTextRequest,
  type GetProjectAccessibilityDeclarationTextRequest
} from '@SLR/solution3-sdk';

const useLegalApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.legal) as LegalApi;
  }, []);

  const getProjectPrivacyPolicyText = useCallback(
    async (requestParameters: GetProjectPrivacyPolicyTextRequest) => {
      try {
        return await apiInstance.getProjectPrivacyPolicyText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectImprintText = useCallback(
    async (requestParameters: GetProjectImprintTextRequest) => {
      try {
        return await apiInstance.getProjectImprintText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectTermsOfUseText = useCallback(
    async (requestParameters: GetProjectTermsOfUseTextRequest) => {
      try {
        return await apiInstance.getProjectTermsOfUseText(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  const getProjectAccessibilityDeclarationText = useCallback(
    async (
      requestParameters: GetProjectAccessibilityDeclarationTextRequest
    ) => {
      try {
        return await apiInstance.getProjectAccessibilityDeclarationText(
          requestParameters
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getProjectPrivacyPolicyText,
    getProjectImprintText,
    getProjectTermsOfUseText,
    getProjectAccessibilityDeclarationText
  };
};

export default useLegalApi;
