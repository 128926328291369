/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect, useState, ChangeEvent } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

type OnChangeRadio = (key: string, value?: string) => void;

type RadioOption = {
  key: string;
  title?: string;
  options: Array<{ value: string; label: string }>;
};

type RadioBlockProps = {
  radioOption: RadioOption;
  initialValue?: string;
  controlledValue?: string;
  shouldReset?: boolean;
  row?: boolean;
  onValueChange: OnChangeRadio;
  disableTypography?: boolean;
};

const RadioBlock = ({
  radioOption,
  initialValue = '',
  controlledValue,
  shouldReset,
  row = true,
  onValueChange,
  disableTypography = false
}: RadioBlockProps) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue);

  /**
   * Update current value when initial value or shouldReset is changed
   * Note: shouldReset is just used to pass down the reset click of the parent view, its value is not important.
   */
  useEffect(() => {
    if (initialValue) {
      setCurrentValue(initialValue);
    }
  }, [initialValue, shouldReset]);

  /**
   * Handle value changes
   * @param event HTMLInputElement
   */
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setCurrentValue(newValue);
    onValueChange(radioOption.key, newValue);
  };

  return (
    <FormControl fullWidth>
      {radioOption.title && (
        <FormLabel sx={{ pb: 1, fontWeight: 500 }}>
          {radioOption.title}
        </FormLabel>
      )}
      <RadioGroup
        row={row}
        sx={{ pl: 1 }}
        value={controlledValue ?? currentValue}
        onChange={handleChange}
        data-cy={`radiosetting-${radioOption.key}`}
      >
        {radioOption.options.map(({ value, label }) => (
          <FormControlLabel
            data-cy={`radiosetting-${radioOption.key}-${value}`}
            key={label}
            value={value}
            control={<Radio />}
            label={label}
            disableTypography={disableTypography}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioBlock;

export type { RadioOption, OnChangeRadio };
