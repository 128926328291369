/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Lars Hemmerling
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  BreadcrumbNavigation,
  ContentWrapper,
  SearchBarFilter,
  SearchBarWrapper
} from 'feature';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useListArticles } from 'feature/hooks/compass';
import { getNode, getPath, getText, getTextFx } from 'localization';
import ArticleGrid from 'pages/compass/article-grid';
import PreviewOffers from 'pages/compass/preview-offers';
import { useState } from 'react';
import {
  SearchParams,
  convertSearchToQueryParams,
  useUrlParams
} from 'utils/url-param';

const QUERY = 'query';

const SEARCH_PARAMS_CONVERSION_TABLE = {
  [SearchParams.page]: 'page',
  [SearchParams.query]: QUERY
};

const UniversalSearch = () => {
  const [search, setSearchParamWithPageSetback] = useUrlParams();
  const [offerCount, setOfferCount] = useState<number | undefined>();
  const MAX_PREVIEW_NUMBER = 4;

  // two lines (*2)
  const previewNumber = useCardPreviewNumber(MAX_PREVIEW_NUMBER) * 2;

  const hasQuery = search[SearchParams.query] !== undefined;

  const queryParams = convertSearchToQueryParams(
    search,
    SEARCH_PARAMS_CONVERSION_TABLE
  );

  const { page, query } = queryParams;

  const { data, isLoading, isError } = useListArticles({
    query,
    page,
    size: previewNumber + 1,
    sort: ['desc']
  });

  const allArticles = data?.embedded?.articles;
  const articleCount = data?.page?.totalElements ?? 0;
  const noResults = !isLoading && allArticles === undefined && offerCount === 0;

  return (
    <ContentWrapper>
      <BreadcrumbNavigation />
      <Typography variant="h1">{getText('universalSearch')}</Typography>

      <Box sx={{ mb: 4 }}>
        <Box sx={{ mb: 4 }}>
          <SearchBarWrapper>
            <SearchBarFilter
              search={search}
              setSearchParamWithPageSetback={setSearchParamWithPageSetback}
              placeholderText={getText('universalPlaceholder', 'searchBar')}
              helperText={
                !isLoading && offerCount !== undefined
                  ? getTextFx(
                      'getUniversalResultCount',
                      'searchBar'
                    )({ offerCount, articleCount })
                  : ''
              }
              fullWidth
            />
          </SearchBarWrapper>
        </Box>

        {hasQuery && noResults ? (
          getNode('getNodeNoResultsForSearch', 'searchBar')({})
        ) : (
          <>
            <PreviewOffers
              keywords={[query]}
              title={getText('offers', 'offer')}
              setOfferCount={setOfferCount}
              linesNr={2}
              noWrapper
              noResultMsg={getNode('getNodeNoOffersForSearch', 'offer')({})}
            />

            <Divider sx={{ mt: 6.5 }} />

            <Box sx={{ mt: 4 }}>
              <PreviewSection
                title={getText('articles', 'compass')}
                showMoreButtonConfig={
                  allArticles && allArticles.length > previewNumber
                    ? {
                        to: `/${getPath('compass')}${
                          query ? `?${QUERY}=${query}` : ''
                        }`,
                        text: getText('furtherArticles', 'compass')
                      }
                    : undefined
                }
                coloredBackground={true}
              >
                <ArticleGrid
                  isLoading={isLoading}
                  isError={isError}
                  articles={data?.embedded?.articles?.slice(0, previewNumber)}
                  noArticlesMsg={getNode(
                    'getNodeNoArticlesForSearch',
                    'compass'
                  )({})}
                />
              </PreviewSection>
            </Box>
          </>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default UniversalSearch;
