/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { USERPORTAL_URL } from 'auth/settings';
import { useProject } from 'context/project';
import { getText } from 'localization';

const useGetPortalOrganizationProfileHref = (
  organizationId?: string,
  additionalQueryParameter?: string
) => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}organizations/${organizationId}?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}${
    additionalQueryParameter ? additionalQueryParameter : ''
  }`;
};

const useGetPortalOrganizationsListHref = () => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}organizations?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}`;
};

const useGetPortalAddOrganizationHref = (organizationName?: string) => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}choose/organization?redirect_uri=${
    window.location.href
  }?solution_uri=${window.location.href}&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace(
    '#',
    ''
  )}&organization_name=${organizationName}&types[]=user`;
};

const useGetPortalOrganizationSecurityHref = () => {
  const { project, primaryColor } = useProject();

  return `${USERPORTAL_URL}profile/security?solution_uri=${
    window.location.href
  }&solution_name=${
    project?.name ?? getText('solutionName')
  }&solution_color=${primaryColor.replace('#', '')}`;
};

export {
  useGetPortalOrganizationProfileHref,
  useGetPortalOrganizationsListHref,
  useGetPortalAddOrganizationHref,
  useGetPortalOrganizationSecurityHref
};
