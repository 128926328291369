/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ReactElement, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Section } from '@SLR/solution3-sdk';
import { useUser } from 'context/user';
import { EditSection, TextSectionPlaceholder } from 'feature/editor';
import { SanitizedHTML } from 'feature';
import { useGetSectionText } from 'feature/hooks';
import { transformToFragment } from 'utils/fragments-utils';
import { isNotEmptyHtml } from 'utils/editor';
import { ScrollToId } from 'routes';
import { PartiallyOptional } from 'types';

type SectionProps = {
  section: Section;
  title?: string;
  headingType: 'h1' | 'h2';
  children: ReactElement;
};

type InfoSectionProps = PartiallyOptional<
  SectionProps,
  'headingType' | 'children'
> & {
  coloredBackground?: boolean;
};

type StaticSectionProps = Omit<InfoSectionProps, 'section' | 'headingType'> & {
  sectionText: string;
};

const SectionLayout = ({
  section,
  title,
  headingType,
  children
}: SectionProps) => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  return (
    <>
      <ScrollToId element={titleRef} />
      <Box data-cy={`${section}-section`}>
        {title && (
          <Typography
            ref={titleRef}
            variant={headingType}
            id={transformToFragment(title)}
          >
            {title}
          </Typography>
        )}
        <Box sx={{ pt: title ? 0 : 3 }}>{children}</Box>
      </Box>
    </>
  );
};

export const InfoSection = ({
  section,
  title,
  headingType = 'h2',
  children
}: InfoSectionProps) => {
  const { isEditModeOn } = useUser();
  const { isLoading, data: sectionText = '' } = useGetSectionText(section);

  return isLoading ? (
    <>
      <TextSectionPlaceholder />
      {children}
    </>
  ) : (
    <SectionLayout title={title} headingType={headingType} section={section}>
      <Box>
        {isEditModeOn ? (
          <EditSection section={section} sectionText={sectionText} />
        ) : (
          <SanitizedHTML dirtyHTML={sectionText} />
        )}
        {children}
      </Box>
    </SectionLayout>
  );
};

export const StaticSection = ({
  sectionText,
  title,
  children
}: StaticSectionProps) => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  return sectionText && isNotEmptyHtml(sectionText) ? (
    <>
      <ScrollToId element={titleRef} />

      <Box data-cy={`${title}-staticsection`}>
        {title && (
          <Typography
            variant="h2"
            ref={titleRef}
            id={transformToFragment(title)}
          >
            {title}
          </Typography>
        )}
        <Box sx={{ pt: title ? 0 : 3 }}>{sectionText}</Box>
        {children}
      </Box>
    </>
  ) : null;
};
