/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Skeleton,
  Typography
} from '@mui/material';
import { LoadingButton } from 'components';
import { useBooking } from 'context/booking';
import dayjs from 'dayjs';
import { ValidatedTextField } from 'feature/forms';
import { UseFormSetValueValidating } from 'feature/forms/use-form';
import { useGetEventSuggestions } from 'feature/hooks';
import { getTextIn } from 'localization';
import { useState } from 'react';
import { type FieldValues, type UseFormSetValue } from 'react-hook-form';
import {
  getOfferBookingAppointment,
  getOfferBookingEndTime,
  offerBookingDataCy
} from '.';

const NUMBER_OF_TIMESLOTS_PER_VIEW = 3;
const getOfferBookingTimeSlotsText = getTextIn('offer-booking-timeslots');
interface Props {
  offerIds?: string[];
  offerDuration?: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  setValueValidating: UseFormSetValueValidating<FieldValues>;
}

const OfferBookingTimeSlots = ({
  offerIds = [],
  offerDuration,
  setValue,
  setValueValidating
}: Props) => {
  const { setSelectedTimeRange } = useBooking();
  const [offset, setOffset] = useState(0);
  const [showPrevButton, setShowPrevButton] = useState(false);

  const {
    data: possibleTimeSlots,
    isLoading: isPossibleTimeSlotsLoading,
    isError
  } = useGetEventSuggestions(offerIds, offset);

  const nextSlots = () =>
    setOffset((prev) => prev + NUMBER_OF_TIMESLOTS_PER_VIEW);
  const restoreFirstSlots = () => setOffset(0);

  const resetSelectedTimeRange = () =>
    setSelectedTimeRange({
      startTime: undefined,
      endTime: undefined
    });

  return (
    <ValidatedTextField
      name="startTime"
      render={({ field, props }) => {
        const { errorMessage, error } = props;
        return (
          <FormControl
            error={error}
            sx={{
              width: '100%'
            }}
          >
            <RadioGroup
              data-cy={`${offerBookingDataCy}-timeSlots-selection`}
              value={field.value ?? ''}
              onChange={(event) => {
                if (event.target.value) {
                  setValueValidating('startTime', event.target.value);
                  setValueValidating(
                    'endTime',
                    getOfferBookingEndTime(event.target.value, offerDuration)
                  );
                  setSelectedTimeRange({
                    startTime: dayjs(event.target.value).toDate(),
                    endTime: getOfferBookingEndTime(
                      event.target.value,
                      offerDuration
                    )
                  });
                }
              }}
            >
              {possibleTimeSlots === undefined && !isPossibleTimeSlotsLoading
                ? getOfferBookingTimeSlotsText('noAppointmentsAnymore')
                : possibleTimeSlots?.map((timeSlot, index) => (
                    <MenuItem
                      key={index}
                      data-cy={`${offerBookingDataCy}-${index}-timeSlot`}
                    >
                      <FormControlLabel
                        value={timeSlot.startTime?.toString()}
                        checked={
                          field.value?.toString() ===
                          timeSlot.startTime?.toString()
                        }
                        control={<Radio size="small" />}
                        label={getOfferBookingAppointment(timeSlot.startTime)}
                      />
                    </MenuItem>
                  ))}
              {isPossibleTimeSlotsLoading && (
                <>
                  <MenuItem disabled>
                    <Skeleton animation="wave" width="100%" />
                  </MenuItem>
                  <MenuItem disabled>
                    <Skeleton animation="wave" width="100%" />
                  </MenuItem>
                  <MenuItem disabled>
                    <Skeleton animation="wave" width="100%" />
                  </MenuItem>
                </>
              )}
            </RadioGroup>
            {possibleTimeSlots?.length === NUMBER_OF_TIMESLOTS_PER_VIEW &&
              !isPossibleTimeSlotsLoading && (
                <LoadingButton
                  variant="outlined"
                  dataCy={`${offerBookingDataCy}-request-suggestions`}
                  label={getOfferBookingTimeSlotsText('requestNext')}
                  disabled={isPossibleTimeSlotsLoading}
                  loading={isPossibleTimeSlotsLoading}
                  onClick={() => {
                    nextSlots();
                    setShowPrevButton(true);
                    setValue('startTime', undefined);
                    setValue('endTime', undefined);
                    resetSelectedTimeRange();
                  }}
                  size="small"
                  sx={{ alignSelf: 'flex-end', mt: 2 }}
                />
              )}
            {possibleTimeSlots?.length === 0 && !isPossibleTimeSlotsLoading && (
              <Box
                py={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Typography>
                  {offset > 0
                    ? getOfferBookingTimeSlotsText('noAdditionalAppointments')
                    : getOfferBookingTimeSlotsText('noAppointments')}
                </Typography>
              </Box>
            )}
            {showPrevButton && !isPossibleTimeSlotsLoading && (
              <LoadingButton
                variant="text"
                dataCy={`${offerBookingDataCy}-request-suggestions`}
                label={getOfferBookingTimeSlotsText('requestPrev')}
                disabled={isPossibleTimeSlotsLoading}
                loading={isPossibleTimeSlotsLoading}
                onClick={() => {
                  restoreFirstSlots();
                  setShowPrevButton(false);
                  setValue('startTime', undefined);
                  setValue('endTime', undefined);
                  resetSelectedTimeRange();
                }}
                size="small"
                sx={{ alignSelf: 'flex-end', mt: 2 }}
              />
            )}
            <Typography color="error" sx={{ pt: 1 }}>
              {errorMessage}
            </Typography>
            {isError && (
              <Typography>
                {getOfferBookingTimeSlotsText('fetchTimeslotsError')}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default OfferBookingTimeSlots;
