/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Box, Skeleton } from '@mui/material';

/**
 * @property lines - The number of lines that should be rendered (default: 1)
 * @property pulse - Whether the skeletons should have a pulse animation (usually for loading visuals) (default: false)
 */
type ParagraphSkeletonProps = {
  lines?: number;
  pulse?: boolean;
};

const ParagraphSkeleton = ({ pulse, lines = 1 }: ParagraphSkeletonProps) => {
  return (
    <Box>
      {new Array(lines).fill(undefined).map((_, idx) => (
        <Skeleton
          animation={pulse ? 'pulse' : false}
          key={`ParagraphSkeleton-${idx}`}
        />
      ))}
    </Box>
  );
};

export default ParagraphSkeleton;
