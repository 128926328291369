/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createContext,
  useContext,
  ReactElement,
  useState,
  useCallback
} from 'react';
import {
  useUser,
  useOrganizationSettings,
  useUpdateOrganizationSettings
} from 'context/user';
import { INIT, type StepType } from 'feature/onboarding';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { getTextIn } from 'localization';

const getTextOnboarding = getTextIn('onboarding');

interface OnboardingContextValues {
  onboardingState: number;
  changeOnboardingState: (stepBinary: number) => void;
  successMode: boolean;
  setSuccessMode: (fired: boolean) => void;
  type: StepType;
  setType: (type: StepType) => void;
}

const defaultOnboardingContextValues: OnboardingContextValues = {
  onboardingState: 0,
  changeOnboardingState: () => 0,
  successMode: false,
  setSuccessMode: () => false,
  type: INIT,
  setType: () => INIT
};

const OnboardingContext = createContext<OnboardingContextValues>(
  defaultOnboardingContextValues
);

const useOnboarding = () => useContext(OnboardingContext);

const OnboardingContextProvider = ({
  children
}: {
  children: ReactElement;
}) => {
  const { perspective } = useUser();
  const organizationId = perspective?.isOrganization ? perspective.id : '';

  const { data } = useOrganizationSettings({ organizationId });
  const onboardingState = data?.onboardingState ?? -1;

  const updateOrganizationSettings = useUpdateOrganizationSettings();

  const changeOnboardingState = useCallback(
    (newOnboardingState: number) => {
      if (
        organizationId &&
        newOnboardingState !== undefined &&
        newOnboardingState !== onboardingState
      ) {
        updateOrganizationSettings.mutate(
          {
            organizationSettingsUpdate: { onboardingState: newOnboardingState }
          },
          {
            onSuccess: () => {
              notifyMutationSuccess(getTextOnboarding('successful'));
            },
            onError: () => {
              notifyMutationError(getTextOnboarding('error'));
            }
          }
        );
      }
    },
    [onboardingState, organizationId, updateOrganizationSettings]
  );

  // hard reset for dev -> remove, when onboarding is finished and presented
  // changeOnboardingState(0);

  const [type, setType] = useState<StepType>(INIT);
  const [successMode, setSuccessMode] = useState(false);

  return (
    <OnboardingContext.Provider
      value={{
        onboardingState,
        changeOnboardingState,
        successMode,
        setSuccessMode,
        type,
        setType
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContextProvider;
export { useOnboarding };
