/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ContentWrapper, OfferGrid } from 'feature';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useGetOffers } from 'feature/hooks';
import { getPath, getTextIn } from 'localization';
import { useEffect } from 'react';

const getTextOffer = getTextIn('offer');

const SORT_BY = ['desc'];

const PreviewOffers = ({
  keywords,
  title = getTextOffer('matchingOffers'),
  setOfferCount,
  linesNr = 1,
  noWrapper = false,
  noResultMsg
}: {
  keywords: string[];
  title?: string;
  setOfferCount?: React.Dispatch<React.SetStateAction<number | undefined>>;
  linesNr?: number;
  noWrapper?: boolean;
  noResultMsg?: string | React.ReactNode;
}) => {
  const maxPreviewNumber = 4;

  const previewNumber = useCardPreviewNumber(maxPreviewNumber) * linesNr;
  const query = keywords.join(' OR ');

  const { data, isLoading, isError } = useGetOffers({
    // preview number + 1 to get the information, if there are more offers than the preview number (for more button)
    size: previewNumber + 1,
    sort: SORT_BY,
    query
  });

  const totalElements = data?.page?.totalElements;

  useEffect(() => {
    setOfferCount?.(totalElements);
  }, [setOfferCount, totalElements]);

  // more button only, if more than 3 (but size is set to 3) ==> set it to 4 and use the max in previewNumber??
  const allOffers = data?.embedded?.offers;
  const offers = allOffers?.slice(0, previewNumber);

  const Content = () => (
    <PreviewSection
      title={title}
      showMoreButtonConfig={
        allOffers && allOffers.length > previewNumber
          ? {
              to: `/${getPath('offers')}${query ? `?query=${query}` : ''}`,
              text: getTextOffer('furtherOffers')
            }
          : undefined
      }
      coloredBackground
    >
      {!!noResultMsg && !isLoading && offers === undefined ? (
        <>{noResultMsg}</>
      ) : (
        <OfferGrid
          isError={isError}
          isLoading={isLoading}
          offers={offers}
          isPreview={true}
        />
      )}
    </PreviewSection>
  );

  return totalElements || !!noResultMsg ? (
    noWrapper ? (
      <Content />
    ) : (
      <ContentWrapper backgroundColor="primary.background">
        <Content />
      </ContentWrapper>
    )
  ) : null;
};

export default PreviewOffers;
