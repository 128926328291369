/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Stack, Typography } from '@mui/material';
import { DialogGeneric } from 'feature';
import { getTextIn, getNode } from 'localization';
import {
  formatAsDateTimeExtended,
  formatAsTime,
  getFormattedRangeString
} from 'utils/date';
import { EventDetailsDialogProps } from './event-details-dialog';
import useDeleteEvent from 'feature/hooks/events/useDeleteEvent';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { useUser } from 'context/user';

const getCalendarText = getTextIn('calendar');

const DeleteEventDialog = ({
  event,
  showDialog,
  closeDialog,
  onDeleteClick
}: EventDetailsDialogProps) => {
  const deleteEvent = useDeleteEvent();
  const { perspective } = useUser();
  const { isProvider, isSeeker } = perspective.roles ?? {};

  const onDelete = () => {
    deleteEvent.mutate(event.id, {
      onSuccess: () => {
        notifyMutationSuccess(getCalendarText('deleteSuccess'));
        onDeleteClick();
      },
      onError: () => {
        notifyMutationError();
      }
    });
  };

  return (
    <DialogGeneric
      open={showDialog}
      title={getCalendarText('cancelAppointment')}
      onConfirm={onDelete}
      onClose={closeDialog}
      confirmText={getCalendarText('cancelAppointment')}
      confirmColor="error"
      prefix="delete-event-confirm-dialog"
    >
      <Stack
        direction="column"
        data-cy="delete-event-confirm-body"
        spacing={2}
        sx={{ py: 2 }}
      >
        <Box>
          <Typography component="span">
            {getNode(
              'getNodeDeleteConfirmation',
              'calendar'
            )({
              children: (
                <strong>{` ${event.title} ${getCalendarText(
                  'on'
                )} ${getFormattedRangeString(
                  event.timeSlot.startTime &&
                    formatAsDateTimeExtended(event.timeSlot.startTime),
                  event.timeSlot.endTime && formatAsTime(event.timeSlot.endTime)
                )} ${getCalendarText('oClock')} `}</strong>
              )
            })}
          </Typography>
        </Box>
        {isSeeker && (
          <Typography>{getCalendarText('seekerNotification')}</Typography>
        )}
        {isProvider && (
          <Typography>{getCalendarText('providerNotification')}</Typography>
        )}
      </Stack>
    </DialogGeneric>
  );
};

export default DeleteEventDialog;
