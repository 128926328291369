/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback } from 'react';
import {
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  type ControllerRenderProps
} from 'react-hook-form';
import { useSchemaValidationFormContext } from './form-provider';
import omit from 'lodash/omit';

interface RenderProps<T extends FieldValues = FieldValues> {
  field: Omit<ControllerRenderProps<T, Path<T>>, 'ref'>;
  props: {
    content: string;
    onEditorChange: (value: string) => void;
    error: boolean;
    errorMessage?: string;
  };
}

interface Props<T extends FieldValues = FieldValues> {
  name: Path<T>;
  render: (props: RenderProps<T>) => React.ReactElement;
}

/**
 * Note: The component can only be used within an {@link SchemaValidationFormProvider}.
 */
const ValidatedHtmlEditor = <T extends FieldValues = FieldValues>({
  name,
  render
}: Props<T>) => {
  const { control, setValueValidating } = useSchemaValidationFormContext<T>();

  const handleEditorChange = useCallback(
    (value: string) => {
      setValueValidating(name, value as PathValue<T, Path<T>>);
    },
    [setValueValidating, name]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const props = {
          content: field.value,
          onEditorChange: handleEditorChange,
          error: !!fieldState.error,
          errorMessage: fieldState.error?.message
        };
        /*
        The "ref" property is removed because it is not supported by the TinyMCE component.
        As we use react hook form with controlled components the reference is not needed either.
        */
        return render({ field: omit(field, 'ref'), props });
      }}
    />
  );
};

export default ValidatedHtmlEditor;
