/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, Button, Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getPath, getText } from 'localization';
import { ContentWrapper } from 'feature';

const ICON_SIZE = { xs: '5rem', md: '10rem' };

export type FeedbackScreenProps = {
  title: string;
  icon: ReactElement;
  text: ReactElement;
  hasHomePageButton?: boolean;
  actionButton?: ReactElement;
  dataCy: string;
};

const FeedbackScreen = ({
  title,
  icon,
  text,
  hasHomePageButton = false,
  actionButton,
  dataCy
}: FeedbackScreenProps) => {
  return (
    <ContentWrapper>
      <Stack
        data-cy={`${dataCy}-feedbackscreen`}
        alignItems="center"
        sx={{ my: { xs: 5, md: 10 } }}
      >
        <Typography variant="h1" data-cy={`${dataCy}-feedbackscreen`}>
          {title}
        </Typography>
        <Box
          sx={{
            svg: {
              width: ICON_SIZE,
              height: ICON_SIZE,
              color: 'primary.main'
            }
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            pt: 3,
            maxWidth: 500
          }}
        >
          {text}
        </Box>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 10 }}
        >
          {hasHomePageButton && (
            <Button
              variant="outlined"
              component={RouterLink}
              to={getPath('home')}
            >
              {getText('home')}
            </Button>
          )}
          {actionButton}
        </Stack>
      </Stack>
    </ContentWrapper>
  );
};

export default FeedbackScreen;
