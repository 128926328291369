/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import {
  type EntityModelGeoArea,
  ListOffersLocationTypesEnum,
  type EntityModelCategory,
  type EntityModelTargetGroup
} from '@SLR/solution3-sdk';
import {
  DropdownButton,
  type OnChangeCheckbox,
  type OnChangeRadio,
  type CheckboxStateValues
} from 'components';
import type { OnClickCheckboxes, OnClickGeoArea } from 'feature';
import type { DateFilter, DurationFilter } from './time/model';
import { OnClickDate, OnClickDuration } from 'utils/url-param';
import { DispatchBooleanStateAction } from 'types';

type EntityModelVariableCheckboxes =
  | EntityModelCategory[]
  | EntityModelTargetGroup[];

type DropdownProps = {
  text: string;
  categories?: EntityModelCategory[];
  targetGroups?: EntityModelTargetGroup[];
  additionalLabel?: string;
  onChange?: OnChangeCheckbox;
  onChangeSort?: OnChangeRadio;
  // this has to be generalized for other button filters (e.g. with a type payload)
  onClick?: OnClickGeoArea;
  onClickApplyDate?: OnClickDate;
  onClickApplyDuration?: OnClickDuration;
  onClickApplyCheckbox?: OnClickCheckboxes;
  dropDownsShouldClose?: boolean;
  setDropDownsShouldClose?: DispatchBooleanStateAction;
  children: (
    onChange:
      | OnChangeCheckbox
      | OnChangeRadio
      | OnClickGeoArea
      | OnClickDate
      | OnClickDuration
      | OnClickCheckboxes,
    setDisabledClickAway: React.Dispatch<React.SetStateAction<boolean>>
  ) => ReactElement;
};

const Dropdown = ({
  text,
  categories,
  targetGroups,
  additionalLabel,
  onChange,
  onChangeSort,
  onClick,
  onClickApplyDate,
  onClickApplyDuration,
  onClickApplyCheckbox,
  dropDownsShouldClose,
  setDropDownsShouldClose,
  children
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [disabledClickAway, setDisabledClickAway] = useState(false);

  useEffect(() => {
    if (dropDownsShouldClose) {
      setOpen(false);
      setDropDownsShouldClose(false);
    }
  }, [dropDownsShouldClose, setDropDownsShouldClose]);

  const toggleDropdown = () => {
    setOpen((isOpen) => !isOpen);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  const isChangeEvent = (
    event: unknown | ChangeEvent<HTMLInputElement>
  ): event is ChangeEvent<HTMLInputElement> => {
    const changeEvent = event as ChangeEvent<HTMLInputElement>;
    return changeEvent?.type === 'change';
  };

  const isEntityModelGeoArea = (
    location: unknown | EntityModelGeoArea
  ): location is EntityModelGeoArea => {
    const geoAreaObject = location as EntityModelGeoArea;
    return typeof geoAreaObject?.id === 'string';
  };

  const isEntityDateFilter = (
    dateFilter: unknown | DateFilter
  ): dateFilter is DateFilter => {
    const dateFilterObject = dateFilter as DateFilter;

    return (
      dateFilterObject &&
      (typeof dateFilterObject.rangeFrom?.toISOString === 'function' ||
        typeof dateFilterObject.rangeTo?.toISOString === 'function' ||
        typeof dateFilterObject.time?.toISOString === 'function')
    );
  };

  const isEntityDurationFilter = (
    durationFilter: unknown | DurationFilter
  ): durationFilter is DurationFilter => {
    const durationFilterObject = durationFilter as DurationFilter;

    return durationFilterObject && 'durationFrom' in durationFilterObject;
  };

  // in the case of different checkboxes with different enums, ListOffersLocationTypesEnum has to be unified with the other enums
  const isCheckboxStateValues = (
    checkboxValues: unknown | CheckboxStateValues
  ): checkboxValues is CheckboxStateValues => {
    const values = checkboxValues as CheckboxStateValues;

    return (
      values &&
      Object.keys(values).every((checkboxKey) =>
        Object.values(ListOffersLocationTypesEnum).includes(
          checkboxKey as ListOffersLocationTypesEnum
        )
      )
    );
  };

  const isVariableCheckboxStateValues = (
    checkboxValues: unknown | CheckboxStateValues,
    variableValues?: EntityModelVariableCheckboxes
  ): checkboxValues is CheckboxStateValues => {
    const values = checkboxValues as CheckboxStateValues;

    return (
      values &&
      Object.keys(values).every((checkboxKey) =>
        variableValues?.some((elem) => elem.id === checkboxKey)
      )
    );
  };

  function onChangeWithAutoClose(event: ChangeEvent<HTMLInputElement>): void;
  function onChangeWithAutoClose(key: string, value?: string): void;
  function onChangeWithAutoClose(
    location: EntityModelGeoArea,
    distance?: number
  ): void;
  function onChangeWithAutoClose(dateFilter: DateFilter): void;
  function onChangeWithAutoClose(durationFilter: DurationFilter): void;
  function onChangeWithAutoClose(checkboxValues: CheckboxStateValues): void;
  function onChangeWithAutoClose(
    eventOrKey: unknown,
    value?: string | number
  ): void {
    if (typeof eventOrKey === 'string') {
      onChangeSort?.(eventOrKey, typeof value === 'string' ? value : undefined);
    } else if (isChangeEvent(eventOrKey)) {
      onChange?.(eventOrKey);
    } else if (
      isCheckboxStateValues(eventOrKey) ||
      isVariableCheckboxStateValues(eventOrKey, categories) ||
      isVariableCheckboxStateValues(eventOrKey, targetGroups)
    ) {
      onClickApplyCheckbox?.(eventOrKey);
    } else if (isEntityDateFilter(eventOrKey)) {
      onClickApplyDate?.(eventOrKey);
    } else if (isEntityDurationFilter(eventOrKey)) {
      onClickApplyDuration?.(eventOrKey);
      // undefined for eventOrKey should only be allowed for GeoArea!!
    } else if (isEntityModelGeoArea(eventOrKey) || eventOrKey === undefined) {
      onClick?.(eventOrKey, typeof value === 'number' ? value : undefined);
    }
    closeDropdown();
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!disabledClickAway) {
          const { scrollX, scrollY } = window;

          closeDropdown();

          // hold position for virtual keyboard close
          window.scrollTo(scrollX, scrollY);
        }
      }}
    >
      <Box sx={{ display: 'flex', margin: 1.25 }}>
        <DropdownButton
          open={open}
          text={text}
          onClick={toggleDropdown}
          additionalLabel={additionalLabel}
        />
        {open && (
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              mt: 5,
              backgroundColor: 'white',
              boxShadow: '0 3px 3px #00000061',
              zIndex: 100
            }}
          >
            <FormControl
              sx={{ mx: 2.25, my: 1.125 }}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                {children(onChangeWithAutoClose, setDisabledClickAway)}
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Dropdown;
