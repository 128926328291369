/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUser } from 'context/user';
import { DialogGeneric } from 'feature';
import { useRequestUserData } from 'feature/hooks';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import { getTextIn, getNode } from 'localization';

const getTextRequestData = getTextIn('account-data-requestData');

const RequestUserDataDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}) => {
  const { currentUser } = useUser();
  const requestDataText = getTextRequestData('action');

  return (
    <DialogGeneric
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={requestDataText}
      confirmText={requestDataText}
      maxWidth="sm"
      prefix="request-user-data-dialog"
    >
      {getNode(
        'getDialogQuestion',
        'account-data-requestData'
      )({
        children: (
          <Typography variant="formLabel" color="primary">
            {currentUser?.email ?? ''}
          </Typography>
        )
      })}
    </DialogGeneric>
  );
};

const RequestUserData = () => {
  const [open, setOpen] = useState(false);
  const requestData = useRequestUserData();

  const handleConfirm = useCallback(() => {
    requestData.mutate('', {
      onSuccess: () => {
        notifyMutationSuccess(getTextRequestData('success'));
      },
      onError: () => {
        notifyMutationError(getTextRequestData('error'));
      }
    });

    setOpen(false);
  }, [requestData]);

  return (
    <>
      <Grid item>
        <Typography component="div" variant="sectionTitle" sx={{ mb: 0.75 }}>
          {getTextRequestData('requestCopy')}
        </Typography>

        <Typography>{getTextRequestData('info')}</Typography>

        <Button
          variant="outlined"
          sx={{ mt: 2.5 }}
          onClick={() => setOpen(true)}
        >
          {getTextRequestData('action')}
        </Button>
      </Grid>

      <RequestUserDataDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RequestUserData;
