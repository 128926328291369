/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { getText, getPath, type Config } from 'localization';
import type { BreadcrumbItem } from './breadcrumb-navigation';

// the url part with the corresponding localization text for the breadcrumb
const getBreadcrumb = () =>
  ({
    home: getText('home'),
    [getPath('universalSearch')]: getText('universalSearch'),
    [getPath('patientInfo')]: getText('patientInfo', 'info'),
    [getPath('doctorInfo')]: getText('doctorInfo', 'info'),
    'anbieter|informationen': getText('providerInfo', 'info'),
    [getPath('providers')]: getText('providers', 'role'),
    [getPath('seekers')]: getText('seekers', 'role'),
    [getPath('compass')]: getText('listTitle', 'compass'),
    [getPath('settings')]: getText('manage', 'settings'),
    [getPath('general')]: getText('general', 'settings-general'),
    [getPath('appearance')]: getText('appearance', 'settings-appearance'),
    [getPath('visibility')]: getText('visibilities', 'settings-visibility'),
    [getPath('participants')]: getText('participants', 'settings-participants'),
    [getPath('categories')]: getText('title', 'settings-categories'),
    [getPath('targetGroups')]: getText('title', 'settings-targetGroups'),
    [getPath('role')]: getText('roles', 'settings'),
    [getPath('account')]: getText('manageAccount', 'user'),
    [getPath('create')]: getText('createOffer', 'offer'),
    [getPath('edit')]: getText('editOffer', 'offer'),
    [getPath('offers')]: getText('offers'),
    [getPath('data')]: getText('manageData', 'account-data'),
    [getPath('contact')]: getText('contact', 'contact'),
    [getPath('reportAccessibility')]: getText('reportAccessibility', 'contact'),
    [getPath('terms')]: getText('terms'),
    [getPath('privacy')]: getText('privacyPolicy'),
    [getPath('imprint')]: getText('imprint'),
    [getPath('accessibility')]: getText('accessibility'),
    [getPath('events')]: getText('events'),
    [getPath('booking')]: getText('bookingList', 'offer-booking')
  } as Config);

// this is an additional customization option, e.g. in the case of one breadcrumb for two url elements
const customTranscription = () => ({
  [`/${getPath('providers')}/${getPath('information')}`]:
    '/anbieter|informationen'
});

type UseAutoBreadcrumbItemsProps = {
  customItem?: { [key: number]: BreadcrumbItem };
};

const useAutoBreadcrumbItems = (props?: UseAutoBreadcrumbItemsProps) => {
  const { customItem = {} } = props ?? {};
  const { pathname } = useLocation();
  const breadcrumbItemsAcc: BreadcrumbItem[] = [];

  const BREADCRUMB = useMemo(getBreadcrumb, []);

  const customPathname =
    customTranscription()[pathname as keyof typeof customTranscription];

  return (customPathname ?? pathname)
    .split('/')
    .reduce((acc, part, index, array) => {
      // home
      if (part === '') {
        // eslint-disable-next-line no-param-reassign
        part = 'home';
      }

      let itemParams;
      const title = BREADCRUMB[part];

      // breadcrumb with path (auto path or custom path)
      if (index < array.length - 1) {
        // auto path
        const path = '/' + array.slice(1, index + 1).join('/');

        if (customItem[index]) {
          itemParams = { ...customItem[index] };

          // add auto path
          if (!customItem[index].path) {
            itemParams.path = path;
          }
        } else if (title) {
          itemParams = { title, path };
        }
      } else {
        // last breadcrumb without path
        itemParams = customItem[index] ?? (title && { title });
      }

      if (itemParams) {
        acc.push(itemParams);
      } else {
        throw new Error(
          `No title available for path "${part}". Add a breadcrumb title to "getBreadcrumb" in breadcrumbs.helper.ts.`
        );
      }

      return acc;
    }, breadcrumbItemsAcc);
};

export { useAutoBreadcrumbItems };
