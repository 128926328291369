/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect } from 'react';
import ImageGallery, { type ReactImageGalleryItem } from 'react-image-gallery';
import {
  type EntityModelGalleryEntry,
  type Image,
  type ImageUrls
} from '@SLR/solution3-sdk';
import { useTheme, Backdrop, Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { isEmptyArray } from 'utils/helper';
import 'react-image-gallery/styles/css/image-gallery.css';

const getHasImages = (images: Array<EntityModelGalleryEntry>) =>
  !isEmptyArray(images);

const findStartIndex = (
  galleryEntries?: Array<EntityModelGalleryEntry> | undefined,
  image?: Image
) => {
  return galleryEntries && getHasImages(galleryEntries) && image
    ? galleryEntries.findIndex((entry) => entry.image.id === image.id)
    : 0;
};

type GalleryViewProps = {
  open: boolean;
  galleryEntries?: EntityModelGalleryEntry[];
  image?: Image;
  onClose: VoidFunction;
  integrated?: boolean;
  backdrop?: boolean;
  size?: keyof ImageUrls;
  showThumbnails?: boolean;
};

const GalleryView = ({
  galleryEntries,
  image,
  open,
  onClose,
  integrated = false,
  backdrop = true,
  size,
  showThumbnails
}: GalleryViewProps) => {
  const theme = useTheme();

  // Prevent background scroll
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  const hasImages = galleryEntries && getHasImages(galleryEntries);
  const startIndex = findStartIndex(galleryEntries, image);

  const integratedSx = {
    '.image-gallery': {
      width: '76vw',
      margin: '0 auto'
    },
    '.image-gallery-icon': {
      color: 'primary.main',
      filter: 'drop-shadow(0 0)'
    },
    '.image-gallery-left-nav': {
      left: -44
    },
    '.image-gallery-right-nav': {
      right: -44
    },
    '.image-gallery-svg': {
      transform: 'scale(0.4)',
      strokeWidth: 2
    },
    '.image-gallery-icon:hover .image-gallery-svg': {
      transform: 'scale(0.48)'
    },
    '.image-gallery-icon:hover': {
      color: theme.palette.primary.dark
    }
  };

  const ImageGalleryBlock = () => (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          m: 2,
          position: 'relative',
          '.image-gallery': {
            width: '75vw',
            maxWidth: 1200
          },
          '.image-gallery-description': {
            textAlign: 'center',
            top: 20,
            bottom: 'unset',
            left: 'unset',
            right: 16,
            color: 'black',
            bgcolor: 'white',
            borderRadius: '5px',
            boxShadow: '1px 1px 5px 1px #373232',
            font: 'normal normal normal 16px/28px Roboto'
          },
          '.image-gallery-icon': {
            color: 'white',
            filter: 'drop-shadow(0 0)',
            opacity: 0.7
          },
          '.image-gallery-left-nav': {
            left: '-9%'
          },
          '.image-gallery-right-nav': {
            right: '-9%'
          },
          '.image-gallery-svg': {
            transform: 'scale(0.6)',
            strokeWidth: 2.5,
            strokeLinejoin: 'unset',
            strokeLinecap: 'unset'
          },
          '.image-gallery-icon:hover': {
            color: 'white',
            opacity: 1
          },
          '.image-gallery-icon:hover .image-gallery-svg': {
            transform: 'scale(0.66)'
          },

          ...(integrated ? integratedSx : {})
        }}
      >
        <ImageGallery
          items={
            galleryEntries && hasImages && open
              ? galleryEntries.map((entry) => {
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  const image = entry?.image;
                  const props = {
                    // original: image?.urls.original,
                    original: image?.urls[size ?? 'original'],
                    thumbnail: image?.urls.thumbnail,
                    description: entry?.description,
                    originalAlt: image?.alternativeText
                  } as ReactImageGalleryItem;

                  return props;
                })
              : image
              ? [
                  {
                    original: image.urls.original,
                    thumbnail: image.urls.thumbnail
                  }
                ]
              : []
          }
          startIndex={startIndex}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={showThumbnails ?? !integrated ?? hasImages}
          slideDuration={250}
          useBrowserFullscreen={false}
        />
      </Box>
    </>
  );

  return backdrop && !integrated ? (
    <Backdrop
      sx={{
        color: '#fff',
        background: 'rgba(0,0,0,0.75)',
        // eslint-disable-next-line @typescript-eslint/no-shadow
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={open}
    >
      <ImageGalleryBlock />
      <IconButton
        aria-label="close-gallery"
        onClick={onClose}
        sx={{
          color: 'white',
          position: 'absolute',
          top: 2,
          right: 2
        }}
      >
        <CloseIcon
          fontSize="large"
          sx={{
            height: '1.5em',
            width: '3rem',
            opacity: 0.7,
            filter: 'drop-shadow(0 2px 2px #1a1a1a)',
            '&:hover': {
              transform: 'scale(1.1)',
              opacity: 1
            },
            cursor: 'pointer'
          }}
        />
      </IconButton>
    </Backdrop>
  ) : (
    <ImageGalleryBlock />
  );
};

export default GalleryView;
