/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ReactElement } from 'react';
import { Box } from '@mui/material';
import Snackbar from 'feature/snackbar';
import { NavBar } from './navbar';
import Footer from './Footer';
import { useUser } from 'context/user';
import { useProject } from 'context/project';
import TwoFaErrorFallback from 'feature/error/2fa-error-fallback';
import { useKeycloakAuth } from 'auth';
import { useLocation } from 'react-router-dom';
import { getPath } from 'localization';

const Layout = ({ children }: { children: ReactElement }) => {
  const location = useLocation();
  const { isAuthenticated } = useKeycloakAuth();
  const { isProjectNew } = useProject();
  const { hasTwoFaEnabled } = useUser();
  const { pathname } = location;

  const pathWhiteList = [
    getPath('contact'),
    getPath('imprint'),
    getPath('privacy'),
    getPath('terms')
  ];

  return (
    <>
      <Snackbar>
        <NavBar />
        <main>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              justifyContent: 'flex-start'
            }}
          >
            {isAuthenticated &&
            isProjectNew &&
            !hasTwoFaEnabled &&
            !pathWhiteList.includes(pathname.replace('/', '')) ? (
              <TwoFaErrorFallback />
            ) : (
              children
            )}
          </Box>
        </main>
        <Footer />
      </Snackbar>
    </>
  );
};

export default Layout;
