/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type Image } from '@SLR/solution3-sdk';
import {
  Avatar,
  Button,
  Box,
  CardContent,
  CardHeader,
  Skeleton,
  Typography
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { CardAvatarProps, Loadable } from 'types';
import { CustomCard } from '.';
import { getText } from 'localization';
import { AvatarFallback } from 'feature/user-avatar';
import CardDescription from './card-description';

export const toAvatar = (image?: Image): CardAvatarProps | undefined => {
  if (!image) {
    return undefined;
  }
  return {
    src: image.urls.small,
    alt: image.alternativeText ?? ''
  };
};

type ParticipantCardProps = {
  title: string;
  avatar?: CardAvatarProps;
  address: string;
  description: string;
  url: string;
  isOrganization: boolean;
};

const AVATAR_SIZE = {
  width: 135,
  height: 135
};

const GenericParticipantCard = ({
  title,
  loading,
  avatar,
  address,
  description,
  url,
  isOrganization
}: Loadable<ParticipantCardProps>) => {
  return (
    <CustomCard href={loading ? '' : url} minWidthPercent={31}>
      <CardHeader
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .MuiCardHeader-content': {
            width: '100%',
            mt: 1
          }
        }}
        disableTypography
        avatar={
          loading ? (
            <Skeleton variant="circular">
              <Avatar sx={AVATAR_SIZE} />
            </Skeleton>
          ) : (
            <Avatar alt={avatar?.alt} src={avatar?.src} sx={AVATAR_SIZE}>
              <AvatarFallback isOrganization={isOrganization} />
            </Avatar>
          )
        }
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="customCardTitle"
              sx={{ mb: 0, height: '3.75rem', minHeight: 'unset' }}
            >
              {!loading && title}
            </Typography>
            {!loading && address && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <PlaceIcon sx={{ color: 'secondary.main', mr: 1 }} />
                <Typography variant="subtitle2">{address}</Typography>
              </Box>
            )}
          </Box>
        }
      />
      <CardContent key="description" sx={{ py: 0 }}>
        <CardDescription html={description} loading={loading} />
      </CardContent>
      <CardContent key="button" sx={{ textAlign: 'right' }}>
        {!loading && <Button variant="text"> {getText('more')}</Button>}
      </CardContent>
    </CustomCard>
  );
};

export default GenericParticipantCard;
