/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ManageData } from 'pages/account';
import { Restricted } from './restricted-route';
import { useUser } from 'context/user';
import { getPath } from 'localization';

const AccountRoutes = () => {
  const { perspective } = useUser();

  return (
    <>
      <Routes>
        {/* navigate to data only until edit profile will be added */}
        <Route index element={<Navigate replace to={getPath('data')} />} />
        <Route
          path={getPath('data')}
          element={
            <Restricted
              isVisible={perspective?.roles?.isSolutionUser}
              forceLogin={true}
            >
              <ManageData />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default AccountRoutes;
