/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, List, ListItem, Typography } from '@mui/material';
import FooterItem from '../footer-item';
import { getTextIn } from 'localization';
import { useProject } from 'context/project';

const getFooterText = getTextIn('footer');

const Contacts = () => {
  const { project } = useProject();
  const {
    operatorZipCode,
    operatorCity,
    operatorStreet,
    operatorHouseNumber,
    operatorLogo
  } = project ?? {};

  return (
    <FooterItem title={getFooterText('contactTitle')}>
      <List dense disablePadding>
        {project?.phone && (
          <ListItem key="phone" disableGutters>
            <Typography component="address" variant="footer">
              {getFooterText('phone')}:{' '}
              <a href={`tel:${project.phone}`}>{project.phone}</a>
            </Typography>
          </ListItem>
        )}
        {operatorZipCode &&
          operatorCity &&
          operatorStreet &&
          operatorHouseNumber && (
            <ListItem key="address" disableGutters>
              <Typography component="address" variant="footer">
                {getFooterText('address')}:{` ${operatorStreet}`}&nbsp;
                {`${operatorHouseNumber}`},{` ${operatorZipCode}`}&nbsp;
                {`${operatorCity}`}
              </Typography>
            </ListItem>
          )}
        {project?.email && (
          <ListItem key="email" disableGutters>
            <Typography component="address" variant="footer">
              {getFooterText('email')}:{' '}
              <a href={`mailto:${project.email}`}>{project.email}</a>
            </Typography>
          </ListItem>
        )}
      </List>
      {operatorLogo ? (
        <Box
          key="logo"
          sx={{
            backgroundColor: 'white',
            mt: 2,
            p: 1,
            width: 'fit-content'
          }}
        >
          <img
            width="auto"
            height={60}
            src={operatorLogo.urls?.original}
            alt={operatorLogo.alternativeText}
          />
        </Box>
      ) : (
        <></>
      )}
    </FooterItem>
  );
};

export default Contacts;
