/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
  ReactElement
} from 'react';
import Keycloak, {
  type KeycloakInitOptions,
  type KeycloakProfile
} from 'keycloak-js';

import {
  KEYCLOAK_BASE_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  IS_DEVELOPMENT
} from 'auth/settings';

const keycloakConfig = {
  url: `${KEYCLOAK_BASE_URL}/`,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID
};

const keycloakProviderInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
  pkceMethod: 'S256',
  enableLogging: IS_DEVELOPMENT
  // redirectUri: window.location.href
};

let currentAccessToken: string | undefined = '';

const getCurrentAccessToken = () => currentAccessToken ?? '';
const setCurrentAccessToken = (token?: string): void => {
  currentAccessToken = token;
};

const keycloak = new Keycloak(keycloakConfig);

interface AuthContextValues {
  isReady: boolean;
  isAuthenticated: boolean;
  keycloakProfile?: KeycloakProfile;
  login: VoidFunction;
  logout: VoidFunction;
  register: VoidFunction;
}

const defaultAuthContextValues: AuthContextValues = {
  isReady: false,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  register: () => {}
};

const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);
const useKeycloakAuth = () => useContext(AuthContext) as AuthContextValues;

interface AuthContextProviderProps {
  children: ReactElement;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [keycloakProfile, setKeycloakProfile] = useState<KeycloakProfile>();

  const login = useCallback(() => {
    keycloak.login({
      // redirectUri
    });
  }, []);

  const logout = useCallback(() => {
    keycloak.logout({
      // redirectUri: window.location.href
    });
  }, []);

  const register = useCallback(() => {
    keycloak.register({
      // redirectUri
    });
  }, []);

  useEffect(() => {
    keycloak.onReady = (authenticated: boolean) => {
      setIsReady(true);
      setAuthenticated(authenticated);
    };

    keycloak.onAuthSuccess = () => {
      keycloak
        .loadUserProfile()
        .then((profile) => {
          setKeycloakProfile(profile);
        })
        .catch((reason) => {
          console.error(
            'error trying to load the keycloak user profile',
            reason
          );
        });
    };

    keycloak.onAuthError = (errorData) => {
      console.error(
        'error on authentication',
        errorData.error,
        errorData.error_description
      );
    };

    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(5)
        .catch((reason) =>
          console.error('error trying to update the token', reason)
        );
    };

    keycloak.onAuthRefreshSuccess = () => {
      setCurrentAccessToken(keycloak.token);
    };

    // Called if there was an error while trying to refresh the token.
    keycloak.onAuthRefreshError = () => {
      keycloak.clearToken();
      keycloak.logout();
    };

    // called on clearToken
    keycloak.onAuthLogout = () => {
      setAuthenticated(false);
      setCurrentAccessToken(undefined);
      setKeycloakProfile(undefined);
    };

    async function initializeKeycloak() {
      keycloak
        .init(keycloakProviderInitOptions)
        .then((authenticated) => {
          setAuthenticated(authenticated);
          setCurrentAccessToken(keycloak.token);
        })
        .catch((reason) => {
          setAuthenticated(false);
          console.error('error initializing Keycloak', reason);
        });
    }

    initializeKeycloak();
  }, []);

  return (
    // Creating the provider and passing the state into it. Whenever the state changes the components using this context will be re-rendered.
    <AuthContext.Provider
      value={{
        isReady,
        isAuthenticated,
        keycloakProfile,
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
export { useKeycloakAuth, getCurrentAccessToken };
