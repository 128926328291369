/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// a collection of router, filter and query related helper functions
import { useEffect } from 'react';
import {
  type EntityModelCategory,
  type EntityModelTargetGroup,
  ListOffersLocationTypesEnum
} from '@SLR/solution3-sdk';
import {
  initCheckboxesBlock,
  type CheckboxStateValues,
  type CheckboxOption
} from 'components';
import { useGetGeoArea } from 'feature/hooks';
import { isEmptyArray } from 'utils/helper';
import { getFormattedRangeString, formatAsDateTimePlain } from 'utils/date';
import { SearchParams, FilterParams, FilterParamsGeo } from './params';
import { sortOptions, sortOptionsWithAdditionalQueryOption } from './config';
import type {
  UrlParams,
  UrlParamsKey,
  SetURLSearchParams,
  SetSearchParamWithPageSetback,
  FilterParamsKeyType,
  CtrlButtonsToShowProps
} from './types';
import type { KeyValueString } from 'types';
import { getTextIn, getTextFx, getText } from 'localization';

const getTrimmedSearchTerm = (searchTerm = '') => {
  const trimmedTerm = searchTerm.trim();
  return trimmedTerm !== '' && trimmedTerm;
};

const HASH_TABLE_ASSIGN_WITHOUT_PARAM = {
  locationTypes: 'withoutLocationType'
};

// reassign the without parameters, combined in the search url to a separate parameter for the BE call
const separateWithoutParam = (
  acc: unknown[][],
  key: keyof typeof HASH_TABLE_ASSIGN_WITHOUT_PARAM,
  value: unknown,
  paramToCheck = 'WITHOUT'
) => {
  if (
    key in HASH_TABLE_ASSIGN_WITHOUT_PARAM &&
    Array.isArray(value) &&
    value.includes(paramToCheck)
  ) {
    // eslint-disable-next-line no-param-reassign
    value = value.filter((param) => param !== paramToCheck);
    acc.push([HASH_TABLE_ASSIGN_WITHOUT_PARAM[key], true]);
  }

  return value;
};

const convertSearchToQueryParams = (
  obj = {},
  hashTable = {},
  newObj: unknown[][] = []
) =>
  Object.fromEntries(
    Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = hashTable[key as keyof typeof hashTable];

      if (newKey) {
        // eslint-disable-next-line no-param-reassign
        value = separateWithoutParam(acc, newKey, value);

        if (!isEmptyArray(value)) {
          acc.push([newKey, value]);
        }
      }
      return acc;
    }, newObj)
  );

const convertCheckBoxesStateToUrlParamString = (state: CheckboxStateValues) => {
  const accumulator: string[] = [];
  return Object.entries(state)
    .reduce((acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    }, accumulator)
    .join(',');
};

const getNewSearch = (search: UrlParams, valueObj: UrlParams) => {
  const newSearch = { ...search, ...valueObj };

  // keep date types in iso format (otherwise, it will switch to utc)
  if (newSearch.time) {
    newSearch.time = new Date(newSearch.time).toISOString();
  }

  if (newSearch.timeRange && Array.isArray(newSearch.timeRange)) {
    newSearch.timeRange = newSearch.timeRange.map((rangeElem) => {
      return rangeElem ? new Date(rangeElem).toISOString() : rangeElem;
    });
  }

  return newSearch;
};

const setSearchParamWithPageSetbackFor =
  (search: UrlParams, setSearch: SetURLSearchParams) =>
  (valueObj: UrlParams, noPageSetback = false) => {
    const newSearch = getNewSearch(search, valueObj);

    if (!noPageSetback) {
      delete newSearch[SearchParams.page];
    }

    // reset values
    Object.keys(search).forEach((key) => {
      const typedKey = key as UrlParamsKey;

      // delete value from search, if an empty string value has been passed
      if (key in valueObj && !valueObj[typedKey] && key !== SearchParams.page) {
        delete newSearch[typedKey];
        delete valueObj[typedKey];
      } else {
        const searchParam = newSearch[typedKey];

        // reconvert array to string notation
        if (Array.isArray(searchParam)) {
          newSearch[typedKey] = searchParam.join(',');
        }
      }
    });

    setSearch(newSearch);
  };

/* filter options block */
const getListOptions = (optionsEnum: KeyValueString) => {
  const getFilterText = getTextIn('filter');

  return Object.values(optionsEnum).map((value) => ({
    id: value,
    name: getFilterText(value.toLowerCase())
  }));
};

type VariableCheckboxOptions =
  | EntityModelCategory[]
  | EntityModelTargetGroup[]
  | undefined;

type VariableCheckboxValues = {
  categories?: EntityModelCategory[];
  targetGroups?: EntityModelTargetGroup[];
};

const getFilterOptions = ({
  filterParam,
  values
}: {
  filterParam?: FilterParamsKeyType;
  values?: VariableCheckboxValues;
}) => {
  const lists = {
    [SearchParams.locationTypes]: () => [
      ...getListOptions(ListOffersLocationTypesEnum)
    ],
    [SearchParams.categories]: () => values?.categories ?? [],
    [SearchParams.targetGroups]: () => values?.targetGroups ?? []
  };

  const options = lists[filterParam as keyof typeof lists]?.();
  return options ?? [];
};

const getSortOptions = (isQuerySet: boolean) => {
  // eslint-disable-next-line no-negated-condition
  return !isQuerySet ? sortOptions : sortOptionsWithAdditionalQueryOption;
};

const getSortParam = (search: UrlParams) => {
  return !search[SearchParams.sort] && !search[SearchParams.query]
    ? 'created,desc'
    : search[SearchParams.sort];
};

const getSortName = (search: UrlParams, isQuerySet: boolean) => {
  const sortParam = getSortParam(search);
  const currentSortOptions = getSortOptions(isQuerySet);

  const sortLabel = currentSortOptions.options.find(
    (option) => sortParam === option.value
  )?.label;

  return sortLabel ?? getText('sortBy', 'filter');
};

const getFilterCheckboxValues = (
  search: UrlParams,
  filterParam: FilterParamsKeyType,
  values?: VariableCheckboxValues
) => {
  return initCheckboxesBlock(
    getFilterOptions({ filterParam, values }),
    search[filterParam]
  );
};

const getCheckboxLabel = (
  checkboxValues: CheckboxOption[] | VariableCheckboxOptions,
  name: string
) => {
  return checkboxValues?.find((value) => value.id === name)?.name;
};

const useCtrlButtonsToShow = ({
  filterButtons,
  numberToShow,
  setFilterButtonsCount
}: CtrlButtonsToShowProps) => {
  const filterButtonsCount = filterButtons.length;

  useEffect(() => {
    setFilterButtonsCount(filterButtonsCount);
  }, [filterButtonsCount, setFilterButtonsCount]);

  const filterButtonsToShow =
    numberToShow == undefined
      ? filterButtons
      : filterButtons.slice(0, numberToShow);

  return filterButtonsToShow;
};

const convertDurationHmNotation = (value: string) => {
  const [hours, minutes] = value.split(':');
  const hoursNumber = hours && Number(hours);
  const minutesNumber = minutes && Number(minutes);

  const convertedDuration = [];

  if (hoursNumber) {
    convertedDuration[0] = `${hoursNumber}h`;
  }

  if (minutesNumber) {
    convertedDuration[1] = `${minutesNumber}min`;
  } else if (!hoursNumber) {
    convertedDuration[1] = `10min`;
  }

  return convertedDuration.join(' ').trim();
};

const formatDate = (dateString: string) => {
  return formatAsDateTimePlain(new Date(dateString));
};

const useGetFilterButtons = (search: UrlParams) => {
  const geoArea = useGetGeoArea(search[SearchParams.geoAreaId] ?? '');

  const filterButtonsAcc: string[] = [];

  const filterButtons = Object.values(FilterParams).reduce((acc, key) => {
    const typedKey = key as FilterParamsKeyType;

    if (key in search) {
      const filterParam = search[typedKey];

      if (Array.isArray(filterParam)) {
        if (typedKey === SearchParams.duration) {
          acc.push(
            `${key}_${getFormattedRangeString(
              convertDurationHmNotation(filterParam[0]),
              convertDurationHmNotation(filterParam[1])
            )}`
          );
        } else if (typedKey === SearchParams.timeRange) {
          const timeRange = search[FilterParams.timeRange];
          const [fromDate, toDate] = timeRange;

          const convertedString = toDate
            ? fromDate
              ? getFormattedRangeString(
                  formatDate(timeRange[0]),
                  formatDate(timeRange[1])
                )
              : `${getText('until', 'filter-time')} ${formatDate(toDate)}`
            : `${getText('asOf', 'filter-time')} ${formatDate(fromDate)}`;

          acc.push(`${'timeRange'}_${convertedString}`);
        } else {
          filterParam.reduce((accInner, filterName) => {
            accInner.push(`${key}_${filterName}`);
            return accInner;
          }, acc);
        }
      } else {
        if (typedKey === SearchParams.geoAreaId) {
          const distanceValue = search[FilterParamsGeo.distance];

          const params: KeyValueString = {
            location: geoArea.data?.name ?? ''
          };

          if (params.location) {
            params.distance = distanceValue ?? '';
          }

          const label = `${SearchParams.geoAreaId}_${getTextFx(
            'getSelectedGeoArea',
            'filter-geoArea'
          )(params)}`;

          acc.push(label);
        } else if (typedKey === SearchParams.time) {
          acc.push(`${'time'}_${formatDate(search[FilterParams.time] ?? '')}`);
        }
      }
    }

    return acc;
  }, filterButtonsAcc);

  return filterButtons;
};

const getFilterButtonLabel =
  (values?: VariableCheckboxValues) =>
  (key: FilterParamsKeyType, name: string) => {
    if (
      key === SearchParams.geoAreaId ||
      key === SearchParams.duration ||
      key === SearchParams.timeRange ||
      key === SearchParams.time
    ) {
      return name;
    } else {
      const checkboxValues =
        key === SearchParams.categories
          ? values?.categories
          : key === SearchParams.targetGroups
          ? values?.targetGroups
          : getFilterOptions({ filterParam: key });

      return getCheckboxLabel(checkboxValues, name);
    }
  };

const getIsFilterSet = (search: UrlParams) => {
  return Object.values(FilterParams).reduce((acc, searchFilter) => {
    if (searchFilter in search) {
      // eslint-disable-next-line no-param-reassign
      acc = true;
    }
    return acc;
  }, false);
};

const onRemoveAllFilters =
  (
    search: UrlParams,
    setSearchParamWithPageSetback: SetSearchParamWithPageSetback
  ) =>
  () => {
    const newSearch = Object.values(FilterParams).reduce(
      (acc, searchFilter) => {
        if (searchFilter in acc) {
          acc[searchFilter] = '';
        }
        return acc;
      },
      { ...search }
    );
    delete newSearch[SearchParams.page];

    setSearchParamWithPageSetback(newSearch);
  };

export {
  getTrimmedSearchTerm,
  convertSearchToQueryParams,
  convertCheckBoxesStateToUrlParamString,
  setSearchParamWithPageSetbackFor,
  getFilterOptions,
  getSortOptions,
  getSortParam,
  getSortName,
  getFilterCheckboxValues,
  getIsFilterSet,
  getFilterButtonLabel,
  onRemoveAllFilters,
  useCtrlButtonsToShow,
  useGetFilterButtons
};
export type { VariableCheckboxOptions, VariableCheckboxValues };
