/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { BookingBadge } from 'components';
import {
  IS_ADMIN,
  PROVIDER_ORGANIZATIONS_VISIBILITY,
  OFFER_VISIBILITY,
  OFFERS_BOOKABLE_ORGANIZATION,
  OFFERS_BOOKABLE_SEEKER,
  HAS_TARGET_GROUPS,
  SEEKER_ORGANIZATIONS_VISIBILITY,
  HAS_KNOWLEDGE_BASE
} from './constants';
import { getText, getTextIn, getPath } from 'localization';

export type RouteLink = {
  path: string;
  title: string;
  icon?: JSX.Element;
  protect?: boolean | string;
};

export type RouteLinks = {
  [key: string]: RouteLink;
};

const getConfigRouteLinks = () => {
  const getTextSettings = getTextIn('settings');

  /**
   * to protect a link, add the protect key with:
   *    - true: check if user is authenticated
   *    - isAdmin: check if user is admin
   *    - providerOrganizationsVisibility: check if should be displayed according to providerOrganizationsVisibility setting
   *    - offerVisibility: check if should be displayed according to offerVisibility setting
   *    - offersBookableSeeker: check if project supports booking and user is a seeker
   *    - offersBookableOrganization: check if project supports booking and perspective is organization
   */
  return {
    topNavigationLinks: [
      {
        path: `${getPath('settings')}/${getPath('general')}`,
        title: getTextSettings('manage'),
        protect: IS_ADMIN
      },
      {
        path: getPath('booking'),
        title: getText('bookingList', 'offer-booking'),
        icon: <BookingBadge />,
        protect: OFFERS_BOOKABLE_SEEKER
      },
      {
        path: getPath('events'),
        title: getText('events'),
        protect: OFFERS_BOOKABLE_ORGANIZATION
      },
      {
        path: getPath('contact'),
        title: getText('contact', 'contact')
      }
    ],
    navigationLinks: [
      { path: '', title: getText('home') },
      {
        path: `${getPath('offers')}?loadFilter=true`,
        title: getText('offers', 'offer'),
        protect: OFFER_VISIBILITY
      },
      {
        path: getPath('patientInfo'),
        title: getText('patientInfoShort', 'info')
      },
      {
        path: getPath('providers'),
        title: getText('providers', 'role'),
        protect: PROVIDER_ORGANIZATIONS_VISIBILITY
      },
      {
        path: getPath('seekers'),
        title: getText('seekers', 'role'),
        protect: SEEKER_ORGANIZATIONS_VISIBILITY
      },
      {
        path: getPath('compass'),
        title: getText('listTitle', 'compass'),
        protect: HAS_KNOWLEDGE_BASE
      }
    ],
    settingsLinks: [
      {
        path: getPath('general'),
        title: getText('general', 'settings-general')
      },
      {
        path: getPath('appearance'),
        title: getText('appearance', 'settings-appearance')
      },
      {
        path: getPath('visibility'),
        title: getText('visibilities', 'settings-visibility')
      },
      {
        path: getPath('participants'),
        title: getText('participants', 'settings-participants')
      },
      {
        path: getPath('categories'),
        title: getText('title', 'settings-categories')
      },
      {
        path: getPath('targetGroups'),
        title: getText('title', 'settings-targetGroups'),
        protect: HAS_TARGET_GROUPS
      }
    ],
    accountLinks: [
      // { path: 'bearbeiten', title: getText('editProfile', 'account-profile') },
      // {
      //   path: 'angebote',
      //   title: getText('offers', 'account-offers')
      // },
      {
        path: getPath('data'),
        title: getText('manageData', 'account-data')
      }
    ]
  };
};

export default getConfigRouteLinks;
