/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type {
  OrganizationProfileUpdate,
  EntityModelOrganizationProfile
} from '@SLR/solution3-sdk';
import useOrganizationProfilesApi from './useOrganizationProfilesApi';
import { useGetProjectId } from 'context/project';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type UpdateOrganizationProfileType = {
  organizationId: string;
  organizationProfileUpdate: OrganizationProfileUpdate;
};

const useUpdateOrganizationProfile = () => {
  const projectId = useGetProjectId();
  const queryClient = useQueryClient();
  const { updateOrganizationProfile } = useOrganizationProfilesApi();
  return useMutation<
    EntityModelOrganizationProfile,
    Error,
    UpdateOrganizationProfileType
  >(
    ({
      organizationId,
      organizationProfileUpdate
    }: UpdateOrganizationProfileType) => {
      return updateOrganizationProfile({
        projectId: projectId ?? '',
        organizationId,
        organizationProfileUpdate
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          'organization profile',
          { projectId, organizationId: data.organizationId }
        ]);
        queryClient.invalidateQueries(['participantsInfo', { projectId }]);
      }
    }
  );
};

export default useUpdateOrganizationProfile;
