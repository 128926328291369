/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  SchemaValidationFormProvider,
  useSchemaValidationForm
} from 'feature/forms';
import { ReactElement } from 'react';
import {
  type FieldValues,
  type DefaultValues,
  type UseFormHandleSubmit,
  type UseFormGetValues,
  type UseFormSetValue,
  type Control
} from 'react-hook-form';

import { ObjectSchema } from 'yup';
import { UseFormSetValueValidating } from './use-form';

type SchemaProviderOptions = { [key: string]: boolean | undefined };
interface Props<T> {
  defaultValues: T;
  values?: T;
  schema:
    | ObjectSchema<T & FieldValues>
    | ((options?: SchemaProviderOptions) => ObjectSchema<T & FieldValues>);
  options?: SchemaProviderOptions;
  children: ({
    handleSubmit,
    getValues,
    setValue,
    setValueValidating,
    control
  }: {
    handleSubmit: UseFormHandleSubmit<T & FieldValues>;
    getValues: UseFormGetValues<T & FieldValues>;
    setValue: UseFormSetValue<T & FieldValues>;
    setValueValidating: UseFormSetValueValidating<T & FieldValues>;
    control: Control<T & FieldValues>;
  }) => ReactElement | ReactElement[];
}

function Fx<T>({
  defaultValues,
  values,
  schema,
  options,
  children
}: Props<T & FieldValues>): ReactElement {
  const form = useSchemaValidationForm<T & FieldValues>(
    defaultValues as DefaultValues<T & FieldValues>,
    values,
    typeof schema === 'function' ? schema(options) : schema
  );

  return (
    <SchemaValidationFormProvider {...form}>
      {children({
        handleSubmit: form.handleSubmit,
        getValues: form.getValues,
        setValue: form.setValue,
        setValueValidating: form.setValueValidating,
        control: form.control
      })}
    </SchemaValidationFormProvider>
  );
}

// a schema independent schema provider
const SchemaProvider: <T>({
  defaultValues,
  values,
  schema,
  options,
  children
}: Props<T & FieldValues>) => ReactElement = Fx;

export default SchemaProvider;
export type { SchemaProviderOptions };
