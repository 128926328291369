/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { getText, getTextIn, getPath } from 'localization';
import { ParticipantGrid } from 'feature';
import { CardPreviewSection, useCardPreviewNumber } from 'feature/cards';
import { useListParticipantsInfo } from 'feature/hooks';

const getInfoText = getTextIn('info');

type ProviderPreviewSectionType = {
  title?: string;
  coloredBackground?: boolean;
};

const ProviderPreviewSection = ({
  title = getText('members')
}: ProviderPreviewSectionType) => {
  const providers = useListParticipantsInfo({
    size: 4,
    showProviders: true,
    showSeekers: false,
    sort: ['random']
  });

  const previewNumber = useCardPreviewNumber();

  return (
    <CardPreviewSection
      title={title}
      showMoreButtonConfig={{
        to: `/${getPath('providers')}`,
        text: getInfoText('moreProviders')
      }}
    >
      <ParticipantGrid
        participantInfos={providers.data?.embedded?.participantInfos?.slice(
          0,
          previewNumber
        )}
        isLoading={providers.isLoading}
        isPreview
        role="provider"
      />
    </CardPreviewSection>
  );
};

export default ProviderPreviewSection;
