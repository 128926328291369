/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';
import { Box } from '@mui/material';
import ImageGalleryDialog from 'feature/gallery/image-gallery-dialog';
import { type Image } from '@SLR/solution3-sdk';

export const HERO_HEIGHTS = {
  xs: '200px',
  sm: '250px',
  lg: '300px',
  xxl: '400px'
};

type HeroSectionProps = {
  image?: Image;
};

const HeroSection = ({ image }: HeroSectionProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleImageClick = () => {
    if (image) {
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Box
        data-cy="hero-section-image"
        sx={{
          backgroundImage: `url(${image?.urls.large})`,
          backgroundColor: 'primary.background',
          backgroundBlendMode: 'unset',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          minHeight: HERO_HEIGHTS,
          cursor: image ? 'pointer' : 'default' // Change cursor based on image presence
        }}
        onClick={handleImageClick} // Only attach onClick handler if image is available
      ></Box>
      {image && (
        <ImageGalleryDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          image={image}
        />
      )}
    </>
  );
};

export default HeroSection;
