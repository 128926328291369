/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Stack, Typography } from '@mui/material';
import FooterItem from '../footer-item';
import FooterImage from 'assets/bmel-logo.png';
import { getText, getTextIn } from 'localization';
import { useProject } from 'context/project';

const getFooterText = getTextIn('footer');

const Notes = () => {
  const { project } = useProject();
  return (
    <FooterItem title={getFooterText('hintTitle')}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Typography variant="footer">
          {(project?.domain ?? getText('projectFallback')) +
            getFooterText('hintIESE')}
          <br></br>
          <br></br>
          {getFooterText('hintSLR')}
        </Typography>
        <img
          src={FooterImage}
          alt={getFooterText('imgAltText')}
          width="auto"
          height={140}
        />
      </Stack>
    </FooterItem>
  );
};

export default Notes;
