/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Section } from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { SanitizedHTML } from 'feature';
import { useReplaceProjectSectionText } from 'feature/hooks';
import { getTextIn } from 'localization';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { EMPTY_HTML, isNotEmptyHtml } from 'utils/editor';
import EditWrapper from './edit-wrapper';
import TextEditor from './text-editor';
import TextSectionPlaceholder from './text-section-placeholder';

const getEditText = getTextIn('edit');

type EditSectionProps = {
  section: Section;
  sectionText?: string;
};

const EditSection = ({ section, sectionText }: EditSectionProps) => {
  const [showFields, setShowFields] = useState(false);
  const initialContent = sectionText ?? '';
  const [content, setContent] = useState<string>(initialContent);
  const projectId = useGetProjectId();
  const replaceProjectSectionText = useReplaceProjectSectionText(section);
  const { enqueueSnackbar } = useSnackbar();

  const onSaveClicked = () => {
    replaceProjectSectionText.mutate(
      {
        projectId: projectId ?? '',
        section,
        text: content || EMPTY_HTML
      },
      {
        onError: () => {
          enqueueSnackbar(getEditText('editTextError'), {
            variant: 'error'
          });
        },
        onSuccess: () => {
          enqueueSnackbar(getEditText('editSuccess'), {
            variant: 'success'
          });
          setShowFields(false);
        }
      }
    );
  };

  const onCancelClicked = () => {
    setShowFields(false);
    setTimeout(() => setContent(initialContent), 300);
  };

  return (
    <EditWrapper
      showFields={showFields}
      setShowFields={setShowFields}
      onCancelClicked={onCancelClicked}
      onSaveClicked={onSaveClicked}
      loading={replaceProjectSectionText.isLoading}
      disabled={initialContent === content}
      dataCyPostfix={`${section}-text`}
    >
      {showFields ? (
        <TextEditor
          placeholderText={getEditText('editorPlaceholder')}
          content={content}
          onEditorChange={setContent}
          customFontSize="18px"
        />
      ) : initialContent && isNotEmptyHtml(initialContent) ? (
        <SanitizedHTML dirtyHTML={initialContent} />
      ) : (
        <TextSectionPlaceholder loading={false} />
      )}
    </EditWrapper>
  );
};

export default EditSection;
