/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import MuiAlert, { type AlertColor } from '@mui/material/Alert';
import {
  SnackbarProvider,
  SnackbarContent,
  useSnackbar,
  type CustomContentProps
} from 'notistack';

const SnackbarAlert = React.forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => {
    const { id, message, variant, anchorOrigin } = props;
    const { closeSnackbar } = useSnackbar();
    return (
      <SnackbarContent ref={ref} role="alert" {...anchorOrigin}>
        <MuiAlert
          data-cy={`snackbar-${variant}-alert`}
          key={id}
          severity={variant as AlertColor}
          onClose={() => closeSnackbar(id)}
          sx={{
            mx: { xs: 0, sm: 1.5, md: 2, xxl: 4 }
          }}
        >
          {message}
        </MuiAlert>
      </SnackbarContent>
    );
  }
);

type SnackbarProps = {
  children: React.ReactNode;
};

const Snackbar = ({ children }: SnackbarProps) => {
  const snackbarRef = React.createRef<SnackbarProvider>();

  return (
    <Box
      sx={{
        '& .notistack-SnackbarContainer': {
          mt: { xs: 8, sm: 13, md: 18 },
          maxWidth: '100%',
          right: 0,
          left: 0
        },
        '& .notistack-SnackbarContainer > div': {
          width: '100%',
          maxWidth: 2120,
          m: '0 auto',
          pr: { xs: 1.5, sm: 2.75, md: 4.5 }
        },
        '& .notistack-Snackbar > div': {
          justifyContent:
            'end' /* fix to align snackbar to right side in xs viewport */,
          width: 'fit-content',
          position: 'absolute',
          right: 0
        },
        '& .notistack-Snackbar .MuiPaper-root': {
          mr: 0
        }
      }}
    >
      <SnackbarProvider
        ref={snackbarRef}
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        TransitionComponent={Fade}
        preventDuplicate
        Components={{
          success: SnackbarAlert,
          error: SnackbarAlert,
          warning: SnackbarAlert,
          info: SnackbarAlert
        }}
      >
        {children}
      </SnackbarProvider>
    </Box>
  );
};

export default Snackbar;
