/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import dayjs, { type Dayjs } from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

const dateToDayjs = (date?: Date | null): Dayjs | undefined =>
  date ? dayjs(date) : undefined;

const dayjsToDate = (date: Dayjs | null): Date | undefined =>
  date ? date.toDate() : undefined;

const formatAsDate = (date: Date): string => dayjs(date).format('DD.MM.YYYY');

const formatAsDateTime = (dateTime: Date): string =>
  dayjs(dateTime).format('DD.MM.YYYY, H:mm');

const formatAsDateTimePlain = (dateTime: Date): string =>
  dayjs(dateTime).format('DD.MM.YYYY H:mm');

const formatAsNonBreakingDateTime = (dateTime: Date): string =>
  dayjs(dateTime).format('DD.MM.YYYY\u00A0H:mm');

const formatAsDateTimeExtended = (dateTime: Date): string =>
  dayjs(dateTime).format('dddd, DD.MM.YYYY; H:mm');

const formatAsTime = (time: Date): string => dayjs(time).format('H:mm');

const RANGE_STRING_SEPARATOR = '\u00A0\u2013 ';

// connect start and end value of a date/time range with a no breaking space and n-dash
const getFormattedRangeString = (start = '', end = '') =>
  `${start}${RANGE_STRING_SEPARATOR}${end}`;

/**
 * Convert a zero based weekday index from SUN-SAT to MON-SUN
 */
const sunSatWeekdayToMonSun = (index: number): number => {
  /*
  SUN: 0 -> 6
  MON: 1 -> 0
  TUE: 2 -> 1
  WED: 3 -> 2
  THU: 4 -> 3
  FRI: 5 -> 4
  SAT: 6 -> 5
   */
  const monSun = index - 1;
  return monSun < 0 ? 6 : monSun;
};

const getWeekInMonth = (dayOfMonth: number): number =>
  Math.floor(dayOfMonth / 7.0);

const addNumberOfDays = (numberOfDays: number) => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + numberOfDays);
  return newDate;
};

const addHours = (numberOfHours: number) => {
  const newDate = new Date();
  newDate.setHours(newDate.getHours() + numberOfHours);
  return newDate;
};

export {
  dateToDayjs,
  dayjsToDate,
  formatAsDate,
  formatAsDateTime,
  formatAsTime,
  formatAsDateTimePlain,
  formatAsNonBreakingDateTime,
  sunSatWeekdayToMonSun,
  getWeekInMonth,
  addNumberOfDays,
  addHours,
  formatAsDateTimeExtended,
  getFormattedRangeString,
  RANGE_STRING_SEPARATOR
};
