/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  type EntityModelOffer,
  type ImageCreate,
  type OfferCreate,
  type OfferUpdate
} from '@SLR/solution3-sdk';
import {
  DEFAULT_MAX_CLOB_LENGTH,
  DialogImage,
  DialogImageSchema,
  NON_EMPTY_HTML,
  type SchemaProviderOptions
} from 'feature/forms';
import { getErrorText, getTextFx, getTextIn } from 'localization';
import { RRule } from 'rrule';
import { type Nullable } from 'types';
import { formatAsDate } from 'utils/date';
import {
  customDurationToIso,
  getIsValidDuration,
  isoDurationToCustom
} from 'utils/duration';
import { isEmptyOrNull, ZIPCODE_REGEX } from 'utils/helper';
import { OTHER } from 'utils/url-param';
import {
  array,
  date,
  object,
  ObjectSchema,
  ref,
  string,
  type StringSchema
} from 'yup';
import {
  FIXED_LOCATION,
  MOBILE_LOCATION,
  ONLINE_LOCATION
} from './locations-form';

type Location = {
  geoAreaId: string;
};

type MobileLocation = {
  locations: Location[];
};

type FixedLocation = {
  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  description: string;
};

type OnlineLocation = {
  websiteUrl: string;
};

enum TimeType {
  fixedTime = 'fixedTime',
  timeRange = 'timeRange',
  businessHours = 'businessHours'
}

type Offer = {
  title: string;
  description: string;
  requirements: string;
  mobileLocation: MobileLocation | null;
  fixedLocation: FixedLocation | null;
  onlineLocation: OnlineLocation | null;
  additionalLocationTest?: string;
  duration: string;
  timeType: TimeType | null;
  startTime: Date | null;
  endTime: Date | null;
  recurrenceRule: string | null;
  picture?: DialogImage | null;
  categoryId?: string | null;
  targetGroupId?: string | null;
};

const EMPTY_MOBILE_LOCATION: MobileLocation = Object.freeze({
  locations: []
});

const EMPTY_FIXED_LOCATION: FixedLocation = Object.freeze({
  street: '',
  houseNumber: '',
  city: '',
  zipCode: '',
  description: ''
});

const EMPTY_ONLINE_LOCATION: OnlineLocation = Object.freeze({
  websiteUrl: ''
});

const EMPTY_OFFER: Offer = Object.freeze({
  title: '',
  description: '',
  requirements: '',
  mobileLocation: EMPTY_MOBILE_LOCATION,
  fixedLocation: EMPTY_FIXED_LOCATION,
  onlineLocation: null,
  additionalLocationTest: '',
  duration: '',
  timeType: TimeType.businessHours,
  startTime: null,
  endTime: null,
  recurrenceRule: null,
  picture: null,
  categoryId: null,
  targetGroupId: null
});

const getOfferText = getTextIn('offer-details');
const getMobileLocationText = getTextIn('offer-details-mobileLocation');
const getFixedLocationText = getTextIn('offer-details-fixedLocation');
const getOnlineLocationText = getTextIn('offer-details-onlineLocation');

const LocationSchema: ObjectSchema<Location> = object().shape({
  geoAreaId: string().required().max(36)
});

const MobileLocationSchema = (): ObjectSchema<MobileLocation> =>
  object().shape({
    locations: array(LocationSchema)
      .required()
      .min(1, getMobileLocationText('atLeastOne'))
      .label(getMobileLocationText('locations'))
  });

const FixedLocationSchema = (): ObjectSchema<FixedLocation> =>
  object().shape({
    street: string()
      .required()
      .default('')
      .max(255)
      .label(getFixedLocationText('street')),
    houseNumber: string()
      .required()
      .default('')
      .max(5)
      .label(getFixedLocationText('houseNumber')),
    city: string()
      .required()
      .default('')
      .max(255)
      .label(getFixedLocationText('city')),
    zipCode: string()
      .required()
      .matches(ZIPCODE_REGEX, { message: getErrorText('zipFormatError') })
      .default('')
      .max(5)
      .label(getFixedLocationText('zipCode')),
    description: string()
      .defined()
      .nonNullable()
      .default('')
      .max(255)
      .label(getFixedLocationText('description'))
  });

const OnlineLocationSchema = (): ObjectSchema<OnlineLocation> =>
  object().shape({
    websiteUrl: string()
      .defined()
      .max(2048)
      .url()
      .label(getOnlineLocationText('url'))
  });

const OfferSchema = (options: SchemaProviderOptions): ObjectSchema<Offer> => {
  return object().shape({
    title: string().required().min(1).max(100).label(getOfferText('title')),
    description: string()
      .required()
      .min(1)
      .max(DEFAULT_MAX_CLOB_LENGTH)
      .test(NON_EMPTY_HTML)
      .label(getOfferText('description')),
    requirements: string()
      .defined()
      .nonNullable()
      .max(DEFAULT_MAX_CLOB_LENGTH)
      .label(getOfferText('requirements')),
    mobileLocation: MobileLocationSchema().defined().nullable(),
    fixedLocation: FixedLocationSchema().defined().nullable(),
    onlineLocation: OnlineLocationSchema().defined().nullable(),

    additionalLocationTest: string().test({
      name: 'additional-location-test',
      test: (_, context) => {
        const notAllLocationsNull =
          context.parent[MOBILE_LOCATION] !== null ||
          context.parent[FIXED_LOCATION] !== null ||
          context.parent[ONLINE_LOCATION] !== null;

        return notAllLocationsNull;
      }
    }),

    // conditional .required
    duration: (() => {
      let optional = string();
      if (options.durationRequired) {
        optional = optional.required();
      }
      return optional
        .test(
          getIsValidDuration(
            getOfferText('durationFormatError'),
            'format',
            'valid-duration-format'
          )
        )
        .test(getIsValidDuration(getOfferText('durationRangeError')))
        .label(getOfferText('duration'));
    })() as StringSchema<string>,

    timeType: string<TimeType>()
      .defined()
      .nullable()
      .oneOf(Object.values(TimeType)),
    startTime: date()
      .defined()
      .nullable()
      .when('timeType', {
        is: (value: TimeType | null) =>
          value === TimeType.fixedTime || value === TimeType.timeRange,
        then: (schema) => schema.nonNullable()
      })
      .typeError(getErrorText('dateFormatError'))
      .label(getOfferText('startTime')),
    endTime: date()
      .defined()
      .nullable()
      .when('timeType', {
        is: (value: TimeType | null) => value === TimeType.timeRange,
        then: (schema) => schema.nonNullable().min(ref('startTime'))
      })
      .typeError(getErrorText('dateFormatError'))
      .label(getOfferText('endTime')),
    recurrenceRule: string()
      .defined()
      .nullable()
      .test({
        name: 'test_recurrence_rule',
        test: (value, context) => {
          const rule = RRule.fromString(value ?? '');
          const startTime = new Date(context.parent.startTime);
          const endTime =
            context.parent.endTime && new Date(context.parent.endTime);

          startTime.setHours(0, 0, 0, 0);
          // compare with last minute of the day
          endTime?.setHours(23, 59, 59, 0);

          const until = rule?.options?.until;
          const noStartTimeError = until && until >= startTime;
          const noEndTimeError = !endTime || (until && until >= endTime);

          return (
            !until ||
            (noStartTimeError && noEndTimeError) ||
            context.createError({
              message: getTextFx(
                'getRecurrenceError',
                'offer-details'
              )({
                ['from']: formatAsDate(
                  context.parent[noEndTimeError ? 'startTime' : 'endTime']
                )
              })
            })
          );
        }
      }),
    picture: DialogImageSchema.defined().nullable(),
    categoryId: string().defined().label(getOfferText('category')),
    targetGroupId: options.targetGroupRequired
      ? string().required().defined().label(getOfferText('targetGroup'))
      : string().nullable().label(getOfferText('targetGroup'))
  });
};
const throwValueMissing = (): never => {
  throw new Error('Value is missing');
};

type Time = {
  startTime: Date;
  endTime: Date;
};

const getTimeType = (apiOffer: EntityModelOffer): TimeType | null => {
  if (apiOffer.startTime) {
    if (apiOffer.endTime) {
      return TimeType.timeRange;
    } else {
      return TimeType.fixedTime;
    }
  } else if (!apiOffer.endTime) {
    return TimeType.businessHours;
  }
  return null;
};

const toFormRepresentation = (
  apiOffer?: EntityModelOffer
): Offer | undefined => {
  if (!apiOffer) {
    return;
  }
  return {
    title: apiOffer.title,
    description: apiOffer.description,
    requirements: apiOffer.requirements ?? '',
    mobileLocation: apiOffer.mobileLocation
      ? {
          locations: apiOffer.mobileLocation.locations
        }
      : null,
    fixedLocation: apiOffer.fixedLocation
      ? {
          street: apiOffer.fixedLocation.street,
          houseNumber: apiOffer.fixedLocation.houseNumber,
          city: apiOffer.fixedLocation.city,
          zipCode: apiOffer.fixedLocation.zipCode,
          description: apiOffer.fixedLocation.description
        }
      : null,
    onlineLocation: apiOffer.onlineLocation
      ? {
          websiteUrl: apiOffer.onlineLocation.websiteUrl ?? ''
        }
      : null,
    duration: isoDurationToCustom(apiOffer.duration) ?? '',
    timeType: getTimeType(apiOffer),
    startTime: apiOffer.startTime ?? null,
    endTime: apiOffer.endTime ?? null,
    recurrenceRule: apiOffer.recurrenceRule ?? null,
    picture: apiOffer.picture ?? null,
    categoryId: apiOffer.category?.id ?? OTHER,
    targetGroupId: apiOffer.targetGroup?.id ?? OTHER
  };
};

const timeTypeToCreate = (offer: Offer): Partial<Time> => {
  switch (offer.timeType) {
    case TimeType.fixedTime:
      return {
        startTime: offer.startTime ?? throwValueMissing()
      };
    case TimeType.timeRange:
      return {
        startTime: offer.startTime ?? throwValueMissing(),
        endTime: offer.endTime ?? throwValueMissing()
      };
    case TimeType.businessHours:
    default:
      return {};
  }
};

const castOtherToNull = (segment: string | null | undefined) => {
  return segment === OTHER ? null : segment;
};

const toCreateRepresentation = (
  offer: Offer,
  isOffersBookable = false
): OfferCreate => {
  const duration = customDurationToIso(offer.duration);

  return {
    title: offer.title,
    description: offer.description,
    requirements: offer.requirements,
    mobileLocation: offer.mobileLocation
      ? {
          locations: offer.mobileLocation.locations
        }
      : undefined,
    fixedLocation: offer.fixedLocation
      ? {
          zipCode: offer.fixedLocation.zipCode,
          city: offer.fixedLocation.city,
          street: offer.fixedLocation.street,
          houseNumber: offer.fixedLocation.houseNumber,
          description: offer.fixedLocation.description
        }
      : undefined,
    onlineLocation: offer.onlineLocation
      ? {
          websiteUrl: isEmptyOrNull(offer.onlineLocation.websiteUrl)
            ? undefined
            : offer.onlineLocation.websiteUrl
        }
      : undefined,

    duration: isOffersBookable ? duration ?? throwValueMissing() : duration,
    ...timeTypeToCreate(offer),
    recurrenceRule: offer.recurrenceRule ?? undefined,
    picture: offer?.picture as ImageCreate,
    // eslint-disable-next-line
    // @ts-ignore
    categoryId: castOtherToNull(offer.categoryId),
    // eslint-disable-next-line
    // @ts-ignore
    targetGroupId: castOtherToNull(offer.targetGroupId)
  };
};

const timeTypeToUpdate = (offer: Offer): Nullable<Time> => {
  switch (offer.timeType) {
    case TimeType.fixedTime:
      return {
        startTime: offer.startTime ?? throwValueMissing(),
        endTime: null
      };
    case TimeType.timeRange:
      return {
        startTime: offer.startTime ?? throwValueMissing(),
        endTime: offer.endTime ?? throwValueMissing()
      };
    case TimeType.businessHours:
    default:
      return {
        startTime: null,
        endTime: null
      };
  }
};

const toUpdateRepresentation = (offer: Offer): OfferUpdate => {
  // Fields with null values are not set in the form and will therefore be deleted in the API with `null` values.
  // See: GP-378
  // eslint-disable-next-line
  // @ts-ignore
  return {
    ...offer,
    duration:
      // set back offer
      offer.duration === ''
        ? offer.duration
        : customDurationToIso(offer.duration),
    categoryId: castOtherToNull(offer.categoryId),
    targetGroupId: castOtherToNull(offer.targetGroupId),
    ...timeTypeToUpdate(offer)
  };
};

export {
  EMPTY_FIXED_LOCATION,
  EMPTY_MOBILE_LOCATION,
  EMPTY_OFFER,
  EMPTY_ONLINE_LOCATION,
  FixedLocationSchema,
  OfferSchema,
  TimeType,
  toCreateRepresentation,
  toFormRepresentation,
  toUpdateRepresentation,
  type Offer
};
