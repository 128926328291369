/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { FormHelperText, Stack, Typography } from '@mui/material';
import { getTextIn, required } from 'localization';
import {
  EMPTY_MOBILE_LOCATION,
  EMPTY_FIXED_LOCATION,
  EMPTY_ONLINE_LOCATION,
  Offer
} from './model';
import MobileLocationForm from './mobile-location-form';
import FixedLocationForm from './fixed-location-form';
import OnlineLocationForm from './online-location-form';
import {
  SwitchSubForm,
  useSchemaValidationFormContext
} from '../../feature/forms';
import { compareObjectDeepJson } from 'utils/helper';

const getOfferText = getTextIn('offer-details');
const getMobileLocationText = getTextIn('offer-details-mobileLocation');
const getFixedLocationText = getTextIn('offer-details-fixedLocation');
const getOnlineLocationText = getTextIn('offer-details-onlineLocation');

const MOBILE_LOCATION = 'mobileLocation';
const FIXED_LOCATION = 'fixedLocation';
const ONLINE_LOCATION = 'onlineLocation';

const LocationsForm = ({
  setHasLocationError,
  isEditMode
}: {
  setHasLocationError: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
}) => {
  const [initEditMode, setInitEditMode] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(false);
  const [onlineOpen, setOnlineOpen] = useState(false);

  const {
    control,
    setValueValidating,
    getValues,
    formState: { errors }
  } = useSchemaValidationFormContext<Offer>();

  const values = getValues();

  // open toggles of filled/selected locations in edit mode
  useEffect(() => {
    if (isEditMode && initEditMode) {
      setInitEditMode(false);
      setMobileOpen(!!values[MOBILE_LOCATION]);
      setFixedOpen(!!values[FIXED_LOCATION]);
      setOnlineOpen(!!values[ONLINE_LOCATION]);
    }
  }, [initEditMode, isEditMode, values]);

  const mobileLocation = useWatch<Offer>({
    control,
    name: MOBILE_LOCATION
  });
  const fixedLocation = useWatch<Offer>({
    control,
    name: FIXED_LOCATION
  });
  const onlineLocation = useWatch<Offer>({
    control,
    name: ONLINE_LOCATION
  });

  const isEmptyMobileLocation = compareObjectDeepJson(
    mobileLocation,
    EMPTY_MOBILE_LOCATION
  );

  const isEmptyFixedLocation = compareObjectDeepJson(
    fixedLocation,
    EMPTY_FIXED_LOCATION
  );
  const isEmptyOnlineLocation = onlineLocation === null;

  const startCaseNoError = !(
    !isEmptyMobileLocation ||
    errors[MOBILE_LOCATION] ||
    !isEmptyFixedLocation ||
    errors[FIXED_LOCATION] ||
    !isEmptyOnlineLocation ||
    errors[ONLINE_LOCATION]
  );

  const atLeastOneValidCase =
    !(
      isEmptyMobileLocation ||
      mobileLocation === null ||
      errors[MOBILE_LOCATION]
    ) ||
    !(
      isEmptyFixedLocation ||
      fixedLocation === null ||
      errors[FIXED_LOCATION]
    ) ||
    (isEmptyOnlineLocation && onlineOpen) ||
    !(isEmptyOnlineLocation || errors[ONLINE_LOCATION]);

  const showLocationError = !(startCaseNoError || atLeastOneValidCase);

  useEffect(() => {
    setHasLocationError(showLocationError);
  }, [setHasLocationError, showLocationError]);

  return (
    <Stack spacing={1}>
      <Typography component="h2" variant="sectionTitle">
        {`${required(getOfferText('location'))}`}
      </Typography>

      {showLocationError && (
        <FormHelperText error>
          {getOfferText('atLeastOneLocation')}
        </FormHelperText>
      )}

      <SwitchSubForm
        checked={mobileOpen}
        handleChange={(checked) => {
          if (!checked || !mobileLocation) {
            setValueValidating(MOBILE_LOCATION, null);
          }

          setMobileOpen(checked);
        }}
        title={getMobileLocationText('title')}
        info={getMobileLocationText('info')}
        switchDataCy="offerform-mobilelocation-switch"
      >
        <MobileLocationForm />
      </SwitchSubForm>

      <SwitchSubForm
        checked={fixedOpen}
        handleChange={(checked) => {
          if (!checked || !fixedLocation) {
            setValueValidating(
              FIXED_LOCATION,
              checked ? EMPTY_FIXED_LOCATION : null
            );
          }

          setFixedOpen(checked);
        }}
        title={getFixedLocationText('title')}
        info={getFixedLocationText('info')}
        switchDataCy="offerform-fixedlocation-switch"
      >
        <FixedLocationForm />
      </SwitchSubForm>

      <SwitchSubForm
        checked={onlineOpen}
        handleChange={(checked) => {
          if (!checked || !onlineLocation) {
            setValueValidating(
              ONLINE_LOCATION,
              checked ? EMPTY_ONLINE_LOCATION : null
            );
          }

          setOnlineOpen(checked);
        }}
        title={getOnlineLocationText('title')}
        info={getOnlineLocationText('info')}
        switchDataCy="offerform-onlinelocation-switch"
      >
        <OnlineLocationForm />
      </SwitchSubForm>
    </Stack>
  );
};

export default LocationsForm;
export { MOBILE_LOCATION, FIXED_LOCATION, ONLINE_LOCATION };
