/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useProject } from 'context/project';
import { leastRestrictiveVisibility } from 'feature/permissions';
import {
  ProfilePage,
  ProviderInfoPage,
  ProviderOffersPage,
  EditProfilePage,
  ParticipantListPage
} from 'pages';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Restricted, RestrictedVisibilityRoute } from './restricted-route';
import { getPath } from 'localization';

const ProviderRoutes = () => {
  const { project, isProjectNew } = useProject();
  const visibility = leastRestrictiveVisibility(
    project?.providerOrganizationsVisibility,
    project?.providerUsersVisibility
  );

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <RestrictedVisibilityRoute visibility={visibility}>
              <ParticipantListPage role="provider" />
            </RestrictedVisibilityRoute>
          }
        />
        <Route
          path={`:profileId/${getPath('edit')}`}
          element={
            <RestrictedVisibilityRoute visibility={visibility}>
              <EditProfilePage />
            </RestrictedVisibilityRoute>
          }
        />
        <Route
          path={`:providerId/${getPath('offers')}`}
          element={
            <RestrictedVisibilityRoute
              visibility={leastRestrictiveVisibility(
                visibility,
                project?.offersVisibility
              )}
            >
              <ProviderOffersPage />
            </RestrictedVisibilityRoute>
          }
        />
        <Route
          path=":profileId"
          element={
            <RestrictedVisibilityRoute visibility={visibility}>
              <ProfilePage />
            </RestrictedVisibilityRoute>
          }
        />
        <Route
          path={getPath('information')}
          element={
            <Restricted isVisible={!isProjectNew}>
              <ProviderInfoPage />
            </Restricted>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export default ProviderRoutes;
