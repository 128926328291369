/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ReactNode } from 'react';
import {
  type SxProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  type Theme,
  Typography,
  IconButton,
  type ButtonProps,
  type Breakpoint
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '../components/loading-button';
import { stripDashes } from 'utils/helper';
import { getText } from 'localization';

export interface DialogGenericProps {
  title: string;
  cancelText?: string;
  confirmText?: string;
  confirmColor?: ButtonProps['color'];
  isLoading?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  prefix: string;
  prefixCy?: string;
  sx?: SxProps<Theme>;
  disableEscapeKeyDown?: boolean;
  children?: ReactNode;
  open: boolean;
  hasActionButtons?: boolean;
  onClose: (reason: string) => void;
  onConfirm?: VoidFunction;
}

/**
 * @param open Open state
 * @param onClose Callback when closing the dialog
 * @param onConfirm Callback when firing confirm button
 * @param title Title text
 * @param cancelText Text cancel button - default: Abbrechen
 * @param confirmText Text confirm button
 * @param prefix Prefix for the component identifiers and unique keys
 * @param prefixCy Prefix for the cypress data-cy identifiers - default is the prefix without dashes
 * @param isLoading Indicates that form is being send
 * @param isDisabled indicates that from can not be submitted yet, e.g. due to validation errors
 * @param fullWidth Set fullWidth - default: false
 * @param maxWidth Max width of the dialog
 * @param prefix
 * @param prefixCy
 * @param disableEscapeKeyDown allows or blocks close by pressing ESC
 * @param hasActionButtons Show or hide generic dialog actions
 */
const DialogGeneric = ({
  open,
  onClose,
  onConfirm,
  title,
  cancelText = getText('cancel'),
  confirmText,
  confirmColor,
  isLoading,
  isDisabled,
  fullWidth = false,
  maxWidth = 'md',
  prefix,
  prefixCy = stripDashes(prefix),
  disableEscapeKeyDown,
  children,
  hasActionButtons = true
}: DialogGenericProps) => {
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => onClose(reason)}
      aria-labelledby={`${prefix}-title`}
      aria-describedby={`${prefix}-description`}
      data-cy={prefixCy}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitle
        id={`${prefix}-title`}
        data-cy={`${prefixCy}-title`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 3,
          pr: 1,
          py: 1
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={() => onClose('buttonClick')}
          sx={{
            color: 'black'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      {hasActionButtons && (
        <DialogActions sx={{ gap: 1, p: 2 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => onClose('buttonClick')}
            data-cy={`${prefixCy}-cancel`}
          >
            {cancelText}
          </Button>
          {confirmText && onConfirm && (
            <LoadingButton
              dataCy={prefixCy}
              label={confirmText}
              color={confirmColor}
              disabled={isDisabled || isLoading}
              loading={isLoading}
              onClick={onConfirm}
              size="small"
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogGeneric;
