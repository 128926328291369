/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Routes, Route } from 'react-router-dom';
import {
  OffersPage,
  OfferCreatePage,
  OfferEditPage,
  OfferPage
} from 'pages/offer';
import { useProject } from 'context/project';
import { RestrictedVisibilityRoute } from './restricted-route';
import { getPath } from 'localization';

const OffersRoutes = () => {
  const { project } = useProject();
  const visibility = project?.offersVisibility;

  return (
    <RestrictedVisibilityRoute visibility={visibility} forceLogin>
      <Routes>
        <Route index element={<OffersPage />} />
        <Route path=":category" element={<OffersPage />} />
        <Route path={getPath('create')} element={<OfferCreatePage />} />
        <Route
          path={`:offerId/${getPath('edit')}`}
          element={<OfferEditPage />}
        />
        <Route path=":category/:offerId" element={<OfferPage />} />
        <Route path=":offerId" element={<OfferPage />} />
      </Routes>
    </RestrictedVisibilityRoute>
  );
};

export default OffersRoutes;
