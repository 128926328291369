/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Typography from '@mui/material/Typography';
import { useSetDocumentTitle } from 'context/project';
import {
  ContentWrapper,
  Pagination,
  ParticipantGrid,
  usePageClick
} from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { RequestErrorFallback } from 'feature/error';
import { useListParticipantsInfo } from 'feature/hooks';
import { getText } from 'localization';
import { useState } from 'react';
import { Role } from 'utils/roles';
import { SearchParams, useUrlParams } from 'utils/url-param';

type ParticipantListType = {
  role: Role;
};

const ParticipantListPage = ({ role }: ParticipantListType) => {
  const title =
    role === 'seeker'
      ? getText('seekers', 'role')
      : getText('providers', 'role');
  useSetDocumentTitle(title);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [search, setSearchParamWithPageSetback] = useUrlParams();

  const page = Number(search[SearchParams.page] ?? 0);

  const participants = useListParticipantsInfo({
    page,
    size: rowsPerPage,
    sort: ['name,asc'],
    showProviders: role === 'provider',
    showSeekers: role === 'seeker'
  });

  const { handlePageClick, totalElements } = usePageClick({
    dataPage: participants?.data?.page,
    page,
    setSearchParamWithPageSetback
  });

  return (
    <RequestErrorFallback queryResult={participants}>
      <ContentWrapper
        additionalSx={{ maxWidth: { lg: 1140 }, px: { lg: '0 !important' } }}
      >
        <BreadcrumbNavigation />
        <Typography variant="h1">{title}</Typography>
        <>
          <ParticipantGrid
            participantInfos={participants.data?.embedded?.participantInfos}
            isLoading={participants.isLoading}
            role={role}
          />
        </>

        <Pagination
          count={totalElements}
          page={page}
          setPage={handlePageClick}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </ContentWrapper>
    </RequestErrorFallback>
  );
};

export default ParticipantListPage;
