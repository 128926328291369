/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { type ImageUpdate } from '@SLR/solution3-sdk';
import PlaceholderImage from 'assets/image-placeholder.svg';
import { useSetDocumentTitle } from 'context/project';
import { useGetPortalOrganizationProfileHref, useUser } from 'context/user';
import { ContentWrapper } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import TextEditor from 'feature/editor/text-editor';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import {
  ActionsPanel,
  DialogImage,
  DialogImageSchema,
  ImageDialogWrapper,
  SchemaValidationFormProvider,
  ValidatedHtmlEditor,
  ValidatedTextField,
  convertDeleteValue,
  useSchemaValidationForm
} from 'feature/forms';
import {
  useGetOrganizationProfile,
  useUpdateOrganizationProfile
} from 'feature/hooks';
import { PROFILE, useOnboardingLogic } from 'feature/onboarding';
import { getErrorText, getText, getTextIn } from 'localization';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Restricted, getProviderProfileUrl, getSeekerProfileUrl } from 'routes';
import { ZIPCODE_REGEX } from 'utils/helper';
import useProjectNavigate from 'utils/useProjectNavigate';
import { ObjectSchema, object, setLocale, string } from 'yup';
import EditBusinessHours from './profile-edit-business-hours';

const ADDRESS_SPACING = { xs: 1, sm: 2, md: 4 };

type EditProfilePageType = {
  isSeeker?: boolean;
};

type ProfileForm = {
  about: string;
  qualification?: string;
  focus?: string;
  zipCode: string;
  city: string;
  street: string;
  houseNumber: string;
  banner?: DialogImage;
};

const EMPTY_PROFILE: ProfileForm = {
  about: '',
  qualification: '',
  focus: '',
  zipCode: '',
  city: '',
  street: '',
  houseNumber: '',
  banner: undefined
};

// AW: this in the isLoaded section in context??
setLocale({
  mixed: {
    required: getErrorText('requiredError')
  }
});

const zipErrorText = getErrorText('zipFormatError');
const ProfileSchema: ObjectSchema<ProfileForm> = object().shape({
  about: string().defined().nonNullable(),
  qualification: string().optional(),
  focus: string().optional(),
  street: string().required(),
  houseNumber: string().required(),
  zipCode: string().required().matches(RegExp(ZIPCODE_REGEX), {
    excludeEmptyString: true,
    message: zipErrorText
  }),
  city: string().required(),
  banner: DialogImageSchema.default(undefined)
});

const getProfileText = getTextIn('profile');

const EditProfilePage = ({ isSeeker = false }: EditProfilePageType) => {
  const editHoursRef = useRef<HTMLButtonElement>(null);
  useSetDocumentTitle(getProfileText('editProfile'));
  const { profileId } = useParams();
  const { navigateBack } = useProjectNavigate();
  const profile = useGetOrganizationProfile(profileId);
  const updateOrganizationProfile = useUpdateOrganizationProfile();

  const userPortalHref = useGetPortalOrganizationProfileHref(
    profileId,
    '&showOrgEdit'
  );
  const { perspective } = useUser();
  const organizationId = perspective?.isOrganization ? perspective.id : '';
  const [validBusinessHours, setValidBusinessHours] = useState(false);
  const { addFlag } = useOnboardingLogic();
  const navigate = useNavigate();

  const customItem = {
    2: {
      title: profile.data?.name ?? getProfileText('profilePage')
    },
    3: {
      title: getProfileText('editProfile')
    }
  };

  const form = useSchemaValidationForm<ProfileForm>(
    EMPTY_PROFILE,
    profile.data as ProfileForm,
    ProfileSchema
  );

  const { handleSubmit, reset, setValue, control } = form;

  const onSubmit: SubmitHandler<ProfileForm> = useCallback(
    (data) => {
      if (validBusinessHours) {
        convertDeleteValue<ProfileForm, ImageUpdate>(data, 'banner');

        updateOrganizationProfile.mutate(
          {
            organizationId,
            organizationProfileUpdate: {
              ...data,
              seeker: isSeeker,
              provider: !isSeeker,
              why: '',
              qualification: isSeeker ? '' : data.qualification,
              seekerInterests: [],
              providerInterests: []
            }
          },
          {
            onSuccess: () => {
              notifyMutationSuccess(getProfileText('changeProfileSuccess'));
              addFlag(PROFILE);
              navigate(
                isSeeker
                  ? getSeekerProfileUrl(profileId)
                  : getProviderProfileUrl(profileId)
              );
            },
            onError: () => {
              notifyMutationError(getProfileText('changeProfileError'));
            }
          }
        );
      }
    },
    [
      addFlag,
      isSeeker,
      navigate,
      validBusinessHours,
      organizationId,
      profileId,
      updateOrganizationProfile
    ]
  );

  useEffect(() => {
    if (validBusinessHours) {
      setValidBusinessHours(false);
      setTimeout(handleSubmit(onSubmit), 0);
    }
  }, [handleSubmit, validBusinessHours, onSubmit]);

  const onConfirmBusinessHours = () => {
    editHoursRef?.current?.click();
  };

  const handleReset = () => {
    reset();
    navigateBack();
  };

  return (
    <Restricted isVisible={profileId === perspective.id} forceLogin>
      <SchemaValidationFormProvider {...form}>
        <ContentWrapper breadcrumbOnly>
          <BreadcrumbNavigation customItem={customItem} />
        </ContentWrapper>
        <ContentWrapper additionalSx={{ alignItems: 'center' }}>
          <Typography variant="h1">{getProfileText('editProfile')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              width: '100%',
              maxWidth: 'md'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2
              }}
            >
              <Box>
                <Typography component="span" color="secondary">
                  {`${getProfileText('hint')}: `}
                </Typography>
                <Typography component="span">
                  {getProfileText('userPortalHint')}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                target="_blank"
                href={userPortalHref}
                data-cy="profileform-gotouserportal"
              >
                {getProfileText('userPortalCaption')}
              </Button>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Grid item xs={12} sm={8}>
                <Controller
                  name="banner"
                  control={control}
                  render={({ field }) => (
                    <ImageDialogWrapper
                      image={field.value}
                      titleElement={
                        <Typography variant="sectionTitle">
                          {getProfileText('titleImage')}
                        </Typography>
                      }
                      buttonText={getProfileText('titleImage')}
                      dataCyPrefix="profileform"
                      onChange={(image) => {
                        setValue(
                          'banner',
                          image === undefined || image === null
                            ? profile?.data?.banner
                            : image
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                  }}
                >
                  <Typography variant="sectionTitle">
                    {getProfileText('profileImage')}
                  </Typography>

                  {profile.data?.logo?.urls ? (
                    <img
                      data-cy="provider-profile-image"
                      key="provider-profile-image"
                      style={{ maxHeight: 200, width: 'auto' }}
                      src={profile.data?.logo?.urls.small}
                      alt={profile.data?.logo?.alternativeText}
                    />
                  ) : (
                    <img
                      style={{
                        maxHeight: 200,
                        objectFit: 'contain',
                        backgroundColor: 'placeholder.main',
                        display: 'inherit'
                      }}
                      src={PlaceholderImage}
                      alt={getText('placeholderImage', 'edit-editHeroImage')}
                    />
                  )}
                  <Button
                    variant="outlined"
                    size="small"
                    target="_blank"
                    href={userPortalHref}
                    sx={{ alignSelf: 'center' }}
                    startIcon={<CameraAltIcon />}
                    data-cy="profileform-gotouserportal-edit-profileimage"
                  >
                    {`${getProfileText('profileImage')} ${getText(
                      'edit',
                      'edit'
                    )}`}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box // address
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="sectionTitle">
                {getProfileText('address')}
              </Typography>
              <Stack direction="row" spacing={ADDRESS_SPACING}>
                <ValidatedTextField
                  name="street"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        helperText={errorMessage}
                        label={getProfileText('street')}
                        required
                        inputProps={{
                          maxLength: 100,
                          'data-cy': 'profileform-street'
                        }}
                        {...field}
                        {...fieldProps}
                        sx={{ width: { xs: '70%', sm: '80%' } }}
                      />
                    );
                  }}
                />
                <ValidatedTextField
                  name="houseNumber"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        helperText={errorMessage}
                        label={getProfileText('houseNumber')}
                        required
                        inputProps={{
                          maxLength: 5,
                          'data-cy': 'profileform-houseNumber'
                        }}
                        {...field}
                        {...fieldProps}
                        sx={{ width: { xs: '30%', sm: '20%' } }}
                      />
                    );
                  }}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={ADDRESS_SPACING}
                sx={{ mt: ADDRESS_SPACING }}
              >
                <ValidatedTextField
                  name="zipCode"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        helperText={errorMessage}
                        label={getProfileText('zipCode')}
                        required
                        inputProps={{
                          maxLength: 5,
                          'data-cy': 'profileform-zipCode'
                        }}
                        {...field}
                        {...fieldProps}
                        sx={{ width: { xs: '30%', sm: '20%' } }}
                      />
                    );
                  }}
                />
                <ValidatedTextField
                  name="city"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        helperText={errorMessage}
                        label={getProfileText('city')}
                        required
                        inputProps={{
                          maxLength: 100,
                          'data-cy': 'profileform-city'
                        }}
                        {...field}
                        {...fieldProps}
                        sx={{ width: { xs: '70%', sm: '80%' } }}
                      />
                    );
                  }}
                />
              </Stack>

              <FormControl sx={{ mt: 1 }}>
                <FormHelperText>{getText('requiredFields')}</FormHelperText>
              </FormControl>
            </Box>

            <ValidatedHtmlEditor
              name="about"
              render={({ field, props }) => {
                const { error, errorMessage, content, onEditorChange } = props;
                return (
                  <FormControl>
                    <Typography variant="editorLabel">
                      {getProfileText('about')}
                    </Typography>
                    <TextEditor
                      placeholderText={getProfileText('aboutPlaceholder')}
                      content={content}
                      onEditorChange={onEditorChange}
                      showError={error}
                      dataCy="profileform-about"
                      {...field}
                    />
                    {error && (
                      <FormHelperText error={error}>
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            {!isSeeker && (
              <ValidatedHtmlEditor
                name="qualification"
                render={({ field, props }) => {
                  const { error, errorMessage, content, onEditorChange } =
                    props;
                  return (
                    <FormControl>
                      <Typography variant="editorLabel">
                        {getProfileText('qualification')}
                      </Typography>
                      <TextEditor
                        placeholderText={getProfileText(
                          'qualificationPlaceholder'
                        )}
                        content={content}
                        onEditorChange={onEditorChange}
                        showError={error}
                        dataCy="profileform-qualification"
                        {...field}
                      />
                      {error && (
                        <FormHelperText error={error}>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
            )}
            <ValidatedHtmlEditor
              name="focus"
              render={({ field, props }) => {
                const { error, errorMessage, content, onEditorChange } = props;
                return (
                  <FormControl>
                    <Typography variant="editorLabel">
                      {getProfileText('focus')}
                    </Typography>
                    <TextEditor
                      placeholderText={getProfileText('focusPlaceholder')}
                      content={content}
                      onEditorChange={onEditorChange}
                      showError={error}
                      dataCy="profileform-focus"
                      {...field}
                    />
                    {error && (
                      <FormHelperText error={error}>
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />

            <Box>
              <FormLabel sx={{ mb: 1 }}>
                <Typography variant="formLabel">
                  {getProfileText('businessHours')}
                </Typography>
              </FormLabel>

              <EditBusinessHours
                editHoursRef={editHoursRef}
                setValidBusinessHours={setValidBusinessHours}
              />
            </Box>
          </Box>
        </ContentWrapper>

        <ActionsPanel
          confirmLabel={getProfileText('saveProfile')}
          inProgress={updateOrganizationProfile.isLoading}
          onConfirm={onConfirmBusinessHours}
          onCancel={handleReset}
        />
      </SchemaValidationFormProvider>
    </Restricted>
  );
};

export default EditProfilePage;
export { type ProfileForm };
