/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Button from '@mui/material/Button';
import { useBooking } from 'context/booking';
import { useIsSeeker } from 'context/user';
import { RemoveFilterDialog } from 'feature/filter';
import { getText, getTextIn } from 'localization';
import { useState } from 'react';

const getFilterText = getTextIn('filter');

type RemoveFilterButtonProps = {
  dataCy?: string;
  text?: string;
  onClick: VoidFunction;
};

const RemoveFilterButton = ({
  dataCy,
  text = getText('cancel'),
  onClick
}: RemoveFilterButtonProps) => {
  const isSeeker = useIsSeeker();
  const { offers: bookinglist } = useBooking();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <Button
        data-cy={dataCy}
        variant="text"
        sx={{
          textTransform: 'none',
          whiteSpace: 'nowrap'
        }}
        onClick={
          bookinglist.length > 0 && isSeeker
            ? () => setShowAlert(true)
            : onClick
        }
      >
        {text}
      </Button>

      <RemoveFilterDialog
        showDialog={showAlert}
        onClose={() => setShowAlert(false)}
        removeFilters={onClick}
        bodyText={getFilterText('removeAllFiltersDialogText')}
      />
    </>
  );
};

export default RemoveFilterButton;
