/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ReactElement } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';

interface RadioTimeProps {
  name: string;
  value: string;
  checked: boolean;
  onCheck: () => void;
  title: string;
  prefixCy?: string;
  children: (disabled: boolean) => ReactElement;
}

const RadioTime = ({
  name,
  value,
  checked = false,
  onCheck,
  title,
  prefixCy = name,
  children
}: RadioTimeProps) => {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'start', md: 'center' }}
      justifyContent="space-between"
    >
      <FormControlLabel
        control={
          <Radio
            checked={checked}
            onChange={onCheck}
            name={name}
            value={value}
            data-cy={`${prefixCy}-radio`}
          />
        }
        label={title}
      />
      {children(!checked)}
    </Stack>
  );
};

export default RadioTime;
