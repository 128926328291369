/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type EntityModelProject } from '@SLR/solution3-sdk';
import { Config, Locales, globalConfigKey } from 'localization';
import { merge } from 'lodash';
import config, { LOCALE_DEFAULT } from 'localization/config';

const LOCALE_SEPARATOR = '-';

const useSetConfig = (project?: EntityModelProject) => {
  const locale = (project?.locale ?? LOCALE_DEFAULT) as keyof Locales;

  if (project) {
    const [language, region, projectTag] = locale.split(LOCALE_SEPARATOR);

    globalThis[globalConfigKey].config = projectTag
      ? (merge(
          config[`${language}${LOCALE_SEPARATOR}${region}` as keyof Locales],
          config[locale]
        ) as Config)
      : config[locale];

    return true;
  }

  return false;
};

export default useSetConfig;
