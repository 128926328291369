/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useProject } from 'context/project';
import ProcessElement from './process-element';
import ProcessViz from './process-viz';
import ProcessWrapper from './process-wrapper';
import { getText, getTextFx, getTextIn, getPath } from 'localization';
import { transformToFragment } from 'utils/fragments-utils';

const useVizLabels = (headlines: string[], path: string) => {
  const { project, isProjectNew } = useProject();

  return [
    {
      text: isProjectNew
        ? getText('patientInfo', 'info')
        : getTextFx(
            'info',
            'process'
          )({ projectName: project?.name ?? getText('solutionName') }),
      linkTo: '/'
    }
  ].concat(
    headlines.map((h) => ({
      text: h,
      linkTo: `/${path}#${transformToFragment(h)}`
    }))
  );
};

const useVizLabelsPatient = () => {
  const getInfoText = getTextIn('info');

  const PATIENT_HEADLINES = [
    getInfoText('patientHelpTitle'),
    getInfoText('patientServicesTitle'),
    getInfoText('patientProvidersText'),
    getInfoText('patientParticipate'),
    getInfoText('patientMore')
  ];

  return useVizLabels(PATIENT_HEADLINES, getPath('patientInfo'));
};

const useVizLabelsProvider = (path: string) => {
  const getInfoText = getTextIn('info');

  const PROVIDER_HEADLINES = [
    getInfoText('servicesTitle'),
    getInfoText('howToParticipateTitle'),
    getText('members'),
    getText('additionalInformation', 'offer-details'),
    getInfoText('helpAndContact')
  ];

  return useVizLabels(PROVIDER_HEADLINES, path);
};

export {
  ProcessViz,
  ProcessElement,
  ProcessWrapper,
  useVizLabels,
  useVizLabelsPatient,
  useVizLabelsProvider
};
