/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Anna Kleiner
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    <http://www.apache.org/licenses/LICENSE-2.0>
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Chip, Skeleton } from '@mui/material';
import { useGetGeoArea } from 'feature/hooks';

type GeoAreaChipProps = {
  id: string;
  index?: number;
  onDelete?: ((event: unknown) => void) | undefined;
};

const GeoAreaChip = ({ id, index, onDelete }: GeoAreaChipProps) => {
  const { data: geoArea, isFetching } = useGetGeoArea(id);
  const label = geoArea?.name ?? id;

  return isFetching ? (
    <Skeleton />
  ) : (
    <Chip
      key={index ?? id}
      label={label}
      color="primary"
      variant="filled"
      onDelete={onDelete}
      data-cy={`geoArea-chip-${label}`}
    />
  );
};

export default GeoAreaChip;
