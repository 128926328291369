/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Card, CardActionArea, CardActions } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type ClickableCardProps = {
  href: string;
  minWidthPercent?: number;
  children: ReactElement[];
  cardActions?: ReactNode;
};

const CustomCard = ({
  children,
  href,
  minWidthPercent,
  cardActions
}: ClickableCardProps) => {
  // Note: the custom card call from offer-card does not use the parameter minWidthPercent, as it uses a grid
  return (
    <Card
      sx={{
        minWidth: minWidthPercent
          ? { xs: 'unset', sm: `${minWidthPercent}%`, xl: '23%' }
          : 'unset',
        maxWidth: minWidthPercent ? 358 : 'unset',
        flexGrow: minWidthPercent ? 1 : 'unset'
      }}
    >
      <CardActionArea component={RouterLink} to={href} sx={{ height: '100%' }}>
        {children}
      </CardActionArea>
      {cardActions && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {cardActions}
        </CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
