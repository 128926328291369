/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
  PropsWithChildren
} from 'react';
// import { Page } from 'index.d';

interface RouterContextValues {
  // breadcrumbs: Page[],
  breadcrumbs: [];
  // setBreadcrumbs: Dispatch<SetStateAction<Page[]>>
  setBreadcrumbs: Dispatch<SetStateAction<[]>>;
}

const defaultRouterContextValues: RouterContextValues = {
  breadcrumbs: [],
  setBreadcrumbs: () => {}
};

const RouterContext = createContext<RouterContextValues>(
  defaultRouterContextValues
);

const useRouter = () => useContext(RouterContext);

const RouterContextProvider = ({ children }: PropsWithChildren) => {
  // const [breadcrumbs, setBreadcrumbs] = useState<Page[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<[]>([]);
  return (
    <RouterContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </RouterContext.Provider>
  );
};

export default RouterContextProvider;
export { useRouter };
