/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Stack, Typography } from '@mui/material';
import compact from 'lodash/compact';
import join from 'lodash/join';

type AddressProps = {
  dataCyPrefix?: string;
  name?: {
    firstName: string;
    lastName: string;
  };
  address: {
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    description?: string;
  };
  asString?: boolean;
};

const Address = ({
  dataCyPrefix,
  name,
  address,
  asString = false
}: AddressProps) => {
  const description = address.description;
  const street = join(compact([address.street, address.houseNumber]), ' ');
  const city = join(compact([address.zipCode, address.city]), ' ');

  return asString ? (
    <>{`${street}${street && ','} ${city}${
      city && description && ','
    } ${description}`}</>
  ) : (
    <Stack data-cy={`${dataCyPrefix}-address`} direction="column">
      {name && (
        <Typography>
          {name.firstName} {name.lastName}
        </Typography>
      )}
      {description && <Typography>{description}</Typography>}
      {street && <Typography>{street}</Typography>}
      {city && <Typography>{city}</Typography>}
    </Stack>
  );
};

export default Address;
