/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useMemo } from 'react';
import { compile } from 'html-to-text';

const compiledConvert = compile({
  limits: {
    maxInputLength: 10_000
  },
  selectors: [
    // singleNewLineParagraphs
    { selector: 'p', options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
    {
      selector: 'pre',
      options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
    },
    // unorderedListItemPrefix
    { selector: 'ul', options: { itemPrefix: ' - ' } },
    // ignoreHref
    { selector: 'a', options: { ignoreHref: true } },
    { selector: 'img', format: 'skip' }
  ],
  wordwrap: null
});

const useHtmlAsPlainText = (html?: string): string => {
  return useMemo(() => {
    if (!html) {
      return '';
    }
    return compiledConvert(html);
  }, [html]);
};

export default useHtmlAsPlainText;
