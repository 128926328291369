/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useKeycloakAuth } from 'auth';
import LoadingSpinner from 'components/loading-spinner';
import { useProject, useSetDocumentTitle } from 'context/project';
import { RequestFailed } from 'feature/error';
import { getErrorText } from 'localization';

type ErrorPageNotFoundProps = {
  documentTitleKey?: string;
  titleKey?: string;
  descriptionKey?: string;
};

const ErrorPageNotFound = ({
  documentTitleKey = 'oops',
  titleKey = 'pageNotFound',
  descriptionKey = 'pageNotFoundDescription'
}: ErrorPageNotFoundProps) => {
  useSetDocumentTitle(getErrorText(documentTitleKey));
  const { isLoading: isLoadingProject } = useProject();
  const { isReady } = useKeycloakAuth();

  return isLoadingProject || !isReady ? (
    <LoadingSpinner dataCy="error-page-not-found" />
  ) : (
    <RequestFailed
      title={getErrorText(titleKey)}
      description={getErrorText(descriptionKey)}
    />
  );
};

export default ErrorPageNotFound;
