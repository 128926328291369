/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useQuery } from '@tanstack/react-query';
import type {
  ListArticlesRequest,
  GetArticleRequest,
  EntityModelArticle
} from '@SLR/solution3-sdk';
import { useGetProjectId } from 'context/project';
import { useArticlesApi } from './use-compass-api';

type ListArticles = Omit<ListArticlesRequest, 'projectId'>;
type GetArticle = Omit<GetArticleRequest, 'projectId'>;

const LIST_ARTICLES = 'listArticles';
const GET_ARTICLE = 'getArticle';

const useListArticles = (params?: ListArticles) => {
  const projectId = useGetProjectId();
  const { listArticles } = useArticlesApi();

  const requestParameters: ListArticlesRequest = {
    ...params,
    projectId: projectId ?? ''
  };

  return useQuery(
    [LIST_ARTICLES, requestParameters],
    () => {
      return listArticles(requestParameters);
    },
    {
      enabled: !!projectId
    }
  );
};

const useGetArticle = ({ articleId }: GetArticle) => {
  const projectId = useGetProjectId();
  const { getArticle } = useArticlesApi();

  const requestParameters: GetArticleRequest = {
    projectId: projectId ?? '',
    articleId
  };

  return useQuery<EntityModelArticle, Response>(
    [GET_ARTICLE, requestParameters],
    () => {
      return getArticle(requestParameters);
    },
    {
      enabled: !!projectId && !!articleId
    }
  );
};

export { useListArticles, useGetArticle, LIST_ARTICLES, GET_ARTICLE };
