/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Edit, Delete } from '@mui/icons-material';
import { type EntityModelOffer, EntityType } from '@SLR/solution3-sdk';
import { useUser, useIsAdmin } from 'context/user';
import { ReportButton } from 'feature';
import OfferDeleteDialog from './offer-delete-dialog';
import OfferAdminDeleteDialog from './offer-admin-delete-dialog';
import { getTextIn, getPath } from 'localization';

const getOfferText = getTextIn('offer');

interface Props {
  offer: EntityModelOffer;
  onDelete: (reason?: string) => void;
  deleting?: boolean;
}

const OfferActions = ({ offer, onDelete, deleting = false }: Props) => {
  const isAdmin = useIsAdmin();
  const { currentUser, perspective } = useUser();
  const offerId = offer?.id ?? '';
  const [dialogOpen, setDialogOpen] = useState(false);

  const isOwner =
    !!perspective.id &&
    (offer.organizationId || offer.userId) === perspective.id;
  const isAdminPerspective = isAdmin && currentUser?.id === perspective.id;

  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        justifyContent: { xs: 'center', md: 'end' }
      }}
    >
      {isOwner ? (
        <Stack
          gap={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: { xs: 'space-between' },
            mt: 0.75,
            width: '100%'
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<Edit />}
            fullWidth
            component={Link}
            to={getPath('getOfferEdit', { offerId })}
            data-cy="offeractions-edit"
          >
            {getOfferText('editOffer')}
          </Button>
          <OfferDeleteDialog
            open={dialogOpen}
            progress={deleting}
            onConfirm={() => onDelete()} // Note: new function is necessary as different signature
            onCancel={() => setDialogOpen(false)}
          />
          <Button
            variant="outlined"
            size="small"
            startIcon={<Delete />}
            color="error"
            fullWidth
            onClick={() => setDialogOpen(true)}
            data-cy="offeractions-delete"
          >
            {getOfferText('deleteOffer')}
          </Button>
        </Stack>
      ) : isAdminPerspective ? (
        <OfferAdminDeleteDialog progress={deleting} handleDelete={onDelete} />
      ) : (
        <ReportButton type={EntityType.Offer} entityId={offerId} />
      )}
    </Box>
  );
};

export default OfferActions;
