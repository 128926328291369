/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useKeycloakAuth } from 'auth';
import { useGetProjectId } from 'context/project';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import useInvitationsApi from './useInvitationsApi';
import { getText } from 'localization';

const useGetInvitation = (invitationId = '') => {
  const { getInvitation } = useInvitationsApi();
  const { isAuthenticated } = useKeycloakAuth();
  const projectId = useGetProjectId();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    ['invitation', { projectId, invitationId }],
    () => {
      return getInvitation({ projectId: projectId ?? '', invitationId });
    },

    {
      staleTime: Infinity,
      enabled: !!projectId && !!invitationId && isAuthenticated,
      onError: (res: Response) => {
        if (res.status === 404) {
          enqueueSnackbar(getText('alreadyAccepted', 'invitation'), {
            variant: 'success'
          });
        }
      }
    }
  );
};

export default useGetInvitation;
