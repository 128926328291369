/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Chip, FormLabel, Stack } from '@mui/material';
import map from 'lodash/map';
import compact from 'lodash/compact';
import { useSchemaValidationFormContext } from 'feature/forms';
import { Article, KEYWORDS } from './model';
import KeywordsSelector from './article-keywords-selector';
import { getText, getTextIn } from 'localization';

const getTextCompass = getTextIn('compass');

const ArticleKeywords = () => {
  const { control } = useSchemaValidationFormContext<Article>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: KEYWORDS
  });

  const removeChips = useMemo(() => {
    return map(fields, (item, index) => {
      return (
        <Controller
          key={item.id}
          control={control}
          name={`${KEYWORDS}.${index}`}
          render={({ field }) => {
            const label =
              field.value.keyword?.name ?? field.value.id ?? field.value;
            return (
              <Chip
                key={index}
                label={label}
                color="primary"
                variant="filled"
                onDelete={() => {
                  remove(index);
                }}
                data-cy={`keywords-chip-${label}`}
              />
            );
          }}
        />
      );
    });
  }, [control, fields, remove]);

  return (
    <Stack spacing={2}>
      <FormLabel>{getTextCompass('articleKeywordsDescription')}</FormLabel>
      <Controller
        control={control}
        name={KEYWORDS}
        render={({ field, fieldState }) => {
          const selectedKeywords = compact(map(field.value, 'keyword'));
          const error = !!fieldState.error;
          const errorMessage = fieldState.error?.message;

          return (
            <KeywordsSelector
              label={getTextCompass('articleKeywordsPlaceholder')}
              selectCaption={getText('add')}
              error={error}
              errorMessage={errorMessage}
              excludedOptions={selectedKeywords}
              onSelectOption={(keyword) => {
                append(
                  typeof keyword === 'string'
                    ? { id: keyword, keyword: { id: keyword, name: keyword } }
                    : { id: keyword.id, keyword }
                );
              }}
            />
          );
        }}
      />

      <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
        {removeChips}
      </Stack>
    </Stack>
  );
};

export default ArticleKeywords;
