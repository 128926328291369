/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Stack from '@mui/material/Stack';
import NavigationLinks from './nav-links';
import { NavLinksBasicProps } from './types';

const NavMainbarLinks = ({
  mainRoute,
  navigationLinks
}: NavLinksBasicProps) => {
  return (
    <Stack
      direction="row"
      spacing={{ md: 3, xl: 6 }}
      sx={{
        height: '100%',
        mx: { md: 2, xl: 4 },
        display: { xs: 'none', md: 'flex' },
        alignItems: 'stretch',
        font: '18px/32px Roboto',
        fontWeight: 'bold'
      }}
    >
      <NavigationLinks
        navigationLinks={navigationLinks}
        mainRoute={mainRoute}
        variant="mainbar"
      />
    </Stack>
  );
};

export default NavMainbarLinks;
