/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createRef, useEffect, useState } from 'react';
import { type Image, type EntityModelGalleryEntry } from '@SLR/solution3-sdk';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LoadingSpinner from 'components/loading-spinner';
import useImageLoader from './use-image-loader';
import GalleryView from './gallery-view';
import { getText } from 'localization';

const maxTiles = 10;
const MAX_LINES = 2;

const ImageListItemLoader = ({
  image,
  srcFormat = '',
  onClick,
  dataCy
}: {
  image: Image;
  srcFormat?: string;
  onClick: VoidFunction;
  dataCy: string;
}) => {
  const { loadedSrc } = useImageLoader({
    image,
    srcFormat,
    size: 'thumbnail'
  });

  return (
    <ImageListItem>
      {loadedSrc ? (
        <img
          src={loadedSrc}
          alt={image.alternativeText}
          loading="lazy"
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        />
      ) : (
        <LoadingSpinner dataCy={dataCy} />
      )}
    </ImageListItem>
  );
};

const getHasHiddenTiles = (elements: Element[]) =>
  elements.reduce(
    (acc, node, index) => {
      const { top } = node.getBoundingClientRect();

      if (top !== acc.top) {
        acc.top = top;
        acc.count++;
      }

      if (acc.index < 0 && acc.count > MAX_LINES) {
        acc.index = index;
      }

      return acc;
    },
    { count: 0, top: -1, index: -1 }
  );

type GalleryGridProps = {
  galleryEntries: EntityModelGalleryEntry[];
  showMoreButton: boolean;
  isSmUp: boolean;
};

const GalleryGrid = ({
  galleryEntries,
  showMoreButton = false,
  isSmUp
}: GalleryGridProps) => {
  const ListRef = createRef<HTMLUListElement>();
  const [firstHiddenTile, setFirstHiddenTile] = useState(-1);
  const [openGalleryView, setOpenGalleryView] = useState(false);
  const [startImage, setStartImage] = useState<Image>();

  useEffect(() => {
    const ListRefCurrent = ListRef.current;

    const checkLineCount = () => {
      const childNodes = ListRefCurrent?.children;
      const iterableChildNodes = childNodes ? Array.from(childNodes) : [];

      setFirstHiddenTile(getHasHiddenTiles(iterableChildNodes).index);
    };

    if (ListRefCurrent) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(checkLineCount).observe(ListRefCurrent);
      }

      checkLineCount();
    }
  }, [ListRef]);

  return (
    <>
      <Box
        sx={{
          px: 1.5,
          display: { xs: 'block', sm: 'none' }
        }}
      >
        {galleryEntries && (
          <GalleryView
            open={!isSmUp}
            galleryEntries={galleryEntries}
            onClose={() => setOpenGalleryView(false)}
            size="small"
            integrated
          />
        )}
      </Box>

      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <ImageList
          data-cy="profile-gallery-grid"
          ref={ListRef}
          variant="quilted"
          cols={6}
          rowHeight={140}
          gap={12}
          sx={{
            width: '100%',
            maxHeight: 292,
            overflow: 'hidden',
            gridTemplateColumns:
              'repeat(auto-fill, minmax(200px, 1fr))!important'
          }}
        >
          {galleryEntries.slice(0, maxTiles).map((entry) => {
            const image = entry?.image;

            return (
              !!image && (
                <ImageListItemLoader
                  key={image.id}
                  image={image}
                  srcFormat="w=164&h=164&fit=crop&auto=format"
                  onClick={() => {
                    setStartImage(image);
                    setOpenGalleryView(true);
                  }}
                  dataCy="profile-gallery-item"
                />
              )
            );
          })}
        </ImageList>

        {showMoreButton &&
          (firstHiddenTile > -1 || galleryEntries.length > 10) && (
            <Box sx={{ display: 'flex', justifyContent: 'end', pt: 1 }}>
              <Button
                data-cy="profile-gallery-grid-more"
                size="small"
                endIcon={<NavigateNextIcon />}
                onClick={() => {
                  setStartImage(
                    galleryEntries[
                      firstHiddenTile > -1 ? firstHiddenTile : maxTiles
                    ].image
                  );
                  setOpenGalleryView(true);
                }}
              >
                {getText('imageGalleryMore', 'profile')}
              </Button>
            </Box>
          )}

        {galleryEntries && (
          <GalleryView
            open={openGalleryView}
            galleryEntries={galleryEntries}
            image={startImage}
            onClose={() => setOpenGalleryView(false)}
          />
        )}
      </Box>
    </>
  );
};

export default GalleryGrid;
