/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TuneIcon from '@mui/icons-material/Tune';
import FilterMenuMobile from './filter-menu-mobile';
import type {
  UrlParams,
  FilterHandler,
  FilterGeoAreaCtrl,
  OnRemoveFilter
} from 'utils/url-param';
import { getText } from 'localization';

type FilterBlockMobileProps = FilterHandler &
  FilterGeoAreaCtrl & {
    search: UrlParams;
    totalElements?: number;
    hideCategoryFilter?: boolean;
    hasTargetGroup?: boolean;
    onRemoveFilter: OnRemoveFilter;
    onRemoveAllFilters: VoidFunction;
  };

const FilterBlockMobile = ({
  search,
  totalElements,
  geoAreaData,
  hideCategoryFilter,
  hasTargetGroup,
  onRemoveFilter,
  onRemoveAllFilters,
  onClickApplyCheckbox,
  onClickApplyGeoArea,
  onClickApplyDate,
  onClickApplyDuration,
  onChangeSort
}: FilterBlockMobileProps) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const title = getText('filterAndSort', 'filter');

  return (
    <>
      {/* filter button and menu */}
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        width="100%"
      >
        <Container
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            p: '0 !important',
            justifyContent: { xs: 'center', sm: 'flex-end' },
            mt: 0.5,
            mb: 1
          }}
        >
          <Button
            data-cy="offerspage-createoffer-button"
            variant="outlined"
            startIcon={<TuneIcon />}
            onClick={() => setOpenMobileFilter(true)}
            sx={{
              mt: { xs: 'unset', sm: 3.25 }
            }}
          >
            {title}
          </Button>
        </Container>
      </Stack>

      <FilterMenuMobile
        open={openMobileFilter}
        onClose={() => setOpenMobileFilter(false)}
        search={search}
        totalElements={totalElements}
        title={title}
        geoAreaData={geoAreaData}
        hideCategoryFilter={hideCategoryFilter}
        hasTargetGroup={hasTargetGroup}
        onRemoveFilter={onRemoveFilter}
        onRemoveAllFilters={onRemoveAllFilters}
        onClickApplyCheckbox={onClickApplyCheckbox}
        onClickApplyGeoArea={onClickApplyGeoArea}
        onClickApplyDate={onClickApplyDate}
        onClickApplyDuration={onClickApplyDuration}
        onChangeSort={onChangeSort}
      />
    </>
  );
};

export default FilterBlockMobile;
