/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, Stack, Typography } from '@mui/material';
import { ParagraphSkeleton } from 'components';
import DOMPurify from 'dompurify';
import { ReactNode } from 'react';
import { isEmptyHtml } from 'utils/editor';
import { customStyle } from './editor/text-editor';

interface SanitizedHTMLProps {
  dirtyHTML: string;
  dataCy?: string;
}

const SanitizedHTML = ({ dirtyHTML, dataCy }: SanitizedHTMLProps) => {
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(dirtyHTML)
      }}
      sx={{
        textAlign: { xs: 'left', sm: 'justify' },
        hyphens: 'auto',
        fontSize: {
          xs: 16,
          md: 18
        },
        customStyle
      }}
      data-cy={dataCy}
    ></Box>
  );
};

interface GenericContentSectionProps {
  title?: ReactNode;
  content?: ReactNode;
  placeholder?: ReactNode;
  empty?: boolean;
  hideEmpty?: boolean;
  loading?: boolean;
}

const GenericContentSection = ({
  title,
  content,
  placeholder,
  hideEmpty = true,
  empty = false,
  loading = false
}: GenericContentSectionProps) => {
  if (loading && placeholder) {
    return (
      <Stack spacing={1} sx={{ display: 'block' }}>
        {title}
        {placeholder}
      </Stack>
    );
  }

  if (hideEmpty && empty) {
    return <></>;
  }

  return (
    <Stack spacing={1} sx={{ display: 'block' }}>
      {title}
      {content}
    </Stack>
  );
};

interface TextContentSectionProps {
  title?: ReactNode;
  content?: string;
  placeholderLines?: number;
  hideEmpty?: boolean;
  loading?: boolean;
  dataCyPostfix?: string;
}

const TextContentSection = ({
  title,
  content,
  placeholderLines = 3,
  hideEmpty = true,
  loading = false,
  dataCyPostfix
}: TextContentSectionProps) => {
  return (
    <GenericContentSection
      title={title}
      content={
        <Typography
          sx={{ whiteSpace: 'pre-wrap' }}
          data-cy={dataCyPostfix ? `textcontent-${dataCyPostfix}` : undefined}
        >
          {content}
        </Typography>
      }
      placeholder={<ParagraphSkeleton lines={placeholderLines} pulse />}
      hideEmpty={hideEmpty}
      empty={!content}
      loading={loading}
    />
  );
};

interface HtmlContentSectionProps {
  title?: ReactNode;
  content?: string;
  placeholderLines?: number;
  hideEmpty?: boolean;
  loading?: boolean;
  dataCyPostfix?: string;
}

const HtmlContentSection = ({
  title,
  content,
  placeholderLines = 3,
  hideEmpty = true,
  loading = false,
  dataCyPostfix
}: HtmlContentSectionProps) => {
  return (
    <GenericContentSection
      title={title}
      content={
        <SanitizedHTML
          dirtyHTML={content ?? ''}
          dataCy={
            dataCyPostfix ? `sanitizedhtmlcontent-${dataCyPostfix}` : undefined
          }
        />
      }
      placeholder={<ParagraphSkeleton lines={placeholderLines} pulse />}
      hideEmpty={hideEmpty}
      empty={isEmptyHtml(content)}
      loading={loading}
    />
  );
};

export {
  GenericContentSection,
  HtmlContentSection,
  SanitizedHTML,
  TextContentSection
};
export type { GenericContentSectionProps };
