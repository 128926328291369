/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Typography from '@mui/material/Typography';
import {
  InvitationFormDialog,
  ParticipantsTable,
  SidebarPageWrapper
} from 'feature';
import { useProject } from 'context/project';
import { getText, getTextIn } from 'localization';
import { Registration } from '@SLR/solution3-sdk';
import { Add, OpenInNew } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import {
  useGetPortalOrganizationProfileHref,
  useGetPortalOrganizationsListHref
} from 'context/user';

const getSettingsText = getTextIn('settings-participants');

const SettingsParticipants = () => {
  const { project } = useProject();
  const [openInvitationForm, setOpenInvitationForm] = useState(false);
  const customerOrgUrl = useGetPortalOrganizationProfileHref(
    project?.organizationId
  );
  const orgListUrl = useGetPortalOrganizationsListHref();
  const portalUrl = project?.organizationId ? customerOrgUrl : orgListUrl;

  return (
    <SidebarPageWrapper>
      <>
        <Typography variant="h1">
          {getSettingsText('allParticipants')}
        </Typography>
        <Stack gap={4}>
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              href={portalUrl}
              target="_blank"
              data-cy="go-to-userportal-customer-organization"
              sx={{
                maxWidth: 230,
                alignSelf: 'start',
                textTransform: 'none'
              }}
            >
              {getText('huhu', 'settings')}
            </Button>
            {(project?.providerOrganizationsRegistration !==
              Registration.None ||
              project?.seekerOrganizationsRegistration !==
                Registration.None) && (
              <Button
                data-cy="settings-participants-invite-organization-button"
                variant="contained"
                startIcon={<Add />}
                onClick={() => setOpenInvitationForm(true)}
                sx={{ maxWidth: 230 }}
              >
                {getSettingsText('inviteParticipants')}
              </Button>
            )}
          </Stack>
          <InvitationFormDialog
            isOpen={openInvitationForm}
            onClose={() => setOpenInvitationForm(false)}
          />
          <ParticipantsTable />
        </Stack>
      </>
    </SidebarPageWrapper>
  );
};

export default SettingsParticipants;
