/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type { InputBaseComponentProps } from '@mui/material/InputBase';
import type { SxProps } from '@mui/material';
import { type Dayjs } from 'dayjs';
import ValidatedDatePicker, {
  DefaultDateTimePicker
} from './validated-date-picker';

/**
 *
 * @param name  Form name
 * @param label  Label
 * @param minDateTime  Optional minimum date time for pickers
 * @param maxDateTime  Optional maximum date time for pickers
 * @param inputProps  Optional input props, e.g.
 *    inputProps={{
 *      'data-cy': 'filter-timerange-start',
 *      sx: {...}
 *    }}
 * @param pickerSx  Optional styling sx for the picker
 * @returns ValidatedDatePicker element
 */
const ConfigurableDatePicker = ({
  name,
  label,
  minDateTime,
  maxDateTime,
  disabled,
  inputProps,
  pickerSx
}: {
  name: string;
  label: string;
  minDateTime?: Dayjs;
  maxDateTime?: Dayjs;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  pickerSx?: SxProps;
}) => {
  return (
    <ValidatedDatePicker
      name={name}
      render={(props) => (
        <DefaultDateTimePicker
          label={label}
          disablePast
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps
            }
          }}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
          disabled={disabled}
          sx={{ ...pickerSx }}
          {...props}
        />
      )}
    />
  );
};

export default ConfigurableDatePicker;
