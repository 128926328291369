/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState, ChangeEvent } from 'react';

type ValidationHash = { [key: string]: boolean };

const checkOverallValidation = (validationHash: ValidationHash) => {
  return Object.values(validationHash).some((value) => !!value);
};

/**
 * Connect the event handlers and text field error messaging with the validation hook logic
 *
 *  - standard case (checkForNotValid)
 *  - multi field case (checkForNotValidHash)
 *
 * @param handleChange An optional change handler to execute
 * @param handleConfirm The confirm handler to execute
 * @param handleCancel An optional cancel handler to execute
 * @param checkForNotValid The condition getter, to check when the checked state is not valid e.g.
 *    const checkForNotValid = () => !newTitle;
 * @param checkForNotValidHash The condition getter, to check when the checked state is not valid for
 *    multiple cases accessed by unique keys; for no valid checks, the keys will be set to true, e.g.
 *    const checkForNotValidHash = () => ({idA: true, idC: true});
 *
 * @returns object { isNotValid, isNotValidFor, onChange, onConfirm, onCancel } with the isNotValid flag and the
 *    event handler for the use in the component
 *
 *  Add then to a textfield the error message handling, e.g.
 *  <TextField
 *    ...
 *    error={isNotValid}
 *    helperText={ isNotValid && getText'THE_ERROR_TEXT') }
 *    ...
 *  />
 *
 *  In the case of the hash variant, the is not valid check works by the isNotValidFor(uniqueId) function, calling
 *  with the unique id, e.g.
 *  <TextField
 *    ...
 *    error={isNotValidFor(uniqueId)}
 *    helperText={ isNotValidFor(uniqueId) && getText'THE_ERROR_TEXT') }
 *    ...
 *  />
 *
 */
const useValidation = ({
  handleChange,
  handleConfirm,
  handleCancel,
  checkForNotValid,
  checkForNotValidHash
}: {
  handleChange?: (event: string) => void;
  handleConfirm: VoidFunction;
  handleCancel?: VoidFunction;
  checkForNotValid?: () => boolean;
  checkForNotValidHash?: () => ValidationHash;
}) => {
  const [validate, setValidate] = useState(false);

  // standard case for one field to check (return boolean)
  const isNotValid = checkForNotValid ? validate && checkForNotValid() : false;

  // multi case with a hash to validate against
  const validationHash = checkForNotValidHash
    ? validate
      ? checkForNotValidHash()
      : {}
    : null;

  const isNotValidFor = (key: string) => validationHash?.[key] ?? false;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValidate(true);
    handleChange?.(event.target.value);
  };

  const onConfirm = () => {
    if (
      checkForNotValid
        ? checkForNotValid()
        : checkForNotValidHash
        ? checkOverallValidation(checkForNotValidHash())
        : false
    ) {
      setValidate(true);
    } else {
      handleConfirm();
    }
  };

  const onCancel = () => {
    setValidate(false);
    handleCancel?.();
  };

  return { isNotValid, isNotValidFor, onChange, onConfirm, onCancel };
};

export { useValidation };
