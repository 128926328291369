/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useMemo } from 'react';
import { APINames, getApiInstance } from 'utils/api-utils';
import {
  type CreateEventSuggestionsRequest,
  EventSuggestionsApi
} from '@SLR/solution3-sdk';

const useEventSuggestionsApi = () => {
  const apiInstance = useMemo(() => {
    return getApiInstance(APINames.eventSuggestion) as EventSuggestionsApi;
  }, []);

  const getEventSuggestions = useCallback(
    async (requestParameters: CreateEventSuggestionsRequest) => {
      try {
        return await apiInstance.createEventSuggestions(requestParameters);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [apiInstance]
  );

  return {
    getEventSuggestions
  };
};

export default useEventSuggestionsApi;
