/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ListItemIcon, MenuItem, Stack } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { ItemText } from 'feature';
import { useKeycloakAuth } from 'auth';
import { getText } from 'localization';

const Logout = () => {
  const { logout } = useKeycloakAuth();

  return (
    <MenuItem
      key="logout"
      sx={{
        height: { xs: 50, sm: 60 },
        p: 0
      }}
      onClick={logout}
      data-cy="logout-button"
    >
      <Stack
        direction="row"
        sx={{
          px: 2.5,
          py: 2
        }}
      >
        <ListItemIcon sx={{ color: 'primary.main' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ItemText text={getText('logout')} />
      </Stack>
    </MenuItem>
  );
};
export default Logout;
