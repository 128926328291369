/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

export type LinkButtonProps = {
  text: string;
  linkTo: string;
  hide?: boolean;
};

type LinkButtonListProps = {
  linkButtons: LinkButtonProps[];
};

const LinkButtonList = ({ linkButtons }: LinkButtonListProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        flexWrap: { sm: 'wrap', lg: 'nowrap' },
        justifyContent: { xs: 'center' },
        gap: { xs: 1, sm: 2, lg: 4, xl: 10 },
        pt: 2
      }}
    >
      {linkButtons.map((button: LinkButtonProps) => {
        if (button.hide == undefined || !button.hide) {
          return (
            <Button
              variant="text"
              component={RouterLink}
              to={button.linkTo}
              endIcon={<NavigateNextIcon />}
              key={button.linkTo}
              data-cy={button.linkTo}
            >
              {button.text}
            </Button>
          );
        }
      })}
    </Box>
  );
};
export default LinkButtonList;
