/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState, type ComponentType, useEffect } from 'react';
import * as dates from 'date-arithmetic';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  type ViewProps,
  type NavigateAction,
  type ViewStatic,
  type TitleOptions,
  DateLocalizer,
  type stringOrDate
} from 'react-big-calendar';
import {
  DATE_FORMAT,
  TIME_FORMAT,
  WEEKDAY_FORMAT
} from 'feature/calendar/calendar';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { getText, getTextIn } from 'localization';
import { type EntityModelEvent } from '@SLR/solution3-sdk';
import Address from 'components/address';
import { useCrypto } from 'context/crypto';
import useGetOrganizationSpecificKey from 'context/crypto/hooks/useGetOrganizationSpecificKey';
import { useSnackbar } from 'notistack';
import { useUser } from 'context/user';
import { getFormattedRangeString } from 'utils/date';

const getCalendarText = getTextIn('calendar');
const RANGE_LENGTH = 7;

const getWeekStart = (
  date: string | number | Date | dayjs.Dayjs | null | undefined
) => dayjs(date).startOf('week').toDate();

const getRange = (date: stringOrDate | undefined) => {
  const rangeStart = getWeekStart(date);
  return Array.from(Array(RANGE_LENGTH), (_, idx) =>
    dayjs(rangeStart).add(idx, 'day').toDate()
  );
};

function inRange(
  e: EntityModelEvent,
  start: Date,
  end: Date,
  accessors: {
    title: (event: EntityModelEvent) => string;
    tooltip: (event: EntityModelEvent) => string;
    end: (event: EntityModelEvent) => Date;
    start: (event: EntityModelEvent) => Date;
  }
) {
  const eStart = dates.startOf(accessors.start(e), 'day');
  const eEnd = accessors.end(e);
  const startsBeforeEnd = dates.lte(eStart, end, 'day');
  const endsAfterStart = dates.eq(eStart, eEnd, 'minutes')
    ? dates.gte(eEnd, start, 'minutes')
    : dates.gt(eEnd, start, 'minutes');
  return startsBeforeEnd && endsAfterStart;
}

const timeRangeLabel = (
  event: EntityModelEvent,
  isSmall: boolean,
  localizer?: DateLocalizer
) => {
  return (
    event.timeSlot.startTime &&
    event.timeSlot.endTime &&
    `${getFormattedRangeString(
      localizer?.format(new Date(event.timeSlot.startTime), TIME_FORMAT),
      localizer?.format(new Date(event.timeSlot.endTime), TIME_FORMAT)
    )}${isSmall ? '' : ` ${getCalendarText('oClock')}`}`
  );
};

type AgendaEventProps = {
  event: EntityModelEvent;
  idx: number;
  colored: boolean;
  isMdDown: boolean;
  localizer: DateLocalizer;
  onSelectEvent:
    | ((
        event: EntityModelEvent,
        e: React.SyntheticEvent<HTMLElement, Event>
      ) => void)
    | undefined;
  onDoubleClickEvent:
    | ((
        event: EntityModelEvent,
        e: React.SyntheticEvent<HTMLElement, Event>
      ) => void)
    | undefined;
  accessors: {
    title: (event: EntityModelEvent) => string;
    tooltip?: ((event: EntityModelEvent) => string) | undefined;
    end: (event: EntityModelEvent) => Date;
    start: (event: EntityModelEvent) => Date;
  };
};

const AgendaEvent = ({
  event,
  idx,
  colored,
  isMdDown,
  localizer,
  onSelectEvent,
  onDoubleClickEvent,
  accessors
}: AgendaEventProps) => {
  const theme = useTheme();
  const { perspective } = useUser();
  const { decryptEventDetails, selectedOrganizationPrivateKey } = useCrypto();
  const { enqueueSnackbar } = useSnackbar();
  const { data: encryptedSymMsgKey } = useGetOrganizationSpecificKey(
    perspective.id,
    event.id
  );
  const [decryptedEvent, setDecryptedEvent] = useState<EntityModelEvent>(event);

  useEffect(() => {
    if (encryptedSymMsgKey && selectedOrganizationPrivateKey) {
      decryptEventDetails(
        event,
        encryptedSymMsgKey,
        selectedOrganizationPrivateKey
      )
        .then(setDecryptedEvent)
        .catch((reason) =>
          enqueueSnackbar(
            `${getText('decryptionError', 'crypto')}: ${reason}`,
            {
              variant: 'error'
            }
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedSymMsgKey, selectedOrganizationPrivateKey]);

  return (
    <tr
      key={idx}
      className="rbc-agenda-content"
      style={{
        backgroundColor: colored ? theme.palette.primary.background : 'unset'
      }}
    >
      <td>
        {idx === 0 && (
          <Typography>
            <b>
              {decryptedEvent.timeSlot.startTime &&
                `${localizer?.format(
                  decryptedEvent.timeSlot.startTime,
                  WEEKDAY_FORMAT
                )}, ${localizer?.format(
                  decryptedEvent.timeSlot.startTime,
                  DATE_FORMAT
                )}`}
            </b>
          </Typography>
        )}
      </td>
      <td>
        <Typography>
          {timeRangeLabel(decryptedEvent, isMdDown, localizer)}
        </Typography>
      </td>
      <td>
        <Address address={decryptedEvent.eventDetails.fixedLocation} />
      </td>
      <td
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
        onClick={(ev) => onSelectEvent && onSelectEvent(event, ev)}
        onDoubleClick={(ev) =>
          onDoubleClickEvent && onDoubleClickEvent(event, ev)
        }
      >
        <Typography color="primary.main">
          {accessors.title(decryptedEvent)}
        </Typography>
        {decryptedEvent.online && (
          <VideoCameraFrontIcon color="primary" fontSize="small" />
        )}
      </td>
      {!isMdDown && (
        <td>
          <Typography>{`${decryptedEvent.eventDetails.firstName} ${decryptedEvent.eventDetails.lastName}`}</Typography>
        </td>
      )}
    </tr>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExtendedAgendaView: ComponentType<any> &
  ViewStatic & { range: (date: Date) => Date[] } = ({
  accessors,
  localizer,
  date,
  events,
  onSelectEvent,
  onDoubleClickEvent
}: ViewProps) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const rangeDates = getRange(date);

  const { noEventsInRange } = localizer.messages;

  const eventsPerDay = rangeDates
    .map((rangeDay) =>
      events?.filter((event) =>
        inRange(
          event as EntityModelEvent,
          rangeDay,
          dayjs(rangeDay).endOf('day').toDate(),
          accessors
        )
      )
    )
    .filter((dayEvents) => dayEvents && dayEvents?.length > 0);

  const renderDay = (dayEvents: EntityModelEvent[], colored: boolean) => {
    return dayEvents.map((dayEvent, idx) => {
      return (
        <AgendaEvent
          event={dayEvent}
          idx={idx}
          colored={colored}
          isMdDown={isMdDown}
          localizer={localizer}
          onSelectEvent={onSelectEvent}
          onDoubleClickEvent={onDoubleClickEvent}
          accessors={accessors}
          key={idx}
        />
      );
    }, []);
  };

  return (
    <>
      {eventsPerDay && eventsPerDay.length !== 0 ? (
        <table className="rbc-agenda-table">
          <thead
            className="rbc-header"
            style={{ textAlign: 'left', color: theme.palette.primary.main }}
          >
            <tr>
              <th>{getCalendarText('date')}</th>
              <th>{getCalendarText('time')}</th>
              <th>{getCalendarText('address')}</th>
              <th>{getCalendarText('event')}</th>
              {!isMdDown && <th>{getCalendarText('patient')}</th>}
            </tr>
          </thead>
          <tbody>
            {eventsPerDay.map((dayEvents, idx) =>
              renderDay(dayEvents as EntityModelEvent[], idx % 2 === 0)
            )}
          </tbody>
        </table>
      ) : (
        noEventsInRange
      )}
    </>
  );
};

ExtendedAgendaView.title = (date: Date, options: TitleOptions) => {
  return getFormattedRangeString(
    options.localizer?.format(getWeekStart(date), DATE_FORMAT, options.culture),
    options.localizer?.format(
      dayjs(date).endOf('week').toDate(),
      DATE_FORMAT,
      options.culture
    )
  );
};

ExtendedAgendaView.navigate = (date: Date, action: NavigateAction) => {
  const sDate = getWeekStart(date);
  switch (action) {
    case 'PREV':
      return dates.add(sDate, -7, 'day');
    case 'NEXT':
      return dates.add(sDate, 7, 'day');
    default:
      return date;
  }
};

//  support onRangeChange
ExtendedAgendaView.range = (date: Date) => {
  return getRange(date);
};

ExtendedAgendaView.propTypes = {
  events: PropTypes.array,
  date: PropTypes.instanceOf(Date),
  length: PropTypes.number,
  selected: PropTypes.object,
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object.isRequired
};
