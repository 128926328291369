/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

type DropdownButtonProps = {
  dataCy?: string;
  open: boolean;
  text?: string;
  additionalLabel?: string;
  onClick: VoidFunction;
};

const DropdownButton = ({
  dataCy,
  open = false,
  text = '',
  additionalLabel,
  onClick
}: DropdownButtonProps) => {
  const Arrow = open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  return (
    <Box position="relative">
      <Button
        data-cy={dataCy}
        variant="outlined"
        endIcon={<Arrow />}
        sx={{
          fontSize: 14,
          px: 2.75,
          py: 0.75
        }}
        onClick={onClick}
      >
        {text}
      </Button>

      {additionalLabel && (
        <Box
          position="absolute"
          sx={{
            left: 9,
            top: -9,
            fontSize: 12,
            color: 'primary.main',
            bgcolor: 'white',
            borderRadius: 0.5,
            px: 0.625
          }}
        >
          {additionalLabel}
        </Box>
      )}
    </Box>
  );
};

export default DropdownButton;
