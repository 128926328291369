/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { getText, getTextIn } from 'localization';
import { AdministerParticipantProps } from './delete-participant-dialog';
import { Role, booleanToRole, roleToBoolean } from 'utils/roles';
import { DialogGeneric } from 'feature';
import { RadioBlock } from 'components';
import { useGetAvailableRoles } from 'context/project/hooks';
import { notifyMutationError, notifyMutationSuccess } from 'feature/error';
import { useUpdateOrganizationProfile } from 'feature/hooks';

const getParticipantsText = getTextIn('settings-participants');

const prefix = 'edit-participant-dialog';

const EditParticipantForm = ({
  open,
  onClose,
  participantSelected
}: AdministerParticipantProps) => {
  const availableRoles = useGetAvailableRoles(
    participantSelected?.user ? 'user' : 'organization'
  );

  const [showOfferLostWarning, setShowOfferLostWarning] = useState(false);
  const [role, setRole] = useState<Role>();

  const updateOrganizationProfile = useUpdateOrganizationProfile();

  const close = () => {
    onClose();
    setShowOfferLostWarning(false);
  };

  const changeRole = (selectedRole: string, prevRole?: Role): void => {
    setShowOfferLostWarning(
      prevRole === 'provider' && selectedRole === 'seeker'
    );
    setRole(selectedRole as Role);
  };

  const responseReaction = {
    onSuccess: () => {
      notifyMutationSuccess();
      close();
    },
    onError: () => {
      notifyMutationError();
    }
  };

  const handleSubmit = () => {
    if (participantSelected?.user === false) {
      updateOrganizationProfile.mutate(
        {
          organizationId: participantSelected.participantId,
          organizationProfileUpdate: roleToBoolean(role)
        },
        responseReaction
      );
    }
  };

  useEffect(() => {
    setRole(
      booleanToRole(participantSelected?.seeker, participantSelected?.provider)
    );
  }, [participantSelected?.seeker, participantSelected?.provider]);

  return (
    <DialogGeneric
      open={open}
      onClose={close}
      onConfirm={handleSubmit}
      title={getParticipantsText('editParticipant')}
      confirmText={getText('save')}
      prefix={prefix}
      fullWidth
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="subtitle1">
          {getParticipantsText('changeRole')}
        </Typography>
        <RadioBlock
          key="role"
          radioOption={availableRoles}
          controlledValue={role}
          onValueChange={(key, value) => value && changeRole(value, role)}
          row
        />
        {showOfferLostWarning && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ px: 1 }}
          >
            <Warning color="primary" fontSize="large" />
            <Typography color="primary">
              {getParticipantsText('offerLostWarning')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </DialogGeneric>
  );
};

export default EditParticipantForm;
