/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner,  Lars Hemmerling
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  CancelRounded as CancelRoundedIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { Box, IconButton, TextField, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useProject } from 'context/project';
import { getPath } from 'localization';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { SearchParams, getTrimmedSearchTerm } from 'utils/url-param';

type SearchBarProps = {
  callback?: VoidFunction;
  height?: number;
  width?: number;
  morePadding?: boolean;
  placeholder?: string;
};

const SearchBar = ({
  callback,
  height = 42,
  width,
  morePadding = false,
  placeholder
}: SearchBarProps) => {
  const { project } = useProject();
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const theme = useTheme();

  const hasCompass = project?.features?.knowledgeBase ?? false;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onSearch = () => {
    const trimmedSearchTerm = getTrimmedSearchTerm(searchTerm);

    if (trimmedSearchTerm) {
      navigate({
        pathname: getPath(hasCompass ? 'universalSearch' : 'offers'),
        search: createSearchParams({
          [SearchParams.query]: trimmedSearchTerm
        }).toString()
      });
    }

    clearSearchTerm();
    callback?.();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch();
    }

    if (event.key === 'Escape') {
      clearSearchTerm();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        borderRadius: 1.75,
        bgcolor: 'common.white',
        '&:hover': {
          bgcolor: alpha(theme.palette.common.white, 0.95)
        },
        ml: 0,
        width: width ?? { xs: '100%', sm: 'auto' },
        height
      }}
    >
      <TextField
        value={searchTerm}
        variant="standard"
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        autoFocus
        sx={{
          width: '100%',
          '& .MuiInputBase-input': {
            p: 0,
            pl: morePadding ? 3 : 1,
            color: 'secondary.main',
            transition: theme.transitions.create('width'),
            width: '100%'
          }
        }}
        InputProps={{
          'aria-label': 'search',
          disableUnderline: true,
          sx: { height: '100%' },

          endAdornment: (
            <>
              {searchTerm && (
                <IconButton
                  aria-label="clear search input"
                  sx={{
                    p: '0',
                    height,
                    width: height,
                    minWidth: 'unset'
                  }}
                  onClick={clearSearchTerm}
                >
                  <CancelRoundedIcon sx={{ color: 'secondary.main' }} />
                </IconButton>
              )}
              <Box
                sx={{
                  verticalAlign: 'middle',
                  display: 'inline-flex',
                  borderRadius: '0 7px 7px 0',
                  borderLeft: '2px solid',
                  borderLeftColor: 'secondary.main',
                  bgcolor: 'secondary.main',
                  ml: 0.25,
                  height,
                  width: height,
                  justifyContent: 'center'
                }}
              >
                <IconButton
                  aria-label="search"
                  onClick={onSearch}
                  disabled={!searchTerm}
                >
                  <SearchIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
            </>
          )
        }}
      />
    </Box>
  );
};
export default SearchBar;
