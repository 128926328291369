/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Box } from '@mui/material';
import { Section } from '@SLR/solution3-sdk';
import { ProviderPreviewSection } from 'feature';
import { InfoPageHeroWrapper, InfoSection, StaticSection } from 'feature/info';
import { EditToggle } from 'feature/editor';
import {
  ProcessWrapper,
  useVizLabelsProvider
} from 'feature/process-visualization';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { useProject, useSetDocumentTitle } from 'context/project';
import { transformToFragment } from 'utils/fragments-utils';
import { getTextIn, getPath } from 'localization';
import { useIsVisible } from 'feature/permissions';
import { ContactButton } from 'components';

const getInfoText = getTextIn('info');

const DoctorInfoPage = () => {
  useSetDocumentTitle(getInfoText('doctorInfo'));

  const { project } = useProject();
  const providerOrganizationsVisible = useIsVisible(
    project?.providerOrganizationsVisibility
  );

  const getOfferText = getTextIn('offer-details');

  const providerVizLabels = useVizLabelsProvider(getPath('doctorInfo'));

  return (
    <>
      <InfoPageHeroWrapper section={Section.DoctorInformation} />

      <ProcessWrapper
        processSection={{
          title: getInfoText('servicesTitle'),
          section: Section.DoctorGains,
          id: transformToFragment(getInfoText('servicesTitle'))
        }}
        vizLabels={providerVizLabels}
      >
        <>
          <EditToggle />
          <BreadcrumbNavigation />

          <InfoSection
            title={getInfoText('doctorInfo')}
            headingType="h1"
            section={Section.DoctorInformation}
          />
        </>
        <InfoSection
          title={getInfoText('howToParticipateTitle')}
          section={Section.DoctorParticipate}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
            <ContactButton data-cy="doctors-info-participate-contact-button" />
          </Box>
        </InfoSection>
        {providerOrganizationsVisible ? (
          <ProviderPreviewSection coloredBackground={true} />
        ) : undefined}
        <InfoSection
          title={getOfferText('additionalInformation')}
          section={Section.DoctorMore}
        />
        <StaticSection
          title={getInfoText('helpAndContact')}
          sectionText={getInfoText('helpAndContactInfo')}
          coloredBackground={true}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
            <ContactButton data-cy="doctors-info-contact-button" />
          </Box>
        </StaticSection>
      </ProcessWrapper>
    </>
  );
};

export default DoctorInfoPage;
