/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useGetProjectId } from 'context/project';
import { useUser } from 'context/user';
import { RadioBlock } from 'components';
import { DialogGeneric } from 'feature';
import { useCreateReport } from 'feature/hooks';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import {
  type CreateReportRequest,
  type ReportCreateDto,
  Reason,
  EntityType
} from '@SLR/solution3-sdk';
import { useKeycloakAuth } from 'auth';
import { getText, getTextIn } from 'localization';
import { getIsValidEmail } from 'utils/helper';
import { Reasons } from 'types';

const getTextReport = getTextIn('report');

const prefix = 'report';
const prefixDetails = 'report-details';

const getRadioOptions = (localizationObject: Reasons) =>
  Object.entries(localizationObject).reduce(
    (acc: { value: string; label: string }[], [value, label]) => {
      acc.push({ value, label });
      return acc;
    },
    []
  );

type ReportButtonProps = {
  entityId?: string;
  type: EntityType;
};

const ReportButton = ({ entityId = '', type }: ReportButtonProps) => {
  const [openSelectReason, setOpenSelectReason] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const [reason, setReason] = useState<Reason | null>(null);
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');

  const {
    perspective: { data }
  } = useUser();

  const userEmail = data?.email;

  const { isAuthenticated } = useKeycloakAuth();
  const projectId = useGetProjectId() ?? '';

  const createReport = useCreateReport();

  const hasUserEmail = isAuthenticated && !!userEmail;

  const reasons = getTextReport('reasons') as unknown as Reasons;
  const radioOptions = getRadioOptions(reasons);

  const getTextReportType = getTextIn(
    `report-${type === EntityType.Offer ? 'offer' : 'profile'}`
  );

  const onConfirmReason = () => {
    setOpenDetails(true);
  };

  const onCloseReason = () => {
    setOpenSelectReason(false);
    setReason(null);
  };

  const onCloseDetails = () => {
    setOpenDetails(false);
  };

  const onConfirmDetails = () => {
    if (entityId && reason) {
      const params: ReportCreateDto = {
        entityType: type,
        entityId,
        reason
      };

      if (email) {
        params.email = email;
      }

      if (description) {
        params.description = description;
      }

      const requestParameters: CreateReportRequest = {
        projectId,
        reportCreateDto: params
      };

      createReport.mutate(requestParameters, {
        onSuccess: () => {
          notifyMutationSuccess(getTextReportType('successful'));
        },
        onError: () => {
          notifyMutationError(getTextReport('error'));
        }
      });

      onCloseReason();
      onCloseDetails();
    }
  };

  type OnValueChangeProps = (key: string, value?: string) => void;

  const onValueChange: OnValueChangeProps = (key, value) => {
    setReason(value as Reason);
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const reportText = getTextReportType('report');

  return (
    <>
      <Button
        startIcon={<ReportProblemOutlinedIcon />}
        onClick={() => {
          setOpenSelectReason(true);
        }}
        data-cy={`${type}-report-button`}
        color="error"
        variant="text"
      >
        {reportText}
      </Button>

      <DialogGeneric
        open={openSelectReason}
        fullWidth={true}
        onClose={onCloseReason}
        onConfirm={onConfirmReason}
        title={reportText}
        confirmText={getText('forward')}
        prefix={prefix}
        isDisabled={!!!reason}
      >
        <Typography variant="body1" sx={{ pb: 2.25 }}>
          {getTextReportType('why')}
        </Typography>
        <RadioBlock
          radioOption={{ key: 'report-profiles', options: radioOptions }}
          row={false}
          onValueChange={onValueChange}
        />
      </DialogGeneric>

      <DialogGeneric
        open={openDetails}
        fullWidth={true}
        onClose={onCloseDetails}
        onConfirm={onConfirmDetails}
        title={reportText}
        confirmText={getTextReport('report')}
        prefix={prefixDetails}
        isDisabled={!hasUserEmail && !getIsValidEmail(email)}
      >
        <Typography variant="body1" sx={{ pb: 1 }}>
          {getTextReportType('reasonWhy')}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', pb: 3 }}>
          {reasons[reason as Reason]}
        </Typography>
        <TextField
          label={getTextReport('description')}
          onChange={onDescriptionChange}
          placeholder={getTextReport('descriptionHelpertext')}
          multiline
          rows={5}
          fullWidth
          inputProps={{
            maxLength: 2000,
            'data-cy': `${prefix}-description`
          }}
        />
        {!hasUserEmail && (
          <TextField
            label={getTextReport('emailRequired')}
            onChange={onEmailChange}
            placeholder={getTextReport('emailInquiryHelpertext')}
            fullWidth
            inputProps={{
              maxLength: 254,
              pattern: 'email',
              'data-cy': `${prefix}-email`
            }}
            sx={{ mt: 2 }}
          />
        )}
        <FormHelperText
          sx={{
            mt: 2
          }}
        >
          {getText('requiredField')}
        </FormHelperText>
      </DialogGeneric>
    </>
  );
};

export default ReportButton;
