/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { SchemaProvider, FormTimesDuration } from 'feature/forms';
import { NdashBox } from 'feature/filter/common';
import {
  SearchParams,
  type UrlParams,
  type OnClickDuration
} from 'utils/url-param';
import {
  DurationFilter,
  DurationFilterSchema,
  EMPTY_DURATION_FILTER
} from './time/model';
import { DURATION_CONFIG, durationToMinutes } from 'utils/duration';
import { getText, getTextIn } from 'localization';

const getTextFilterTime = getTextIn('filter-time');

type InputSliderValues = number[];

type InputSliderProps = {
  value: InputSliderValues;
  setValue: Dispatch<SetStateAction<InputSliderValues>>;
};

const MIN_DURATION_FILTER = 10;

const convertToHhMm = (value: number) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return `0${hours}:${
    (minutes < MIN_DURATION_FILTER ? '0' : '') + minutes || '00'
  }`;
};

const convertToMinutes = (values: DurationFilter) => {
  const durationFrom = durationToMinutes(values.durationFrom, 'min');
  const durationTo = durationToMinutes(values.durationTo, 'max');

  return [durationFrom, durationTo] as InputSliderValues;
};

const RangeSlider = ({ value, setValue }: InputSliderProps) => {
  return (
    <Stack spacing={2} sx={{ mt: 2.5, width: { xs: 'auto', md: 295 } }}>
      <Stack gap={2} direction="row" sx={{}}>
        <FormTimesDuration
          name="durationFrom"
          label={getTextFilterTime('from')}
        />

        <NdashBox />

        <FormTimesDuration name="durationTo" label={getTextFilterTime('to')} />
      </Stack>

      <Slider
        size="small"
        value={value}
        min={MIN_DURATION_FILTER}
        max={DURATION_CONFIG.max}
        step={DURATION_CONFIG.step}
        onChange={(_: Event, newValue: InputSliderValues) => {
          setValue(newValue);
        }}
        aria-labelledby="input-slider"
        sx={{ width: '97%', left: '2%', mt: '8px !important' }}
      />
    </Stack>
  );
};

const DurationFilterBlock = ({
  search,
  onClick
}: {
  search: UrlParams;
  onClick: OnClickDuration;
}) => {
  const filterValue = search[SearchParams.duration] || [];
  const [durationFrom, durationTo] = filterValue;

  // range slider format in minutes
  const [durationRange, setDurationRange] = useState<number[]>([
    // default 10min - see MIN_DURATION_FILTER
    durationToMinutes(
      durationFrom && durationFrom !== '00:00' ? durationFrom : '0:10',
      'min'
    ),
    durationToMinutes(durationTo, 'max')
  ]);

  // form hooks format in hh:mm
  const durationValues = useMemo(() => {
    const newDuration = { ...EMPTY_DURATION_FILTER };

    newDuration.durationFrom = convertToHhMm(durationRange[0]);
    newDuration.durationTo = convertToHhMm(durationRange[1]);

    return newDuration;
  }, [durationRange]);

  return (
    <SchemaProvider<DurationFilter>
      defaultValues={EMPTY_DURATION_FILTER}
      values={durationValues}
      schema={DurationFilterSchema}
    >
      {({ handleSubmit, getValues }) => {
        const valuesMinutes = convertToMinutes(getValues());

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <RangeSlider value={valuesMinutes} setValue={setDurationRange} />

            <Button
              variant="contained"
              onClick={handleSubmit(onClick)}
              sx={{ mt: 2.5, mb: 1.75 }}
            >
              {getText('apply')}
            </Button>
          </Box>
        );
      }}
    </SchemaProvider>
  );
};

export default DurationFilterBlock;
