/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Typography } from '@mui/material';
import { ContentWrapper } from 'feature';
import { BreadcrumbNavigation } from 'feature/breadcrumbs';
import { getTextIn } from 'localization';
import ContactForm from './contact-form';
import { useCallback } from 'react';
import { useUser } from 'context/user';
import { useCreateAdminMessage } from 'feature/hooks';
import { type AnonymousMessage } from '@SLR/solution3-sdk';
import { notifyMutationSuccess, notifyMutationError } from 'feature/error';
import useProjectNavigate from 'utils/useProjectNavigate';
import { useSetDocumentTitle } from 'context/project';

const getContactText = getTextIn('contact');

const ContactPage = ({
  subject,
  content,
  title = getContactText('contact')
}: {
  subject?: string;
  content?: string;
  title?: string;
}) => {
  const { navigateToHome } = useProjectNavigate();

  const { currentUser } = useUser();
  const createAdminMessage = useCreateAdminMessage();

  const handleConfirm = useCallback(
    (anonymousMessage: AnonymousMessage) => {
      createAdminMessage.mutate(anonymousMessage, {
        onSuccess: () => {
          notifyMutationSuccess(getContactText('successMessage'));
          navigateToHome();
        },
        onError: () => {
          notifyMutationError(getContactText('errorMessage'));
        }
      });
    },
    [createAdminMessage, navigateToHome]
  );

  useSetDocumentTitle(title);

  return (
    <ContentWrapper breadcrumbOnly>
      <BreadcrumbNavigation />
      <Typography variant="h1" align="center">
        {title}
      </Typography>
      <ContactForm
        currentUser={currentUser}
        isLoading={createAdminMessage.isLoading}
        subject={subject}
        content={content}
        onConfirm={handleConfirm}
      />
    </ContentWrapper>
  );
};

export default ContactPage;
