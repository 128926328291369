/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  RadioSubForm,
  FormTimesDuration,
  useSchemaValidationFormContext
} from 'feature/forms';
import { getTextIn, required } from 'localization';
import { Offer, TimeType } from './model';
import FixedTimeForm from './fixed-time-form';
import TimeRangeForm from './time-range-form';
import { useBooking } from 'context/booking';

const getOfferText = getTextIn('offer-details');
const getBusinessTimeText = getTextIn('offer-details-businessTime');
const getFixedTimeText = getTextIn('offer-details-fixedTime');
const getTimeRangeText = getTextIn('offer-details-timeRange');

const TimesForm = () => {
  const { isOffersBookable } = useBooking();
  const { control, setValue } = useSchemaValidationFormContext<Offer>();

  const timeType = useWatch<Offer>({
    control,
    name: 'timeType'
  });

  return (
    <Stack spacing={1}>
      <Typography component="h2" variant="sectionTitle">
        {`${required(getOfferText('time'))}`}
      </Typography>

      <Box width={{ sm: '50%' }}>
        <FormTimesDuration
          label={`${getOfferText('duration')}${isOffersBookable ? '*' : ''}`}
          helperText={getOfferText('durationHelperText')}
          dataCy="offerform-duration-edit"
        />
      </Box>

      <FormControl>
        <FormLabel id="radio-buttons-time" sx={{ display: 'none' }}>
          {getOfferText('times')}
        </FormLabel>
        <RadioGroup
          name="radio-buttons-time"
          aria-labelledby="radio-buttons-time"
        >
          <RadioSubForm
            checked={timeType === TimeType.fixedTime}
            onChange={() => {
              setValue('timeType', TimeType.fixedTime);
              setValue('endTime', null);
            }}
            name="time"
            value="fixed"
            title={getFixedTimeText('title')}
            info={getFixedTimeText('info')}
            prefixCy="offerform-fixedtime"
          >
            <FixedTimeForm />
          </RadioSubForm>

          <RadioSubForm
            checked={timeType === TimeType.timeRange}
            onChange={() => {
              setValue('timeType', TimeType.timeRange);
            }}
            name="time"
            value="range"
            title={getTimeRangeText('title')}
            info={getTimeRangeText('info')}
            prefixCy="offerform-timerange"
          >
            <TimeRangeForm />
          </RadioSubForm>

          <RadioSubForm
            checked={timeType === TimeType.businessHours}
            onChange={() => {
              setValue('timeType', TimeType.businessHours);
            }}
            name="time"
            value="business"
            title={getBusinessTimeText('title')}
            info={getBusinessTimeText('info')}
            prefixCy="offerform-businesstime"
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default TimesForm;
