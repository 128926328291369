/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ObjectSchema, object, string, date, ref } from 'yup';
import { getIsValidDuration } from 'utils/duration';
import { getText, getTextIn, getTextFx, getErrorText } from 'localization';

enum FilterTimeType {
  fixedTime = 'fixedTime',
  timeRange = 'timeRange'
}

interface DateFilter {
  timeType: FilterTimeType | null;
  time: Date | null;
  rangeFrom: Date | null;
  rangeTo: Date | null;
}

interface DurationFilter {
  durationFrom: string;
  durationTo: string;
}

const EMPTY_DURATION_FILTER: DurationFilter = Object.freeze({
  durationFrom: '',
  durationTo: ''
});

const EMPTY_DATE_FILTER: DateFilter = Object.freeze({
  timeType: null,
  time: null,
  rangeFrom: null,
  rangeTo: null
});

const getTextFilterTime = getTextIn('filter-time');

const DateFilterSchema = (
  maxDateFromNow: Date,
  minDateFromNow?: Date,
  minPointInTimeFromNow?: Date
): ObjectSchema<DateFilter> => {
  const INSERT_VALID_VALUE = getErrorText('dateFormatError');
  const now = new Date();

  return object().shape({
    timeType: string<FilterTimeType>()
      .defined()
      .nullable()
      .oneOf(Object.values(FilterTimeType)),

    time: date()
      .defined()
      .nullable()
      .min(minPointInTimeFromNow ?? now)
      .max(maxDateFromNow)
      .typeError(INSERT_VALID_VALUE)
      .label(getTextFilterTime('pointInTime')),

    rangeFrom: date()
      .defined()
      .nullable()
      .min(minDateFromNow ?? now)
      .max(maxDateFromNow)
      .typeError(INSERT_VALID_VALUE)
      .label(getTextFilterTime('rangeFrom')),

    rangeTo: date()
      .defined()
      .nullable()
      .min(minDateFromNow ?? now)
      .max(maxDateFromNow)
      .when('rangeFrom', {
        is: (value: Date | null) => value != null,
        then: (schema) =>
          schema.nullable().min(ref('rangeFrom'), ({ min }) => {
            return String(min) === 'Invalid Date'
              ? getTextFilterTime('valueDependsOnStartValue')
              : getTextFx('getTimeValueFrom', 'filter-time')({ min });
          })
      })
      .label(getTextFilterTime('rangeTo'))
      .typeError(INSERT_VALID_VALUE)
  });
};

const DurationFilterSchema = (): ObjectSchema<DurationFilter> => {
  return object().shape({
    durationFrom: string()
      .defined()
      .test(
        getIsValidDuration(
          getTextFilterTime('validCustomDurationMin'),
          'rangeFilter'
        )
      )
      .label(getText('duration', 'filter')),

    durationTo: string()
      .defined()
      .test(
        getIsValidDuration(
          getTextFilterTime('validCustomDuration'),
          'rangeFilter'
        )
      )
      .label('durationTo')
  });
};

export {
  DateFilterSchema,
  DurationFilterSchema,
  EMPTY_DATE_FILTER,
  EMPTY_DURATION_FILTER,
  FilterTimeType
};

export type { DateFilter, DurationFilter };
