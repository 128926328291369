/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useSnackbar } from 'notistack';
import { type Image } from '@SLR/solution3-sdk';
import { useProject } from 'context/project';
import { useUpdateProject } from 'context/project/hooks';
import { SidebarPageWrapper } from 'feature';
import { useValidation } from 'feature/hooks';
import CtrlButtons from './ctrl-buttons';
import ColorSettings from './color-settings';
import { getTextIn } from 'localization';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ManageImageSection from 'feature/manage-image-section';
import { filterImageChange } from 'utils/image-utils';

const getSettingsText = getTextIn('settings-appearance');

const colorHasChanged = (newColor: string, color?: string) => {
  return newColor.toUpperCase() !== color?.toUpperCase();
};

const SettingsAppearance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { project, primaryColor, secondaryColor, resetColors } = useProject();
  const updateProject = useUpdateProject();
  const [updatedLogo, setUpdatedLogo] = useState<Image>();
  const [resetClicked, setResetClicked] = useState(false);

  const projectLogo = project?.logo;

  const newAlternativeText = updatedLogo?.alternativeText;
  const hasNewAlternativeText = !!newAlternativeText?.trim();

  const hasColorChange =
    colorHasChanged(primaryColor, project?.primaryColor) ||
    colorHasChanged(secondaryColor, project?.secondaryColor);

  const enableConfirmButton =
    (updatedLogo &&
      hasNewAlternativeText &&
      (hasColorChange ||
        projectLogo?.alternativeText !== newAlternativeText ||
        projectLogo?.id !== updatedLogo?.id)) ||
    (!updatedLogo && (!!projectLogo || hasColorChange));

  const handleAltTextChange = (text: string) =>
    setUpdatedLogo((prevImage) => {
      if (prevImage) {
        return { ...prevImage, alternativeText: text };
      }
    });

  useEffect(() => {
    if (projectLogo) {
      setUpdatedLogo(projectLogo);
    }
  }, [projectLogo]);

  const onConfirmClick = () => {
    if (project?.id) {
      updateProject.mutate(
        {
          projectId: project.id,
          projectUpdate: {
            ...(hasColorChange && { primaryColor, secondaryColor }),
            // See: GP-378
            // eslint-disable-next-line
            // @ts-ignore
            logo: filterImageChange(updatedLogo, projectLogo)
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar(getSettingsText('changeAppearanceSuccess'), {
              variant: 'success'
            });
          },
          onError: () => {
            enqueueSnackbar(getSettingsText('changeAppearanceError'), {
              variant: 'error'
            });
          }
        }
      );
    }
  };

  const handleCancelClick = () => {
    setResetClicked((prevState) => !prevState);
    resetColors();
    setUpdatedLogo(projectLogo);
  };

  const { isNotValid, onChange, onConfirm, onCancel } = useValidation({
    handleChange: handleAltTextChange,
    handleConfirm: onConfirmClick,
    handleCancel: handleCancelClick,
    checkForNotValid: () => !!updatedLogo && !hasNewAlternativeText
  });

  return (
    <SidebarPageWrapper>
      <>
        <Typography variant="h1">
          {getSettingsText('appearanceSettings')}
        </Typography>
        <Typography variant="formLabel">{getSettingsText('logo')}</Typography>
        <ManageImageSection
          image={updatedLogo}
          handleAltTextChange={onChange}
          keyValue="logo"
          handleAddImage={setUpdatedLogo}
          handleDeleteImage={() => setUpdatedLogo(undefined)}
          isNotValid={isNotValid}
        />
        <ColorSettings resetClicked={resetClicked} />
        <CtrlButtons
          disabled={!enableConfirmButton}
          isLoading={updateProject.isLoading}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </>
    </SidebarPageWrapper>
  );
};

export default SettingsAppearance;
