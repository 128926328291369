/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Visibility } from '@SLR/solution3-sdk';
import { useKeycloakAuth } from 'auth';
import LoadingSpinner from 'components/loading-spinner';
import { useProject } from 'context/project';
import { useUser } from 'context/user';
import { useIsVisible } from 'feature/permissions';
import { ErrorPageNotFound } from 'pages/error';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface RestrictedProps {
  forceLogin?: boolean;
  placeholder?: JSX.Element;
}

interface RestrictedRouteProps {
  isVisible: boolean;
}

interface RestrictedVisibilityRouteProps {
  visibility?: Visibility;
}

interface RestrictedBookingRouteProps {
  seekerOnly?: boolean;
}

const Restricted = ({
  isVisible,
  forceLogin = true,
  placeholder = <ErrorPageNotFound />,
  children
}: PropsWithChildren<RestrictedRouteProps & RestrictedProps>) => {
  const { isReady, isAuthenticated, login } = useKeycloakAuth();
  const { isLoadingAndFetchingCurrentUser, isLoadingAndFetchingOrganizations } =
    useUser();
  const { isLoading: isLoadingProject } = useProject();
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const shouldLogin = !isVisible && forceLogin;

  useEffect(() => {
    if (shouldLogin) {
      if (isReady && !isAuthenticated) {
        login();
      }
    }
  }, [shouldLogin, isReady, isAuthenticated, login]);

  useEffect(() => {
    if (
      !isLoadingAndFetchingCurrentUser &&
      !isLoadingProject &&
      !isLoadingAndFetchingOrganizations
    ) {
      setTimeout(() => setShowPlaceholder(true), 100);
    }
  }, [
    isLoadingProject,
    isLoadingAndFetchingCurrentUser,
    isLoadingAndFetchingOrganizations
  ]);

  if (shouldLogin && !isAuthenticated) {
    return <LoadingSpinner dataCy="restricted-route" />;
  }

  return isVisible ? (
    <>{children ?? <Outlet />}</>
  ) : showPlaceholder ? (
    placeholder
  ) : (
    <LoadingSpinner dataCy="restricted-route" />
  );
};

const RestrictedVisibilityRoute = ({
  visibility,
  forceLogin = true,
  children
}: PropsWithChildren<RestrictedVisibilityRouteProps & RestrictedProps>) => {
  const isVisible = useIsVisible(visibility);

  return (
    <Restricted isVisible={isVisible} forceLogin={forceLogin}>
      {children}
    </Restricted>
  );
};

const RestrictedBookingRoute = ({
  forceLogin = true,
  seekerOnly = false,
  children
}: PropsWithChildren<RestrictedProps & RestrictedBookingRouteProps>) => {
  const { showBookableContent, mayBookOffers } = useUser();
  const isVisible = seekerOnly
    ? showBookableContent && mayBookOffers
    : showBookableContent;

  return (
    <Restricted isVisible={isVisible} forceLogin={forceLogin}>
      {children}
    </Restricted>
  );
};

const ForceAuth = ({ children }: PropsWithChildren) => {
  const { isReady, isAuthenticated, login } = useKeycloakAuth();

  useEffect(() => {
    if (isReady && !isAuthenticated) {
      login();
    }
  }, [isReady, isAuthenticated, login]);

  if (!isAuthenticated) {
    return <LoadingSpinner dataCy="force-auth" />;
  }

  return <>{children ?? <Outlet />}</>;
};

export {
  Restricted,
  RestrictedVisibilityRoute,
  RestrictedBookingRoute,
  ForceAuth
};
