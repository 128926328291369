/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { FormLabel, Stack } from '@mui/material';
import { GeoAreaChip, GeoAreaSelector } from 'components';
import { useSchemaValidationFormContext } from 'feature/forms';
import { getText, getTextIn, required } from 'localization';
import compact from 'lodash/compact';
import map from 'lodash/map';
import { useMemo } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Offer } from './model';

const getMobileLocationText = getTextIn('offer-details-mobileLocation');

const MobileLocationForm = () => {
  const { control } = useSchemaValidationFormContext<Offer>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mobileLocation.locations'
  });

  const geoAreaChips = useMemo(() => {
    return map(fields, (item, index) => {
      return (
        <Controller
          key={item.id}
          control={control}
          name={`mobileLocation.locations.${index}`}
          render={({ field }) => {
            return (
              <GeoAreaChip
                id={field.value.geoAreaId}
                index={index}
                onDelete={() => {
                  remove(index);
                }}
              />
            );
          }}
        />
      );
    });
  }, [control, fields, remove]);

  return (
    <Stack spacing={2}>
      <FormLabel>{getMobileLocationText('hint')}</FormLabel>
      <Controller
        control={control}
        name="mobileLocation.locations"
        render={({ field, fieldState }) => {
          const selectedGeoAreas = compact(map(field.value, 'geoArea'));
          const error = !!fieldState.error;
          const errorMessage = fieldState?.error?.message;

          return (
            <GeoAreaSelector
              label={`${required(getMobileLocationText('availableLocations'))}`}
              selectCaption={getText('add')}
              error={error}
              errorMessage={errorMessage}
              excludedGeoAreas={selectedGeoAreas}
              onGeoAreaSelected={(geoArea) => {
                append({ geoAreaId: geoArea.id });
              }}
            />
          );
        }}
      />

      <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
        {geoAreaChips}
      </Stack>
    </Stack>
  );
};

export default MobileLocationForm;
