/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type EntityModelOffer, type OfferUpdate } from '@SLR/solution3-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useOffersApi, {
  OFFERS_LIST_KEY,
  OFFER_KEY,
  OFFER_LOCATIONS_KEY
} from './useOffersApi';

const useUpdateOffer = (offerId: string) => {
  const queryClient = useQueryClient();
  const { updateOffer } = useOffersApi();
  const projectId = useGetProjectId();

  return useMutation<EntityModelOffer, Error, OfferUpdate>(
    (offerUpdate: OfferUpdate) =>
      updateOffer({ projectId: projectId ?? '', offerId, offerUpdate }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OFFER_KEY, { projectId, offerId }]);
        queryClient.invalidateQueries([
          OFFER_LOCATIONS_KEY,
          { projectId, offerId }
        ]);
        queryClient.invalidateQueries([OFFERS_LIST_KEY]);
        queryClient.invalidateQueries([
          'offerLocations',
          { projectId, offerId }
        ]);
      }
    }
  );
};

export default useUpdateOffer;
