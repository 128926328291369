/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Section } from '@SLR/solution3-sdk';
import { OfferGrid } from 'feature';
import { PreviewSection, useCardPreviewNumber } from 'feature/cards';
import { EditToggle } from 'feature/editor';
import { useGetOffers } from 'feature/hooks';
import { HomePageHeroWrapper, InfoSection } from 'feature/info';
import { useIsVisible } from 'feature/permissions';
import {
  ProcessWrapper,
  useVizLabelsPatient
} from 'feature/process-visualization';
import { useMemo } from 'react';

import { useProject, useSetDocumentTitle } from 'context/project';
import { getPath, getText, getTextIn } from 'localization';

const getInfoText = getTextIn('info');

const SORT_BY = ['created,desc'];

const Home = () => {
  useSetDocumentTitle(getText('home'));
  const { project, isProjectNew } = useProject();
  const offersVisible = useIsVisible(project?.offersVisibility) || undefined;
  const previewNumber = useCardPreviewNumber();
  const patientVizLabels = useVizLabelsPatient();

  const {
    data: offers,
    isLoading,
    isError
  } = useGetOffers({
    size: 4,
    sort: SORT_BY
  });

  const infoPagesLinkButtons = useMemo(
    () => [
      {
        text: getInfoText('patientInfoShort'),
        linkTo: getPath('patientInfo')
      },
      {
        text: getInfoText('providerInfoShort'),
        linkTo: getPath('providerInfo'),
        hide: isProjectNew
      },
      {
        text: getInfoText('doctorInfoShort'),
        linkTo: getPath('doctorInfo')
      }
    ],
    [isProjectNew]
  );

  return (
    <>
      <HomePageHeroWrapper />

      <ProcessWrapper
        processSection={{
          title: getInfoText('howItWorksSectionTitle'),
          section: Section.HowItWorks,
          linkButtons: infoPagesLinkButtons
        }}
        vizLabels={patientVizLabels}
      >
        <>
          <EditToggle />
          <InfoSection section={Section.Introduction} />
        </>
        <InfoSection
          title={getInfoText('whatToAchieveSectionTitle')}
          section={Section.WhatToAchieve}
        />
        {offersVisible && (
          <PreviewSection
            title={getText('newOffers', 'offer')}
            showMoreButtonConfig={{
              to: getPath('offers'),
              text: getText('moreOffers', 'offer')
            }}
            coloredBackground
          >
            <OfferGrid
              isError={isError}
              isLoading={isLoading}
              offers={offers?.embedded?.offers?.slice(0, previewNumber)}
              isPreview
            />
          </PreviewSection>
        )}
      </ProcessWrapper>
    </>
  );
};

export default Home;
