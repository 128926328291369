/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useState } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { Button, Stack } from '@mui/material';
import {
  type EntityModelGeoArea,
  type PagedModelEntityModelGeoArea
} from '@SLR/solution3-sdk';
import { useListGeoAreas, GeoArea } from 'feature/hooks';
import SearchFieldAutocomplete from 'components/search-field-autocomplete';

const getSelectableGeoAreas = (
  listGeoAreas: UseQueryResult<PagedModelEntityModelGeoArea, unknown>,
  excludedGeoAreas: GeoArea[]
) =>
  (listGeoAreas?.data?.embedded?.geoAreas ?? []).filter(
    (area: EntityModelGeoArea) =>
      !excludedGeoAreas.some((ignoredGeoArea) => ignoredGeoArea.id === area.id)
  );

interface Props {
  label: string;
  selectCaption?: string;
  excludedGeoAreas?: GeoArea[];
  error?: boolean;
  errorMessage?: string;
  onGeoAreaSelected: (geoArea: GeoArea) => void;
}

const GeoAreaSelector = ({
  label,
  selectCaption,
  error,
  errorMessage,
  excludedGeoAreas = [],
  onGeoAreaSelected
}: Props) => {
  const [query, setQuery] = useState<string>('');
  const listGeoAreas = useListGeoAreas(query);

  const [selectedGeoArea, setSelectedGeoArea] = useState<EntityModelGeoArea>();

  const selectableOptions = getSelectableGeoAreas(
    listGeoAreas,
    excludedGeoAreas
  );

  const { isFetching } = listGeoAreas;

  const handleSelect = useCallback(() => {
    if (selectedGeoArea) {
      onGeoAreaSelected(selectedGeoArea);
    }
    // Reset search field
    setQuery('');
    setSelectedGeoArea(undefined);
  }, [onGeoAreaSelected, selectedGeoArea]);

  return (
    <Stack direction="row" spacing={2} alignItems="stretch">
      {SearchFieldAutocomplete<EntityModelGeoArea>({
        label,
        query,
        selectableOptions,
        isFetching,
        error,
        errorMessage,
        onQueryChanged: setQuery,
        selectedOptionName: selectedGeoArea?.name ?? '',
        onOptionSelected: setSelectedGeoArea,
        onAccept: handleSelect
      })}

      <Button
        variant="outlined"
        disabled={!selectedGeoArea}
        onClick={handleSelect}
        sx={{ height: 56 }}
        data-cy="geoareaselector-select-button"
      >
        {selectCaption}
      </Button>
    </Stack>
  );
};

export default GeoAreaSelector;
