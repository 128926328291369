/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createTheme, darken, lighten } from '@mui/material';
import { type EntityModelProject } from '@SLR/solution3-sdk';
import { useState, useEffect } from 'react';
import theme, { responsiveTheme } from 'theme/theme';

const useColors = (isLoading: boolean, project?: EntityModelProject) => {
  const [colorTheme, setColorTheme] = useState(theme);
  const [primaryColor, setPrimaryColor] = useState(
    project?.primaryColor ?? responsiveTheme.palette.primary.main
  );
  const [secondaryColor, setSecondaryColor] = useState(
    project?.secondaryColor ?? responsiveTheme.palette.secondary.main
  );

  const resetColors = () => {
    setPrimaryColor(
      project?.primaryColor ?? responsiveTheme.palette.primary.main
    );
    setSecondaryColor(
      project?.secondaryColor ?? responsiveTheme.palette.secondary.main
    );
  };

  useEffect(() => {
    setColorTheme(
      createTheme(theme, {
        palette: {
          primary: {
            main: primaryColor,
            dark: darken(primaryColor, 0.2),
            light: lighten(primaryColor, 0.2),
            calendar: lighten(primaryColor, 0.7),
            background: lighten(primaryColor, 0.9)
          },
          secondary: {
            main: secondaryColor,
            dark: darken(secondaryColor, 0.2),
            light: lighten(secondaryColor, 0.2),
            background: lighten(secondaryColor, 0.9)
          },
          placeholder: {
            main: '#E9E9E9',
            dark: darken('#E9E9E9', 0.5)
          },
          hover: {
            main: 'rgba(0, 0, 0, 0.04)'
          }
        }
      })
    );
  }, [primaryColor, secondaryColor, setColorTheme]);

  useEffect(() => {
    if (project?.primaryColor && project?.secondaryColor) {
      setPrimaryColor(project.primaryColor);
      setSecondaryColor(project.secondaryColor);
    }
  }, [project?.primaryColor, project?.secondaryColor]);

  const themingReady =
    colorTheme.palette.primary.main !== theme.palette.primary.main ||
    project?.primaryColor === undefined;

  return [
    {
      primaryColor,
      secondaryColor,
      setPrimaryColor,
      setSecondaryColor,
      resetColors
    },
    colorTheme,
    themingReady
  ] as const;
};

export default useColors;
