/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { SchemaProvider } from 'feature/forms';
import { DateFilterSchema, type DateFilter } from './model';
import TimeForm from './time-form';
import { addHours, dateToDayjs } from 'utils/date';
import { getText } from 'localization';
import { useBooking } from 'context/booking';
import { useProject } from 'context/project';

interface TimeFormBlockProps {
  defaultFilter: DateFilter;
  dateFilter?: DateFilter;
  onConfirm: (dateFilter: DateFilter) => void;
  onCancel: VoidFunction;
}

const TimeFormBlock = ({
  defaultFilter,
  dateFilter,
  onConfirm,
  onCancel
}: TimeFormBlockProps) => {
  const { maxDateFromNow, minDateFromNow } = useProject();
  const { isOffersBookable } = useBooking();

  const minPointInTimeFromNow = addHours(4);

  return (
    <SchemaProvider<DateFilter>
      defaultValues={defaultFilter}
      values={dateFilter}
      schema={DateFilterSchema(
        maxDateFromNow,
        minDateFromNow,
        minPointInTimeFromNow
      )}
    >
      {({ handleSubmit, getValues }) => {
        // get the minimum value for the rangeTo picker
        const rangeFromValue = dateToDayjs(getValues().rangeFrom);

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            <Stack spacing={4}>
              <TimeForm
                values={dateFilter}
                rangeFromValue={rangeFromValue}
                minPointInTime={dateToDayjs(minPointInTimeFromNow)}
                showOnlyRange={!isOffersBookable}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{
                flexWrap: 'wrap'
              }}
            >
              <Button
                variant="text"
                onClick={onCancel}
                sx={{ mt: 1.5, mb: 1.75, display: { xs: 'flex', md: 'none' } }}
              >
                {getText('removeAll')}
              </Button>

              <Button
                onClick={handleSubmit(onConfirm)}
                sx={{ mt: 1.5, mb: 1.75, width: { xs: 'unset', md: '100%' } }}
              >
                {getText('apply')}
              </Button>
            </Stack>
          </Box>
        );
      }}
    </SchemaProvider>
  );
};

export default TimeFormBlock;
