/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { type ListEventsRequest } from '@SLR/solution3-sdk';
import { useQuery } from '@tanstack/react-query';
import { useGetProjectId } from 'context/project';
import useEventsApi from './useEventsApi';
import { useUser } from 'context/user';

type EventParams = {
  from: Date;
  to: Date;
};

const useListEvents = (params: EventParams) => {
  const { listEvents } = useEventsApi();
  const projectId = useGetProjectId();
  const { perspective } = useUser();

  const organizationId = perspective?.isOrganization
    ? perspective.id
    : undefined;

  const eventRequestParameters: ListEventsRequest = {
    projectId: projectId ?? '',
    organizationId: organizationId ?? '',
    ...params,
    includeReferenced: true
  };

  return useQuery(
    ['events', eventRequestParameters],
    () => {
      return listEvents(eventRequestParameters);
    },
    {
      staleTime: Infinity,
      enabled: !!projectId && !!organizationId,
      select: (data) => data?.embedded?.events
    }
  );
};

export default useListEvents;
