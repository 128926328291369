/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Link as RouterLink, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { ShowProtectedLink } from 'routes';
import { NavLinksBasicProps } from './types';
import { getPath } from 'localization';
import { Typography } from '@mui/material';

// a special case, as this path has the same mainRoute - to avoid this special case, change the route
const useDoNotSelect = () => {
  const location = useLocation();
  return location.pathname.includes(getPath('providerInfo'));
};

interface NavLinksProps extends NavLinksBasicProps {
  variant: string;
  colorUnselected?: string;
}

const NavLinks = ({ navigationLinks, mainRoute, variant }: NavLinksProps) => {
  const doNotSelect = useDoNotSelect();
  const isTopbar = variant === 'topbar';

  return (
    <>
      {navigationLinks.map(({ path, title, icon, protect }) => {
        const isSelected =
          mainRoute === path.split('/')[0].split('?')[0] && !doNotSelect;

        const showProtectedLink = ShowProtectedLink({
          protect
        });

        return (
          showProtectedLink && (
            <Stack
              key={`${title}-${path}`}
              sx={{
                justifyContent: 'flex-end'
              }}
            >
              <Link
                data-cy={`navigationLinks-${variant}-${path}`}
                component={RouterLink}
                to={path}
                color={
                  isSelected
                    ? 'secondary'
                    : `primary${isTopbar ? '.contrastText' : ''}`
                }
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  // sm only occurs to topbar
                  mt: { sm: 0, md: 1 },
                  textDecoration: 'none'
                }}
              >
                <Typography
                  sx={{
                    color: 'inherit',
                    font: 'inherit !important',
                    fontWeight: 'inherit',
                    textAlign: 'center !important',
                    display: '-webkit-box',
                    minWidth: 54,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 180,
                    WebkitLineClamp: isTopbar ? 1 : 2,
                    WebkitBoxOrient: 'vertical',
                    WebkitBoxPack: 'center'
                  }}
                >
                  {icon ?? title}
                </Typography>
              </Link>
              {/* indicator current selected link */}
              <Box
                sx={{
                  // sm only occurs to topbar
                  display: { sm: 'none', md: 'flex' },
                  flexShrink: 1,
                  height: 8,
                  width: '100%',
                  bgcolor: isSelected ? 'secondary.main' : 'none'
                }}
              />
            </Stack>
          )
        );
      })}
    </>
  );
};

export default NavLinks;
