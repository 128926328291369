/*
 * Copyright (C) Fraunhofer IESE 2023-2024 - Alexander Werner, Anna Kleiner,
 * Joshua Ginkel, Stefan Schweitzer, Mher Ter-Tovmasyan, Jordan Gwenet,
 * Timo Höcker, Steffen Hupp, Tobias Dietz
 *
 * SPDX-License-Identifier: Apache-2.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useBooking } from 'context/booking';
import { useProject } from 'context/project';
import TextEditor from 'feature/editor/text-editor';
import {
  ActionsPanel,
  FormWrapper,
  ImageDialogWrapper,
  SchemaProvider,
  ValidatedHtmlEditor,
  ValidatedTextField
} from 'feature/forms';
import { getText, getTextIn, required } from 'localization';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { compareObjectDeepJson } from 'utils/helper';
import { CATEGORIES, TARGET_GROUPS } from 'utils/url-param';
import LocationsForm, {
  FIXED_LOCATION,
  MOBILE_LOCATION,
  ONLINE_LOCATION
} from './locations-form';
import {
  EMPTY_FIXED_LOCATION,
  EMPTY_MOBILE_LOCATION,
  EMPTY_ONLINE_LOCATION,
  Offer,
  OfferSchema
} from './model';
import SegmentsForm from './segments-form';
import TimesForm from './times-form';

const getOfferText = getTextIn('offer-details');

type Props = {
  defaultOffer: Offer;
  offer?: Offer;
  inProgress?: boolean;
  onConfirm: (offer: Offer) => void;
  onCancel: VoidFunction;
};

const LOCATION_TYPES = [MOBILE_LOCATION, FIXED_LOCATION, ONLINE_LOCATION];

const EditableOfferForm = ({
  defaultOffer,
  offer,
  inProgress = false,
  onConfirm,
  onCancel
}: Props) => {
  const [hasLocationError, setHasLocationError] = useState(false);
  const { isOffersBookable } = useBooking();
  const { project } = useProject();
  const hasTargetGroup = project?.features?.targetGroups ?? false;

  return (
    <SchemaProvider<Offer>
      defaultValues={defaultOffer}
      values={offer}
      schema={OfferSchema}
      options={{
        durationRequired: isOffersBookable,
        targetGroupRequired: hasTargetGroup
      }}
    >
      {({ handleSubmit, control, setValue, setValueValidating, getValues }) => {
        const handleClearedSubmit = () => {
          const { mobileLocation, fixedLocation, onlineLocation } = getValues();

          const isEmptyMobileLocation = compareObjectDeepJson(
            mobileLocation,
            EMPTY_MOBILE_LOCATION
          );

          const isEmptyFixedLocation = compareObjectDeepJson(
            fixedLocation,
            EMPTY_FIXED_LOCATION
          );

          const isEmptyOnlineLocation = compareObjectDeepJson(
            onlineLocation,
            EMPTY_ONLINE_LOCATION
          );

          handleSubmit(onConfirm, (errors) => {
            const errorKeys = Object.keys(errors);

            const onlyLocationKeysInErrorKeys = errorKeys.every((key) =>
              LOCATION_TYPES.includes(key)
            );

            const errorsOnlyForEmptyLocations =
              (!errors[MOBILE_LOCATION] || isEmptyMobileLocation) &&
              (!errors[FIXED_LOCATION] || isEmptyFixedLocation) &&
              (!errors[ONLINE_LOCATION] || isEmptyOnlineLocation);

            // remove location errors in the error object, if they were fired on empty locations,
            // but the "at least on location selected" condition has been fulfilled
            if (
              !hasLocationError &&
              (errors[MOBILE_LOCATION] ||
                errors[FIXED_LOCATION] ||
                errors[ONLINE_LOCATION]) &&
              onlyLocationKeysInErrorKeys &&
              errorsOnlyForEmptyLocations
            ) {
              LOCATION_TYPES.forEach((locationType) => {
                if (errors[locationType]) {
                  setValueValidating(locationType, null);
                }
              });

              handleSubmit(onConfirm)();
            }
          })();
        };

        return (
          <>
            <FormWrapper>
              <Stack spacing={4}>
                <Controller
                  name="picture"
                  control={control}
                  render={({ field }) => (
                    <ImageDialogWrapper
                      image={field.value}
                      editTitleText={getOfferText('editPictureTitle')}
                      titleElement={
                        <Typography component="h2" variant="sectionTitle">
                          {getOfferText('pictureTitle')}
                        </Typography>
                      }
                      dataCyPrefix="offerform"
                      onChange={(image) => {
                        setValue(
                          'picture',
                          image === undefined ? offer?.picture : image
                        );
                      }}
                    />
                  )}
                />
                <ValidatedTextField
                  name="title"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        label={`${required(getOfferText('title'))}`}
                        placeholder={getOfferText('titlePlaceholder')}
                        helperText={
                          errorMessage ?? getOfferText('titleHelperText')
                        }
                        inputProps={{
                          maxLength: 100,
                          'data-cy': 'offerform-title'
                        }}
                        {...field}
                        {...fieldProps}
                      />
                    );
                  }}
                />
                <ValidatedHtmlEditor
                  name="description"
                  render={({ field, props }) => {
                    const { error, errorMessage, content, onEditorChange } =
                      props;
                    return (
                      <FormControl>
                        <Typography variant="editorLabel">
                          {getOfferText('descriptionLabel') + ' *'}
                        </Typography>
                        <TextEditor
                          placeholderText={getOfferText(
                            'descriptionPlaceholder'
                          )}
                          content={content}
                          onEditorChange={onEditorChange}
                          showError={error}
                          dataCy="offerform-description"
                          {...field}
                        />
                        {error && (
                          <FormHelperText error={error}>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
                <ValidatedTextField
                  name="requirements"
                  render={({ field, props }) => {
                    const { errorMessage, ...fieldProps } = props;
                    return (
                      <TextField
                        multiline
                        minRows={4}
                        label={getOfferText('requirements')}
                        placeholder={getOfferText('requirementsPlaceholder')}
                        helperText={errorMessage}
                        inputProps={{ 'data-cy': 'offerform-requirements' }}
                        {...field}
                        {...fieldProps}
                      />
                    );
                  }}
                />

                <SegmentsForm segmentType={CATEGORIES} />

                {hasTargetGroup && <SegmentsForm segmentType={TARGET_GROUPS} />}

                <LocationsForm
                  setHasLocationError={setHasLocationError}
                  isEditMode={!!offer}
                />

                <TimesForm />

                <FormControl>
                  <FormHelperText>{getText('requiredFields')}</FormHelperText>
                </FormControl>
              </Stack>
            </FormWrapper>

            <ActionsPanel
              confirmLabel={getOfferText('saveOffer')}
              inProgress={inProgress}
              onConfirm={handleClearedSubmit}
              onCancel={onCancel}
            />
          </>
        );
      }}
    </SchemaProvider>
  );
};

export default EditableOfferForm;
